import React, { useRef, useState, useEffect } from 'react'
import InputField from '../../components/InputField'
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { useApi } from '../../contexts/ApiProvider';
import { useFlash } from '../../contexts/FlashProvider';
import { useNavigate } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import NewCampaignPage from '../../components/NewCampaignPage';
import LoginModal from '../../components/LoginModal';
// import { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import styles
import '../../styles/DescriptionQuill.css'
import DescriptionQuill from '../../components/DescriptionQuill';
import ImageModal from '../../components/ImageModal';



function NewCampaignDescription() {



    const [title, setTitle] = useState('')



    const imageField = useRef();
    const titleField = useRef();
    const [formErrors, setFormErrors] = useState({});
    const api = useApi()
    const flash = useFlash()
    const navigate = useNavigate()
    const [showModal, setShowModal] = useState(false)
    const quillRef = useRef();
    const youtubeURLField = useRef();


    // const [selectedFiles, setSelectedFiles] = useState([]);
    const [base64Images, setBase64Images] = useState([]);

    const [modalImage, setModalImage] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);

    const handlShowImageModal = (image) => {


        setModalImage(base64Images[image]);
        setShowImageModal(true)

    }
    const handlHideImageModal = (image) => {
        setModalImage();
        setShowImageModal(false)

    }



    const handleRemoveImage = (index) => {
        const updatedBase64Images = base64Images.filter((_, i) => i !== index);
        // const updatedSelectedFiles = selectedFiles.filter((_, i) => i !== index);
        setBase64Images(updatedBase64Images);
        // setSelectedFiles(updatedSelectedFiles);
    };


    const handleFileChange = (event) => {
        var files = Array.from(event.target.files);
        // files.concat(base64Images)
        if (files.length > 3) {
            alert('You can only upload up to 3 images.');
            return;
        }




        // setSelectedFiles(files);TODO:IMPORTANT: CHANGED FROM MAP
        const promises = files.forEach((file) =>
            convertToBase64(file)



        );

        Promise.all(promises).then((base64Strings) => {
            setBase64Images((prev) => [...prev, base64Strings]);
        });
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };



    const handleTitleChange = (e) => {
        e.preventDefault()
        setTitle(e.target.value)
    }



    const handleClose = () => setShowModal(false)

    const onSubmit = async (ev) => {
        ev.preventDefault();
        // const description = descriptionField.current.value;
        // const title = titleField.current.value;
        const youtubeUrl = youtubeURLField.current.value;



        //client sid field validation
        const errors = {};
        // if (!description) {
        //     errors.description = 'Description must not be empty.';
        // }
        if (!title) {
            errors.title = 'Title must not be empty.';
        }

        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }
        // end client side field validation 


        (async () => {
            const quillEditor = quillRef.current.getEditor();
            const content = quillEditor.root.innerHTML;
            const response = await api.post(`/new_campaign/description`, JSON.stringify({ description: content, title: title, youtube_url: youtubeUrl, images: base64Images }));
            if (response.ok) {
                flash('Your description and title were set', 'success');
                navigate(`/new_campaign/confirmation`, { replace: true });

            }
            else {
                if ("errors" in response.body) {
                    setFormErrors(() => response.body.errors.json);
                    flash(response.body.errors.json.message, 'danger');
                } else {
                    flash('There was an error sending your message', 'danger');
                }
                // "errors" in response.body ?
                //     (setFormErrors(() => response.body.errors.json),
                //         flash(response.body.errors.json.message, 'danger'))
                //     :
                //     flash('There was an error sending your message', 'danger')



            }
        })();


    };



    useEffect(() => {
        // setRequestSent(true);
        titleField.current && titleField.current.focus();

        (async () => {

            const response = await api.get(`/new_campaign/description`);
            if (response.ok) {

                // descriptionField.current.value = response.body.description
                if (quillRef.current) {
                    const editor = quillRef.current.getEditor();
                    const initialContent = response.body.description;
                    const delta = editor.clipboard.convert(initialContent);
                    editor.setContents(delta, 'silent');
                }
                setTitle(response.body.title)

                // imageField.current.value = response.body.image
                setBase64Images(response.body.images)
                youtubeURLField.current.value = response.body.youtube_url


            }
            else {
                'errors' in response.body ?
                    flash(response.body.errors.json.message, 'danger')
                    :
                    flash("Error with request", 'danger')
                // setRequestSent(false)
                return navigate('/')
            }

        })();



    }, [api, flash])

    // useEffect(() => {
    //     if (quillRef.current) {
    //         const quillEditor = quillRef.current.getEditor();
    //         // Apply the default color to the initial content
    //         const defaultText = quillEditor.getText();
    //         if (defaultText.trim().length === 0) {
    //             quillEditor.format('color', "#ffffff"); // Set default text color to white
    //         }
    //     }
    // }, []);


    const pageDescription = <div>
        <ul>
            <li>
                <p>Titles help users search for and understand your campaign and are required</p>
            </li>


        </ul>
    </div>

    const HELP_HEADER = <h6>Setting your title and description:</h6>

    return (

        <NewCampaignPage currentStep={4} handleSubmit={onSubmit} title="Enter a title and description for your campaign" description={pageDescription} helpHeader={HELP_HEADER}>



            <Row style={{ justifyContent: 'center' }}>
                <ImageModal image={modalImage} show={showImageModal} hide={handlHideImageModal} />

                {
                    showModal && <LoginModal handleClose={handleClose} showModal={showModal} setShowModal={setShowModal} />

                }

                <Col sm={12} lg={5}>
                    <Form onSubmit={onSubmit}>
                        {/* <Row className="">
                            <Col xs={12} md={8} lg={7}> */}

                        <InputField
                            formLabel={<strong>Title<span className="text-danger">**</span></strong>}
                            value={title ? title : ''}
                            onChange={handleTitleChange}
                            name="title"
                            label="Title"
                            error={formErrors.title}
                            formText={'Cannot be changed once campaign is submitted. Must be between 3 and 90 charachters.'}
                            charRange={{ 'min': 3, 'max': 90 }}

                        />

                        <DescriptionQuill
                            formErrors={formErrors}
                            quillRef={quillRef}
                        />




                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label><strong>Upload images (up to 3)</strong></Form.Label>
                            <Form.Control onChange={handleFileChange} accept="image/*" ref={imageField} type="file" />
                            <Form.Text>Optional*This will appear in the description of your campaign</Form.Text>
                            <div>{formErrors && 'images' in formErrors && <Form.Text className="text-danger">{formErrors['images']}</Form.Text>}</div>
                            <Row>

                                {base64Images.map((image, index) => (
                                    <Col key={index}>
                                        <div style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
                                            {/* <img onClick={() => handlShowImageModal(image)} src={image} alt={`uploaded`} style={{ maxWidth: '200px' }} /> */}
                                            <Image
                                                src={image}
                                                style={{
                                                    width: "100%",
                                                    height: "200px",
                                                    objectFit: 'contain',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => handlShowImageModal(index)}
                                            />


                                        </div>
                                        <div>
                                            <Button
                                                onClick={() => handleRemoveImage(index)}
                                                className="rounded-pill"
                                                variant='danger'
                                            >
                                                <strong>Remove</strong>
                                            </Button>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        </Form.Group>

                        <InputField
                            formLabel={<strong>Youtube URL</strong>}
                            fieldRef={youtubeURLField}
                            name="youtube_url"
                            label="Youtube URL"
                            error={formErrors.youtube_url}
                            formText="Optional* This will appear in the description of your campaign"
                        />

                    </Form>
                </Col>
            </Row>

        </NewCampaignPage>
    )
}

export default NewCampaignDescription
