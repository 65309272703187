import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/esm/Button';
import InfoPopover from './InfoPopover';
import Popover from 'react-bootstrap/Popover';

function PaymentAmount({ amount, setAmount }) {

    const handleChange = (e) => {

        //post new amount
        setAmount(() => e.target.value)
    }
    const buttonStyle = {
        fontSize: '1.5rem',
        margin: '.5rem',
        fontWeight: '800',
        width: '100%'
    }

    return (
        <Row className="my-3">
            <Col xs={12} md={12} >
                <Form.Label>
                    <h5><strong>Max Contribution Amount</strong><strong className="text-danger"> * </strong>
                        <span >
                            <InfoPopover popover={
                                <Popover id={`popover-basic-`}>
                                    <Popover.Body>
                                        <ul className="fs-6">
                                            <li>
                                                The maximum you will be charged if the campaign is funded.
                                            </li>
                                            <li>
                                                You are not charged at checkout
                                            </li>
                                            <li>
                                                If the campaign raises enough to purchase media, you are charged after the fundraising period
                                            </li>
                                            <li>
                                                If the campaign raises more than the cost, your payment is reduced.
                                            </li>
                                        </ul>
                                    </Popover.Body>
                                </Popover>
                            }
                            />
                        </span>
                    </h5>
                </Form.Label>
                <Row className="mx-0 my-2">
                    <Col>
                        <Button onClick={() => setAmount(50)} style={buttonStyle} variant='dark'>
                            $50
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => setAmount(100)} style={buttonStyle} variant='dark'>
                            $100
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => setAmount(250)} style={buttonStyle} variant='dark'>
                            $250
                        </Button>
                    </Col>
                    <Col>
                        <Button onClick={() => setAmount(1000)} style={buttonStyle} variant='dark'>
                            $1,000
                        </Button>
                    </Col>
                </Row>
                <InputGroup style={{ fontWeight: '800', borderRadius: '1.5rem' }}>
                    <InputGroup.Text style={{ fontWeight: '800', fontSize: '1.5rem', }}>$</InputGroup.Text>
                    <Form.Control style={{ fontWeight: '800', fontSize: '1.5rem' }} className="p-3" onChange={handleChange} value={amount} aria-label="Amount (to the nearest dollar)" />
                    <InputGroup.Text style={{ fontWeight: '800', fontSize: '2rem' }}>.00</InputGroup.Text>
                </InputGroup>
            </Col>

            <Col xs={12}>
                <div>{<Form.Text className=""><strong>Payment is processed at the end of the fundraising period, if enough money is raised to purchase media.</strong></Form.Text>}</div>

            </Col>

        </Row>
    )
}

export default PaymentAmount
