import React, { useState } from 'react'
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Campaign from './Campaign';
import ConfirmDeleteModal from './ConfirmDeleteModal';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';



function CampaignRow({ campaigns }) {

    const [showModal, setShowModal] = useState(false);
    const api = useApi();
    const flash = useFlash();
    const [deleteID, setDeleteID] = useState(false)

    const handleShowModal = (campaign_id) => {
        setDeleteID(campaign_id)
        setShowModal(true);
    }

    const handleHideModal = () => setShowModal(false);

    const handleDeleteCampaign = (campaign_id) => {


        (async () => {
            setShowModal(true);
            const response = await api.delete(`/new_campaign/delete-campaign/${campaign_id}`);
            if (response.ok) {
                console.log(response);
                setShowModal(false);
                flash('Deleted campaign', 'success')


            }
            else {
                flash('Error with your request', 'danger')
                setShowModal(false);
            }
        })().catch(() => { setShowModal(false); flash('Error with request', 'danger') });

    }


    return (
        campaigns === undefined ?

            <div className="mt-5 d-flex justify-content-center"><Spinner animation="border" /></div>
            :
            <>
                {campaigns === null ?
                    <p>Could not retrieve blog campaigns.</p>
                    :
                    campaigns.length > 0 ?
                        <Row>
                            <ConfirmDeleteModal deleteID={deleteID} handleDeleteCampaign={handleDeleteCampaign} showModal={showModal} handleShowModal={handleShowModal} handleHideModal={handleHideModal} />

                            {campaigns.map((campaign, index) =>
                                <div key={index}>
                                    <Campaign showModal={showModal} campaign={campaign} isLoading={false} handleShowModal={handleShowModal} handleHideModal={handleHideModal} />
                                </div>
                            )}
                        </Row>

                        :
                        <div className="mt-5 d-flex justify-content-center"><h4>You have no campaigns yet   </h4></div>
                }
            </>




    )
}

export default CampaignRow
