import Form from 'react-bootstrap/Form';


export default function TextAreaField(
    { name, label, type, placeholder, error, fieldRef, formText, as, additionalFields, rows, onChange, value, formLabel, onFocus, onBlur, charRange }
) {
    return (

        <Form.Group className="mb-3 " controlId="exampleForm.ControlTextarea1">
            {formLabel &&
                <Form.Label
                >{formLabel}</Form.Label>
            }
            <Form.Control value={value} placeholder={placeholder} ref={fieldRef} as="textarea" rows={!rows ? 2 : rows} onChange={onChange} onFocus={onFocus}
                onBlur={onBlur} />
            <div>{formText && <Form.Text>{formText}</Form.Text>}</div>
            <div><Form.Text className="text-danger">{error}</Form.Text></div>
            {
                charRange ?
                    value && value.length <= charRange['max'] && charRange['min'] <= value.length ?
                        <div style={{ textAlign: 'right' }}><Form.Text className="text-secondary">{value && value.length}</Form.Text></div>
                        :
                        <div style={{ textAlign: 'right' }}><Form.Text className="text-danger">{value && value.length}{charRange}</Form.Text></div>
                    :
                    <div></div>
            }
        </Form.Group>
        // <Form.Group controlId={name} className="InputField">
        //   {label && <Form.Label>{label}</Form.Label>}
        //   <Form.Control
        //     type={type || 'text'}
        //     placeholder={placeholder}
        //     ref={fieldRef}
        //     as={as}
        //     {...additionalFields}
        //   />
        // </Form.Group>
    );
}