import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link, } from 'react-router-dom';
import InstagramIcon from './InstagramIcon';
import XIcon from './XIcon';
import YoutubeIcon from './YoutubeIcon';
import SocialMediaIcon from './SocialMediaIcon';
import { discordIcon } from '../utils/staticUrls'

function Footer() {
    const footerStyle = {
        marginTop: 'auto',
        textAlign: 'center',
        borderTop: "1px groove"
    };

    return (




        <footer style={{ marginTop: '8 rem' }}>
            <Row style={footerStyle} className="mx-3 m-5" >
                <Col className="fs-5 my-3">
                    <Link className="me-3" to="/how-it-works">How it works</Link>
                    <span className="me-3">|</span>
                    <Link className="me-3" to="/terms-of-service">Terms of service</Link>
                    <span className="me-3">|</span>
                    <Link className="me-3" to="/privacy-policy">Privacy Policy</Link>
                    <span className="me-3">|</span>
                    <Link className="me-3" to="/contact">Contact us</Link>
                </Col>
                <Col className="pb-3" xs={12}>
                    <div className="fs-5" style={{ color: "white" }}>
                        <a href="mailto:info@crowdpost.com">info@crowdpost.com</a> | <a href="tel:18889219011">888-921-9011</a> | <a href="https://www.instagram.com/crowdpostmedia/" target="_blank" rel="noopener noreferrer" >

                            <InstagramIcon />
                        </a>
                        <span className="mx-1">|</span>
                        <a href="https://x.com/CrowdPostMedia" target="_blank" rel="noopener noreferrer" >
                            <XIcon />
                        </a>
                        <span className="mx-1">|</span>
                        <a href="https://www.youtube.com/@CrowdPost" target="_blank" rel="noopener noreferrer" >
                            <YoutubeIcon />
                        </a>
                        <span className="mx-1">|</span>
                        <a href="https://discord.gg/GcXbBSHd" target="_blank" rel="noopener noreferrer" >
                            <SocialMediaIcon iconPath={discordIcon} alt={'Discord server icon'} />
                        </a>
                    </div>

                </Col>
                <Col className="pb-3" xs={12}>
                    <div className="fs-5" style={{ color: "white" }}>
                        By using this site, you are agreeing by the site's <Link to="/terms-of-service">terms of use</Link> and <Link to="/privacy-policy">privacy policy</Link>. <Link to="/refund-policy">Refund Policy</Link>
                    </div>
                    <div className="text-secondary small">
                        © CrowdPost Media LLC
                    </div>
                </Col>
            </Row>
        </footer >



    );
};



export default Footer
