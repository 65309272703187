import React from 'react'
import Body from '../components/Body'
import { Helmet } from 'react-helmet';
import { useUser } from '../contexts/UserProvider'
import { useNavigate } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { PiTruckDuotone } from "react-icons/pi";
import CircleIcon from './CircleIcon';
import CardRowGroup from '../components/CardRowGroup';
import YoutubeVideo from '../components/YoutubeVideo';
import { BsChatLeftTextFill } from "react-icons/bs";
import { LuSettings2 } from "react-icons/lu";
import { RiImageEditLine } from "react-icons/ri";
import { RiAiGenerate } from "react-icons/ri";
import youtubeUrls from '../utils/youtubeUrls';
import { billboardSvg } from '../utils/staticUrls';
import PricingTable from '../components/PricingTable';


function AIImagePage() {


    const { createCampaign } = useUser();
    const navigate = useNavigate()


    const ICON_SIZE = 26

    const timeframes = [
        {
            label: "Choose your media type",
            subText: ['Your contribution is not charged yet', 'Contributions are processed at the end of the campaign fundraising period if the campaign raises enought to purchase media.',],
            icon: <div >
                <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><PiTruckDuotone size={ICON_SIZE} color="white" /> </CircleIcon>
                <strong className="mx-2">|</strong>
                <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><img src={billboardSvg} alt="My Icon" width="24" height="24" /> </CircleIcon>

            </div>
        },
        {
            label: "Enter a text prompt up to 4,000 charachters",
            subText: ['Search for a point of interest and set a radius around it', 'Draw a custom area on the map', 'Optionally name your camapign areas'],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><BsChatLeftTextFill size={ICON_SIZE} color="white" /> </CircleIcon>
        },
        {
            label: "Add additional image settings",
            subText: ['Title your campaign', 'Add an optional description about your campaign', 'You can link a youtube video', 'You can add up to 3 description images'],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><LuSettings2 size={ICON_SIZE} color="white" /> </CircleIcon>
        },
        {
            label: "Use your image",
            subText: ['Receive payment and campaign status updates.'],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><RiImageEditLine size={ICON_SIZE} color="white" /> </CircleIcon>
        },
        {
            label: "Create a mockup billboard or billboard truck",
            subText: ['Title your campaign', 'Add an optional description about your campaign', 'You can link a youtube video', 'You can add up to 3 description images'],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><RiAiGenerate size={ICON_SIZE} color="white" /> </CircleIcon>
        }
    ];


    const handleNewCampaign = async () => {
        const proceed = await createCampaign();
        //This used to throw an error
        if (proceed) {
            navigate('/new_campaign/campaign_type', { replace: true })
        }
    }




    return (
        <>
            <Helmet>
                <title>CrowdPost - Generate AI Images for your CrowdPost campaigns</title>
                <meta property="og:title" content={`Let your voice reach the real town square`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
                {/* <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." /> */}

            </Helmet>
            <Body footer>



                <Row className="" >
                    <Col xs={12} className="text-center"
                    >
                        <h1 className="pricingHeader">Generate AI Images</h1>



                    </Col>
                </Row>
                <Row className="justify-content-center my-5">
                    <Col xs={{ span: 12 }} lg={{ span: 9, }}>


                        <YoutubeVideo
                            url={youtubeUrls.AI_IMGS_CAMPAIGNS}
                            title={"Contributing to  campaigns"}
                        />


                    </Col>
                    <Col className="d-flex justify-content-center" xs={12}>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <Button
                                variant="success"
                                className="my-2 rounded-pill d-flex align-items-center justify-content-center px-4 py-2 w-100" // Apply the Flexbox styles here
                                onClick={() => handleNewCampaign()}
                            >
                                <h2 className="m-0"><strong>Start a Campaign</strong></h2>
                            </Button>
                        </div>
                    </Col>



                </Row>
                <CardRowGroup timeframes={timeframes} />
                <Row>
                    <Col xs={12} className="text-center"
                    >
                        <h1 className="pricingHeader">Pricing</h1>
                    </Col>
                    <Col xs={12} className="text-center"
                    >
                        <PricingTable />
                    </Col>
                </Row>

            </Body >


        </>
    )
}

export default AIImagePage