import React from 'react'
import Button from 'react-bootstrap/esm/Button';





import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';



function ImageModal({ show, hide, image }) {
    return (
        <Modal size="lg" show={show} onHide={hide} >
            {image &&

                <Image src={image} style={{ width: "100%", height: "450px", objectFit: 'contain' }} />
            }
            <Modal.Footer>
                <Button variant="secondary" onClick={hide} >Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ImageModal
