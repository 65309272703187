import React from 'react';
import Badge from 'react-bootstrap/Badge';

const CircleBadge = ({ number }) => {
  const circleStyle = {
    width: '40px',
    height: '40px',
    borderRadius: '50%',
    backgroundColor: 'red',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'white',
    fontSize: '1.5rem',
    marginRight: '.5em'
  };

  return (
    <Badge bg='success' style={circleStyle}>
      {number}
    </Badge>
  );
};

export default CircleBadge;
