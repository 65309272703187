import React from 'react'
import Placeholder from 'react-bootstrap/Placeholder';
import Card from 'react-bootstrap/Card';

function ConfirmationComponent({ children, value, name, disableTitle, header }) {

    return (
        <>
            {
                header && name !== 'mockup' &&
                <h4 className="fw-bold">{header}</h4>
            }

            {
                value === undefined ?
                    <div>
                        {name === 'mockup' && <h4 className="fw-bold">Mockup</h4>}
                        <Placeholder as={Card.Title} animation="glow">
                            <Placeholder className="rounded p-3" xs={6} lg={3} />
                        </Placeholder>
                    </div>
                    :
                    value === false ?
                        <p className="text-danger">No {name} set</p>
                        :
                        children

            }

        </>
    )
}

export default ConfirmationComponent
