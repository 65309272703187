import React from 'react'
import Button from 'react-bootstrap/Button'
import { useApi } from '../contexts/ApiProvider';


function FollowButton({ url, isFollowing,handleRefresh }) {
    const api = useApi();

    // const [follow, setFollow] = useState(isFollowing);

    console.log('button', isFollowing)

    const handleUnFollow = () => {
        (async () => {
            const response = await api.delete(`/me/following/${url}`);
            if (response.ok) {
                // setFollow(false)
                handleRefresh()
            }
            else {
                // setFollow(null);
                handleRefresh()
            }
        })();
        
    }

    const handleFollow = () => {
        (async () => {
            const response = await api.post(`/me/following/${url}`);
            if (response.ok) {
                // setFollow(true)
                handleRefresh()

            }
            else {
                // setFollow(null);
            }
        })();

    }


    return (
        <>
            {
                isFollowing ?
                    <Button onClick={handleUnFollow} size="sm" variant="outline-primary">
                        Unfollow
                    </Button>
                    :
                    <Button onClick={handleFollow} size="sm" variant="outline-primary">
                        Follow
                    </Button>
            }
        </>

    )
}

export default FollowButton
