import React from 'react'
import GetPrice from './GetPrice'

function PricingComponent({ partial_estimated_min_price, full_estimated_min_price }) {
    return (

        partial_estimated_min_price &&
        <>

            <h5>
                <strong>Estimated pricing</strong>
            </h5>
            <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '.5em' }}>Minimum needed to be posted: </div>

                <GetPrice justPrice priceInCents={partial_estimated_min_price} />
            </div>
            {full_estimated_min_price &&
                <div style={{ display: 'flex' }}>
                    <div style={{ marginRight: '.5em' }}>Minimum for media in all areas: </div>
                    <GetPrice justPrice priceInCents={full_estimated_min_price} />
                </div>


            }

        </>


    )
}

export default PricingComponent
