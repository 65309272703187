import React from 'react';
import LoginPage from '../pages/LoginPage';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';


function LoginModal({ showModal, handleClose }) {

    return (
        <div>
            {
                showModal &&
                <Row>
                    <Col xs={12}>
                        <Modal size="lg" onHide={handleClose} show={showModal}>
                            <Modal.Header closeButton>
                                <Modal.Title >
                                    <h1>Login</h1>
                                </Modal.Title>
                            </Modal.Header>
                            <Modal.Body >
                                <LoginPage modal={true} handleClose={handleClose} />
                            </Modal.Body>
                        </Modal>
                    </Col>
                </Row>

            }
        </div>
    )
}

export default LoginModal
