const BASE_API_URL = process.env.REACT_APP_BASE_API_URL;


export default class MicroblogApiClient {


  constructor(onError) {
    this.onError = onError
    this.base_url = BASE_API_URL + '/api';

  }



  async request(options) {
    //handle refresh token process TODO: THIS MIGHT HAVE TO BE IN A TRY CATCH AND MAKE REQ IF IT FAILS
    let response = await this.requestInternal(options);

    // console.log('heres the main req',response)

    var refreshResponse
    if (response) {
      // console.log('INTERNAL REQUEST RESPONSE', response)
    }
    if (response.status === 401 && options.url !== '/tokens') {

      //DO REQUEST AND RETRY IF STATUS CODE IS 401. ALL ROUTES EXCEPT /TOKENS ROUTE
      try {
        // alert('refresh');
        refreshResponse = await this.put('/tokens', {
          access_token: localStorage.getItem('accessToken'),
        });
        // alert(100)


      } catch (error) {
        console.warn(error);


        refreshResponse = false
      }
      finally {
        if (refreshResponse && refreshResponse.status === 401) {

          // if  ( (refreshResponse && refreshResponse.status === 401) && ((refreshResponse.body.errors.json.message === 'Invalid refresh token') ||  (refreshResponse.body.errors.json.message === 'Invalid credentials') ) ) {
          // console.log(refreshResponse)

          this.logout()
          window.localStorage.removeItem('accessToken');
          return response
        }

        if (refreshResponse && refreshResponse.ok) {


          // console.log(`Access token: ${refreshResponse.body.access_token}`)



          localStorage.setItem('accessToken', refreshResponse.body.access_token)
          // alert('valid resp');
          response = await this.requestInternal(options);

        }
      }

    }

    if ((!response) || (response.status === 500) && (this.onError)) {

      this.onError(response);
    }
    // console.log(response)
    return response;
  }


  async requestInternal(options) {
    //request sending logic
    // console.log('internal req options', options)
    if (options.url.includes('new_campaign')) {
      // alert('0');
      var campaign_id = window.localStorage.getItem("campaignID")
      // console.log(campaign_id, 'CAMPAIGN ID');
      if (campaign_id) {

        if ((options.query === null) || (options.query === undefined) || !('query' in options)) {

          options.query = { 'campaign_id': campaign_id }
        }
        else {

          // options.query = options.query + `&campaign_id=${campaign_id}`
          options['query']['campaign_id'] = campaign_id
        }

      }


    }


    let query = new URLSearchParams(options.query || {}).toString();
    if (query !== '') {
      query = '?' + query;
    }


    let response;

    try {


      response = await fetch(this.base_url + options.url + query, {
        method: options.method,
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('accessToken')}`,
          ...options.headers,
        },
        // credentials:'include',
        credentials: ((options.url === '/tokens') || (options.url.includes('/google-auth'))) ? 'include' : 'omit',
        body: options.body ? JSON.stringify(options.body) : null,
      });

      // console.log(response, 'after 1')
      // if (response) {
      //   console.log(response, 'alert92')
      // }


    }
    catch (error) {
      // console.log(error, " Error was caught. ", response)
      if (response && response.status === 401 && options.url !== '/tokens') {
        return response
      }
      else {
        return {
          ok: false,
          status: 500,
          body: {
            code: 500,
            message: 'The server is unresponsive',
            description: "Hi",
          },
          json: (async () => {
            return {
              code: 500,
              message: 'The server is unresponsive',
              description: "Hi",
            };
          })
        };

      }

      // response = {
      //   ok: false,
      //   status: 500,
      //   json: (async () => {
      //     return {
      //       code: 500,
      //       message: 'The server is unresponsive3',
      //       description: "Hi",
      //     };
      //   })
      // };


    }

    // console.log('responseBody from finally', response)
    var responseBody
    try {

      responseBody = response.status === 204 ?
        ''
        :
        response.status === 500 ?
          await response.json()
          :
          await response.json()
    }
    catch {
      responseBody = { errors: { 'json': { 'message': 'Internal server error' } } }
    }


    return {
      ok: response.ok,
      status: response.status,
      body: responseBody
    };
    // let responseBody = response.status === 500 ? await response.json() : await response.json()

    // return {
    //   ok: response.ok,
    //   status: response.status,
    //   body: responseBody
    // };




  }

  async get(url, query, options) {
    return this.request({ method: 'GET', url, query, ...options });
  }

  async post(url, body, options) {
    return this.request({ method: 'POST', url, body, ...options });
  }

  async put(url, body, options) {
    return this.request({ method: 'PUT', url, body, ...options });
  }

  async delete(url, options) {
    return this.request({ method: 'DELETE', url, ...options });
  }

  async login(username, password) {

    //gets access token from flask. stores in in local storage else gives alert. refresh is set in http only cookie via flask. 
    var response
    try {

      response = await this.post('/tokens', null, {
        headers: {
          'Authorization': "Basic " + btoa(username + ":" + password)
        }
      });
      if (!response.ok) {

        return 'errors' in response.body ? { "resp": "fail", 'error': response.body.errors.json } : "Unknown error"
        // return response.status === 401 ? { "resp": "fail", 'error': response.body.errors.json } : "Unknown error"
      }

      localStorage.removeItem('accessToken');
      localStorage.setItem('accessToken', response.body.access_token);
      let resp = { "resp": 'ok', 'error': null };

      if ('new_user_greeting' in response.body && response.body.new_user_greeting === true) {
        resp['new_user_greeting'] = true
      }
      return resp;
    }
    catch {
      // if ( response && 'errors' in response)
      // let resp = { "resp": 'ok', 'error': null };
      return
    }
  }



  async logout() {
    //gets access token from flask. stores in in local storage else gives alert. refresh is set in http only cookie via flask. 
    await this.delete('/tokens')
    localStorage.removeItem('accessToken');
    localStorage.removeItem('campaignID');
    localStorage.removeItem('taskID');
  }

  isAuthenticated() {
    return localStorage.getItem('accessToken') !== null;
  }

  // loginCheck() {
  //   this.get('/am-valid')
  //   return localStorage.getItem('accessToken') !== null;
  // }




}