import React, { useState, useEffect, useRef } from 'react';
import Placeholder from 'react-bootstrap/Placeholder';
import { useApi } from "../../contexts/ApiProvider";
import { useNavigate } from 'react-router'
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Image from 'react-bootstrap/Image';
import FormButtonBlock from '../../components/FormButtonBlock';
import { useFlash } from '../../contexts/FlashProvider';
import Form from 'react-bootstrap/Form';
import { Link, } from 'react-router-dom';
import NewCampaignPage from '../../components/NewCampaignPage';
import Stack from 'react-bootstrap/Stack';
import CampaignConfirmationMap from '../../components/CampaignConfirmationMap';
import ConfirmationComponent from './ConfirmationComponent';
import { useUser } from '../../contexts/UserProvider';
import YoutubeComponent from '../../components/YoutubeComponent';
import Card from 'react-bootstrap/Card';


function NewCampaignConfirmation() {

    const api = useApi();
    const navigate = useNavigate();
    // const { campaignId } = useParams()
    const [images, setImages] = useState();
    const user = useUser()
    const timeoutId = useRef();
    const [mapLocation, setMapLocation] = useState(false);

    const [taskIds, setTaskIds] = useState();


    const [mode, setMode] = useState();
    const [description, setDescription] = useState();

    const [terms, setTerms] = useState(false);

    const [startDate, setStartDate] = useState();

    const [mockup, setMockup] = useState();
    const [formComplete, setFormComplete] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const flash = useFlash();


    // const [isMobile, setIsMobile] = useState(false);

    // const MOBILE_BREAKPOINT = 991

    // useEffect(() => {
    //     const checkMobileView = () => {
    //         if (window.innerWidth <= MOBILE_BREAKPOINT) {

    //             setIsMobile(true);


    //         }

    //     };

    //     checkMobileView(); // Check on component mount
    //     window.addEventListener('resize', checkMobileView); // Check on window resize

    //     return () => window.removeEventListener('resize', checkMobileView);
    // }, []);





    // const handleTaskTimer = () => {


    //     // ```This is necessary to query the task id endpoint to return the mockup image from the task que```


    //     if (window.localStorage.getItem('currentMockupTaskId')) {

    //         let task_id = window.localStorage.getItem('currentMockupTaskId')

    //         let fetchData = (async () => {


    //             const response = await api.get(`/new_campaign/get-active-mockup-image/${task_id}`);

    //             if (response.ok) {

    //                 if (response.body.success === true) {
    //                     console.log('success', response.body)

    //                     setMockup(response.body.mockup_url);
    //                     setImages(response.body.images);
    //                     setIsLoading(false)
    //                     window.localStorage.removeItem('currentMockupTaskId');
    //                     flash('Your preview mockup was loaded', 'success')
    //                     clearInterval(timeoutId.current)
    //                     return

    //                 }
    //                 else if (!response.body.success) {
    //                     timeoutId.current = setTimeout(fetchData, 2000);
    //                 }
    //                 else {
    //                     alert('Invlaid data')
    //                     clearInterval(timeoutId.current)
    //                     window.localStorage.removeItem('currentMockupTaskId');
    //                     return

    //                 }
    //             }
    //             else {

    //                 // console.log(response)
    //                 //                     alert(1)

    //                 // setMockup(false);
    //                 window.localStorage.removeItem('currentMockupTaskId');
    //                 clearInterval(timeoutId.current)
    //                 return

    //             }

    //         });


    //         fetchData();


    //     }
    //     else {

    //         setMockup(false)

    //     }

    // }

    const handleTaskReq = (taskId, intervalId) => {


        // ```This is necessary to query the task id endpoint to return the mockup image from the task que. taskID:{type,task_id}```

        (async () => {
            const response = await api.get(`/new_campaign/get-active-mockup-image/${taskId['task_id']}`);
            if (response.ok) {

                if (response.body.success === true) {

                    (async () => {

                        const response = await api.get(`/new_campaign/image_confirmation`);
                        if (response.ok) {


                            setMockup(response.body.mockup_url);

                            setImages(response.body.images)
                            flash('Your campaign media was loaded', 'success')


                        }
                        else {
                            setMockup(false);
                            setImages(false);
                        }

                    })();


                    // console.log('success', response.body)

                    // // setMockup(response.body.mockup_url);
                    // setMockup(response.body.data)
                    // // setMockup(response.body.mockup_url);
                    // // setImages(response.body.images);
                    // setIsLoading(false)
                    // flash('Your preview mockup was loaded', 'success')
                    // clearInterval(timeoutId.current)
                    // return response.body.data

                }


            }
            else {
                // return false
                // clearInterval(currentIntervalId)
                // counter = counter + 1
                // handleMockupLoading(false);
                // console.log('error');
                // console.log(response);
                // 'body' in response && 'errors' in response.body ?
                //     flash(response.body.errors.json.message, 'danger')
                //     :
                //     flash('Error with request', 'danger')
                var updatedTaskIds = taskIds.filter((e) => e.task_id !== taskId['task_id'])
                setTaskIds(updatedTaskIds)
                clearInterval(intervalId)
                return response.body

            }

        })();



    }


    const handleuseTaskTimer2 = () => {


        // ```This is necessary to query the task id endpoint to return the mockup image from the task que```
        let currentIntervalId = setInterval(() => {
            if (taskIds && taskIds.length > 0) {
                taskIds.forEach((v, i) => {
                    var resp = handleTaskReq(v, currentIntervalId);
                    console.warn(resp, 'timer resp')
                });
            }
        }, 3000)

    }

    useEffect(() => {

        if (taskIds && taskIds.length > 0) {
            handleuseTaskTimer2()
        }
    }, [taskIds])



    useEffect(() => {

        // localStorage.removeItem('anonymousID');

        try {

            (async () => {

                const response = await api.get(`/new_campaign/start_date`);
                if (response.ok) {

                    if ('start_date' in response.body) {
                        const inputDate = new Date(response.body.start_date);
                        const formattedDate = inputDate.toLocaleString("en-US", {
                            month: "2-digit",
                            day: "2-digit",
                            year: "numeric",
                            hour: "2-digit",
                            minute: "2-digit",
                            timeZoneName: "short" // This shows the abbreviated timezone
                        });
                        // const inputDate = new Date(response.body.start_date);
                        // const dateFormatter = new Intl.DateTimeFormat("en-US", { month: "2-digit", day: "2-digit", year: "numeric" });
                        // const formattedDate = dateFormatter.format(inputDate);
                        setStartDate(formattedDate)
                    }
                    else {
                        setStartDate(false)
                    }
                }
                else {
                    'errors' in response.body ?

                        flash(response.body.errors.json.message, 'danger')
                        :
                        flash("Error with start data request", 'danger')


                }

            })();
        } catch (error) {
            flash("Error with start data request", 'danger');
            console.log(error)
        }




        (async () => {

            const response = await api.get(`/new_campaign/campaign_type`);
            if (response.ok) {

                'mode_name' in response.body ? setMode(response.body.mode_name) : setMode(false)

            }
            else {
                'errors' in response.body ?

                    flash(response.body.errors.json.message, 'danger')
                    :
                    flash("Error with campaign type data request", 'danger')



            }

        })();

        (async () => {

            const response = await api.get(`/new_campaign/description`);
            if (response.ok) {

                response.body ? setDescription(response.body) : setDescription(false)

                // response.body.title ? setTitle(response.body.title) : setTitle(false)
            }
            else {
                'errors' in response.body ?
                    flash(response.body.errors.json.message, 'danger')
                    :
                    flash("Error with request", 'danger')
                return false
            }

        })();



        (async () => {

            const response = await api.get(`/new_campaign/image_confirmation`);
            if (response.ok) {

                if ('task_ids' in response.body) {
                    setTaskIds(response.body.task_ids)
                }

                setMockup(response.body.mockup_url);

                setImages(response.body.images)


            }
            else {
                setMockup(false);
                setImages(false);
            }

        })();




        (async () => {

            const response = await api.get(`/new_campaign/location`);
            if (response.ok) {
                Object.keys(response.body.poi).length === 0 && Object.keys(response.body.polygons).length === 0 ?
                    setMapLocation(false)
                    :
                    setMapLocation(response.body)


            }
            else {

                setMapLocation(false)


            }

        })();

        // handleTaskTimer2()

    }, [api, flash])




    const handleSubmit = (ev) => {
        ev.preventDefault();
        setIsLoading(true);
        if (!terms) {
            return flash('You must agree to the terms and conditions before submitting your campaign', 'danger')
        }

        (async () => {
            const response = await api.post(`/new_campaign/confirmation`, JSON.stringify({ terms: terms }));
            if (response.ok) {

                setIsLoading(false);
                flash(<span><strong>Congratulations! </strong>Your campaign was submitted.</span>, 'success')
                return navigate(`/my_campaigns`, { replace: true })

            }
            else {
                setIsLoading(false);
                "errors" in response.body ?
                    flash(response.body.errors.json.message, 'danger')
                    :
                    flash('There was an error sending your message', 'danger')



            }
        })();




    }


    useEffect(() => {


        // Check if all individual states are non-null
        const notNull =
            mode !== null &&
            startDate !== null &&
            images !== null &&
            description !== null &&
            mapLocation !== null &&
            mapLocation !== false &&
            terms !== false &&
            terms !== null;

        const notUndefined =
            mode !== undefined &&
            startDate !== undefined &&
            images !== undefined &&
            description !== undefined &&
            mapLocation !== undefined &&
            terms !== undefined;


        notUndefined && notNull ?
            setFormComplete(true)
            :
            setFormComplete(false);

    }, [mode, mapLocation, startDate, images, description, terms]);






    const handleTermsChange = (event) => {
        setTerms(event.target.checked);
    };


    const HELP_HEADER = <div> Agree to our terms and conditions and post: </div>

    return (

        <NewCampaignPage helpHeader={HELP_HEADER} title={`Finalize your campaign`} description={"Agree to CrowdPost's terms of service"} handleSubmit={handleSubmit} disabled={false} currentStep={5} >


            {/* <Container fluid>

                <Col className="" xs={12}> */}

            <Stack gap={3}>

                <h2 className="fw-bold">Campaign Summary <hr /></h2>

                <ConfirmationComponent header="Mode" name="mode" value={mode}>
                    <p>{mode}</p>
                </ConfirmationComponent>

                <hr />


                <ConfirmationComponent header="Location" name="location" value={mapLocation && mode}>
                    <CampaignConfirmationMap location={mapLocation} campaign_mode={mode} />
                    {/* <CampaignLocationMap location={mapLocation} campaign_mode={mode} /> */}
                </ConfirmationComponent>

                <hr />



                {/* {mockup && <hr />} */}


                <ConfirmationComponent header="Image(s)" name="image(s)" value={images}>

                    {/* <Stack gap={2} direction={"horizontal"}> */}

                    {
                        images && images.length > 0 ?

                            <div>
                                {/* {
                                                    images['mockup_url'] && <div>
                                                        <Image style={{
    
                                                            maxHeight: '400px'
                                                        }}
    
                                                            src={images['mockup_url']} fluid />
    
                                                    </div>
                                                } */}
                                {images.map((value, index) =>
                                    <div key={index} className="my-2">
                                        <div>
                                            <h5>{value['board_name']} </h5>
                                        </div>
                                        {
                                            value['url'] ?
                                                <Image src={value['url']} style={{
                                                    backgroundColor: 'white',
                                                    maxHeight: '400px'
                                                }} fluid />
                                                :
                                                <p className="text-danger">No image set</p>
                                        }
                                        {/* <div
                                                            style={{
                                                                border: '2px solid black',
                                                                aspectRatio: `${value['campaign_aspect_ratio']}`,
                                                                position: 'relative',
                                                                overflow: 'hidden',
                                                                // maxHeight: '400px'
                                                                // height: '80px' this causes distoriations
                                                            }}
                                                        >
                                                            <img
                                                                src={images[_key]['image']}
                                                                alt="Uploaded"
                                                                style={{
                                                                    width: '100%',
                                                                    height: '100%',
                                                                    objectFit: 'contain',
                                                                }}
                                                            />
                                                        </div> */}
                                    </div>
                                )}
                            </div>

                            :
                            <p className="text-danger">No image set</p>

                    }
                    {/* </Stack> */}
                    {/* <Stack gap={2} direction={"horizontal"}>
                                        {
                                            images && Object.keys(images).length > 0 ?
    
                                                Object.keys(images).map((_key, index) =>
                                                    <label
                                                        // htmlFor={`fileInput-`}
                                                        className="canvas-side d-flex align-items-center justify-content-center w-100"
                                                        style={{
                                                            border: '2px dashed black',
                                                            aspectRatio: images[_key]['aspect_ratio'],
                                                            // minHeight: '100%',
                                                            display: 'grid',
                                                            placeItems: 'center',
                                                            cursor: 'pointer',
                                                            // height: containerHeight
                                                        }}
                                                        key={index}
                                                    >
                                                        <Image src={images[_key]['image']} style={{ objectFit: 'contain', width: '100%', height: '100%' }} />
                                                    </label>
                                                )
    
                                                :
                                                <div>
                                                    <p>No image set</p>
                                                </div>
                                        }
                                    </Stack> */}

                </ConfirmationComponent>

                {mockup && <h4 className="fw-bold">Mockup</h4>}
                {
                    mockup === undefined ?
                        <div>
                            <Placeholder as={Card.Title} animation="glow">
                                <Placeholder className="rounded p-3" xs={6} lg={3} />
                            </Placeholder>
                        </div>
                        :
                        mockup === false ?
                            <p className="text-danger"></p>
                            :
                            < div >
                                {
                                    mockup.map((imgData, index) =>
                                        <Image key={index} style={{
                                            backgroundColor: 'white',
                                            maxHeight: '400px'
                                        }}

                                            src={imgData['image']} fluid />
                                    )
                                }
                                {/* {JSON.stringify(mockup)}a */}



                            </div>
                }


                <hr />

                <ConfirmationComponent header="End of fundraising period" name="start date" value={startDate}>
                    <p>{startDate}</p>
                </ConfirmationComponent>

                <hr />

                <ConfirmationComponent header="Title & Description" name="title" value={description}>
                    {
                        description &&
                        <Row style={{ wordBreak: 'break-word' }}>

                            <Col xs={12}>
                                {description.title ? <h5><strong>{description.title}</strong></h5> : <strong className="text-danger">No title set</strong>}
                                {
                                    description.description && description.description.length > 0 &&
                                    <div dangerouslySetInnerHTML={{ __html: description.description }} />
                                }
                                {
                                    description.youtube_id &&
                                    <div style={{
                                        "position": "relative", paddingBottom: "56.25%", height: "0", overflow: "hidden", maxWidth: "100%", background: "black"
                                    }}>
                                        <YoutubeComponent
                                            video_id={description.youtube_id
                                            }
                                        />
                                    </div>
                                }
                                {
                                    description.images &&
                                    <Row>
                                        {
                                            description.images.map((i, index) =>
                                                <Col key={index}>
                                                    <Image src={i} style={{ height: '200px', width: "100%", objectFit: 'contain' }} />
                                                </Col>
                                            )
                                        }
                                    </Row>
                                }
                            </Col>

                        </Row>
                    }



                    {/*
                                <div>
    
                                    <p className="my-0 py-0"><strong>Title</strong></p>
                                    <p className="my-0 py-0">{title}</p>
                                    {
                                        videoId &&
                                        <YoutubeComponent
                                            video_id={video_id}
                                        />
                                    }
                                    {descriptionImages &&
                                        <Row>
                                            {
                                                descriptionImages && descriptionImages.map((i) =>
                                                    <Col>
                                                        <Image src={i} style={{ height: '200px', width: "100%" }} />
                                                    </Col>
                                                )
                                            }
                                        </Row>
                                    }
    
                                </div>
    
    
                             */}

                </ConfirmationComponent>


                {/*
                                <div>
                                    <div>{description ? <p className="mb-0"><strong className="error-font">Description: </strong>{description}</p> : <p>No description</p>}</div>
                                    <div>
    
    
                                        {
                                            title.current ?
                                                <>
                                                    <strong className="error-font">Title: </strong>{title.current}
                                                </>
                                                :
                                                <span><strong>Title: </strong>No title </span>
                                        }
                                    </div>
                                </div> */}
                <hr />
                <Form onSubmit={handleSubmit}>

                    <Row className="mb-2">
                        <h4>Terms & Conditions</h4>
                        <Col>
                            <Form.Group className="mb-3" controlId="terms">
                                <Form.Check
                                    type="checkbox"
                                    label="Agree to terms & conditions"
                                    checked={terms}
                                    onChange={handleTermsChange}
                                // ref={terms}

                                />
                                <Form.Text>
                                    <div >
                                        By posting this campaign, you are agreeing by the site's <Link to="/terms-of-service">terms of use</Link> and <Link to="/privacy-policy">privacy policy</Link> and <Link to="/dmca">DMCA policy</Link>.
                                        <br />
                                        You must be posting original content or have permission to post the content. CrowdPost is not liable for copyright infringment.

                                    </div>
                                </Form.Text>

                            </Form.Group>

                        </Col>

                    </Row>

                    <hr />

                    <Row>
                        {
                            (!formComplete || !user.user) &&
                            <div>


                                {
                                    !user.user ?
                                        <div>
                                            <div><span className="text-danger">Must sign in to submit a campaign. </span><Link to="/login">Login here</Link></div>
                                        </div>
                                        :
                                        !formComplete ? <div>
                                            <div className="text-danger">Please complete all fields</div>
                                        </div>
                                            : <div></div>

                                }
                            </div>
                        }
                        <Col xs={12} md={6} lg={4}>


                            {
                                (!formComplete || isLoading === true || !user.user) ?
                                    <div>
                                        <FormButtonBlock isProcessing={true} text={"Submit Campaign"} />


                                    </div>

                                    :

                                    <FormButtonBlock isProcessing={false} text={"Submit Campaign"} />
                            }

                        </Col>
                    </Row>

                </Form>


            </Stack>

            {/* </Col>




            </Container> */}




        </NewCampaignPage >
    )
}

export default NewCampaignConfirmation
