import React from 'react'
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';




function CampaignImageList({ imageType, images }) {

    return (
        <>
            {
                images === undefined ?
                    <Spinner />
                    :
                    images === false ?
                        <p>No images set</p>
                        :

                        imageType === 'map' ?
                            <Col>
                                {/* <BillboardImage imageType={imageType} handleShowAll={handleShowAll} showAllButton={true} status={status} campaign_id={campaign_id} isLiked={isLiked} image={images[0]} /> */}
                            </Col>
                            :
                            <Col>
                                {
                                    images && Object.keys(images).length === 1 ?
                                        <div className="w-100" style={{
                                            display: 'flex',
                                            objectPosition: 'center',
                                            justifyContent: "center",
                                            alignItems: "center",
                                        }}>
                                            <Image
                                                fluid
                                                src={images[Object.keys(images)[0]]['image']}
                                                style={{

                                                    maxHeight: '400px',


                                                }}
                                            />
                                        </div>
                                        :
                                        (!images || images.length === 0) ?
                                            <div style={{ width: '100%', maxHeight: '250px', textAlign: 'center' }}>
                                                <h2 className="text-light"><strong>No image set</strong></h2>
                                            </div>
                                            :
                                            <Carousel style={{ //TODO:Change to 350 on mobile
                                                height: "500px", display: "flex",
                                                justifyContent: "center", alignItems: "center"
                                            }} interval={null}>
                                                {images.map((image, index) =>
                                                    image['image'] && <Carousel.Item key={index}
                                                        style={{ textAlign: 'center', alignItems: "center" }}>
                                                        <div

                                                        >
                                                            <Image
                                                                fluid
                                                                src={image['image']}
                                                                style={{
                                                                    height: '400px',
                                                                    // margin: "auto",
                                                                    width: '100%',
                                                                    objectFit: 'contain',
                                                                    backgroundColor: 'white'
                                                                }}
                                                            />
                                                        </div>
                                                    </Carousel.Item>
                                                )}
                                            </Carousel>
                                }


                            </Col>

            }

        </>
    )

}

export default CampaignImageList
