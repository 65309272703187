import React from 'react'
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';



function AIImgThumbnail({ image, handleFileAIInputChange, mediaName, isLoading, key }) {
    const textColor = 'text-muted fs-6 mb-2'
    return (

        !isLoading ?
            <Col xs={6} sm={4} md={3} className="mb-4" key={key}>


                <Card>
                    <Card.Img variant="top" src={image['url']} />
                    <Card.Body style={{ overflowY: 'scroll', height: '250px', whiteSpace: 'initial' }}>

                        <div><strong>Image quality</strong></div>
                        <div className={textColor}>{image['ai_is_hd']}</div>
                        <div><strong>Style</strong></div>
                        <div className={textColor}>{image['ai_style']}</div>
                        <div><strong>Size</strong></div>
                        <div className={textColor}>{image['ai_size']}</div>
                        <div><strong>Message</strong></div>
                        <Card.Text className={textColor}>{image['ai_message']} </Card.Text>
                    </Card.Body>
                    {
                        mediaName &&

                        <Card.Footer className="d-flex justify-content-between">
                            <Button
                                size={'sm'}
                                style={{
                                    marginRight: '.5rem'
                                }}
                                onClick={() => handleFileAIInputChange(
                                    image['url'],
                                    mediaName
                                )}

                            >
                                Use image
                            </Button>
                            <Button size={'sm'} href={image['url']} download={image['url']}>Download</Button>
                        </Card.Footer>
                    }

                </Card>



            </Col>
            :
            <Col xs={6} sm={4} md={3} className="mb-4">

                <Card>
                    <Placeholder sm={12} as="div" animation="glow" style={{ maxHeight: "200px", height: "400px", overflow: "hidden" }}>
                        <Placeholder xs={12} style={{ borderRadius: '.25rem', height: "100%" }} />
                    </Placeholder>

                    <Card.Img variant="top" src={image['url']} />
                    <Card.Body style={{ overflowY: 'scroll', height: '250px', whiteSpace: 'initial' }}>
                        {/* {JSON.stringify(image)} */}
                        <div><strong>Image quality</strong></div>
                        <div className={textColor}>{image['ai_is_hd'] ? 'HD' : "Standard"}</div>
                        <div><strong>Style</strong></div>
                        <div className={textColor}>{image['ai_style']}</div>
                        <div><strong>Size</strong></div>
                        <div className={textColor}>{image['ai_size']}</div>
                        <div><strong>Message</strong></div>
                        <Card.Text className={textColor}>{image['ai_message']} </Card.Text>
                    </Card.Body>


                    <Card.Footer className="d-flex justify-content-between">
                        <Placeholder.Button variant="primary" xs={3} />
                        <Placeholder.Button variant="primary" xs={3} />
                        {/* <Button
                    size={'sm'}
                    style={{
                        marginRight: '.5rem'
                    }}
                    onClick={() => handleFileAIInputChange(
                        image['url'],
                        mediaName
                    )}

                >
                    Use image
                </Button>
                <Button size={'sm'} href={image['url']} download={image['url']}>Download</Button> */}
                    </Card.Footer>


                </Card>

            </Col>
    )
}

export default AIImgThumbnail
