import React from "react";
import { FaEnvelope } from 'react-icons/fa';
import SocialMediaIcon from "./SocialMediaIcon";
import { mailIcon } from "../utils/staticUrls";

function EmailShare({ email, subject, body, campaignId }) {

  const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/campaign/${campaignId}`;


  const STOCK_SUBJECT = `Check out this billboard campaign`;
  const STOCK_MESSAGE = `Support this campaign at ${baseUrl}`

  const mailtoLink = email && subject && body ? `mailto:?subject=${encodeURIComponent(STOCK_SUBJECT)}&body=${encodeURIComponent(STOCK_MESSAGE)}` : `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;


  //TODO: If not email ubject and body return as tandard message. else return this to mailtoLink. attach mailto link to href. 


  return (

    <div>
      <a href={mailtoLink} target="_self" rel="noopener" aria-label="">
        <SocialMediaIcon iconPath={mailIcon} size={50}/>
        {/* <FaEnvelope size={50} color="white" /> */}
      </a>
    </div>
    // <a className="resp-sharing-button__link" href={mailtoLink} target="_self" rel="noopener" aria-label="">
    //   <div className="resp-sharing-button resp-sharing-button--email resp-sharing-button--small"><div aria-hidden="true" className="resp-sharing-button__icon resp-sharing-button__icon--solid">
    //     <FaEnvelope size={160} color="white" />
    //   </div>
    //   </div>
    // </a>
  );
}

export default EmailShare;