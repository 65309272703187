import { Navigate } from 'react-router-dom';
import { useUser } from '../contexts/UserProvider';
//login page sould be unaccessible when user is logged in 


export default function PublicRoute({ children }) {
  const { user } = useUser();



  if (user === undefined) {
    return null;
  }
  else if (user) {
    // return children
    // return navigate("/")
    return <Navigate to="/" />
  }
  else {
    return children;
  }
}