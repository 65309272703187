import React, { useState, useRef, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Placeholder from 'react-bootstrap/Placeholder';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

// import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import GetPrice from './GetPrice';
import Badge from 'react-bootstrap/Badge';
import { initMapboxDraw, handleShowBillboardLayer } from '../utils/ShowBillboardLayers';
import ImpressionValue from './ImpressionValue';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_KEY


function CampaignLocationMap({ location, campaign_mode, isProcessing }) {
    const mapContainer = useRef(null);
    const map = useRef(null);
    const TRUCK_STRING_MATCH = 'truck'
    const [avgTotalImp, setAvgTotalImp] = useState(false)
    const [poi, setPoi] = useState(location.poi)
    const popupRef = useRef(new mapboxgl.Popup({ closeButton: true }));
    // const [polygon, setPolygon] = useState()


    const [featureCollection, setFeatureCollection] = useState();



    const [draw, setDraw] = useState(null)

    const toggleDrawMode = () => map.current.fitBounds(location.bbox)

    // useEffect(() => {
    //     if (map.current) {
    //         if (isMobile) {
    //             map.current.setCooperativeGestures(true)
    //         }
    //         else {
    //             map.current.setCooperativeGestures(false)
    //         }
    //     }
    // }, [isMobile])


    const showPopup = (id) => {
        let counter = 1

        var name
        var s = draw.getAll()
        s = s.features
        for (let i in s) {
            i = s[i]

            var coordinates;
            if (i.id === id && 'poi_name' in i.properties && i.properties.poi_name) {

                name = i.properties.name
                coordinates = i.geometry.coordinates[0][0]

            }
            else if (i.id === id) {

                // counter++
                // name = `Custom area ${counter}`
                name = i.properties.name
                coordinates = i.geometry.coordinates[0][0]

            }
            else {
                counter++
            }

        }
        if (name) {
            const popupContent = `<p style="">${name}</p>`;
            popupRef.current.setLngLat(coordinates).setHTML(popupContent).addTo(map.current);
            map.current.flyTo({
                center: coordinates,
                zoom: 9,
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
        }

    }


    useEffect(() => {

        if (map.current) return; // initialize map only once

        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: process.env.REACT_APP_MAPBOX_NEW_CAMPAIGN_LOCATION_V1,
            bounds: location.bbox,
            projection: 'mercator',
            cooperativeGestures: true
        });
        map.current.on("load", () => {
            map.current.addControl(new mapboxgl.FullscreenControl(), "top-left");

            let r = initMapboxDraw(map)

            setDraw(r)
            map.current.addControl(r, 'top-right');

            r.set(location.polygons)

            var sum = location.polygons.features.reduce((acc, value) => {
                if (value.hasOwnProperty('properties') && value['properties'].hasOwnProperty('imp')) {
                    return acc + value['properties']['imp']
                }
                return acc
            }, 0);
            console.warn(location.polygons, 'here')
            setAvgTotalImp(sum)
            var layerId
            var billbboardPointsLayer

            if (campaign_mode.includes(TRUCK_STRING_MATCH)) {
                handleShowBillboardLayer(map, false)

            }
            else {
                handleShowBillboardLayer(map, true)

            }
        })


    }, [map, location.bbox, location.polygons]); //radius was here


    useEffect(() => {
        if (draw && location && campaign_mode) {

            var layerId
            if (campaign_mode.includes(TRUCK_STRING_MATCH)) {
                layerId = 'ca-polygon'
                if (map.current.getLayer(layerId)) {
                    map.current.setLayoutProperty(layerId, 'visibility', 'none');
                }
            }
            else {
                layerId = 'truck-area'
                if (map.current.getLayer(layerId)) {
                    map.current.setLayoutProperty(layerId, 'visibility', 'none');
                }
            }


            if ('polygons' in location) {
                const sourceId = 'custom-source-id'
                // setPolygon(response.body.polygons)
                if (!!map.current.getSource(sourceId)) {

                    setFeatureCollection(location.polygons)
                    map.current.addSource(sourceId, {
                        type: 'geojson',
                        data: location.polygons,
                    });

                    map.current.addLayer({
                        id: 'custom-layer-id',
                        type: 'fill',
                        source: sourceId,
                        paint: {
                            // 'fill-color': '#ff0000',
                            'fill-opacity': 0,
                        },
                    });
                }



            }
            else {
                setFeatureCollection(null)
            }
        }
        else {
            setPoi(null)
            // setPolygon(null)
            setFeatureCollection(null)

        }



    }, [draw, campaign_mode, location])





    useEffect(() => {


        if (draw) {

            // const sourceIds = Object.keys(sourceCaches);


            if (featureCollection && Object.keys(featureCollection).length === 0) {
                // if ((featureCollection) && (Object.keys(featureCollection).length !== 0)) {
                var sum = featureCollection['features'].reduce((acc, value) => {
                    if (value.hasOwnProperty('properties') && value['properties'].hasOwnProperty('imp')) {
                        return acc + value['properties']['imp']
                    }
                    return acc
                }, 0);

                setAvgTotalImp(sum)
                var currentFeatureCollection = draw.getAll();

                let counter = 1
                currentFeatureCollection['features'].forEach((i) => {
                    if ('poi_name' in i.properties) {

                        i['name'] = i['poi_name'];
                    }
                    else {
                        i['name'] = `Custom area ${counter}`;
                        counter++;
                    }
                })

                var poi_features = currentFeatureCollection.features.filter((item) => ("poi_name" in item.properties && item.properties['poi_name'] !== null));

                var newPOI = {}
                for (let i = 0; i < poi_features.length; i++) {
                    var _feature = poi_features[i];
                    newPOI[_feature.properties.id] = {
                        radius: _feature.properties.poi_radius,
                        name: _feature.properties.poi_name
                    };
                }

                setPoi(() => newPOI)


            }
        }



    }, [featureCollection, draw])



    const handleResize = () => {
        map.current && map.current.resize()
    }
    useEffect(() => {


        const observer = new ResizeObserver(() => handleResize(mapContainer));
        if (mapContainer.current) {
            observer.observe(mapContainer.current);
            handleResize(mapContainer); // Update the height initially
        }

        return () => {
            // window.removeEventListener('resize', handleResize);
            observer && observer.disconnect();
        };
    }, []);





    return (
        <Row>

            <Col className='d-block d-lg-block px-0 mx-0' xs={12}>
                {/* <Col className={showMap ? 'vh-100 d-block px-0 mx-0' : 'vh-100 d-none px-0 mx-0'} lg={9} xs={12}> */}


                <div ref={mapContainer} style={{ 'height': '400px' }} >

                    <div className="d-block">
                        {draw &&
                            <Button
                                // variant="dark"
                                onClick={toggleDrawMode}
                                style={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "0px",
                                    zIndex: "1",
                                    margin: "1.5rem",
                                    paddingLeft: '2rem',
                                    paddingRight: '2rem'
                                }}
                                className={`btn btn-dark`}
                            >
                                <strong> Re-center
                                </strong>
                            </Button  >
                        }
                    </div>


                </div>




            </Col>

            <Col className="d-flex mt-3" xs={12} >
                <Stack gap={3}>
                    <div className="">{
                        poi === undefined ?
                            <Placeholder className="rounded" animation="glow">
                                <Placeholder xs={4} className="rounded" />
                            </Placeholder>
                            :
                            poi !== null && Object.keys(poi).length !== 0 &&
                            <div>
                                {/* <h5 className="fw-bolder">Points of interest</h5> */}

                                {Object.entries(poi).map((i, index) =>
                                    <Row key={index}>

                                        <Col xs={8}>
                                            <p>{i[1].name}<br />
                                                <strong>Radius: </strong>{
                                                    i[1].radius}
                                                {
                                                    i[1].radius > 1 ?
                                                        <span> miles</span>
                                                        :
                                                        <span> mile</span>
                                                }
                                            </p>
                                        </Col>
                                        <Col className="d-flex justify-content-end" xs={4}>
                                            <div className="align-items-center justify-content-between  py-2">
                                                <div className="">

                                                    <Badge pill bg="success" onClick={() => showPopup(i[0])} style={{ marginRight: ".4rem", cursor: 'pointer', fontSize: '1rem' }} >Show</Badge>


                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                )}
                            </div>


                    }
                    </div>


                    <div>
                        {
                            location === undefined || isProcessing === true ?
                                <div>
                                    <div className="my-2">
                                        <Placeholder className="rounded" animation="glow">
                                            <Placeholder xs={4} className="rounded" />
                                        </Placeholder>
                                    </div>
                                    <div className="my-2">
                                        <Placeholder className="rounded" animation="glow">
                                            <Placeholder xs={4} className="rounded" />
                                        </Placeholder>
                                    </div>
                                    <div className="my-2">
                                        <Placeholder className="rounded" animation="glow">
                                            <Placeholder xs={4} className="rounded" />
                                        </Placeholder>
                                    </div>
                                </div>
                                :
                                <div>
                                    {/* <h5 className="fw-bolder">Custom Areas</h5> */}
                                    {(location.polygons !== null && location.polygons.features.length !== 0) &&
                                        location.polygons.features.map((i, index) =>
                                            <Row key={index}>

                                                <div className="d-flex align-items-center justify-content-between py-2">

                                                    <div>
                                                        <div>
                                                            {
                                                                'properties' in i && 'poi_name' in i.properties && i.properties.poi_name ?
                                                                    <span >{i.properties.name}<Badge className="mx-2 bg-primary rounded-pill">{i.properties.poi_radius} mile radius</Badge></span>
                                                                    :
                                                                    <span >{i.properties.name}</span>
                                                            }
                                                        </div>
                                                        <div className="text-muted">
                                                            {
                                                                'properties' in i && 'imp' in i['properties'] &&
                                                                <ImpressionValue impression={i['properties']['imp']} />
                                                            }
                                                        </div>
                                                    </div>

                                                    <div className="">
                                                        <Badge pill bg="success" onClick={() => showPopup(i.id)} style={{ marginRight: ".4rem", cursor: 'pointer', fontSize: '1rem' }} >Show</Badge>
                                                    </div>

                                                </div>


                                            </Row>
                                        )}
                                </div>


                        }
                    </div>

                    <Col xs={12} className="d-flex justify-content-between">
                        <span>
                            {avgTotalImp && parseInt(avgTotalImp) > 0 ?
                                <span className="d-flex">
                                    <span><strong>Est. avg weekly impressions per billboard: </strong> </span>
                                    <span className="mx-1">
                                        <ImpressionValue label={" "} impression={avgTotalImp} />
                                    </span>
                                </span>
                                :
                                <div></div>
                            }
                        </span>
                    </Col>


                    {((location.partial_estimated_min_price) || (location.full_estimated_min_price)) &&
                        <>
                            <hr />
                            <h5>
                                Estimated pricing
                            </h5>
                            {
                                location.partial_estimated_min_price &&
                                <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: '.5em' }}>Minimum needed to be posted: </div>

                                    <GetPrice justPrice priceInCents={location.partial_estimated_min_price} />
                                </div>
                            }
                            {'full_estimated_min_price' in location && location.full_estimated_min_price &&
                                <div style={{ display: 'flex' }}>
                                    <div style={{ marginRight: '.5em' }}>Minimum for media in all areas: </div>

                                    <GetPrice justPrice priceInCents={location.full_estimated_min_price} />
                                </div>


                            }

                        </>
                    }


                </Stack>

            </Col>


        </Row>
    )
}

export default CampaignLocationMap
