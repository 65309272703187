import React from 'react'

import { Link } from 'react-router-dom'
import GoogleButton from '../components/GoogleButton'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Carousel from 'react-bootstrap/Carousel';
import Image from 'react-bootstrap/Image';



function HeroSectionV1({ handleNewCampaign, landingPageImages, CAROUSEL_INTERVAL, }) {
    return (
        <Container  fluid="xxl" className="d-flex align-items-center  heroSectionV1">
            <Row >
                {/* For large screens (md and above), use 6 columns for heading and 6 columns for image */}
                <Col sm={12}>
                    <h1  className="text-center gradient-text fw-bolder heroSectionV1Header">Let your voice reach the <i>real</i> town square</h1>
                </Col>
                <Col className="align-content-center" sm={12} lg={6}>
                    <div className="text-left">

                        <h2 className="mb-5 d-none d-lg-block">


                            <strong><span >Create free outdoor media campaigns</span> <strong > anyone can contribute too</strong>,  <strong className="gradient-text">CrowdPost</strong> manages the rest from start to finish.</strong>
                        </h2>

                    </div>


                    <Carousel className="landingPageCarousel d-lg-none text-center" indicators={false} interval={null}>
                        {
                            landingPageImages.map((image, index) =>
                                <Carousel.Item key={index}
                                >
                                    <Image
                                        src={`${image}`}
                                        alt="Image"
                                        style={{
                                            height: '300px',
                                            objectFit: 'contain',
                                            width: '100%',
                                        }}

                                    />

                                </Carousel.Item>
                            )}

                    </Carousel>


                    <h2 className="my-4 text-center d-lg-none">


                        <span >Create free outdoor media campaigns</span> <strong > anyone can contribute too</strong>, <strong className="gradient-text">CrowdPost</strong> manages the rest from start to finish.
                    </h2>

                    <Col className="d-flex m-0 p-0 justify-content-center" xs={12}>

                        <Row className="m-0 p-0 d-flex justify-content-center " style={{maxWidth:"500px"}}>
                            <Col className="d-lg-none d-block justify-content-center mb-5" xs={12}>
                                <div className="">
                                    <Button
                                        variant="success"
                                        className="d-block w-100 rounded-pill align-items-center justify-content-center px-5 py-3" // Apply the Flexbox styles here
                                        onClick={() => handleNewCampaign()}
                                    >
                                        <h4 className="m-0"><strong>Start a Campaign</strong></h4>
                                    </Button>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <div className="d-grid gap-2 text-center">
                                    
                                    <Link to={{ pathname: '/register' }} >
                                        <Button variant="outline-light" size="lg" className="rounded-pill w-100">
                                            <strong>Sign up with Email</strong>
                                        </Button>
                                    </Link>
                                    <div className="d-flex align-items-center">
                                        <hr style={{ flex: 1 }} />
                                        <span className="mx-2">or</span>
                                        <hr style={{ flex: 1 }} />
                                    </div>
                                    <GoogleButton outline roundedPill text="Sign up with Google" />
                                </div>
                            </Col>
                        </Row>
                    </Col>

                </Col>

                <Col lg={6} className="d-none d-lg-block align-content-center">
                    <Carousel className="landingPageCarousel" indicators={false} interval={CAROUSEL_INTERVAL}>
                        {
                            landingPageImages.map((image, index) =>
                                <Carousel.Item key={index} className="text-center align-content-center">
                                    <Image
                                        src={`${image}`}
                                        alt="Image"
                                        className="align-content-center"
                                        style={{
                                            // maxHeight: '600px',
                                            // minHeight: '350px',
                                            height: "550px",
                                            objectFit: 'contain',
                                            width: '100%',
                                            borderRadius: '.5em'

                                        }}
                                    />

                                </Carousel.Item>
                            )}

                    </Carousel>

                </Col>

                <Col className="my-5 d-none d-lg-flex justify-content-center" xs={12}>

                    <Button
                        variant="success"
                        className="rounded-pill d-flex align-items-center justify-content-center px-5 py-3" // Apply the Flexbox styles here
                        onClick={() => handleNewCampaign()}
                    >
                        <h3 className="m-0"><strong>Start a Campaign</strong></h3>
                    </Button>

                </Col>

            </Row>
        </Container>
    )
}

export default HeroSectionV1
