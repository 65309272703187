import React, { useState } from 'react'
import 'react-quill/dist/quill.snow.css'; // Import styles
import '../styles/DescriptionQuill.css'
import ReactQuill, { Quill } from 'react-quill';
import Form from 'react-bootstrap/Form';

function DescriptionQuill({ formErrors, quillRef }) {


    const handleContentChange = () => {
        const quillEditor = quillRef.current.getEditor();
        const text = quillEditor.getText();
        setCharCount(text.trim().length); // Use trim() to ignore leading/trailing whitespace
    };


    const Font = Quill.import('formats/font');
    Font.whitelist = ['sans-serif', 'serif', 'monospace', 'courier-new', 'times-new-roman', 'georgia'];

    const customColors = [
        '#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff',
        '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff',
        '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff',
        '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2',
        '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'
    ];



    // Register a custom color picker module
    const ColorPicker = Quill.import('formats/color');
    ColorPicker.whitelist = customColors;
    Quill.register(ColorPicker, true);


    // const CustomColor = Quill.import('formats/color');
    // CustomColor.whitelist = ['black', 'white', 'red', 'green', 'blue', 'yellow', 'purple', 'orange'];
    // Quill.register(CustomColor, true);
    Quill.register(Font, true);
    const modules = {
        toolbar: {
            container: [
                [{ 'font': Font.whitelist }],
                [{ 'size': [] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'color': ColorPicker.whitelist }, { 'background': [] }],
                [{ 'align': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['clean']
            ],
        },
    };

    const formats = [
        'font', 'size', 'bold', 'italic', 'underline', 'strike',
        'color', 'background', 'align', 'list', 'bullet', 'clean',
    ];
    const [charCount, setCharCount] = useState(0);

    return (
        <div className="description">
            <p><strong>Description</strong></p>
            <div >
                <ReactQuill
                    formats={formats}
                    ref={quillRef}
                    modules={modules}
                    className="description-quill bg-dark text-light rounded"
                    style={{
                        border: '1px solid grey',


                    }}
                    onChange={handleContentChange}
                />
            </div>

            <Form.Text className="text-secondary">Optional field. This displays publicly on the campaign homepage. Describe why you are creating your campaign, what your goals are and convey why people should crowdfund your campaign. Text must be between 5 and 4,000 charachters</Form.Text>

            {
                charCount && charCount && charCount < 4000 && 5 <= charCount ?
                    <div style={{ textAlign: 'right' }}><Form.Text className="text-secondary">{charCount && charCount}</Form.Text></div>
                    :
                    charCount !== 0 && <div style={{ textAlign: 'right' }}><Form.Text className="text-danger">{charCount && charCount}</Form.Text></div>
            }

            {'description' in formErrors &&
                <div><Form.Text className="text-danger">{formErrors.description}</Form.Text></div>
            }


        </div>
    )
}

export default DescriptionQuill
