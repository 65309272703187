import React, { useRef, useState } from 'react'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Form from 'react-bootstrap/Form';
// import InputField from './InputField';
// import RGBColorPicker from './RGBColorPicker';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import Popover from 'react-bootstrap/Popover';
import { FaGear } from "react-icons/fa6";
// import FontEditor from './FontEditor';
// import ImageSettingEditor from './ImageSettingEditor';
import EmojiPicker from './EmojiPicker';
// import EmojiModal from './EmojiModal';
import { FaFaceSmile } from "react-icons/fa6";
import TextAreaField from './TextAreaField';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
// import SidebarExample from './SidebarExample';
// import DesktopSidebar from './DesktopSidebar';

import ImageMsgSettingEditor from './ImageMsgSettingEditor';
import { useImageBuilder } from '../contexts/ImageBuilderProvider';
import EditorDisplayButton from './EditorDisplayButton';



function CustomMedia({ handleImageHover, image_data, mediaName, imageHandlers, fontHandlers, handleImageMessageListChange, handleToggleEditMessage }) {
    // function CustomMedia({ tabKey, handleImageHover, height, image_data, handleFileInputChange, mediaName, imageHandlers, fontHandlers, mediaRef, handleImageMessageListChange, handleToggleEditMessage }) {



    const { handleMessageChange, handleAddOnImageMsg, handleRemoveMessage, handleImageMessageLayersSortChange } = useImageBuilder()

    const { handleEmojiUpdate } = fontHandlers

    const backgroundInputRef = useRef()

    const [isProcessing, setIsProcessing] = useState(false);
    const fileInputRef = useRef(null);

    const api = useApi();
    const flash = useFlash();

    const popover4 = useRef();
    // const popover6 = useRef();
    const popover2 = useRef();

    const [flashMessage, setFlashMessage] = useState()



    const handleAddImageMessage = (bs64_img = false) => {

        if (isProcessing === false) {
            setIsProcessing(true);
            (async () => {

                const response = await api.post(`/new_campaign/new-image-message`, JSON.stringify({ image_id: image_data.id, bs64_img: bs64_img }));
                if (response.ok) {

                    //append to image list
                    handleAddOnImageMsg(mediaName, response.body);
                    setIsProcessing(false);


                }
                else {
                    setIsProcessing(false);


                    'body' in response && 'errors' in response.body ?
                        flash(response.body.errors.json.message, 'danger')
                        :
                        flash('Error with request', 'danger')

                }

            })();


        }



    }


    const handleDeleteImageMessage = (index, list_index) => {
        // /new-image-message
        // console.log(e)
        // e.preventDefault();
        //TODO:add req to a get new image message
        if (isProcessing === false) {
            setIsProcessing(true);
            (async () => {


                const response = await api.delete(`/new_campaign/delete-image-message/${index}`);
                if (response.ok) {

                    //append to image list
                    // handleAddOnImageMsg(mediaName, response.body);
                    setIsProcessing(false);

                    if (response.body.success === 'ok') {
                        //remove imgmessage from list
                        handleRemoveMessage(mediaName, list_index)
                    }
                    return;

                }
                else {
                    setIsProcessing(false);


                    'body' in response && 'errors' in response.body ?
                        flash(response.body.errors.json.message, 'danger')
                        :
                        flash('Error with request', 'danger')

                }

            })();


        }



    }



    const handleButtonClick = (isBackground = false) => {
        isBackground ?
            backgroundInputRef.current.click()
            :
            fileInputRef.current.click()

    };

    const handleFileChange = (event) => {
        const files = event.target.files;

        if (files.length > 0) {
            let file = files[0]
            const reader = new FileReader();
            reader.onloadend = () => {
                const base64String = reader.result.split(',')[1];

                handleAddImageMessage(base64String)

            };
            reader.readAsDataURL(file);
        }
        // Handle the selected files here
    };


    const handleSaveProgress = (index) => {
        // /new-image-message
        // console.log(e)
        // e.preventDefault();
        //TODO:add req to a get new image message
        setFlashMessage()
        if (isProcessing === false) {
            setIsProcessing(true);
            (async () => {

                const response = await api.put(`/new_campaign/save-custom-media`, JSON.stringify({
                    'custom_messages': image_data['image_messages'],
                    'background_data': image_data['image'],

                })
                );
                if (response.ok) {

                    //append to image list
                    // handleAddOnImageMsg(mediaName, response.body);
                    setIsProcessing(false);
                    setFlashMessage({ 'color': 'success', 'message': 'Your progress was saved' })
                    setTimeout(() => {
                        setFlashMessage()
                    }, 7000);
                    flash('Your progress was saved', 'success')
                    return;

                }
                else {
                    setIsProcessing(false);


                    if ('body' in response && 'errors' in response.body) {

                        flash(response.body.errors.json.message, 'danger')
                        setFlashMessage({ 'color': 'danger', 'message': response.body.errors.json.message })
                    }
                    else {

                        flash('Error with request', 'danger')
                        setFlashMessage({ 'color': 'danger', 'message': 'Error with request' })
                    }
                    setTimeout(() => {
                        setFlashMessage()
                    }, 7000);


                }

            })();


        }



    }

    const handleZIndexChange = (e, layerIndex) => {


        // let targetIndex = parseInt(e.target.value);

        const newIndex = parseInt(e.target.value, 10);
        // const updatedObj = [...myObj];
        let updatedLayers = [...image_data['image_messages']];
        const [movedItem] = updatedLayers.splice(layerIndex, 1);
        updatedLayers.splice(newIndex, 0, movedItem);

        const reorderedLayers = updatedLayers.map((item, i) => ({
            ...item,
            z_index: 100 - i,


        }));



        handleImageMessageLayersSortChange(mediaName, reorderedLayers)

    }


    return (

        <Col className="" xs={12} lg={12}>
            <Form>

                {/* {JSON.stringify(image_data['image_messages'])} */}
                <Row className="mt-2">
                    <Col xs={12} lg={8} xxl={7}><Form.Label><strong>Message layers</strong></Form.Label></Col>
                    {image_data['image_messages'] && image_data['image_messages'].length > 1 && <Col className="d-none d-lg-block"><Form.Label><strong>Order</strong></Form.Label></Col>}

                </Row>

                <div className="my-2">
                    {
                        image_data['image_messages'] && image_data['image_messages'].length > 0 &&
                        image_data['image_messages'].map((v, index) =>
                            v['is_image'] ?
                                <div key={index}>is image</div>
                                :
                                <Row key={index} className="d-flex">
                                    <Col className="my-2" xs={12} lg={8} xxl={7} >
                                        {
                                            v['image_url'] && v['image_url'].length > 0 ?
                                                <Image fluid style={{ maxHeight: '200px' }} src={v['image_url']} className="rounded" />
                                                :

                                                <TextAreaField
                                                    placeholder={"Enter your message here"}
                                                    // placeholder={v['name']}
                                                    // label={v['label']}

                                                    onChange={(e) => { handleImageHover(true); handleMessageChange(e, mediaName, index) }}
                                                    // onChange={(e) => handleMessageChange(e, mediaName, index)}
                                                    formText={'Optional message'}
                                                    value={!v["message"] ? '' : v["message"]}
                                                    onFocus={(e) => { e.preventDefault(); handleImageHover(true) }}
                                                    onBlur={(e) => { e.preventDefault(); handleImageHover(false) }}
                                                />
                                        }
                                    </Col>

                                    {
                                        (!v['image_url'] || v['image_url'].length === 0) ?

                                            <Col className="my-2" xs={12} lg={'auto'} style={{
                                                alignContent: 'center'
                                            }}>

                                                <Row className="d-flex justify-content-end">
                                                    {
                                                        image_data['image_messages'].length > 1 &&
                                                        <Col className="d-lg-flex align-items-center mb-4" xs={"auto"}>
                                                            <Form.Group >
                                                                <Form.Label className="d-block d-lg-none">Order</Form.Label>
                                                                <Form.Select value={index} onChange={(e) => handleZIndexChange(e, index)} aria-label="Default select example">
                                                                    {image_data['image_messages'].map((option, i) => (
                                                                        <option key={i} value={i}>
                                                                            {i + 1}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>


                                                        </Col>
                                                    }
                                                    <Col className="d-none d-lg-flex align-items-center mb-4" xs={"auto"}>
                                                        <OverlayTrigger rootClose={true} trigger="click" placement="left" overlay={<div>
                                                            <EmojiPicker ref={popover2} handleEmojiUpdate={handleEmojiUpdate} index={index} mediaName={mediaName} handleImageMessageListChange={handleImageMessageListChange} messageValue={v['message']} />
                                                        </div>}>
                                                            <Button variant="primary"><FaFaceSmile size={24} /></Button>
                                                        </OverlayTrigger>
                                                    </Col>

                                                    <Col className="d-flex align-items-end mb-4" xs={{ span: "auto" }}>

                                                        <EditorDisplayButton onClick={() => handleToggleEditMessage(index)} color="primary" body={"Layer settings"}>


                                                            <FaGear  size={24} />


                                                        </EditorDisplayButton>


                                                    </Col>

                                                    <Col xs="auto" className="d-flex align-items-end justify-content-between mb-4">
                                                        <EditorDisplayButton onClick={() => handleDeleteImageMessage(v['id'], index)} color="danger" body={"Delete layer"}>


                                                            <div><strong>x</strong></div>

                                                        </EditorDisplayButton>
                                                    </Col>

                                                </Row>
                                            </Col>
                                            :
                                            <Col xs={12} lg={'auto'}>
                                                <Row className="d-flex justify-content-end">
                                                    {
                                                        image_data['image_messages'].length > 1 &&
                                                        <Col className="d-none d-lg-flex align-items-center mb-4" xs={"auto"}>
                                                            <Form.Group >
                                                                <Form.Label className="d-block d-lg-none">Order</Form.Label>

                                                                <Form.Select value={index} onChange={(e) => handleZIndexChange(e, index)} aria-label="Default select example">
                                                                    {image_data['image_messages'].map((option, i) => (
                                                                        <option key={i} value={i}>
                                                                            {i + 1}
                                                                        </option>
                                                                    ))}
                                                                </Form.Select>
                                                            </Form.Group>


                                                        </Col>
                                                    }
                                                    <Col className="d-flex align-items-center mb-4" xs={"auto"}>
                                                        <OverlayTrigger rootClose={true} trigger="click" placement="left" overlay={<div>
                                                            <ImageMsgSettingEditor mediaName={mediaName} fieldRef={popover4} msgData={v} index={index} image_data={image_data} imageHandlers={imageHandlers} hideFullsize={true} />

                                                        </div>}>
                                                            <Button aria-controls={`example-collapse-text-${mediaName}`} variant="outline-primary"><FaGear size={24} /></Button>
                                                        </OverlayTrigger>
                                                    </Col>

                                                    <Col xs="auto" className="d-flex align-items-center justify-content-between mb-4">
                                                        <Button onClick={() => handleDeleteImageMessage(v['id'], index)} variant="danger">

                                                            <div><strong>x</strong></div>
                                                        </Button>
                                                    </Col>

                                                </Row>
                                            </Col>
                                    }
                                </Row>
                        )
                    }
                </div>
                {
                    <div className="d-inline">
                        <div className="mb-3">
                            <Button className="rounded-pill" variant="outline-primary" disabled={isProcessing} onClick={() => handleAddImageMessage()}>Add message</Button>

                            <Button variant="outline-primary" disabled={isProcessing} className="m-3 rounded-pill" onClick={() => handleButtonClick()}>
                                Add image
                            </Button>
                            <Button className="rounded-pill" variant="outline-primary" disabled={isProcessing} onClick={() => handleSaveProgress()}>
                                Save progress
                            </Button>

                            <Form.Control style={{ display: 'none' }} onChange={(e) => handleFileChange(e)} ref={fileInputRef} accept="image/*" type="file" />

                            <div className="text-left">
                                {
                                    flashMessage && <span className={` fw-bold text-${flashMessage['color']}`}> {flashMessage['message']} </span>
                                }
                            </div>
                        </div>

                    </div>
                }


            </Form >


        </Col >



    )
}

export default CustomMedia
