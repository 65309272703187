import React, { useState, useEffect } from 'react'
import Body from '../components/Body'
import { useApi } from '../contexts/ApiProvider';
import Spinner from 'react-bootstrap/Spinner';
import Container from 'react-bootstrap/Container';
import Stack from 'react-bootstrap/Stack';
import Campaign from '../components/Campaign';




function SupportedCampaigns() {

    //TODO: Remove whatever the fuck is below. Put the Campaign component here. Put how much they contributed also!!!!

    const [campaigns, setCampaigns] = useState();
    const api = useApi();
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        setIsLoading(true);
        (async () => {
            const response = await api.get('/supported_campaigns');
            if (response.ok) {
                setIsLoading(false);
                response.body.data.length === 0 ?
                    setCampaigns(null)
                    :
                    setCampaigns(() => response.body.data);

            }
            else {
                setIsLoading(false);
                setCampaigns(null);
            }
        })();


    }, [api]);


    return (
        <Body scrollable sidebar>
            <div >
                <h2 className="pricingSubHeader">Supported Campaigns</h2>
                <Container>
                    {
                        campaigns === undefined ?
                            <div className="mt-5 d-flex justify-content-center"><Spinner /></div>
                            :
                            campaigns === null ?
                                <div className="mt-5 d-flex justify-content-center"><h4>No supported campaigns yet</h4></div>

                                :
                                <Stack gap={3}>
                                    {campaigns.map((campaign, v) =>
                                        <Campaign key={campaign.id} campaign={campaign} isProcessing={isLoading} />
                                    )}
                                </Stack>
                    }
                </Container>
            </div>
        </Body>
    )
}

export default SupportedCampaigns
