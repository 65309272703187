import React from 'react'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import CustomRangeField from './CustomRangeField';
import Row from 'react-bootstrap/Row';
import RadiusBadge from './RadiusBadge';


function POIRadius({ showRadius, radius, handleRadiusChange, confirm, handleSetRadius, lastCircle }) {


    const MAX_RADIUS = 50;
    const MIN_RADIUS = .25;

    const onRadiusChange = (e) => {

        let radius = e.target.value
        console.log(radius, e)

        handleRadiusChange(radius)

    }


    // const increment = () => {
    //     if (radius < MAX_RADIUS) {
    //         handleRadiusChange((parseFloat(radius) + 0.25).toFixed(2));
    //     }
    // };

    // // Decrement function to decrease the value by 0.25
    // const decrement = () => {
    //     if (radius > MIN_RADIUS) {
    //         handleRadiusChange((parseFloat(radius) - 0.25).toFixed(2));
    //     }
    // };



    return (
        <Form.Group className="">
            <Form.Label>
                <h3 className="d-none d-lg-block"><strong>Radius</strong></h3>
                <h5 className="d-lg-none d-block mb-0"><strong>Radius</strong></h5>

            </Form.Label>
            {
                showRadius && lastCircle ?


                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Col >
                            <div >
                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                            
                                    <div
                                        className="m-2 w-100"
                                    >
                                        
                                        <CustomRangeField
                                            min={MIN_RADIUS}
                                            max={MAX_RADIUS}
                                            step={.25}
                                            value={radius}
                                            onChange={(e) => onRadiusChange(e)}
                                            hideValue
                                        />
                                    </div>
                                    {/* <div>
                                            <p className="mb-0 pb-0 text-muted">{`Current size:  miles`}</p>
                                        </div> */}
                                    {/* <div
                                        style={iconStyle}
                                        onClick={increment}
                                    >
                                        <FaPlus style={{ color: 'white', fontSize: '20px' }} />
                                    </div> */}
                                </div>
                                <div>
                                    
                                    <p className="mb-0 pb-0 text-muted">Current size: <RadiusBadge radius={radius} /></p>
                                </div>
                            </div>
                        </Col>

                        {confirm &&
                            <Col style={{ alignContent: 'center', textAlign: 'end' }}><Button onClick={() => handleSetRadius()} variant='primary' size="sm">Confirm</Button></Col>
                        }




                    </div>

                    :
                    <Row>
                        <Col xs={9}>
                            <div className="text-secondary">Search for a location to set a radius

                            </div>
                        </Col>

                        {confirm &&
                            <Col className="d-lg-none" xs="auto" style={{ alignContent: 'center', textAlign: 'end' }}><Button onClick={() => handleSetRadius()} variant='danger' size="sm">Cancel</Button></Col>
                        }

                    </Row>


                // <CustomRangeField
                //     min={MIN_RADIUS}
                //     max={MAX_RADIUS}
                //     step={.25}
                //     value={radius}
                //     onChange={handleRadiusChange}

                //     disabled
                // />
            }


            {/* <p className="mb-0 pb-0 text-muted">{(showRadius && lastCircle) ? `Current size: ${radius} miles` : <strong>Search for a location to set a radius</strong>}</p> */}
        </Form.Group >
    )
}

export default POIRadius
