import React from 'react';
import Body from '../components/Body';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import OrDivider from '../components/OrDivider';
import GoogleButton from '../components/GoogleButton';
import { Helmet } from 'react-helmet';
import SignUp from '../components/SignUp';
import { Link } from 'react-router-dom';


export default function RegistrationPage() {


    return (
        <>
            <Helmet>
                <title>CrowdPost - Sign Up</title>
                <meta property="og:title" content={`Let your voice reach the real town square`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
                <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." />

            </Helmet>
            <Body scrollable footer>



                <Row className="my-4" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
                    <Col  xxl={5}>
                        <h1 className="mb-3"><strong>Sign up</strong></h1>
                        <SignUp />

                        <OrDivider />

                        <Row className="d-flex justify-content-center my-3">

                            <Col >
                                <GoogleButton signUp={true} text={'Sign up with google'} />
                            </Col>
                        </Row>


                        <div>
                            <p className="fs-5 mt-3">Request a new account account confirmation token <Link to="/new-confirmation-token">here</Link></p>
                        </div>
                    </Col>

                </Row>
            </Body>



        </>
    );
}