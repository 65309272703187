import React from 'react'
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/esm/Container';
import Footer from '../components/Footer';
import "../styles/PricingPage.css"
import ContributionPricing from '../components/ContributionPricing';



function PricingPage() {



    return (
        <>
            <Helmet>
                <title>CrowdPost - Pricing</title>
                <meta property="og:title" content={`Let your voice reach the real town square`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
                {/* <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." /> */}

            </Helmet>
            <Container className="mb-5">

                <Row >

                    <Col className="mb-3 mt-5" xs={12}>
                        <h1 className="pricingHeader">Simple pricing for powerful fundraising</h1>
                        <h4 className="text-muted">
                            Creating CrowdPost campaigns is free. Contributions are only charged if the campaign reaches the threshold to purchase media in the campaign area. If enough is raised but the campaign is denied by our media partners, contributors are charged 99¢. If a campaign is accepted, contributors are charged based off the percentage their contribution made up of the total amount raised, up to their original contribution amount. We do this so users are only charged for what is purchased. If a campaign is accepted and raises enough to purchase media, CrowdPost charges a 20% management fee from the total raised. 
                            {/* Creating a campaign on CrowdPost is free. CrowdPost charges a {PLATFORM_FEE} platform fee off the total amount raised. When contributing to campaigns your payment is not processed until we receive a decision from our media partners, at that point there are 3 possible payment outcomes. */}
                            {/* Creating a campaign on CrowdPost is free. CrowdPost charges a {PLATFORM_FEE} platform fee off the total amount raised. When contributing to campaigns your payment is not processed until we receive a decision from our media partners, at that point there are 3 possible payment outcomes: */}
                            {/* <ol className="mt-3">
                                <li> If a campaign does not raise enough funds to purchase media <strong>you will not be charged.</strong> </li>
                                <li> If a campaign is denied by our media partners, you will be charged 99¢ instead of your original contribution amount.</li>
                                <li> If a campaign is accepted, you will be charged based on your share of the total amount raised, up to your maximum contribution. This is so that if a campaign raises more money than the cost of the campaign, you wont be overcharged. </li>

                            </ol> */}


                            {/* 
                            1) If a campaign does not raise enough funds <strong>you will not be charged.</strong>  2) If a campaign is denied by our media partners, charged 99¢ instead of your original contribution. 3) If a campaign is accepted, you will be charged based on your share of the total amount raised, up to your maximum contribution. If a campaign raises more money than the cost of the campaign, you wont be overcharged.  CrowdPost purchases as much media as possible in the campaign area after a {PLATFORM_FEE} platform fee off the total amount raised. */}
                        </h4>
                    </Col>
                </Row>





            </Container >

            <Container fluid className="bg-dark my-5">
                <Container className="py-5">

                    <Row className="my-5">
                        <Col className="my-5" xs={12} >
                            <h1 className="pricingSubHeader">Creating CrowdPost campaigns is free</h1>
                            <h3 className="text-secondary">
                                Create an account, submit a campaign and let the people decide what is in our public discourse.
                            </h3>
                        </Col>
                    </Row>

                </Container>


            </Container>

            <Container>

                <ContributionPricing receipt/>

            </Container>

            <div style={{ marginTop: '20rem' }}>
                <Footer />
            </div>



        </>
    )
}

export default PricingPage