import React, { useRef, useState, useEffect } from 'react'
import InputField from './InputField'
import Form from 'react-bootstrap/Form';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import 'react-quill/dist/quill.snow.css'; // Import styles
import '../styles/DescriptionQuill.css'
import DescriptionQuill from './DescriptionQuill';
import ImageModal from './ImageModal';




function CampaignDescriptionForm({ campaignId, descriptionData, handleDescriptionUpdate }) {




    const imageField = useRef();

    const [formErrors, setFormErrors] = useState({});
    const api = useApi()
    const flash = useFlash()
    const [isLoading, setIsLoading] = useState(false)

    const quillRef = useRef();
    const [youtubeURL, setYoutubeURL] = useState(descriptionData.youtube_url);



    const [base64Images, setBase64Images] = useState(descriptionData.images);

    const [modalImage, setModalImage] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);

    const handlShowImageModal = (image) => {
        console.log(image)
        console.log(base64Images[image])
        setModalImage(base64Images[image]);
        setShowImageModal(true)

    }
    const handlHideImageModal = (image) => {
        setModalImage();
        setShowImageModal(false)

    }


    useEffect(() => {
        if (quillRef.current && descriptionData.description) {

            const editor = quillRef.current.getEditor();
            const initialContent = descriptionData.description;
            const delta = editor.clipboard.convert(initialContent);
            editor.setContents(delta, 'silent');
        }

    }, [])


    const handleRemoveImage = (index) => {
        const updatedBase64Images = base64Images.filter((_, i) => i !== index);
        // const updatedSelectedFiles = selectedFiles.filter((_, i) => i !== index);
        setBase64Images(updatedBase64Images);
        // setSelectedFiles(updatedSelectedFiles);
    };


    const handleFileChange = (event) => {
        var files = Array.from(event.target.files);
        // files.concat(base64Images)
        if (files.length > 3) {
            alert('You can only upload up to 3 images.');
            return;
        }

        console.log(files)

        console.log(typeof (files[0]))
        // setSelectedFiles(files);
        const promises = files.map((file) =>
            convertToBase64(file)



        );

        Promise.all(promises).then((base64Strings) => {
            setBase64Images((prev) => [...prev, base64Strings]);
        });
    };

    const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result);
            reader.onerror = (error) => reject(error);
        });
    };






    const onSubmit = async (ev) => {
        ev.preventDefault();





        //client sid field validation
        const errors = {};
        // if (!description) {
        //     errors.description = 'Description must not be empty.';
        // }


        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }
        // end client side field validation 
        setIsLoading(true);
        try {


            (async () => {
                const quillEditor = quillRef.current.getEditor();
                const content = quillEditor.root.innerHTML;
                const response = await api.put(`/update-description/${campaignId}`, { description: content, youtube_url: youtubeURL, images: base64Images });
                if (response.ok) {
                    setIsLoading(false);
                    flash('Your description and title were set', 'success');
                    handleDescriptionUpdate(response.body)

                }
                else {
                    setIsLoading(false);
                    if ("errors" in response.body) {
                        setFormErrors(() => response.body.errors.json);
                        flash(response.body.errors.json.message, 'danger');
                    }
                    else if (response.status === 401) {
                        console.log(response.body);
                        flash(response.body.description, 'danger');

                    } else {
                        flash('There was an error sending your message', 'danger');
                    }
                    // "errors" in response.body ?
                    //     (setFormErrors(() => response.body.errors.json),
                    //         flash(response.body.errors.json.message, 'danger'))
                    //     :
                    //     flash('There was an error sending your message', 'danger')



                }
            })();
        } catch (error) {
            setIsLoading(false)
        }


    };






    return (


        <Row style={{ justifyContent: 'center' }}>
            <ImageModal image={modalImage} show={showImageModal} hide={handlHideImageModal} />

            {/* {JSON.stringify(descriptionData)} */}

            <Col sm={12} >
                <Form onSubmit={onSubmit}>


                    <DescriptionQuill
                        formErrors={formErrors}
                        quillRef={quillRef}
                    />

                    <Form.Group controlId="formFile" className="mb-3">
                        <Form.Label><strong>Upload an image</strong></Form.Label>
                        <Form.Control onChange={handleFileChange} multiple accept="image/*" ref={imageField} type="file" />
                        <Form.Text>Optional*This will appear in the description of your campaign</Form.Text>
                        <div>{formErrors && 'images' in formErrors && <Form.Text className="text-danger">{formErrors['images']}</Form.Text>}</div>
                        <Row>

                            {base64Images.map((image, index) => (
                                <Col key={index}>
                                    <div style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
                                        {/* <img onClick={() => handlShowImageModal(image)} src={image} alt={`uploaded`} style={{ maxWidth: '200px' }} /> */}
                                        <Image
                                            src={image}
                                            style={{
                                                width: "100%",
                                                height: "200px",
                                                objectFit: 'contain',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handlShowImageModal(index)}
                                        />


                                    </div>
                                    <div>
                                        <Button
                                            onClick={() => handleRemoveImage(index)}
                                            className="rounded-pill"
                                            variant='danger'
                                        >
                                            <strong>Remove</strong>
                                        </Button>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Form.Group>

                    <InputField
                        formLabel={<strong>Youtube URL</strong>}
                        value={youtubeURL}
                        onChange={(e) => { e.preventDefault(); setYoutubeURL(e.target.value) }}
                        name="youtube_url"
                        label="Youtube URL"
                        error={formErrors.youtube_url}
                        formText="Optional* This will appear in the description of your campaign"
                    />

                    <Row>
                        <Col xs={12}>
                            <Button disabled={isLoading} type="submit" variant="primary" className="d-block w-100"><strong>Submit</strong></Button>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>






    )
}

export default CampaignDescriptionForm
