import React from 'react'

import Campaigns from '../components/Campaigns';
import { Helmet } from 'react-helmet';
import Body from '../components/Body';




export default function ExplorePage() {

    return (
        <>


            <Body fullwidth classStr={{ padding: 0, margin: 0 }}>


                
                    <Campaigns routeName="explore" />
                
            </Body>

        </>
    );
}