import React, { useEffect, useState } from 'react'


function GetPrice({ priceInCents, children, userPayment, justPrice }) {

    const [price, setPrice] = useState()

    const handlePrice = (priceInCents) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(priceInCents / 100);
    };


    useEffect(() => {
        let p = handlePrice(priceInCents)

        setPrice(() => p)
    }, [priceInCents])


    return (
        // <Row>
        <div>
            {
                userPayment ?
                    <>
                        <strong>{price} </strong>{!userPayment && !children && <span className="text-muted">raised</span>}
                    </>
                    :
                    <>
                        <strong>{price} </strong>{ !justPrice && (<span className="text-muted">{children}</span>)} 
                    </>
            }
        </div>

    )
}

export default GetPrice
