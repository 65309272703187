import React, { useState } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';


const secondsTable = [
    ['year', 60 * 60 * 24 * 365],
    ['month', 60 * 60 * 24 * 30],
    ['week', 60 * 60 * 24 * 7],
    ['day', 60 * 60 * 24],
    ['hour', 60 * 60],
    ['minute', 60],
];




function getElapsedTime(startDate, endDate) {
    const totalSeconds = Math.round((startDate.getTime() - endDate.getTime()) / 1000);
    const elapsedSeconds = Math.round((new Date().getTime() - startDate.getTime()) / 1000);

    const absTotalSeconds = Math.abs(totalSeconds);
    const absElapsedSeconds = Math.abs(elapsedSeconds);
    let percentComplete = absElapsedSeconds / absTotalSeconds;
    percentComplete = Math.round(percentComplete * 100);

    percentComplete = percentComplete > 100 ? 100 : percentComplete

    const currentDate = new Date();

    // Compare the getTime() values
    if (startDate.getTime() > currentDate.getTime()) {

        percentComplete = 0;
        return [0, 'second']
    }
    // const seconds = Math.round((date.getTime() - new Date().getTime()) / 1000);
    const absSeconds = Math.abs(absElapsedSeconds);

    let bestUnit, bestTime, bestInterval;

    for (let [unit, unitSeconds] of secondsTable) {
        if (absSeconds >= unitSeconds) {
            bestUnit = unit;
            bestTime = Math.round(elapsedSeconds / unitSeconds);
            bestInterval = unitSeconds / 2;
            break;
        }
    };
    if (!bestUnit) {
        bestUnit = 'second';
        bestTime = parseInt(elapsedSeconds / 10) * 10;
        bestInterval = 10;
    }

    return [percentComplete, bestInterval];
}



function ElapsedTime({ startDate, endDate, mobile, createDate, daysLeft }) {

    endDate = new Date(Date.parse(startDate));
    startDate = new Date(Date.parse(createDate));



    const [percentComplete, interval] = getElapsedTime(startDate, endDate)
    const [, setUpdate] = useState(0); //each time u want this to re render increment setUpdate


    const start_date = startDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit', })

    const end_date = endDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
    const end_time = endDate.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })



    return (

        mobile ?
            <Row >
                {
                    daysLeft === false || daysLeft === null || daysLeft === undefined ?

                        <p></p>
                        :
                        daysLeft && daysLeft > 5 ?
                            <div className='text-center text-success'>{daysLeft} days left to contribute</div>
                            :
                            daysLeft < 0 ?
                                <div className='text-center text-secondary'>No days left to contribute</div>
                                :
                                daysLeft === 0 ?
                                    <div className='text-center text-warning'>Last day to contribute</div>
                                    :
                                    <div className='text-warning'>{daysLeft} {daysLeft === 1 ? 'day' : 'days'} left to contribute</div>

                }
                {

                    <>
                        <Col xs={12}>
                            <ProgressBar variant="success" now={percentComplete} label={`${percentComplete}%`} style={{ fontSize: '18px', fontWeight: '600' }} />
                        </Col>
                        <Col xs={12}>
                            <Row>
                                <Col className="text-start">
                                    {/* {start_date} <br />{start_time} */}
                                    {start_date} <br />
                                </Col>
                                <Col className="d-flex justify-content-end text-end">
                                    {end_date} <br />{end_time}
                                </Col>
                            </Row>
                        </Col>
                    </>
                }
            </Row>
            :
            <Row className="d-lg-inline">
                <Col className="text-center fw-bold">

                    {
                        daysLeft === false || daysLeft === null || daysLeft === undefined ?

                            <p></p>
                            :
                            daysLeft && daysLeft > 5 ?
                                <div className='text-success'>{daysLeft} days left to contribute</div>
                                :
                                daysLeft < 0 ?
                                    <div className='text-secondary'>No days left to contribute</div>
                                    :
                                    daysLeft === 0 ?
                                        <div className='text-warning'>Last day to contribute</div>
                                        :
                                        <div className='text-warning'>{daysLeft} {daysLeft === 1 ? 'day' : 'days'} left to contribute</div>

                    }

                </Col>
    

                        <Col style={{ marginBottom: '1rem' }}>
                            {start_date} <br />
                        </Col>
                        <Col xs={6} lg={12}>
                            {
                                percentComplete === 0 ?
                                    <ProgressBar className="bg-secondary" variant="primary" now={100} label={`${percentComplete}%`} style={{ 'color': 'black', fontWeight: '600' }} />
                                    :
                                    <ProgressBar className="bg-secondary" variant="success" now={percentComplete} label={`${percentComplete}%`} style={{ 'color': 'black', fontWeight: '600' }} />
                            }

                        </Col>
                        <Col style={{ textAlign: "end", marginTop: '1rem' }}>
                            {end_date} <br />{end_time}
                        </Col>

            </Row >



        // <div><strong> -- </strong>{rtf.format(time,unit)}</div>
    )
}

export default ElapsedTime