import { createContext, useContext, useState, useCallback } from 'react';






const StripeContext = createContext();

export default function StripeProvider({ children }) {
    const [key, setKey] = useState(useState(Math.random() * 2.5))
    const [paymentIntentId, setPaymentIntentId] = useState();

    const handleRefreshStripe = useCallback(() => setKey(Math.random() * 2.5), [])

    const handleUpdatePaymentIntentId = useCallback((data) => setPaymentIntentId(data), [])



    return (
        <StripeContext.Provider value={{ key, handleRefreshStripe, paymentIntentId, handleUpdatePaymentIntentId }}>


            {

                children
            }
        </StripeContext.Provider>
    );
}

export function useStripeContext() {
    return useContext(StripeContext);
}