import React from 'react'

function YoutubeComponent({ video_id }) {

    return (
        video_id && 
        <iframe width="560" height="315" src={`https://www.youtube.com/embed/${video_id}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>

    )
}

export default YoutubeComponent
