import React, { useEffect, useState, useRef } from 'react'
import { useApi } from '../contexts/ApiProvider'
import { useFlash } from '../contexts/FlashProvider'
import { useNavigate, useParams } from 'react-router'
import Body from '../components/Body'


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Badge from 'react-bootstrap/Badge';




import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import { Link } from 'react-router-dom';
import { MdArrowBackIosNew } from 'react-icons/md';

function CampaignMapPage() {
    const api = useApi()
    const flash = useFlash();
    const { campaignId } = useParams();
    const navigate = useNavigate();
    const [respData, setRespData] = useState();
    const mapContainer = useRef(null);
    const map = useRef(null);
    const popupRef = useRef(new mapboxgl.Popup({ closeButton: true }));

    useEffect(() => {
        (async () => {
            const response = await api.get("/campaign-map/" + campaignId);
            if (response.ok) {
                setRespData(() => response.body);
                console.log(response)

                if (map.current) {

                    return

                }



                var starting_loc = false


                if (!starting_loc) {
                    // if (starting_loc && starting_loc[0].includes(undefined)) {

                    map.current = new mapboxgl.Map({
                        container: mapContainer.current,
                        style: process.env.REACT_APP_MAPBOX_CAMPAIGN_PAGE_MAP,
                        bounds: response.body.location.bbox,
                        // center: [44,74],
                        // // center: [defaultLoc[0], defaultLoc[1]],
                        // zoom: 9,
                        projection: 'mercator',
                    })


                    map.current.on('load', () => {
                        console.log(response.body.location, ' LOCATION ')
                        // Add GeoJSON source
                        map.current.addSource('geojson-data', {
                            type: 'geojson',
                            data: response.body.location.polygons, // URL to your GeoJSON file
                        });

                        // Add a layer to use the GeoJSON source
                        map.current.addLayer({
                            id: 'geojson-layer',
                            type: 'fill', // or 'line', 'circle', 'symbol', etc.
                            source: 'geojson-data',
                            layout: {},
                            paint: {
                                'fill-color': '#888888', // Customize the fill color
                                'fill-opacity': 0.4, // Customize the fill opacity
                            },
                        });
                    });



                }
                else {
                    map.current = new mapboxgl.Map({
                        container: mapContainer.current,
                        style: process.env.REACT_APP_MAPBOX_CAMPAIGN_PAGE_MAP,
                        // center: [74, 44],
                        bounds: starting_loc,
                        // center: [defaultLoc[0], defaultLoc[1]],
                        // zoom: 9,
                        projection: 'mercator',
                    })
                }

            }
            else {
                setRespData(false);
                console.log(response)
                navigate('/', { pathname: "/" })
                flash('Error', 'danger')

            }
        })();
    }, [map])



    const showPopup = (id) => {

        let counter = 1
        console.log(id)
        var name
        const s = map.current.querySourceFeatures('geojson-data');
        // s = s.features
        for (let i in s) {
            i = s[i]
            console.log(i)
            var coordinates;
            if (i.properties.id === id && 'poi_name' in i.properties && i.properties.poi_name) {
                name = i.properties.name
                coordinates = i.geometry.coordinates[0][0]
                console.log('GO', i.geometry.coordinates[0][0])
            }
            else if (i.properties.id === id) {
                // counter++
                // name = `Custom area ${counter}`
                name = i.properties.name
                coordinates = i.geometry.coordinates[0][0]
            }
            else {
                counter++;
            }
        }
        if (name) {
            const popupContent = `<p >${name}</p>`;
            popupRef.current.setLngLat(coordinates).setHTML(popupContent).addTo(map.current);
            map.current.flyTo({
                center: coordinates,
                zoom: 9,
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
        }

    }




    return (
        <>
            <Container fluid>
                <Row>
                    <Col xs={12}>
                        <Link

                            to={{ pathname: '/campaign/' + campaignId }}
                        ><Button style={{ textDecoration: 'none' }} variant="light">
                                <MdArrowBackIosNew size={20} />  <u>Campaign Page</u>
                            </Button>
                        </Link>
                    </Col>
                </Row>
            </Container>
            <Body>
                {

                    !respData ?

                        <Spinner />
                        :
                        <Row>

                            <Col xs={12}>
                                <h1>
                                    <strong>Map for Campaign: {respData['title']}</strong>
                                </h1>
                            </Col>
                            <Col className='campaignMap px-0 mx-0' lg={12} xs={12}>
                                <div ref={mapContainer} style={{ 'height': '50vh' }} />
                                <div className="mt-3">
                                    {/* <h5 className="fw-bolder">Custom Areas</h5> */}
                                    {(respData.location.polygons !== null && respData.location.polygons.features.length !== 0) &&
                                        respData.location.polygons.features.map((i, index) =>
                                            <Row key={index}>

                                                <div className="d-flex align-items-center justify-content-between py-2">
                                                    {
                                                        'properties' in i && 'poi_name' in i.properties && i.properties.poi_name ?
                                                            <span >{i.properties.name}<Badge className="mx-2 bg-primary rounded-pill">{i.properties.poi_radius} mile radius</Badge></span>
                                                            :
                                                            <span >{i.properties.name}</span>

                                                    }

                                                    <div className="">
                                                        <Badge pill bg="success" onClick={() => showPopup(i.id)} style={{ marginRight: ".4rem", cursor: 'pointer', fontSize: '1rem' }} >Show</Badge>
                                                    </div>

                                                </div>


                                            </Row>
                                        )}
                                </div>

                            </Col>



                        </Row>


                }

            </Body>
        </>
    )
}

export default CampaignMapPage
