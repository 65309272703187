import React, { useEffect, createContext, useContext, useState, } from 'react';


const ImageBuilderContext = createContext();

export default function ImageBuilderProvider({ children }) {
    const [activeLayer, setActiveLayer] = useState();
    const [respData, setRespData] = useState();
    const [imgHover, setImgHover] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isMockupLoading, setIsMockupLoading] = useState(false)
    const [modalData, setModalData] = useState()

    const [showModal, setModal] = useState(false);

    const [taskIds, setTaskIds] = useState(false)

    const [imgEditDisplay, setImgEditDisplay] = useState(false);

    const handleToggleImgEditDisplay = (mediaName) => {


        if (!imgEditDisplay) {
            setImgEditDisplay({ [mediaName]: true })
        }
        else if (Object.hasOwn(imgEditDisplay, mediaName)) {
            imgEditDisplay[mediaName] ?
                setImgEditDisplay((prev) => ({
                    ...prev,
                    [mediaName]: false
                }))
                :
                setImgEditDisplay((prev) => ({
                    ...prev,
                    [mediaName]: true
                }))
        }
        else if (!Object.hasOwn(imgEditDisplay, mediaName)) {
            setImgEditDisplay((prev) => ({
                ...prev,
                [mediaName]: true
            }))

        }
    }
    // imgEditDisplay ? setImgEditDisplay(false) : setImgEditDisplay(true);
    const defaultColors = [
        "#ffffff",
        "#000000",
        "#ff0000",
        "#00ff00",
        "#0000ff",
        "#800080"
    ]


    const handleMockupLoading = (data) => setIsMockupLoading(data);

    const handleImgHoverChange = () => imgHover ? setImgHover(false) : setImgHover(true);

    const handleImgHoverUpdate = (data) => setImgHover(data);


    const handleDisabledChange = (data) => setDisabled(data);

    const handleModalDataChange = (data) => setModalData(data);

    const handleShowModalChange = (data) => setModal(data);


    const handleUpdateAIImages = (images, accountBalance) => {

        setRespData((prevState) => ({
            ...prevState,
            'ai_images': {
                ...prevState.ai_images,
                'images': [...images, ...(prevState.ai_images.images || [])]
                // 'images': [...images, ...(prevState.ai_images.images || [])]
            },
            'account_balance': accountBalance
            // 'ai_images': [...data['images'], ...(prevState.ai_images || [])]
            // 'imgs': data
        }));

    }

    const handleRespDataChange = ({ k = false, data }) => {


        if (k) {
            if (k.includes('mockup_url')) {



                setRespData((prev) => ({
                    ...prev,
                    [k]: data

                }));
            }
            else {

                data = data['data']
                setRespData((prev) => ({
                    ...prev,
                    [k]: {
                        ...prev[k],
                        ...data
                    }

                }));
            }
        }
        else {



            setRespData((prev) => ({ ...prev, ...data }));
        }

    }


    const handleImageTypesChange = (key, data) => {





        setRespData((prev) => ({
            ...prev,
            'image_types': {
                ...prev['image_types'],
                [key]: {
                    ...prev['image_types'][key],
                    ...data
                }
            }
        }));
    }



    const handleMessageChange = (e, mediaName, msgIndex) => {





        if (msgIndex === 'base') {
            return

        }
        else {

            let newImageMessages = [...respData['image_types'][mediaName]['image_messages']]
            newImageMessages[msgIndex] = {
                ...newImageMessages[msgIndex],
                message: e.target.value
            }
            const updated_resp_data = {
                ...respData,
                'image_types': {
                    ...respData['image_types'],
                    [mediaName]: {
                        ...respData['image_types'][mediaName],
                        'image_messages': [
                            ...newImageMessages
                        ]
                    }
                }

            }

            setRespData(updated_resp_data)
            // handleImageMessageChange(mediaName, updated_resp_data);

        }
    }



    const handleImageMessageLayersSortChange = (mediaName, updates) => {
        ///todo: make this have an argument defaulitng to false. if it is true. then change the entire array. this will coe from the z index call that re sorts everything. 

        const updated_resp_data = {
            ...respData,
            'image_types': {
                ...respData['image_types'],
                [mediaName]: {
                    ...respData['image_types'][mediaName],
                    'image_messages': updates
                }
            }

        }

        setRespData(updated_resp_data)
        // handleImageMessageChange(mediaName, updated_resp_data);
    }




    const handleImageMessageListContextChange = (mediaName, msgIndex, updates) => {
        ///todo: make this have an argument defaulitng to false. if it is true. then change the entire array. this will coe from the z index call that re sorts everything. 
        let newImageMessages = [...respData['image_types'][mediaName]['image_messages']]
        newImageMessages[msgIndex] = {
            ...newImageMessages[msgIndex],
            ...updates
        }
        const updated_resp_data = {
            ...respData,
            'image_types': {
                ...respData['image_types'],
                [mediaName]: {
                    ...respData['image_types'][mediaName],
                    'image_messages': [
                        ...newImageMessages
                    ]
                }
            }

        }

        setRespData(updated_resp_data)
        // handleImageMessageChange(mediaName, updated_resp_data);

    }





    const handleUpdateRespData = (mediaName, new_data) => {


        const updated_resp_data = {
            ...respData,
            'image_types': {
                ...respData['image_types'],
                [mediaName]: {
                    ...respData['image_types'][mediaName],
                    'image': {
                        ...respData['image_types'][mediaName]['image'],
                        ...new_data
                    }
                }
            }

        }

        setRespData(updated_resp_data)
        // handleImageMessageChange(mediaName, updated_resp_data);

    }


    const handleAddOnImageMsg = (mediaName, data) => {

        // const updated_resp_data = {
        //     ...image_data,
        //     "image_messages": [
        //         ...image_data["image_messages"],
        //         data
        //     ]
        // }

        var updated_resp_data

        if (respData['image_types'][mediaName]['image_messages'] && respData['image_types'][mediaName]['image_messages'].length === 0) {
            updated_resp_data = {
                ...respData,
                'image_types': {
                    ...respData['image_types'],
                    [mediaName]: {
                        ...respData['image_types'][mediaName],
                        'image_messages': data
                    }
                }

            }
        }
        else {

            updated_resp_data = {
                ...respData,
                'image_types': {
                    ...respData['image_types'],
                    [mediaName]: {
                        ...respData['image_types'][mediaName],
                        'image_messages': [
                            ...respData['image_types'][mediaName]['image_messages'],
                            data
                        ]
                    }
                }

            }
        }


        setRespData(updated_resp_data)
    }


    const handleRemoveMessage = (mediaName, index) => {

        let newImageMessages = [...respData['image_types'][mediaName]['image_messages']]
        newImageMessages.splice(index, 1);

        const updated_resp_data = {
            ...respData,
            'image_types': {
                ...respData['image_types'],
                [mediaName]: {
                    ...respData['image_types'][mediaName],
                    'image_messages': newImageMessages

                }
            }

        }


        setRespData(updated_resp_data)


    }


    const handleUpdateBaseMessage = (mediaName, data) => {


        const updated_resp_data = {
            ...respData,
            'image_types': {
                ...respData['image_types'],
                [mediaName]: {
                    ...respData['image_types'][mediaName],
                    'base_message': {
                        ...respData['image_types'][mediaName]['base_message'],
                        ...data
                    }

                }
            }

        }


        setRespData(updated_resp_data)
    }


    const handleThumbnailChange = (index) => {
        (
            setRespData((prevState) => {
                const updatedImageTypes = Object.keys(prevState['image_types']).reduce(
                    (acc, key) => {
                        acc[key] = {
                            ...prevState['image_types'][key],
                            image: {
                                ...prevState['image_types'][key].image,
                                thumbnail: key === index ? true : false,
                            },
                        };
                        return acc;
                    },
                    {}
                );

                return {
                    ...prevState,
                    'image_types': updatedImageTypes,
                };
            })

        )
    }



    const handleFontBackgroundColorOpacityChange = (e, index, mediaName) => {
        let opacity = e.target.value;
        let currentColor;
        let color;


        if (parseInt(opacity) <= 99) {


            if (opacity <= 9) {
                opacity = `0${opacity}`
            }


            if (respData['image_types'][mediaName]['image']['background_color'].length === 9) {
                currentColor = respData['image_types'][mediaName]['image']['background_color'].slice(0, 7); // Remove the alpha value
                color = currentColor + opacity

            }
            else {
                color = respData['image_types'][mediaName]['image']['background_color'] + `${opacity}`

            }

            setRespData((prev) => ({
                ...prev,
                'image_types': {
                    ...prev.image_types,
                    [mediaName]: {
                        ...prev.image_types[mediaName],
                        'image': {
                            ...prev.image_types[mediaName]['image'],
                            background_color: color,
                            background_color_opacity: opacity
                        }
                    }
                }
            }))



        }
        if (parseInt(opacity) === 100) {
            currentColor = respData['image_types'][mediaName]['image']['background_color'].slice(0, 7); // Remove the alpha value
            color = currentColor;
            setRespData((prev) => ({
                ...prev,
                'image_types': {
                    ...prev.image_types,
                    [mediaName]: {
                        ...prev.image_types[mediaName],
                        'image': {
                            ...prev.image_types[mediaName]['image'],
                            background_color: color,
                            background_color_opacity: opacity
                        }
                    }
                }
            }))
            // handleImageMessageListChange(index, { "background_opacity": opacity, "background_color": color })



        }
    }



    const handleOutlineChange = (e, key_type, base = true, mediaName, index = false) => {

        let data
        if (key_type === 'outline_blur') {

            data = { "outline_blur": e.target.value }

        }
        else if (key_type === 'outline_color') {
            data = { "outline_color": e }
        }
        else if (key_type === 'outline_width') {

            data = { "outline_width": e.target.value }

        }
        else if (key_type === 'change_to_blur') {
            data = { 'outline_width': 0, 'outline_type': 'shadow', 'outline_color': 'white' }

        }
        else if (key_type === 'change_to_none') {


            data = { 'outline_color': 'transparent', 'outline_type': 'none', 'outline_width': 0 }

        }
        else if (key_type === 'change_to_outline') {

            data = { 'outline_type': 'outline', 'outline_blur': 0, 'outline_color': 'white' }


        }
        if (base) {

            handleUpdateBaseMessage(mediaName, data)
        }
        else {
            handleImageMessageListContextChange(mediaName, index, data)
        }
        return


    }


    const handleUpdateTaskIds = (data) => setTaskIds(data);

    const handleShowModal = (isSample, respUrl = false) => {


        isSample ?
            handleModalDataChange(() => ({ header: 'Sample Mockup', image: respUrl ? respUrl : respData['sample_mockup_image'] }))
            :
            handleModalDataChange(() => ({ header: 'Current saved mockup', image: respUrl ? respUrl : respData['mockup_image'] }))


        handleShowModalChange(true);
    }


    return (
        <ImageBuilderContext.Provider value={{ activeLayer, handleFontBackgroundColorOpacityChange, handleRespDataChange, respData, handleImageTypesChange, handleMessageChange, handleImageMessageListContextChange, handleUpdateRespData, handleAddOnImageMsg, handleRemoveMessage, handleUpdateBaseMessage, handleThumbnailChange, handleUpdateAIImages, imgHover, handleImgHoverChange, disabled, handleDisabledChange, handleOutlineChange, handleImgHoverUpdate, handleMockupLoading, isMockupLoading, handleImageMessageLayersSortChange, taskIds, handleUpdateTaskIds, showModal, handleShowModalChange, modalData, handleShowModal, imgEditDisplay, handleToggleImgEditDisplay, defaultColors }
        }>

            {

                children
            }
        </ImageBuilderContext.Provider >
    );
}

export function useImageBuilder() {
    return useContext(ImageBuilderContext);
}