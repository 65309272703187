import React from 'react'
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
// import { useTheme } from '../contexts/ThemeProvider';



function FormButtonBlock({ isProcessing, text }) {

    const darkTheme = 'dark'
    // const {darkTheme} = useTheme()

    return (
        <div className="d-grid gap-2">
            {
                isProcessing ?
                    <Placeholder.Button animation="glow" variant={darkTheme ? 'light' : 'dark'} xs={12} className="p-2 mt-3 fs-5" >{text}</Placeholder.Button>
                    // <Button disabled={isProcessing} className="p-2 mt-3" variant="dark" type="submit" size="lg">{text}</Button>
                    :
                    <Button disabled={isProcessing} className="p-2 mt-3" variant={darkTheme ? 'light' : 'dark'} type="submit" size="lg"><strong>{text}</strong></Button>
            }
        </div>
    )
}

export default FormButtonBlock
