import React, { useEffect, useState } from 'react'
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useApi } from '../contexts/ApiProvider';
import { useStripeContext } from '../contexts/StripeProvider';
import LoadingPage from '../pages/LoadingPage';


const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE);

function PaymentWrapper({ children }) {
    // const [key, setKey] = useState(Math.random() * 2.5)
    const darkTheme = true;
    const [clientSecret, setClientSecret] = useState();

    const api = useApi();
    const stripeContext = useStripeContext()

    useEffect(() => {

        (async () => {
            const response = await api.get('/authorized_payment_config');
            if (response.ok) {

                setClientSecret(() => response.body.clientSecret);
                stripeContext.handleUpdatePaymentIntentId(response.body.paymentIntentId)
            }
            else {
                setClientSecret(null);
                stripeContext.handleUpdatePaymentIntentId(null)

            }
        })();


    }, [api]);


    return (
        clientSecret && stripePromise ? < Elements key={stripeContext.key} stripe={stripePromise} options={{ clientSecret, appearance: { theme: `${darkTheme ? 'night' : 'default'}`, mode: 'payment', paymentMethodCreation: 'manual', } }} >
            {children}
        </Elements >
            :
            <LoadingPage />

    )
}

export default PaymentWrapper


// :
// <Body classStr={{ "height": "100vh" }}>
//     <div className="d-flex justify-content-center align-items-center">
//         <Spinner />
//     </div>
// </Body>