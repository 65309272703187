import React from 'react'
import { FaSquareXTwitter } from "react-icons/fa6";
import XIcon from './XIcon';
import SocialMediaIcon from './SocialMediaIcon';


function TwitterShare({ campaignId }) {


    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/campaign/${campaignId}`;
    const text = encodeURIComponent("Contribute to this crowdfunded billboard campaign! If not enough is raised nobody is charged.");
    const url = encodeURIComponent(`${baseUrl}`);


    const twitterShareUrl = `https://twitter.com/intent/tweet/?text=${text}&url=${url}`;


    return (
        <div style={{marginTop:'5px'}}>
            {/* <a className="resp-sharing-button__link" href={twitterShareUrl} target="_blank" rel="noopener" aria-label=""> */}
            <a style={{ color: 'white' }} href={twitterShareUrl} target="_blank" rel="noopener noreferrer"  aria-label="">

                {/* <FaSquareXTwitter size={50}/> */}
                {/* <XIcon  size={200} /> */}
                <SocialMediaIcon iconPath={'/imgs/logo.svg'} size={35} />


            </a>

        </div>

    )
}

export default TwitterShare

