import React from 'react';
import { FaTimes } from 'react-icons/fa'; // Import the icon from react-icons

const RemoveMarker = ({ imageId, onRemove, size }) => {


    return (
        <div
            style={{
                width: '20px',
                height: '20px',
                backgroundColor: 'red',
                borderRadius: '50%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
                color: 'white',
                fontSize: '20px', // Adjust size as needed
            }}
            onClick={onRemove}
            onTouchStart={onRemove}
        >
            <FaTimes size={16} />
        </div>
    );
};

export default RemoveMarker;
