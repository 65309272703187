import React from 'react'
import Body from '../components/Body'
import { Helmet } from 'react-helmet';
import { useUser } from '../contexts/UserProvider'
import { useNavigate } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import CircleIcon from './CircleIcon';
import CardRowGroup from '../components/CardRowGroup';
import { RiSecurePaymentFill } from "react-icons/ri";
import { MdPerson } from "react-icons/md";
import { AiOutlineMessage } from "react-icons/ai";
import { IoIosNotifications } from "react-icons/io";
import { GiTimeTrap } from "react-icons/gi";
import YoutubeVideo from '../components/YoutubeVideo';
import youtubeUrls from '../utils/youtubeUrls'
function ContributingToCampaignsPage() {


    const { createCampaign } = useUser();
    const navigate = useNavigate()




    const ICON_SIZE = 26

    const timeframes = [
        {
            label: "Set a maximum contribution amount",
            subText: ['Your contribution is not charged yet', 'Contributions are processed at the end of the campaign fundraising period if the campaign raises enought to purchase media.',],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><RiSecurePaymentFill size={ICON_SIZE} color="white" /> </CircleIcon>
        },
        {
            label: "Contribute anonymously or add your name",
            subText: ['Search for a point of interest and set a radius around it', 'Draw a custom area on the map', 'Optionally name your camapign areas'],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><MdPerson size={ICON_SIZE} color="white" /> </CircleIcon>
        },
        {
            label: "Add an optional description",
            subText: ['Title your campaign', 'Add an optional description about your campaign', 'You can link a youtube video', 'You can add up to 3 description images'],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><AiOutlineMessage size={ICON_SIZE} color="white" /> </CircleIcon>
        },
        {
            label: "Add an email for optional updates",
            subText: ['Receive payment and campaign status updates.'],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><IoIosNotifications size={ICON_SIZE} color="white" /> </CircleIcon>
        },
        {
            label: "Payments are handled after the fundraising period",
            subText: ['Title your campaign', 'Add an optional description about your campaign', 'You can link a youtube video', 'You can add up to 3 description images'],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><GiTimeTrap size={ICON_SIZE} color="white" /> </CircleIcon>
        }
    ];







    const handleNewCampaign = async () => {
        const proceed = await createCampaign();
        //This used to throw an error
        if (proceed) {
            navigate('/new_campaign/campaign_type', { replace: true })
        }
    }





    return (
        <>
            <Helmet>
                <title>CrowdPost - Contributing to campaigns</title>
                <meta property="og:title" content={`Let your voice reach the real town square`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
                {/* <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." /> */}

            </Helmet>
            <Body footer>



                <Row className="" >
                    <Col xs={12} className="text-center"
                    >
                        <h1 className="pricingHeader">Contributing to campaigns</h1>



                    </Col>
                </Row>
                <Row className="justify-content-center my-5">
                    <Col xs={{ span: 12 }} lg={{ span: 9, }}>


                        <YoutubeVideo
                            url={youtubeUrls.CONTRIBUTING_CAMPAIGNS}
                            title={"Contributing to  campaigns"}
                        />


                    </Col>
                    <Col className="d-flex justify-content-center" xs={12}>
                        <div className="d-flex flex-column justify-content-center align-items-center">
                            <Button
                                variant="success"
                                className="my-2 rounded-pill d-flex align-items-center justify-content-center px-4 py-2 w-100" // Apply the Flexbox styles here
                                onClick={() => handleNewCampaign()}
                            >
                                <h2 className="m-0"><strong>Start a Campaign</strong></h2>
                            </Button>
                        </div>
                    </Col>



                </Row>
                <CardRowGroup timeframes={timeframes} />
            </Body >


        </>
    )
}

export default ContributingToCampaignsPage