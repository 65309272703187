import React, { useState, useRef } from 'react'
import Body from '../components/Body'
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import Form from 'react-bootstrap/Form';
import InputField from '../components/InputField';
import FormButtonBlock from '../components/FormButtonBlock';
import TextAreaField from '../components/TextAreaField';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CheckboxComponent from '../components/CheckboxComponent';




function DMCATakedown() {
    const [circumvention, setCircumvention] = useState('no');
    const [formErrors, setFormErrors] = useState({});
    const fullNameField = useRef();
    const isCopyrightHolderField = useRef();
    const natureOfOwnershipField = useRef();
    const orignalWorkURLField = useRef();
    const filesToTakedownField = useRef();
    const contactField = useRef();
    const goodFaithField = useRef();
    const perjuryField = useRef();
    const fairUseField = useRef();
    const acknowledgeField = useRef();
    const eSignField = useRef();
    const technologicalMeasuresField = useRef();
    const circumventionExplantionField = useRef();

    const [disabled, setDisabled] = useState(false)

    const flash = useFlash();

    const api = useApi();

    const onSubmit = (ev) => {
        setDisabled(true)
        ev.preventDefault();

        const fullName = fullNameField.current.value;
        const isCopyrightHolder = isCopyrightHolderField.current.value;
        const natureOfOwnership = natureOfOwnershipField.current.value;
        const orignalWorkURL = orignalWorkURLField.current.value;
        const filesToTakedown = filesToTakedownField.current.value;
        const contact = contactField.current.value;
        const goodFaith = goodFaithField.current.checked;
        const perjury = perjuryField.current.checked;
        const fairUse = fairUseField.current.checked;
        const acknowledge = acknowledgeField.current.checked;
        const eSign = eSignField.current.value;
        const technologicalMeasures = technologicalMeasuresField.current ? technologicalMeasuresField.current.value : '';
        const circumventionExplantion = circumventionExplantionField.current ? circumventionExplantionField.current.value : '';


        (async () => {
            setFormErrors({})
            const response = await api.post(`/dmca-takedown`, {
                fullName: fullName,
                isCopyrightHolder: isCopyrightHolder,
                natureOfOwnership: natureOfOwnership,
                orignalWorkURL: orignalWorkURL,
                filesToTakedown: filesToTakedown,
                contact: contact,
                goodFaith: goodFaith,
                perjury: perjury,
                fairUse: fairUse,
                acknowledge: acknowledge,
                eSign: eSign,
                circumvention: circumvention,
                technologicalMeasures: technologicalMeasures,
                circumventionExplantion: circumventionExplantion,
            });
            if (response.ok) {
                setDisabled(false)
                flash("Your request was submmited", 'success');

            }
            else {
                setDisabled(false)


                if ('errors' in response.body) {
                    flash(response.body.errors.json.message, 'danger');
                    setFormErrors(response.body.errors.json);

                }
                else {

                    flash('Error with your request', 'danger')
                }


            }

        })();

    }


    const handleCircumventionField = (event) => {
        setCircumvention(event.target.value);
    };



    return (
        <Body classStr={{ height: "100%" }} footer>
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '3rem', marginBottom: '5rem' }}>
                <Col className="text-center mb-5" xs={12}>
                    <h2 className="fw-bolder">
                        DMCA Takedown Request
                    </h2>
                </Col>

                <Col className="border-black" sm={"5"} lg={"4"} xxl={"8"}  >
                    <Form onSubmit={onSubmit}>

                        <InputField
                            formLabel={<strong>Your full name</strong>}
                            fieldRef={fullNameField}
                            label="Full name"
                            error={formErrors.fullName}

                        />
                        <Form.Group>
                            <Form.Label>
                                <strong>
                                    Are you the copyright holder or authorized to act on the copyright owner's behalf?
                                </strong>
                            </Form.Label>

                            <Form.Select ref={isCopyrightHolderField} className="mb-3" aria-label="Default select example">
                                <option selected value="1">Yes, I am the copyright holder</option>
                                <option value="2">Yes, I am authorized to act on the copyright owner's behalf.</option>
                                <option value="no">No.</option>
                            </Form.Select>
                            <div><Form.Text className="text-danger">{formErrors.isCopyrightHolder}</Form.Text></div>
                        </Form.Group>


                        <TextAreaField

                            formLabel={<strong>Please describe the nature of your copyright ownership or authorization to act on the owner's behalf.</strong>}
                            name="authorization_to_act"
                            fieldRef={natureOfOwnershipField}
                            error={formErrors.natureOfOwnership}

                        />
                        <TextAreaField

                            formLabel={<strong>Please provide a detailed description of the original copyrighted work that has allegedly been infringed. If possible, include a URL to where it is posted online.</strong>}
                            error={formErrors.orignalWorkURL}
                            name="authorization_to_act"
                            fieldRef={orignalWorkURLField}
                        />
                        <TextAreaField

                            formLabel={<strong>What files should be taken down? Please provide URLs for each file, or if the entire repository, the repository’s URL.</strong>}
                            error={formErrors.filesToTakedown}
                            name="authorization_to_act"
                            fieldRef={filesToTakedownField}

                        />

                        <Form.Group>
                            <Form.Label>
                                <strong>
                                    Do you claim to have any technological measures in place to control access to your copyrighted content? Please see our Complaints about Anti-Circumvention Technology if you are unsure.
                                </strong>
                            </Form.Label>
                            <Form.Select
                                className="mb-3"
                                aria-label="Circumventing technology"
                                value={circumvention}
                                onChange={handleCircumventionField}
                            >
                                <option value="no">No.</option>
                                <option value="yes">Yes</option>
                            </Form.Select>

                            <div><Form.Text className="text-danger">{formErrors.isCopyrightHolder}</Form.Text></div>
                        </Form.Group>


                        {
                            circumvention === 'yes' &&
                            <>
                                <TextAreaField

                                    formLabel={<strong>What technological measures do you have in place and how do they effectively control access to your copyrighted material?</strong>}
                                    error={formErrors.technologicalMeasures}
                                    name="technological_measures"
                                    fieldRef={technologicalMeasuresField}

                                />

                                <TextAreaField

                                    formLabel={<strong>How is the accused project designed to circumvent your technological protection measures?</strong>}
                                    error={formErrors.circumventionExplantion}
                                    name="circumvention_explanation"
                                    fieldRef={circumventionExplantionField}

                                />
                            </>

                        }



                        <TextAreaField

                            formLabel={<strong>So that we can get back to you, please provide either your telephone number or physical address.</strong>}
                            error={formErrors.contact}
                            name="authorization_to_act"
                            fieldRef={contactField}

                        />
                        <CheckboxComponent
                            formLabel="I have a good faith belief that use of the copyrighted materials described above on the infringing web pages is not authorized by the copyright owner, or its agent, or the law."
                            name="goodFaith"
                            fieldRef={goodFaithField}
                            error={formErrors.goodFaith}
                        />
                        <CheckboxComponent
                            formLabel="I swear, under penalty of perjury, that the information in this notification is accurate and that I am the copyright owner, or am authorized to act on behalf of the owner, of an exclusive right that is allegedly infringed."
                            name="perjury"
                            fieldRef={perjuryField}
                            error={formErrors.perjury}
                        />
                        <CheckboxComponent
                            formLabel="I have taken fair use into consideration."
                            name="fairUse"
                            fieldRef={fairUseField}
                            error={formErrors.fairUse}
                        />

                        <CheckboxComponent
                            formLabel="I have read and understand Crowdpost's Guide to Submitting a DMCA Takedown Notice"
                            name="acknowledge"
                            fieldRef={acknowledgeField}
                            error={formErrors.acknowledge}
                        />

                        <InputField
                            fieldRef={eSignField}
                            formLabel={<strong>Please type your full legal name below to electronically sign this request. (Must match full name field)</strong>}
                            label="Full name"
                            error={formErrors.eSign}

                        />
                        <FormButtonBlock isProcessing={disabled} text={"Send request"} />
                    </Form>

                </Col>
            </Row>

        </Body >
    )
}

export default DMCATakedown
