import React, { useState, useEffect, useRef } from 'react'
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import PaymentAmount from './PaymentAmount'
import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import { useParams, useLocation, useNavigate } from 'react-router'
import GetPrice from './GetPrice'
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import InputField from './InputField';
import { Link, } from 'react-router-dom';


import FormButtonBlock from './FormButtonBlock';

import PaymentSuccessModal from './PaymentSuccessModal';
import { useStripeContext } from '../contexts/StripeProvider';

function CheckoutSummary({ isProcessing, handleIsProcessing, setupIntentId }) {
    const stripe = useStripe();
    const elements = useElements();
    const { campaignId } = useParams()
    const location = useLocation();
    const navigate = useNavigate();
    const api = useApi()
    const [amount, setAmount] = useState(1);
    const [hasEmail, setHasEmail] = useState();
    const [anonymous, setAnonymous] = useState(false);
    const [errorMessage, setErrorMessage] = useState()
    const email = useRef("");
    const email2 = useRef();
    const [emailMatch, setEmailMatch] = useState();
    const donationName = useRef("");
    const { handleRefreshStripe } = useStripeContext()
    const payment_message = useRef();
    const flash = useFlash();

    const [formErrors, setFormErrors] = useState({});
    const [showModal, setShowModal] = useState(false);



    // Parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const modal = queryParams.get("modal")
    const token = queryParams.get("token")


    useEffect(() => {
        // return () => {
        //     if (elements){

        //         elements.unmount();
        //         // elements.mount();
        //     }

        // }

    }, [setupIntentId])

    // useEffect(() => {

    //     return () => {
    //         // Cleanup logic
    //         if (elements) {
    //             // Optionally clear any stored card information or reset the elements
    //             elements.getElement(PaymentElement)?.clear();
    //         }
    //     };
    // }, [])


    // seti_1Q5rCMP55zOmkot0gfENqSzT
    // seti_1Q5rCNP55zOmkot0uWur8jHm


    useEffect(() => {
        modal === 'true' && token ? setShowModal(true) : setShowModal(false)

    }, [])


    useEffect(() => {
        modal === 'true' && token ? setShowModal(true) : setShowModal(false)

    }, [location])






    const handleAnonymous = (event) => {
        setAnonymous(event.target.checked);
    };

    const handlePaymentElementChange = (e) => {
        if (e.complete === true) {
            handleIsProcessing(false)
        }
        else {
            handleIsProcessing(true)
        }
    }

    function isValidEmail(email) {
        const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailPattern.test(email);
    }

    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        try {


            handleIsProcessing(true);




            //client sid field validation
            const errors = {};
            if (email.current && email2.current && email.current && email.current.value !== email2.current.value) {
                errors.email = 'Both email fields must match';
            }
            // if (email.current && email2.current && Object.keys(errors).length === 0 && !isValidEmail(email.current.value)) {
            //     errors.email = 'Enter a valid email format';
            // }





            setFormErrors(errors);
            if (Object.keys(errors).length > 0) {
                handleIsProcessing(false)
                handleRefreshStripe()
                return;
            }
            // end client side field validation 


            (async () => {
                const response = await api.post(`/campaign-payment/${campaignId}`, {
                    amount: parseInt(amount),
                    anonymous: anonymous,
                    setup_intent_id: setupIntentId,
                    payment_message: payment_message.current ? payment_message.current.value : null,
                    name: donationName.current ? donationName.current.value : null,
                    email: email.current ? email.current.value : null,
                    email2: email2.current ? email2.current.value : null,
                });//Checks to make sure everything in the response body is kosher. 
                if (response.ok) {

                    const result = await stripe.confirmSetup({
                        elements,
                        confirmParams: {
                            // Make sure to change this to your payment completion page
                            return_url: `${process.env.REACT_APP_BASE_API_URL}/campaign-payment/${campaignId}`
                        },
                        redirect: 'if_required'
                    }); //This confirms the stripe setup by submitting what is in the elements to stripe to check for errors. 



                    if (result.error) {
                        if (result.error.type === "card_error" || result.error.type === "validation_error") {
                            handleIsProcessing(false);
                            flash(result.error.message, 'danger')
                            setErrorMessage(() => result.error.message);
                            elements.unmount()
                            elements.mount()
                            handleRefreshStripe()
                        }
                        else {
                            console.log('unkown error', result)
                            setErrorMessage(() => result.error.message);

                            // elements.unmount()
                            // elements.mount()
                            handleRefreshStripe()

                            handleIsProcessing(false);
                            // elements.unmount()
                            // elements.mount()
                        }
                    } else {
                        if (result.setupIntent.id === setupIntentId) {
                            console.log("SetupIntent confirmed successfully with the same ID:", result.setupIntent.id);

                        } else {
                            console.error("Warning: SetupIntent ID mismatch. Original:", setupIntentId, "Returned:", result.setupIntent.id);
                        }
                        //Upon successful completion of the stripe setup. Call the PUT to edit the Payment Intent with the stripe setup intened and payment ID.
                        (async () => {
                            const response2 = await api.put(`/campaign-payment/${campaignId}`, {
                                setup_intent_id: result.setupIntent.id,
                                payment_id: response.body.payment_id,
                            });
                            if (response2.ok) {
                                // flash("Your intent to support this campaign has been submitted", 'success')
                                handleIsProcessing(false);
                                //TODO:GET THE IMAGE AND PASS IT INTO STATE
                                let payment_args = {
                                    modal: true,
                                    campaignId: campaignId,
                                    amount: parseInt(amount),


                                };
                                const queryParams = new URLSearchParams(payment_args).toString();
                                return navigate(`/campaign/${campaignId}?${queryParams}`);
                                // return navigate(`/explore?${queryParams}`);
                            }
                            else {
                                if ('errors' in response.body) {
                                    setFormErrors(response.body.errors.json)
                                    flash(response.body.errors.json.message, 'danger')
                                    handleIsProcessing(false);
                                    handleRefreshStripe()
                                    return
                                }

                                flash("There was an application error processing your request.")
                                handleIsProcessing(false);
                                handleRefreshStripe();
                                // return navigate(`/?modal=true`, { replace: true });
                            }

                        })();
                    }
                }
                else {
                    handleRefreshStripe();
                    if (response.body && 'errors' in response.body) {
                        setFormErrors(response.body.errors.json)
                        flash(response.body.errors.json.message, 'danger')
                        handleIsProcessing(false);

                        return
                    }
                    flash("Payment error", 'danger');
                    handleIsProcessing(false);
                    return
                    // flash(response.body.errors.json.email, 'danger')
                    // handleIsProcessing(false);
                }
            })();



        } catch (error) {
            console.log(error);
            handleIsProcessing(false);
            handleRefreshStripe();
        }




    };

    const handleCloseModal = () => {
        setShowModal(false);
    };


    const handleEmail = (e) => {
        console.log(e.target)
        if (e.target.value && e.target.value.length === 0) {
            setHasEmail(false);


        }
        else {

            setHasEmail(e.target.value)
        }
    };



    useEffect(() => {
        if (hasEmail && emailMatch && hasEmail.length !== 0 && emailMatch !== 0 && hasEmail !== emailMatch) {
            setFormErrors((prev) => ({ ...prev, 'email2': 'Both email fields must match' }))
        }
        // if (hasEmail && emailMatch && hasEmail.length !== 0 && emailMatch !== 0 && hasEmail !== emailMatch && !isValidEmail(emailMatch)) {
        //     setFormErrors((prev) => ({ ...prev, 'email': 'Enter a valid email format' }))
        // }
        if (hasEmail && emailMatch && hasEmail.length !== 0 && emailMatch !== 0 && hasEmail !== emailMatch) {
            setFormErrors((prev) => ({ ...prev, 'email2': 'Both email fields must match' }))
        }
        else {
            setFormErrors((prev) => ({ ...prev, 'email2': '' }))
        }
    }, [emailMatch, hasEmail])




    return (
        <div className="px-lg-5" >
            {/* <div className="px-5 p-5" style={{border:'1px solid grey', borderRadius:'1rem'}}> */}

            <h2 className="my-3"><strong>
                Contribution Details</strong></h2>
            <Form className="mb-5" onSubmit={handleSubmit}>
                <PaymentAmount amount={amount} setAmount={setAmount} />

                {
                    !anonymous &&
                    < Col className="mt-4" xs={12} >
                        <InputField fieldRef={donationName} type="text" placeholder="Name" label={"Name"} error={formErrors.name}
                            formText={<span>Optional** The name your contribution will appear as on the public campaign page.</span>} />
                    </Col>
                }

                <Form.Group className="mb-4">

                    <Form.Check
                        type='checkbox'
                        id={`default-checkbox`}
                        label={<span><strong>Anonymous?</strong></span>}
                        checked={anonymous}
                        onChange={handleAnonymous}
                    />
                    <Form.Text>
                        <span className="text-muted">Optional** Don't display my name publicly on the fundraiser.</span>

                    </Form.Text>
                </Form.Group>



                <div className="mb-4">
                    <InputField error={formErrors.payment_message} placeholder="Message" fieldRef={payment_message} type="text" label={"Message"} formText={<div><strong>Optional**</strong> The message must be between 2-120 characters. Your message will appear on the campaign page alongside your name and contribution amount, even posting anonymously.</div>} />
                </div>



                <InputField onChange={(e) => handleEmail(e)} label="Email" error={formErrors.email} fieldRef={email} type='email' placeholder="Email" formText={<span><strong>Optional** </strong>Receive campaign and payment status updates, no promontional email will be sent</span>} />

                {
                    hasEmail && hasEmail.length > 0 &&
                    <InputField onChange={(e) => setEmailMatch(e.target.value)} label="Confirm email" error={formErrors.email2} fieldRef={email2} type='email' placeholder="Confirm email" formText="Make sure you email matches the previous field" />
                }



                <hr className="my-5" />

                <h2><strong>Payment Details</strong></h2>


                <PaymentElement onChange={(e) => handlePaymentElementChange(e)} />

                <hr className="my-3" />
                <h2 ><strong>Total</strong></h2>
                <h4>
                    <GetPrice priceInCents={amount * 100} >max contribution</GetPrice>
                </h4>
                {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}

                <div className="small text-secondary ">Your contribution will only be processed if enough money is raised to purchase media for this campaign. If the campaign raises enough and is accepted, you will be charged based off the percentage your contribution made up of the total amount raised, up to your maximum contribution amount of <span className="d-inline-flex">{<GetPrice priceInCents={amount * 100} />}</span>. If enough money is raised and the campaign is denied you will be charged 99¢. <Link to="/refund-policy">Refund Policy</Link></div>
                {isProcessing ?

                    <FormButtonBlock text={"Set payment method"} isProcessing={isProcessing} />
                    :
                    <FormButtonBlock text={"Set payment method"} isProcessing={isProcessing} />

                }



            </Form >

            <PaymentSuccessModal campaignId={campaignId} handleCloseModal={handleCloseModal} showModal={showModal} api={api} token={token} />
        </div>

    )
}

export default CheckoutSummary
