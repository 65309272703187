import React from 'react'

function RadioComponent({ label, onChangeFunc,checked,value }) {
    return (
        <div>
            <label className="d-flex align-content-center custom-radio-label">
                <input
                    type="radio"
                    className="custom-radio-input m-2"
                    // style={{ transform: 'scale(1.5)', boxShadow: '0 0 10px 5px rgba(0, 0, 255, 0.3)', }}
                    value={value}
                    checked={checked}
                    onChange={() => { onChangeFunc(value) }}
                />
                <p className={`fs-5 custom-radio-checkmark${true ? ' checked' : ''}`}><strong>{label}</strong></p>
            </label>
        </div>
    )
}

export default RadioComponent
