import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'



function AboutSidebar({ faqs, activeSection,handleSectionChange }) {
    // const [activeSection, setActiveSection] = useState(null);
    const scrollToSection = (id) => {
        const section = document.getElementById(id);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            handleSectionChange(id);
        }
    };



    return (
        <Navbar sticky='top' className='flex-column AboutSidebar d-none d-md-block'>
            <Navbar.Brand className="fw-bolder fs-4">On this page</Navbar.Brand>
            <hr className="my-0" />
            {
                <>
                    {/* 
                    <Nav.Item eventKey="whatwedo" onClick={() => scrollToSection('whatwedo')} href="#whatwedo"><Nav.Link
                        active={activeSection === `whatwedo`}
                    >What we do</Nav.Link></Nav.Item>
                    <Nav.Item eventKey="howitworks" onClick={() => scrollToSection('howitworks')} href="#howitworks"><Nav.Link
                        active={activeSection === `howitworks`}
                    >How it works</Nav.Link></Nav.Item> */}
                    {/* <Nav.Item eventKey="faq" onClick={() => scrollToSection('faq')} href="#faq"><Nav.Link>FAQ</Nav.Link></Nav.Item> */}

                    {
                        faqs.map((v, a) =>
                            <Nav.Item key={a} onClick={() => scrollToSection(`sidebar_${a}`)}><Nav.Link
                                active={activeSection === `sidebar_${a}`}
                            >{v.label}</Nav.Link></Nav.Item>
                        )
                    }
                    {/* {
                        faqs.map((v, a) =>
                            <Nav.Item eventKey={a} onClick={() => scrollToSection(`sidebar_${a}`)}><Nav.Link
                                active={activeSection === `sidebar_${a}`}
                            >{v.label}</Nav.Link></Nav.Item>
                        )
                    } */}



                </>
            }


        </Navbar >

    )
}

export default AboutSidebar