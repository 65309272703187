// import React, { createContext, useContext, useCallback, useMemo } from 'react';
// import MicroblogApiClient from '../MicroblogApiClient';

import { useApi } from "../contexts/ApiProvider";
import { useFlash } from '../contexts/FlashProvider';
import { useImageBuilder } from '../contexts/ImageBuilderProvider';



export default function useTaskTimer() {
    const flash = useFlash();
    const { taskIds, handleUpdateTaskIds, handleRespDataChange, handleMockupLoading, handleShowModal } = useImageBuilder()

    const api = useApi();



    const handleTaskReq = (taskId, intervalId) => {


        // ```This is necessary to query the task id endpoint to return the mockup image from the task que. taskID:{type,task_id}```

        if (taskId['type'] === "is_sample_mockup") {
            (async () => {
                const response = await api.get(`/new_campaign/get-sample-mockup-image/${taskId['task_id']}`);
                var updatedTaskIds
                if (response.ok) {

                    if (response.body.success === true && response.body.data !== false) {


                        // counter = counter + 1
                        handleRespDataChange({ k: "sample_mockup_url", data: response.body.data });
                        // setStopInterval(true);
                        handleMockupLoading(false);
                        clearInterval(intervalId)
                        // window.localStorage.removeItem('sampleMockupTaskId');
                        handleShowModal(true, response.body.data)
                        updatedTaskIds = taskIds.filter((e) => e.task_id !== taskId['task_id'])
                        handleUpdateTaskIds(updatedTaskIds)
                        return response.body
                    }
                    // return response.body
                }
                else {

                    // return false
                    // clearInterval(currentIntervalId)
                    // counter = counter + 1
                    // handleMockupLoading(false);
                    // console.log('error');
                    // console.log(response);
                    if (response.status !== 404) {
                        'body' in response && 'errors' in response.body ?
                            flash(response.body.errors.json.message, 'danger')
                            :
                            flash('Error with request', 'danger')
                    }

                    handleMockupLoading(false)
                    updatedTaskIds = taskIds.filter((e) => e.task_id !== taskId['task_id'])
                    handleUpdateTaskIds(updatedTaskIds)
                    clearInterval(intervalId)
                    return response.body

                }

            })();
        }
        else if (taskId['type'] === "is_active_mockup") {
            (async () => {
                const response = await api.get(`/new_campaign/get-active-mockup-image/${taskId['task_id']}`);
                if (response.ok) {
                    var updatedTaskIds
                    if (response.body.success === true && response.body.data !== false) {


                        // counter = counter + 1
                        handleRespDataChange({ k: "mockup_url", data: response.body.data });
                        // setStopInterval(true);
                        handleMockupLoading(false);
                        clearInterval(intervalId)
                        // window.localStorage.removeItem('sampleMockupTaskId');
                        // handleShowModal(true, response.body.data)
                        updatedTaskIds = taskIds.filter((e) => e.task_id !== taskId['task_id'])
                        handleUpdateTaskIds(updatedTaskIds)
                        return response.body
                    }
                    // return response.body

                }
                else {
                    // return false
                    // clearInterval(currentIntervalId)
                    // counter = counter + 1
                    // handleMockupLoading(false);
                    // console.log('error');
                    // console.log(response);
                    if (response.status !== 404) {
                        'body' in response && 'errors' in response.body ?
                            flash(response.body.errors.json.message, 'danger')
                            :
                            flash('Error with request', 'danger')
                    }
                    updatedTaskIds = taskIds.filter((e) => e.task_id !== taskId['task_id'])
                    handleUpdateTaskIds(updatedTaskIds)
                    clearInterval(intervalId)
                    handleMockupLoading(false);
                    return response.body
                }

            })();
        }
        // else if (taskId['type'] === "is_ai"){

        //     clearInterval(intervalId)
        // }
        // else {

        //     clearInterval(intervalId)
        // }

    }


    const handleuseTaskTimer2 = () => {


        // ```This is necessary to query the task id endpoint to return the mockup image from the task que```
        let currentIntervalId = setInterval(() => {
            if (taskIds && taskIds.length > 0) {
                taskIds.forEach((v, i) => {

                    var resp = handleTaskReq(v, currentIntervalId);

                });
            }
        }, 3000)

    }




    return handleuseTaskTimer2;
}