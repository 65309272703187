import React from 'react'
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ContributionOutcomeCardGroup() {
    const LIST_CLASSES = "fs-5 text-secondary"


    return (


        <Row>
            <Col className="d-flex flex-sm-column flex-lg-row" >
                <CardGroup>
                    <Card>
                        <Card.Body>
                            <Card.Title bsPrefix="bg-success p-2 rounded" ><strong>Accepted Campaigns</strong></Card.Title>
                            <ul className="h-100 " style={{ textAlign: 'left' }}>
                                <li style={{ marginTop: "0" }}>
                                    <strong>Campaign contributions are charged</strong>
                                </li>
                                <li className="text-secondary">If the campaign raises more than the cost, your contribution is reduced accordingly</li>
                                <li className="text-secondary">
                                    CrowdPost manages the campaign to maximize impressions
                                </li>
                                <li className="text-secondary">
                                    Billboard campaigns can be active for 4-6 weeks while mobile trucks can be active anywhere between 6 hours to 1 month depending on the amount raised.
                                </li>
                            </ul>
                        </Card.Body>
                        <Card.Footer>
                            <small className="text-muted">Campaign status is moved to <strong className="text-success">Accepted</strong></small>
                        </Card.Footer>
                    </Card>
                    <Card>
                        <Card.Body >
                            <Card.Title bsPrefix="bg-warning p-2 text-secondary rounded"><strong>Underfunded Campaigns</strong></Card.Title>
                            <ul className="campaignContributionList h-100 " style={{ textAlign: 'left' }}>
                                <li style={{ marginTop: "0" }}>
                                    <strong>No contributions are charged</strong>
                                </li>
                                <li className="text-secondary">
                                    Not enough money was raised to purchase media
                                </li>
                                <li className="text-secondary">
                                    You are never charged for underfunded campaigns   
                                </li>
                            </ul>
                        </Card.Body>
                        <Card.Footer>
                            <small className="text-muted">Campaign status is moved to <strong className="text-warning">Underfunded</strong></small>
                        </Card.Footer>
                    </Card>
                    <Card>
                        <Card.Body >
                            <Card.Title bsPrefix="bg-danger p-2 rounded"><strong>Denied Campaigns</strong></Card.Title>
                            <ul className=" h-100 " style={{ textAlign: 'left' }}>
                                <li >
                                    <strong>A 99¢ platform fee is charged instead of the original contribution amount.</strong>
                                </li>
                                <li className="text-secondary">
                                    The campaign raised enough money but the media was rejected by our media partners.
                                </li>
                                <li className="text-secondary">
                                    The reason(s) for denial will be posted on the campaign's homepage.
                                </li>
                                <li className="text-secondary">
                                    The denied campaign will always remain on the platform.
                                </li>
                            </ul>
                        </Card.Body>
                        <Card.Footer>
                            <small className="text-muted">Campaign status is moved to <strong className="text-danger">Denied</strong></small>
                        </Card.Footer>
                    </Card>
                </CardGroup>
            </Col>
        </Row>




    )
}

export default ContributionOutcomeCardGroup
