import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';



import CampaignUpdateModal from './modals/CampaignUpdateModal';

function CampaignUpdates({ handleCampaignUpdates, campaignUpdates, user, setShowPostUpdateModal, handleDeleteMessage, getUpdates, campaignId, showPostUpdateModal, username }) {
    return (

        <>

            {((campaignUpdates && 'data' in campaignUpdates && campaignUpdates.data.length > 0) || (user && username === user.username)) &&
                <Col>
                    <hr />
                    {/* {campaignId} */}
                    <h3 className='fw-bold'>
                        Updates ({campaignUpdates.update_len})
                    </h3>
                    <Stack gap={3}>


                        {
                            campaignUpdates.data.map((v, i) =>
                                <Row key={i}>
                                    {
                                        v['tweet'] && v['tweet'].length > 0 ?

                                            <Col>
                                                <div dangerouslySetInnerHTML={{ __html: v['tweet'] }} />
                                            </Col>

                                            :
                                            <Col>
                                                <div>
                                                    <span className="fw-bold pr-2">{new Date(Date.parse(v.timestamp)).toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })} </span>
                                                </div>
                                                <div>
                                                    {v.message}
                                                </div>
                                            </Col>
                                    }

                                    {
                                        user && user.username === v.username &&
                                        <Col className="d-flex justify-content-end align-items-center align-content-center">
                                            {/* <Badge style={{ cursor: 'pointer', marginRight: '1rem' }} onClick={() => handleEditMessage(i)} className="px-3" >Edit</Badge> */}
                                            <Badge style={{ cursor: 'pointer', marginRight: '1rem' }} onClick={() => handleDeleteMessage(v.update_id)} className="px-3" bg='danger'>Remove</Badge>
                                        </Col>
                                    }
                                </Row>
                            )
                        }
                    </Stack>
                    <Row>
                        <Col xs="auto">
                            {
                                campaignUpdates && campaignUpdates.data.length === 1 && <Button className="rounded-pill mt-5" variant='light' onClick={() => getUpdates()}>
                                    <strong>Show all</strong>
                                </Button>
                            }
                        </Col>
                        <Col xs="auto">
                            {
                                user && user.username === username &&
                                <div className="mt-5"><Button className="rounded-pill fw-bold" variant="light" onClick={() => setShowPostUpdateModal(true)}>Post update</Button></div>
                            }
                        </Col>
                    </Row>

                </Col>



            }
            <CampaignUpdateModal
                showModal={setShowPostUpdateModal}
                showVar={showPostUpdateModal}
                campaignId={campaignId}
                handleCampaignUpdates={handleCampaignUpdates}
            />
        </>


    )
}

export default CampaignUpdates
