import React, { useEffect, useRef } from 'react'
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Popover from 'react-bootstrap/Popover';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import DynamicForm from '../../components/DynamicForm';
import CampaignModes from '../../components/CampaignModes';
// import { useTheme } from '../../contexts/ThemeProvider';
import MapLegend from '../../components/MapLegend';
import InfoPopover from '../../components/InfoPopover';


function NewCampaignTypesMobile({ map, title, subTitle, selectedCardId, handleCardClick, modes, handleHideLayer, handleSubmit, disabled }) {

    const mobileRef = useRef(null);


    useEffect(() => {

        const checkVisibility = () => {
            //Checks to see if the mobile component is visible so it can disable scroll in mobile view else enable the scroll. 
            if (mobileRef.current && mobileRef.current.offsetHeight > 0) {

                // Mobile view is visible
                window.scrollTo(0, 0);

                document.body.style.overflow = 'hidden';
            } else {
                // Desktop view is active, or mobile is hidden
                document.body.style.overflow = 'auto';
            }
        };

        checkVisibility(); // Initial check  }
        window.addEventListener('resize', checkVisibility);

        return () => {
            document.body.style.overflow = 'auto'; // Re-enable scrolling on unmount
            window.removeEventListener('resize', checkVisibility); // Cleanup
        };
    }, []);

    const popover = (
        <Popover id="popover-basic">
            <Popover.Header as="h3">Information</Popover.Header>
            <Popover.Body>
                {subTitle}
            </Popover.Body>
        </Popover>
    );


    return (
        <div ref={mobileRef}>
            <Row className="d-block d-xl-none " >
                <Col className="mx-1">
                    <h4 className="my-0">{title}
                        <InfoPopover popover={popover} />
                        {/* <OverlayTrigger flip={true} trigger={['hover', 'focus']} placement="bottom" overlay={popover}>
                            <Button className="mx-2" variant="link" style={{ padding: 0 }}>
                                <FaCircleInfo style={{ verticalAlign: 'top' }} size={16} />
                            </Button>
                        </OverlayTrigger> */}
                    </h4>
                    <p className="text-muted my-0">Show or hide the campaign boundaries below</p>
                </Col>
            </Row>
            {/* <p className="mb-1">{subTitle}</p> */}
            <Row className="d-block d-xl-none m-0 p-0" style={{ width: '100%', height: '90vh', position: 'relative' }}>

                <MapLegend handleHideLayer={handleHideLayer} modes={modes} />



                <div ref={map['container']} style={{ width: '100%', height: '100%' }}></div>

                <Col xs={'12'} className="fixed-bottom p-0" style={{ zIndex: '100000' }} >
                    <DynamicForm
                        // buttonText={'Submit Location'}
                        disabled={disabled}
                    >
                        <ButtonGroup size="sm" className="w-100">

                            {
                                modes ?
                                    modes.map((i, index) =>

                                        <CampaignModes key={index} mobile color={i.color} handleCardClick={handleCardClick} selectedCardId={selectedCardId} id={index} title={i.name} isLoading={false} />
                                    )
                                    :
                                    [1, 2].map((i, index) =>
                                        <div key={index}>
                                            <CampaignModes mobile handleCardClick={handleCardClick} selectedCardId={selectedCardId} isLoading={true} />
                                        </div>
                                    )
                            }
                        </ButtonGroup>
                        <Button

                            onClick={(e) => handleSubmit(e)}
                            variant={'primary'}
                            style={{
                                borderRadius: '0px',
                                border: '1px solid white'

                            }}
                            className="w-100"
                        ><strong className="d-inline-flex">Continue</strong></Button>



                    </DynamicForm>



                </Col>




            </Row>
        </div>








    )
}

export default NewCampaignTypesMobile
