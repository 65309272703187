import React from 'react'

import ShareComponents from './ShareComponents'
import { BiParty } from "react-icons/bi";


import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import GetPrice from './GetPrice';

function PaymentSuccessModal({ showModal, handleCloseModal, campaignId, amount }) {

    return (

        <div >
            <Modal show={showModal} onHide={handleCloseModal} >
                <Modal.Header closeButton className="">

                    <div className="w-100 d-flex justify-content-center align-items-center gradient-text">

                        <BiParty style={{ color: 'darkcyan' }} size={45} />
                        <h3 className="fw-bold mx-2 mb-0">Congratulations!</h3>
                        <BiParty style={{ color: 'hotpink' }} size={45} />
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">

                        <h5 className="">You voted with your dollars to further public discourse. If the campaign is successfully posted your card will be charged <span className=""><GetPrice priceInCents={amount * 100} userPayment={true} /></span></h5>
                    </div>

                    <hr />
                    <ShareComponents campaignId={campaignId} amount={amount} />

                </Modal.Body>

                <Modal.Footer>
                    <Button onClick={handleCloseModal} variant="secondary">Close</Button>
                </Modal.Footer>

            </Modal>
        </div>
    )
}

export default PaymentSuccessModal
