import React from 'react'
import Modal from 'react-bootstrap/esm/Modal'
import NewCampaignMapDescription from '../NewCampaignMapDescription'
import Button from 'react-bootstrap/esm/Button'

function NewCampaignMapHelpModal({ hide, show }) {
    return (

        <Modal centered size="lg" scrollable onHide={hide} show={show}>
            <Modal.Header closeButton><Modal.Title >Setting a campaign location</Modal.Title></Modal.Header>
            <Modal.Body>
                <NewCampaignMapDescription />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={hide} variant="secondary">Close</Button>
            </Modal.Footer>
        </Modal>

    )
}

export default NewCampaignMapHelpModal
