import React from 'react'

import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import DynamicForm from '../../components/DynamicForm';
import CampaignModes from '../../components/CampaignModes';
// import { useTheme } from '../../contexts/ThemeProvider';
import MapLegend from '../../components/MapLegend';


function NewCampaignTypesDesktop({ map, title, subTitle, selectedCardId, handleCardClick, modes, handleSetModes, handleSubmit, handleHideLayer, disabled }) {
    return (

        <Row className="d-none d-xl-flex" style={{ height: "100vh" }}>

            <Col className='campaignMap px-0 mx-0' xs={{ order: 2, span: 12 }} lg={{ span: 9, order: 1 }} >

                <div ref={map['container']} style={{ 'height': '100%', overflowY: 'hidden' }} />

            </Col>

            <Col style={{ height: "100%", overflow: 'scroll' }} className="d-flex  justify-content-center" xs={{ order: 1, span: 12 }} lg={{ span: 3, order: 2 }}>
                <Row style={{ height: "100%", overflow: 'scroll' }} className="d-flex  justify-content-center" >

                    <Col style={{ height: "100%", overflow: 'scroll' }} xs={12} md={10} lg={12} className="mt-3 justify-content-center">

                        <h1 className="fw-bolder">Select a campaign type</h1>
                        <p className="lead text-muted mb-4">{subTitle}</p>

                        {/* <div className="my-4"> */}
                        <MapLegend handleHideLayer={handleHideLayer} modes={modes} />
                        <hr />
                        {/* </div> */}
                        <DynamicForm
                            // buttonText={'Submit Location'}
                            disabled={disabled}
                        >
                            <Row className="d-flex justify-content-center mb-3 ">

                                {
                                    modes ? modes.map((i, index) =>
                                        <Col key={index} xs={12} md={5} lg={12}>

                                            <CampaignModes color={i.color} handleCardClick={handleCardClick} selectedCardId={selectedCardId} id={index} title={i.name} isLoading={false} />
                                        </Col>
                                    )
                                        :
                                        <Col xs={12} md={5} lg={12}>

                                            {[1, 2].map((i, index) => <div key={index}>
                                                <CampaignModes handleCardClick={handleCardClick} selectedCardId={selectedCardId} isLoading={true} />
                                            </div>)}
                                        </Col>

                                }
                            </Row>

                            <Row className="mx-2 my-3 mb-5"><Col className="text-end" xs={12}><Button onClick={(e) => handleSubmit(e)} disabled={disabled} className="rounded shadow py-3 px-5 mr-3" variant="light" type="button" size="lg"><strong>Continue</strong></Button></Col></Row>




                        </DynamicForm>

                    </Col>

                </Row>



            </Col>





        </Row >

    )
}

export default NewCampaignTypesDesktop
