import React, { useState, useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import { useNavigate, useParams } from 'react-router'
import { useApi } from '../contexts/ApiProvider';
import ShareModal from "../components/ShareModal"
import { Helmet } from 'react-helmet';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import { MdOutlineArrowBackIos } from 'react-icons/md';
import BillboardImage from '../components/BillboardImage';
import LoadingSpinner from '../components/LoadingSpinner';
import { useFlash } from '../contexts/FlashProvider';
import { useUser } from '../contexts/UserProvider';
import CampaignPageDesktopStack from '../components/CampaignPageDesktopStack';
import CampaignPageMobileStack from '../components/CampaignPageMobileStack';
import FlashMessage from '../components/FlashMessage';
import DonationProtectionModal from '../components/DonationProtectionModal';
import { CiFlag1 } from "react-icons/ci";
import ReportModal from '../components/ReportModal';
import WhatCanBePostedModal from '../components/WhatCanBePostedModal';
import PaymentSuccessModal from '../components/PaymentSuccessModal';
import { useLocation } from 'react-router-dom'



function CampaignPage() {
    const { user } = useUser();
    const api = useApi();

    const flash = useFlash();
    const [comments, setComments] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showWhatCanBePostedModal, setShowWhatCanBePostedModal] = useState(false);
    const [showShareModal, setShowShareModal] = useState(false);
    const handleUpdateShareModal = (data) => setShowShareModal(data);
    const [showContactModal, setShowContactModal] = useState(false);
    const [showPostUpdateModal, setShowPostUpdateModal] = useState(false);
    const [donorSummary, setDonorSummary] = useState(null);
    const [showAll, setShowAll] = useState(false);
    const { campaignId } = useParams();
    const [showFlagModal, setShowFlagModal] = useState(false)
    const handleHideFlagModal = () => setShowFlagModal(false)
    const handleShowFlagModal = () => setShowFlagModal(true)

    //TODO: MAKE A REQUEST TO MAKE STIRPE PAYEMNT INTENT
    const [campaign, setCampaign] = useState(null);
    const [campaignUpdates, setCampaignUpdates] = useState();
    // const [campaign, setCampaign] = useState(location.state ? location.state.campaign : null);
    const rowStyle = { paddingTop: '1rem', paddingBottom: '1rem', marginTop: '1rem', }
    const [modalImages, setModalImages] = useState()
    const navigate = useNavigate()

    const [showDonationProtectedModal, setShowDonationProtectedModal] = useState(false);

    const handleShowDonationProtectedModal = (data) => setShowDonationProtectedModal(data)
    const handleWhatCanBePostedModal = (data) => setShowWhatCanBePostedModal(data)

    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const modal = queryParams.get("modal")
    const paymentCampaignId = queryParams.get("campaignId")
    const amount = queryParams.get("amount")
    const [showPaymentSuccessModal, setShowPaymentSuccessModal] = useState(modal && modal === 'true' ? true : false);
    const handleClosePaymentSuccessModal = () => {
        setShowPaymentSuccessModal(false);
    };




    useEffect(() => {
        // Create a <script> element
        const script = document.createElement('script');
        script.src = 'https://platform.twitter.com/widgets.js';
        script.async = true;

        // Append the <script> element to the document body
        document.body.appendChild(script);

        // Clean up function
        return () => {
            // Remove the <script> element from the document body when the component unmounts
            document.body.removeChild(script);
        };
    }, [campaignUpdates]); // Empty dependency array ensures the effect runs only once when the component mounts




    const handleShowAll = (v) => {
        if (v) {
            setModalImages(v);
            showAll === true ? setShowAll(false) : setShowAll(true)
        }
        else {
            showAll === true ? setShowAll(false) : setShowAll(true)
        }

    }

    const handleCampaignUpdates = (data) => {

        setCampaignUpdates(data);
    }

    const handleDeleteMessage = (updateId) => {

        //SHOW MODALS HERE TO PROCEED
        (async () => {
            const response = await api.delete(`/delete-update/${campaignId}/${updateId}`);
            if (response.ok) {
                console.log(response.body)
                setCampaignUpdates(() => response.body);

            }
            else {
                setCampaignUpdates(null);
            }
        })();
    }

    const getComments = (nextLink) => {

        campaignId && (async () => {

            const response = await api.get(nextLink);

            if (response.ok) {
                setComments(() => response.body);

            }
            else {
                flash('error', 'danger')
            }
        })();

    }

    const getUpdates = () => {
        campaignId && (async () => {

            const response = await api.get(`/campaign-updates/${campaignId}`, new URLSearchParams({ all: true }));

            if (response.ok) {
                setCampaignUpdates(() => response.body);

            }
            else {
                flash('error')
            }
        })();
    }

    useEffect(() => {
        (async () => {
            const response = await api.get("/campaign_id/" + campaignId);
            if (response.ok) {
                setCampaign(() => response.body.data);

            }
            else {
                navigate('/')
                setCampaign(null);

            }
        })();
        (async () => {
            const response = await api.get("/campaign_donors/" + campaignId);
            if (response.ok) {

                setDonorSummary(() => response.body.data);

            }
            else {
                setDonorSummary(null);
            }
        })();
        (async () => {
            const response = await api.get("/campaign_comments/" + campaignId);
            if (response.ok) {
                setComments(() => response.body);

            }
            else {
                setComments(null);
            }
        })();

        (async () => {
            const response = await api.get("/campaign-updates/" + campaignId);
            if (response.ok) {
                setCampaignUpdates(() => response.body);

            }
            else {
                setCampaignUpdates(null);
            }
        })();

    }, [api, flash]);

    const cardStyle = {
        border: "none",
        borderRadius: "10px",
        // boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)", // black shaded border shadow
        position: "sticky",
        top: 0,
        zIndex: 1,
        // backgroundColor: `${darkTheme ? 'var(--bs-gray-800)' : 'white'}`,
    }


    const handleDescriptionUpdate = (data) =>
        setCampaign((prev) => ({
            ...prev,
            'description':
                data

        })
        )



    return (
        < >
            {campaign && (<Helmet>
                <title>CrowdPost - {campaign.description.title}</title>
                {/* <meta property="og:title" content={`Contribute to this campaign`} /> */}
                {/* <meta property="og:type" content="website" /> */}
                {/* <meta property="og:image:secure_url" content={campaign.images[0]['image']} /> */}
                {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.url} `} /> */}
                {/* <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." /> */}

                {
                    campaignUpdates && 'data' in campaignUpdates && campaignUpdates.data.filter(item => item['tweet'] && item['tweet'].length > 0) &&
                    <script async src="https://platform.twitter.com/widgets.js" />
                }

            </Helmet>)}
            <Container fluid>
                <FlashMessage />
                {
                    campaign === null ?
                        <LoadingSpinner />
                        :
                        <>

                            <Row className="scrollable" style={{ justifyContent: 'space-evenly' }}  >
                                {
                                    showPaymentSuccessModal && <PaymentSuccessModal showModal={showPaymentSuccessModal} handleCloseModal={handleClosePaymentSuccessModal} campaignId={campaignId} amount={amount} />
                                }
                                
                                <ShareModal show={showShareModal} hide={handleUpdateShareModal} campaignUrl={campaign.url} />

                                <CampaignPageDesktopStack campaign={campaign} comments={comments} modalImages={modalImages} handleShowAll={handleShowAll} showAll={showAll} user={user} setShowPostUpdateModal={setShowPostUpdateModal} setShowContactModal={setShowContactModal} showPostUpdateModal={showPostUpdateModal} campaignUpdates={campaignUpdates} handleDeleteMessage={handleDeleteMessage} getUpdates={getUpdates} rowStyle={rowStyle} getComments={getComments} cardStyle={cardStyle} donorSummary={donorSummary} handleShowDonationProtectedModal={handleShowDonationProtectedModal} handleDescriptionUpdate={handleDescriptionUpdate} handleWhatCanBePostedModal={handleWhatCanBePostedModal} handleUpdateShareModal={handleUpdateShareModal} handleCampaignUpdates={handleCampaignUpdates}/>





                                <CampaignPageMobileStack campaign={campaign} comments={comments} modalImages={modalImages} handleShowAll={handleShowAll} showAll={showAll} user={user} setShowPostUpdateModal={setShowPostUpdateModal} setShowContactModal={setShowContactModal} showContactModal={showContactModal} showPostUpdateModal={showPostUpdateModal} campaignUpdates={campaignUpdates} handleDeleteMessage={handleDeleteMessage} getUpdates={getUpdates} rowStyle={rowStyle} getComments={getComments} handleShowDonationProtectedModal={handleShowDonationProtectedModal} handleDescriptionUpdate={handleDescriptionUpdate} handleWhatCanBePostedModal={handleWhatCanBePostedModal} handleUpdateShareModal={handleUpdateShareModal} handleCampaignUpdates={handleCampaignUpdates}/>



                                <Col className="my-5" xs={12}>

                                    <ReportModal show={showFlagModal} hide={handleHideFlagModal} />

                                    <Button variant="dark" onClick={() => handleShowFlagModal()} className="rounded-pill">
                                        <span>
                                            <CiFlag1 size={32} /> Report campaign
                                        </span>
                                    </Button>
                                </Col>
                            </Row>



                            <DonationProtectionModal showModal={showDonationProtectedModal} setShowModal={handleShowDonationProtectedModal} />
                            <WhatCanBePostedModal showModal={showWhatCanBePostedModal} setShowModal={handleWhatCanBePostedModal} />

                            <ShareModal campaign={campaign} showModal={showModal} setShowModal={setShowModal} />

                            {
                                showAll &&
                                <Modal onHide={() => handleShowAll()} fullscreen show={showAll} >
                                    <ModalBody className="m-4">
                                        <MdOutlineArrowBackIos onClick={handleShowAll} style={{ cursor: 'pointer' }} size={40} />

                                        {
                                            modalImages === 'map' ?
                                                campaign.map_images.map((i, index) =>
                                                    <Row>

                                                        <Col key={index} className="m-4">


                                                            <BillboardImage imageType={'map'}
                                                                image={i}
                                                                handleShowAll={handleShowAll}
                                                            />

                                                        </Col>

                                                    </Row>


                                                )

                                                :


                                                Object.keys(campaign.images).map((i, index) =>
                                                    <Row>

                                                        <Col key={index} className="m-4">


                                                            <BillboardImage imageType={'image'} status={campaign.status} statusLabel={campaign.status_label} campaign_id={campaign.campaign_id} isLiked={campaign.isLiked} best_board={campaign.images[i].best_board} image={campaign.images[i].image} index={i} />

                                                            <Col className="mt-3">
                                                                <h5>
                                                                    <strong>
                                                                        Image aspect-ratio:
                                                                    </strong>{campaign.images[i].aspect_ratio.toFixed(2)}

                                                                </h5>
                                                            </Col>
                                                            <Col>
                                                                <h5>
                                                                    <strong>
                                                                        Recommended billboard:
                                                                    </strong>{campaign.images[i].best_board.name} ({campaign.images[i].best_board.aspectRatio} aspect ratio)
                                                                </h5>
                                                            </Col>

                                                        </Col>

                                                    </Row>


                                                )

                                        }
                                    </ModalBody>
                                </Modal>
                            }


                        </>
                }

            </Container >
            {/* <div className="pt-5">
                <Footer />
            </div> */}
        </ >
    );
}

export default CampaignPage

