import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { NavLink } from 'react-router-dom';
import { useUser } from '../contexts/UserProvider';


function DesktopSidebar() {
    const { user } = useUser();
    return (
        <Navbar sticky='top' className='flex-column Sidebar d-none d-lg-block'>
            {
                user && <>
                    <Nav.Item><Nav.Link exact="true" as={NavLink} to={`/user/${user.username}`}>Profile</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link exact="true" as={NavLink} to="/my_campaigns">My Campaigns</Nav.Link></Nav.Item>
                    {/* <Nav.Item><Nav.Link exact="true" as={NavLink} to={`/new_campaign/campaign_type`}>Start a campaign</Nav.Link></Nav.Item> */}
                    <Nav.Item><Nav.Link exact="true" as={NavLink} to={`/account-balance`}>AI Image Credits</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link exact="true" as={NavLink} to="/supported_campaigns">Supported Campaigns</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link exact="true" as={NavLink} to="/favorite_campaigns">Favorite Campaigns</Nav.Link></Nav.Item>
                    <Nav.Item><Nav.Link exact="true" as={NavLink} to="/password">Manage Account</Nav.Link></Nav.Item>
                </>
            }
        </Navbar >
    )
}

export default DesktopSidebar
