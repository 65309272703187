import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import TimeAgo from './TimeAgo';
import GetPrice from './GetPrice';
import Badge from 'react-bootstrap/Badge';
import { RiUserVoiceLine } from 'react-icons/ri'


function Comment(props) {
    return (
        <Row className="" >
            <Col xs={'auto'} className="m-0 p-0">
                <Badge bg='none' className="mx-2 rounded-circle p-3" style={{ backgroundColor: 'rgb(240, 240, 240)' }} ><RiUserVoiceLine size={23} color="green" /> </Badge>
            </Col>
            <Col style={{ display: 'flex', flexDirection: 'column' }}>

                <div className="fs-5">{props.donor.charAt(0).toUpperCase() + props.donor.slice(1)}</div>
                <div className="fs-6">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <div style={{ marginRight: '10px' }}><strong>
                            <GetPrice priceInCents={props.amount} />
                        </strong></div>
                        <div style={{ alignItems: 'center', marginRight: '10px', color: 'rgba(128, 128, 128, 0.6)' }}>•</div>
                        <div style={{}}><u><TimeAgo isoDate={props.timePosted} /></u></div>
                    </div>
                </div>
                <div className="fs-5">{props.message}</div>
            </Col>

        </Row>

        // <Row className="pt-2 pb-2 mb-4">
        //     <Col xs={12}>
        //         <h5>{props.donor}</h5>
        //     </Col>
        //     <Col xs={12}>
        //         <Row>
        //             <Col xs={5}>
        //                 <GetPrice priceInCents={props.amount} />
        //             </Col>
        //             <Col xs={5}>
        //                 <TimeAgo isoDate={props.timePosted} />
        //             </Col>
        //         </Row>
        //     </Col>
        //     <Col xs={12}>
        //         {props.content}
        //     </Col>
        // </Row>

    )
}

export default Comment
