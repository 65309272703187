import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import { NavLink, useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserProvider';
import Offcanvas from 'react-bootstrap/Offcanvas';



function Sidebar() {
    const { user, createCampaign } = useUser();
    const navigate = useNavigate();

    const handleNewCampaign = async () => {

        const x = await createCampaign();
        if (x === true) {


            navigate('/new_campaign/campaign_type', { replace: true })

        }
    }

    return (
        <div>
            {
                user  &&
                <Navbar sticky='top' expand={'lg'} className='flex-column MobileSidebar d-block d-lg-none'>
                    <Navbar.Toggle aria-controls={`offcanvasNavbar-expand`} />
                    <Navbar.Offcanvas
                        id={`offcanvasNavbar-expand`}
                        aria-labelledby={`offcanvasNavbarLabel-expand`}
                        placement="start"
                    >
                        <Offcanvas.Header closeButton>
                            <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                                Your profile
                            </Offcanvas.Title>
                        </Offcanvas.Header>

                        <Offcanvas.Body className='flex-column Sidebar'>
                            <Nav.Item><Nav.Link exact="true" as={NavLink} to={`/user/${user.username}`}>Profile</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link exact="true" as={NavLink} to={`/account-balance`}>AI Images & Credits</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link exact="true" as={NavLink} to="/my_campaigns">My Campaigns</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link onClick={() => handleNewCampaign()}>Start a campaign</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link exact="true" as={NavLink} to="/supported_campaigns">Supported Campaigns</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link exact="true" as={NavLink} to="/favorite_campaigns">Favorite Campaigns</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link exact="true" as={NavLink} to="/password">Manage Account</Nav.Link></Nav.Item>
                        </Offcanvas.Body>
                    </Navbar.Offcanvas>
                </Navbar >
            }

        </div >

    )
}

export default Sidebar