import React from 'react'
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { useImageBuilder } from '../contexts/ImageBuilderProvider';
import Placeholder from 'react-bootstrap/Placeholder';

function MockupButtons({ handleSubmit, handleShowModal }) {
    const { respData, disabled, isMockupLoading } = useImageBuilder()

    return (
        <Col xs="12"
            className="d-flex justify-content-evenly">


            {
                respData ?

                    ((respData['mode_name']) && (isMockupLoading || disabled)) ?
                        <Button className="rounded-pill" size="sm" disabled variant="success" onClick={() => handleSubmit(true)} bg="success">{disabled ? `Preview mockup` : <span><strong> Generating mockup...</strong> <Spinner size={'sm'} /></span>}</Button>
                        :
                        <Button className="rounded-pill" size="sm" variant="success" onClick={() => handleSubmit(true)} bg="success"><strong>Preview mockup</strong></Button>
                    :
                    <Placeholder className="rounded" animation="glow">
                        <Placeholder xs={4} className="rounded" />
                    </Placeholder>
            }

            {
                respData ?
                    respData['sample_mockup_url'] &&
                    <Button className="rounded-pill mx-3" size="sm" onClick={() => handleShowModal(true, respData['sample_mockup_url'])} variant="primary"><strong>Show last preview</strong></Button>
                    :
                    <Placeholder className="rounded" animation="glow">
                        <Placeholder xs={4} className="rounded" />
                    </Placeholder>
            }
            {
                respData ?
                    respData['mockup_url'] &&
                    <Button className="rounded-pill" size="sm"  onClick={() => handleShowModal(false, respData['mockup_url'])} variant="primary"><strong>Show saved mockup</strong></Button>
                    :
                    <Placeholder className="rounded" animation="glow">
                        <Placeholder xs={4} className="rounded" />
                    </Placeholder>
            }
        </Col>

    )
}

export default MockupButtons
