import React from 'react'
import ShareComponents from './ShareComponents';
import Offcanvas from 'react-bootstrap/Offcanvas';

function ShareCanvas({ campaignId, show, handleShowUpdate }) {

    return (
        <Offcanvas placement="bottom" show={show} onHide={() => handleShowUpdate(false)} >
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Share</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <ShareComponents campaignId={campaignId} />
            </Offcanvas.Body>
        </Offcanvas >

    )
}

export default ShareCanvas
