import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';



function InputValidationRow({ children, isTouched, validationRules }) {



    const renderValidationMessages = (rules, touched) => {
        return (
            touched &&
            rules.map((rule, index) => (
                <small
                    key={index}
                    className={rule.isValid ? 'text-success' : 'text-danger'}
                >
                    {rule.isValid ? <FaCheckCircle /> : <FaTimesCircle />} {rule.message}
                    <br />
                </small>
            ))
        );
    };


    return (
        <Row>

            <Col xs={12} className="">
                <Row>
                    <Col xs={11}>
                        {children}
                    </Col>

                    <Col className="d-flex justify-content-end align-items-center" xs={1}>
                        {isTouched && (
                            <span className="ml-2">
                                {validationRules.every((rule) => rule.isValid) ? (
                                    <FaCheckCircle color="green" size={22} />
                                ) : (
                                    <FaTimesCircle color="red" size={22} />
                                )}
                            </span>
                        )}
                    </Col>
                </Row>
            </Col>

            <Col xs={12}>
                <div className="validation">
                    {renderValidationMessages(validationRules, isTouched)}
                </div>
            </Col>
        </Row>


    )
}

export default InputValidationRow
