import React from 'react'

function YoutubeVideo({ url, title }) {

    return (
        url ?

            <div style={{ position: "relative", width: "100%", height: "0", paddingBottom: '56.25%', overflow: "hidden" }}>
                <iframe
                    src={url}
                    frameBorder="0"
                    allowFullScreen
                    style={{ position: "absolute", top: "0", left: "0", width: "100%", height: "100%",borderRadius:'.75rem' }}
                    referrerpolicy="strict-origin-when-cross-origin"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                    title="YouTube video player"
                ></iframe>
            </div>
            // <iframe width="560" height="315" src={url} title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>

            // <div style={{ position: 'relative', paddingBottom: '56.25%', height: 0, overflow: 'hidden' }}>
            //     <iframe
            //         style={{
            //             position: 'absolute',
            //             top: 0,
            //             left: 0,
            //             width: '100%',
            //             height: '100%',
            //             border: '0',
            //         }}
            //         src={url}
            //         title={title ? title : "YouTube Video"}
            //         allowFullScreen
            //     ></iframe>
            // </div>
            :
            <div>non url</div>

    )
}

export default YoutubeVideo
