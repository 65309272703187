import React from 'react'
import Spinner from 'react-bootstrap/Spinner';

function LoadingSpinner() {
    return (
        <div style={{ height: '100vh', 'width': '100vw', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
            <Spinner size={100} />
        </div>
    )
}

export default LoadingSpinner
