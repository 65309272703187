import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import InfoPopover from './InfoPopover';
import Popover from 'react-bootstrap/Popover';

function MapLegend({ mobile, modes, legend, handleHideLayer, textDark }) {

    return (



        mobile ?

            modes && modes.map((type, index) =>


                // <Col key={index} xs={12} md={12} xxl={6} className="my-1" 

                <Form.Check // prettier-ignore
                    checked={type.state}
                    onChange={() => handleHideLayer(index)}
                    style={{ cursor: 'pointer' }}
                    type="switch"
                    id="custom-switch"
                    className="fs-6"
                    reverse
                    label={<span className=""><span className="d-inline-block rounded" style={{ marginRight: ".25rem", height: "16px", backgroundColor: `${type.color}`, width: '16px', border: 'white solid 1px' }}></span>{type.name} area</span>}
                />

            )
            :
            <Row className={`justify-content-evenly  my-lg-2 p-0 m-0`}>
                {modes ?
                    modes.map((type, index) =>
                        <Col key={index} xs={'auto'} className="my-1 small" style={{ display: 'flex', justifyContent: 'center' }}>

                            <Form.Check // prettier-ignore
                                checked={type.state}
                                onChange={() => handleHideLayer(index)}
                                style={{ cursor: 'pointer' }}
                                type="switch"
                                id="custom-switch"
                                reverse
                                label={<span className="text-align-center"><span className="d-inline-block rounded" style={{ marginRight: ".25rem", height: "16px", backgroundColor: `${type.color}`, width: '16px', border: 'white solid 1px', }}></span>{type.name.includes('truck') ? 'Truck boundaries' : 'Billboard points'}</span>}
                            />




                        </Col>


                    )
                    :
                    <Col xs={12} className="d-flex my-1 m-0 p-0">
                        {
                            legend === undefined ?
                                <div></div>
                                :
                                legend === false ?
                                    <div></div>
                                    :
                                    legend.map((type, index) =>


                                        <div key={index} style={{ flex: 1, border: '1px solid black ' }} className="p-1">
                                            <div className="d-block d-lg-none">
                                                <div className="d-flex fw-bold align-items-center" style={{
                                                    color: 'black',
                                                    textShadow: " -1px -1px 0 white,1px -1px 0 white, -1px 1px 0 white,1px 1px 0 white"
                                                }}>
                                                    <span className="rounded " style={{ marginRight: "4px", height: "16px", backgroundColor: type.legend_color, width: '16px', border: 'white solid 1px', }}></span>
                                                    {type.label} area
                                                    <span >
                                                        <InfoPopover popover={
                                                            <Popover id={`popover-basic-`}>
                                                                <Popover.Header as="h3">Pricing</Popover.Header>
                                                                <Popover.Body>
                                                                    {type.description}
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                        />
                                                    </span>
                                                </div>
                                                <div >
                                                </div>
                                                <div style={{ textShadow: " -1px -1px 0 white,1px -1px 0 white, -1px 1px 0 white,1px 1px 0 white", }} className="d-none d-lg-block text-dark small ">
                                                    {type.description}
                                                </div>
                                            </div>
                                            
                                            
                                            
                                            
                                            <div className="d-none d-lg-block">
                                                <div className="d-flex fw-bold align-items-center" >
                                                    <span className="rounded " style={{ marginRight: "4px", height: "16px", backgroundColor: type.legend_color, width: '16px', border: 'white solid 1px', }}></span>
                                                    {type.label} area
                                                    <span >
                                                        <InfoPopover popover={
                                                            <Popover id={`popover-basic-`}>
                                                                <Popover.Header as="h3">Pricing</Popover.Header>
                                                                <Popover.Body>
                                                                    {type.description}
                                                                </Popover.Body>
                                                            </Popover>
                                                        }
                                                        />
                                                    </span>
                                                </div>
                                                <div >
                                                </div>
                                                <div className="d-none d-lg-block  small ">
                                                    {type.description}
                                                </div>
                                            </div>

                                        </div>
                                    )

                        }



                    </Col>

                }
            </Row >




    )
}

export default MapLegend
