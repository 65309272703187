import React from 'react'
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
// import { FaTruckMoving } from "react-icons/fa";
import { PiTruckDuotone } from "react-icons/pi";
import { billboardSvg } from '../utils/staticUrls';
function CampaignModes({ id, selectedCardId, handleCardClick, title, body, color, isLoading, mobile }) {



    return (

        mobile ?
            (isLoading || !title) ?
                <Placeholder as={Card.Body} className="rounded text-center" animation="glow">
                    <Placeholder xs={11} className="py-4 rounded bg-dark" />
                </Placeholder>
                :
                <Button
                    key={id}
                    onClick={(e) => handleCardClick(e, id)}
                    variant={`${selectedCardId === id ? 'success' : 'dark'}`}
                    style={{
                        borderRadius: '0px',
                        border: '1px solid white'

                    }}
                    className="w-100 p-2 py-3"
                >
                    <strong className="d-inline-flex">{title} campaign</strong>
                    {/* <strong className="d-inline-flex">{title}<span className="rounded" style={{ marginLeft: ".5rem", height: "20px", backgroundColor: ``, width: '20px', border: 'white solid 1px' }}></span></strong> */}

                </Button>
            :

            (isLoading || !title) ?
                <Placeholder as={Card.Title} className="rounded" animation="glow">
                    <Placeholder xs={12} className="p-5 rounded my-3" />
                </Placeholder>
                :

                <Button

                    key={id}
                    onClick={(e) => handleCardClick(e, id)}
                    variant={`${selectedCardId === id ? 'success' : 'dark'}`}
                    style={{

                        border: '.03em solid white',


                    }}
                    className="w-100 p-3 mb-5 fs-5 fw-bolder"
                >
                    <span

                    >
                        {
                            title.includes('truck') ?
                                <span className="shadow" style={{ backgroundColor: 'white', padding: '.4rem', borderRadius: '50%' }}><PiTruckDuotone size={24} color="#22262b" /></span>
                                :
                                <span className="shadow" style={{ backgroundColor: 'white', padding: '.4rem', borderRadius: '50%' }}><img src={billboardSvg} alt="My Icon" width="24" height="24" /></span>
                        }
                    </span>
                    <span>  {title} campaign</span>

                    {/* <strong className="d-inline-flex">{title}<span className="rounded" style={{ marginLeft: ".5rem", height: "20px", backgroundColor: ``, width: '20px', border: 'white solid 1px' }}></span></strong> */}

                </Button>





    )
}

export default CampaignModes

/* <Card
key={id}
className='m-3 text-center shadow-lg'
onClick={(e) => handleCardClick(e, id)}
style={{
    cursor: "pointer",

}}
>
{/* <Card.Img variant="top" src="holder.js/100px180" /> */
/* <Card.Body className={`${selectedCardId === id ? 'selected' : ''}`}
>
    {
        (isLoading || !title) ?
            <Placeholder as={Card.Title} className="rounded" animation="glow">
                <Placeholder xs={4} className="rounded" />
            </Placeholder>
            :
            <div className="d-flex  justify-content-center">
                <Card.Title className="fw-bolder">
                    <span>
                        {
                            title.includes('truck') ?
                                <span className="shadow" style={{ backgroundColor: 'white', padding: '.4rem', borderRadius: '50%' }}><PiTruckDuotone size={24} color="#22262b" /></span>
                                :
                                <span className="shadow" style={{ backgroundColor: 'white', padding: '.4rem', borderRadius: '50%' }}><img src={`/Billboard.svg`} alt="My Icon" width="24" height="24" /></span>
                        }
                    </span>
                    <span>  {title} campaign</span>
                </Card.Title>

            </div>

    }

</Card.Body>
</Card > */

