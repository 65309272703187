import React, { useState, useEffect } from 'react'
import Stack from 'react-bootstrap/Stack';
// import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import { useApi } from '../contexts/ApiProvider';
import Spinner from 'react-bootstrap/esm/Spinner';
import AIImgThumbnail from './AIImgThumbnail';
import PaginationNavV2 from './PaginationNavV2';





function AIImageList({ inline, isLoading, noRequest, aiImages, newMessage, handleFileAIInputChange, mediaName }) {
    const api = useApi()
    const [isProcessing, setIsProcessing] = useState(false)
    const [respData, setRespData] = useState();
    const ROUTE_NAME = '/get-ai-images';


    useEffect(() => {
        if (noRequest) {
            setRespData(aiImages)
        }
        else {

            (async () => {
                setIsProcessing(true);
                var response;
                try {
                    response = await api.get(ROUTE_NAME);
                } catch (error) {

                    setRespData(() => false);

                }

                if (response.ok) {

                    setRespData(() => response.body);
                }
                else {
                    setRespData(() => false);

                }
                setIsProcessing(false);
            })();
        }

    }, [api, aiImages]);



    const onPageChange = (pageNum) => {


        (async () => {
            setIsProcessing(true);

            if (!pageNum || pageNum === 0) {
                pageNum = 1
            }



            var search_param_dict = {

                page: pageNum,

            }
            const params = new URLSearchParams(search_param_dict);


            try {
                const response = await api.get(`/${ROUTE_NAME}?` + params);
                if (response.ok) {

                    setRespData(() => response.body);

                    setIsProcessing(false);
                }
                else {
                    setRespData(() => false);


                    setIsProcessing(false);
                }
            } catch (error) {

                setRespData(() => false);

                setIsProcessing(false);

            }


            setIsProcessing(false);
            return;

        })();



    }

    return (
        <div style={{ position: 'relative' }}>
            <Stack
                style={{
                    filter: isProcessing ? 'blur(2px)' : 'none',
                    pointerEvents: isProcessing ? 'none' : 'auto', // Disable interaction when loading
                    opacity: isProcessing ? 0.6 : 1, // Optionally reduce opacity for a visual effect
                }}
            >
                {
                    respData === undefined ?
                        <div><Spinner size={16} /></div>
                        :
                        respData === false ?
                            <div className="fw-bold fs-5">No images. Create AI images when creating a campaign. </div>
                            :
                            <div>
                                <Row style={
                                    inline ? {
                                        display: "flex",
                                        flexWrap: "nowrap",
                                        "overflowX": "auto",
                                        "whiteSpace": "nowrap",
                                    }
                                        :
                                        {}
                                }
                                >
                                    {
                                        isLoading &&
                                        <>
                                            <AIImgThumbnail
                                                isLoading
                                                image={{
                                                    'ai_is_hd': newMessage['ai_is_hd'],
                                                    'ai_style': newMessage['ai_style'],
                                                    'ai_size': newMessage['ai_size'],
                                                    'ai_message': newMessage['ai_message'],

                                                }}


                                            />
                                        </>

                                    }
                                    {
                                        respData.images && respData.images.length > 0 ?
                                            respData.images.map((i, _idx) =>
                                                <AIImgThumbnail key={_idx} mediaName={mediaName} handleFileAIInputChange={handleFileAIInputChange} image={i} />
                                            )
                                            // respData.images.map((i) => <Image style={{ height: '250px', width: '100%' }} src={i['url']} />)
                                            :
                                            <div className="fw-bold fs-5">No images. Create AI images when creating a campaign. </div>
                                    }

                                </Row >

                            </div>
                }

            </Stack >

            {
                isProcessing && (
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <Spinner animation="border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </Spinner>
                    </div>
                )
            }
            
            <div>
                {
                    ((respData) && ('pagination' in respData)) &&
                    <PaginationNavV2 totalPages={respData['pagination']} onPageChange={onPageChange} />
                }
            </div>
        </div >
    )
}

export default AIImageList
