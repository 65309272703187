import React, { useEffect, useState } from 'react'
import Body from '../components/Body'

import { useApi } from '../contexts/ApiProvider'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';

import {
    PaymentElement,
    useStripe,
    useElements
} from "@stripe/react-stripe-js";
import FormButtonBlock from '../components/FormButtonBlock'
import { useFlash } from '../contexts/FlashProvider'
import { useStripeContext } from '../contexts/StripeProvider'
import AIImageList from '../components/AIImageList';



function AccountBalancePage({ isModal }) {

    const stripe = useStripe();

    const api = useApi()
    const stripeContext = useStripeContext()


    const [isProcessing, setIsProcessing] = useState(true)
    const [respData, setRespData] = useState();
    const [selectedPricing, setSelectedPricing] = useState(0);
    const flash = useFlash();
    const elements = useElements();
    const [errorMessage, setErrorMessage] = useState()

    const handlePrice = (priceInCents) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(priceInCents / 100);
    };



    const handleSelectChange = (event) => {

        setSelectedPricing(event.target.value);
    };


    useEffect(() => {



        (async () => {
            const response = await api.get(`/get-account-balance`);
            if (response.ok) {


                setRespData(() => response.body);


            }
            else {

                setRespData(null);



            }
        })();


    }, [api]);




    const handleIsProcessing = (s) => setIsProcessing(s)


    const handlePaymentElementChange = (e) => {

        if (e.complete === true) {
            handleIsProcessing(false)
        }
        else {
            handleIsProcessing(true)
        }
    }



    const handleSubmit = async (e) => {

        e.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }


        handleIsProcessing(true);

        (async () => {
            const response = await api.post(`/add-funds`, {
                // payment_id: response.body.payment_id,
                chosen_pricing: respData['pricing'][selectedPricing]['price'],
                paymentIntentId: stripeContext.paymentIntentId
            });
            if (response.ok) {
                let paymentIntentId = response.body.paymentIntentId
                const result = await stripe.confirmPayment({
                    paymentIntentId,
                    elements,
                    confirmParams: {
                        // Make sure to change this to your payment completion page
                        return_url: `${process.env.REACT_APP_BASE_API_URL}/account-balance`
                    },
                    redirect: 'if_required'
                }); //This confirms the stripe setup by submitting what is in the elements to stripe to check for errors. 



                if (result.error) {
                    if (result.error.type === "card_error" || result.error.type === "validation_error") {
                        handleIsProcessing(false);
                        flash(result.error.message, 'danger')
                        console.log(result)
                        setErrorMessage(() => result.error.message);
                        elements.unmount()
                        elements.mount()
                    }
                    else {
                        handleIsProcessing(false);
                        // elements.unmount()
                        // elements.mount()
                    }
                } else {
                    console.log(response.body)
                    flash('Payment complete', "success")
                    stripeContext.handleRefreshStripe()
                    setRespData(response.body)
                    // refreshStripe.handleRefreshStripe(Math.random() * 2.5)

                }

                return true
            }
            else {
                if (response.status !== 401) {
                    'errors' in response.body ?
                        flash(response.body.errors.json.message)
                        :
                        flash("Error")
                    handleIsProcessing(false);
                    return false
                }
            }

        })();




    }





    return (

        // TODO:ADD USER
        <Body scrollable sidebar>

            {!isModal &&
                < Row className="mb-5">
                    <Col className="mb-3" xs={12}>
                        <h1 className="pricingSubHeader">Account Balance</h1>
                    </Col>
                    <Col className="text-muted mb-3" xs={12}>
                        <h4 >Add a balance to your account to create images from text using CrowdPost's AI.</h4>
                    </Col>
                </Row>
            }
            <Container >
                <Row>
                    <Col className="text-left mb-4" xs={12}>
                        <h5>Images left: <u>{respData && `${respData.current_quantity} out of ${respData.paid_quantity}`} </u></h5>
                    </Col>
                    <hr />
                    <Form onSubmit={handleSubmit} className="text-left mb-4">
                        <Col xs={12} lg={5} className="mb-4">
                            <Form.Group controlId="formSelect mb-5">
                                <Form.Label><strong>Choose pricing option:</strong></Form.Label>
                                <Form.Control onChange={(e) => handleSelectChange(e)} value={selectedPricing} as="select" >
                                    {respData && respData.pricing.map((v, i) =>
                                        <option value={i} key={i}>{v['label']}</option>
                                    )}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col xs={12}>
                            <PaymentElement onChange={(e) => handlePaymentElementChange(e)} />
                        </Col>
                        <Col xs={12}>
                            <span className="fs-2"><strong>Total: {respData && handlePrice(respData.pricing[selectedPricing].price)} </strong></span>
                        </Col>
                        {errorMessage && <div style={{ color: 'red' }}>{errorMessage}</div>}
                        {isProcessing ?
                            <FormButtonBlock text={"Pay now"} isProcessing={isProcessing} />
                            :
                            <FormButtonBlock text={"Pay now"} isProcessing={isProcessing} />
                        }
                    </Form>
                </Row>

                <hr />


                {!isModal &&
                    <Row className="my-5">
                        <Col className="text-center" xs={12}>
                            <h1><strong>Your CrowdPost AI Images</strong></h1>
                            <h4 className="text-muted">When creating your campaign media, use the CrowdPost AI feature to generate AI images that fit best given your chosen media type. </h4>
                        </Col>
                        <Col xs={12}>
                            <AIImageList />
                        </Col>
                    </Row>
                }

            </Container>



        </Body >


    )
}

export default AccountBalancePage


