import { useContext } from 'react';
import Alert from 'react-bootstrap/Alert';
import Collapse from 'react-bootstrap/Collapse';
import { FlashContext } from '../contexts/FlashProvider';

export default function FlashMessage() {
  const { flashMessage, visible, hideFlash } = useContext(FlashContext);

  return (
    <Collapse in={visible}>
      <div>
        <Alert variant={flashMessage.type || 'info'} dismissible
          onClose={hideFlash}
          // style={{
          //   position: "fixed",
          //   top: "0",
          //   left: ".5%",
          //   width: "99%",
          //   zIndex: "1000000",
          // }}
        >
          {flashMessage.message}
        </Alert>
      </div>
    </Collapse >
  );
}