import React from 'react'
import Popover from 'react-bootstrap/Popover';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';


function EditorDisplayButton({ header, body, children, color,onClick }) {
    return (
        <OverlayTrigger
            trigger={['focus', 'hover']}
            placement="bottom"
            overlay={
                <Popover id={`popover-${12}`}>
                    {header && <Popover.Header>{header} </Popover.Header>}
                    <Popover.Body>{body}</Popover.Body>
                </Popover>
            }
        >
            <Button onClick={onClick} variant={color ? color : "outline-secondary"} aria-label={header ? header : body}>
                {children}
            </Button>
        </OverlayTrigger>
    )
}

export default EditorDisplayButton

