import { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Body from '../components/Body';
import InputField from '../components/InputField';
import TextAreaField from '../components/TextAreaField';

import { useFlash } from '../contexts/FlashProvider';

import { useApi } from '../contexts/ApiProvider';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import {discordIcon} from '../utils/staticUrls'


import FormButtonBlock from '../components/FormButtonBlock';


function ContactPage() {
    const api = useApi();

    const contactNameField = useRef();
    const contactEmailField = useRef();
    const contactMessageField = useRef();
    const [formErrors, setFormErrors] = useState({});

    const flash = useFlash();



    const onSubmit = (ev) => {
        ev.preventDefault()


        const contactName = contactNameField.current.value;
        const contactEmail = contactEmailField.current.value;
        const contactMessage = contactMessageField.current.value;


        //client sid field validation
        const errors = {};
        if (!contactName) {
            errors.contactName = 'Must include a name.';
        }
        if (!contactEmail) {
            errors.contactEmail = 'Must include an email.';
        }
        if (!contactMessage) {
            errors.contactMessage = 'Must include a contact message.';
        }

        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        // end client side field validation 

        (async () => {
            const response = await api.post("/help", {
                'contactName': contactName,
                'contactEmail': contactEmail,
                'contactMessage': contactMessage,
            });
            if (response.ok) {
                flash('Your message was sent', 'success');
            }
            else {
                flash('Error sending your message was sent', 'danger');
            }

        })();

        return true

    };




    return (
        <>
            <Helmet>
                <title>CrowdPost - Contact Us</title>
                <meta property="og:title" content={`Let your voice reach the real town square`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
                <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." />

            </Helmet>
            <Body footer >
                <Row className="d-flex justify-content-center">
                    <Col xs={12} className="text-center">
                        <h1 className=" page-header ">Contact CrowdPost</h1>
                    </Col>

                    <Col className="mb-5" xs={12} lg={7}>
                        <h4 className="mb-4 text-secondary">
                            We are here to help answer any questions you have. Schedule a video call, send us a message or give us a call. We're here to help.
                        </h4>
                        <Form id="contact_form" onSubmit={onSubmit}>
                            <InputField fieldRef={contactNameField}
                                name="contactName" label="Name"
                                error={formErrors.contactName} />
                            <InputField fieldRef={contactEmailField}
                                name="contactEmail" label="Email" type="email"
                                error={formErrors.contactEmail} />
                            <TextAreaField

                                fieldRef={contactMessageField}
                                name="contactMessage"
                                label="Message"
                                placeholder="Message"
                                type="textarea"
                                error={formErrors.contactMessage}
                                rows={8}
                            />
                            <FormButtonBlock text={"Contact"} />
                        </Form>
                    </Col>

                    <hr />
                    <Col xs={12} className="my-5 text-center">
                        <h3 className="mb-3 fw-bold">Call CrowdPost</h3>

                        <Button href={"tel:18889219011"} className="rounded-pill" size="lg" variant="primary"><strong>888-920-9211</strong></Button>

                    </Col>
                    <hr />
                    <Col xs={12} className="my-5 text-center">
                        <div className="mb-1 fw-bold fs-3">Join our discord server</div>
                        <div className="text-muted fs-5">Ask questions, share campaign ideas, give us feedback and more.</div>
                        <a href="https://discord.gg/GcXbBSHd" target="_blank" rel="noopener noreferrer" >
                            <img
                                src={discordIcon}
                                alt={'Social media icon'}
                                style={{ width: 300, height: 200 }}
                            />

                        </a>


                    </Col>
                    {/* <hr/> */}
                    {/* <Col xs={12} className="my-5">
                        <h1>Schedule a video call</h1>
                        <iframe src="https://calendar.google.com/calendar/appointments/schedules/AcZssZ28h799cFam3RI4V7Q1PQNsoDfdc8zbUKeru94QQIdRIgmwZYFe1fCK9MYwi2XqvUSKlcXIY5oe?gv=true" style={{"border": "0"}} width="100%" height="600" frameborder="0"></iframe>
                    </Col> */}
                </Row>
            </Body>
        </>
    )
}

export default ContactPage
