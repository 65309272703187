import React, { useRef, useState, useEffect } from 'react'
import { FcPanorama } from "react-icons/fc";
import MediaSimulation from './MediaSimulation'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import PostMessageMedia from './PostMessageMedia';
import UploadMedia from './UploadMedia';
import TextToImage from './TextToImage';
import CustomMedia from './CustomMedia';
import { useImageBuilder } from '../contexts/ImageBuilderProvider';
import Collapse from 'react-bootstrap/Collapse';
import '../styles/CampaignMediaComponentStyles.css'
import '../styles/NewCampaignSidebarLayout.css'
import FontEditorDisplay from './FontEditorDisplay';
import TextAreaField from './TextAreaField';
import NewCampaignImageBackground from './NewCampaignImageBackground';
import TabEditorBody from './TabEditorBody';


function CampaignMediaComponent({ image_data, handleUpdateRespData, mediaName, addMediaRef, showThumbnail, isMounted, mediaRefs }) {

    const quillRef = useRef(null);
    const { handleImageTypesChange, handleImageMessageListContextChange, handleMessageChange, handleUpdateBaseMessage, handleImgHoverUpdate, handleToggleImgEditDisplay, imgEditDisplay } = useImageBuilder()



    const api = useApi();
    const flash = useFlash();
    const [value, setValue] = useState('');



    const [activeEditMessage, setActiveEditMessage] = useState(false);



    const [imageHover, setImageHover] = useState(false);


    const [height, setHeight] = useState(0);
    const [imgWidth, setImgWidth] = useState(0);
    const [minFontSize, setMinFontSize] = useState()
    const [tabKey, setTabKey] = useState('externalUpload');
    const mediaRef = useRef();
    const [messageWidth, setMessageWidth] = useState(6)

    const defaultColors = [
        "#ffffff",
        "#000000",
        "#ff0000",
        "#00ff00",
        "#0000ff",
        "#800080"
    ]


    const handleFileInputChange = async (event, index, onComplete) => {

        const file = event.target.files[0];
        const reader = new FileReader();

        const allowedExtensions = ['jpg', 'png', 'jpeg', 'webp']; // Allowed file extensions
        const fileExtension = file.name.split('.').pop().toLowerCase();

        if (!allowedExtensions.includes(fileExtension)) {
            flash(`Invalid file type. Allowed types: ${allowedExtensions.join(', ')}`, 'warning');
            onComplete();
            return;
        }


        reader.onloadstart = () => {

        };


        reader.onloadend = () => {



            // Create new Image object
            const img = new Image();
            img.onload = function () {
                // Calculate aspect ratio
                const aspectRatio = img.naturalWidth / img.naturalHeight;

                (async () => {

                    const response = await api.post(`/new_campaign/process-background-image`, JSON.stringify({ image: reader.result, media_type: mediaName }));
                    if (response.ok) {

                        const updated_resp_data = {
                            ...image_data.image,
                            isLoading: false,
                            url: response.body.url,
                            filename: file.name,
                            image_aspect_ratio: aspectRatio,
                        }

                        handleUpdateRespData(mediaName, updated_resp_data);
                        onComplete()

                    }
                    else {
                        onComplete()


                        'body' in response && 'errors' in response.body ?
                            flash(response.body.errors.json.message, 'danger')
                            :
                            flash('Error with request', 'danger')

                    }

                })();




            };
            img.src = reader.result; // Set src to initiate image loading

        };



        if (file) {
            reader.readAsDataURL(file);

        }
    };


    // function imageToBase64(url) {
    //     return fetch(url)
    //         .then((response) => {
    //             if (!response.ok) {
    //                 throw new Error(`Error fetching image. Status code: ${response.status}`);
    //             }
    //             return response.blob(); // Convert the response to a Blob
    //         })
    //         .then((blob) => {
    //             return new Promise((resolve, reject) => {
    //                 const reader = new FileReader();
    //                 reader.onloadend = () => resolve(reader.result); // Get base64 string without the data type
    //                 reader.onerror = reject;
    //                 reader.readAsDataURL(blob); // Read the Blob as a data URL
    //             });
    //         });
    // }






    // TODO: MAKE SURE THAT THE IMAGES ARE IN B64



    const handleFileAIInputChange = async (url, mediaName) => {

        try {
            // const base64String = await imageToBase64(url);

            // console.log(base64String); // Use the variable as needed




            // const updated_resp_data = {
            //     url: base64String
            // }
            const updated_resp_data = {
                url: url
            }
            // const updated_resp_data = {
            //     url: process.env.REACT_APP_BASE_API_URL + '/api' + url
            // }



            handleUpdateRespData(mediaName, updated_resp_data);





        } catch (error) {
            console.error('Error converting image to base64:', error);
        }



        // const updated_resp_data = {
        //     url: convertImageToBase64(url)
        // }
        // // const updated_resp_data = {
        // //     url: process.env.REACT_APP_BASE_API_URL + '/api' + url
        // // }



        // handleUpdateRespData(mediaName, updated_resp_data);








    };





    const handleRemoveImage = (index) => {



        const updated_resp_data = {
            image: {
                ...image_data.image,
                url: false,
                final_url: false,
                filename: false
            }

        };            // const updated_resp_data = { ...image_data, image_types: { ...image_data.image_types, [index]: nextImageTypes } };

        handleUpdateRespData(mediaName, updated_resp_data);

    };



    const handleImageMessageListChange = (index, updates) => {
        handleImageHover(true);

        handleImageMessageListContextChange(mediaName, index, updates)


    }






    useEffect(() => {

        height && setMinFontSize(parseInt(.08 * height))

    }, [height])



    useEffect(() => {
        if (imgEditDisplay && Object.hasOwn(imgEditDisplay, mediaName)) {
            imgEditDisplay[mediaName] === true && setActiveEditMessage(false)
        }

    }, [imgEditDisplay])



    const handleFontSizeChange = (e, index) => {

        const updated_resp_data = {
            ...image_data,
            [index]: {
                ...image_data[index],
                font_size: e.target.value
            }

        };
        handleUpdateRespData(mediaName, updated_resp_data);


    };

    const handleFontFamily = (e, index) => {

        const updated_resp_data = {
            ...image_data,
            [index]: {
                ...image_data[index],
                font_family: e.target.value
            }

        };
        handleUpdateRespData(mediaName, updated_resp_data);

    }

    const handleRangeChange = (e, index) => {
        const updated_resp_data = {
            ...image_data,
            [index]: {
                ...image_data[index],
                angle: e.target.value
            }

        };
        handleUpdateRespData(mediaName, updated_resp_data);
    }

    const handleResize = (mediaRef) => {

        if (mediaRef && mediaRef.current) {
            let height = mediaRef.current.clientHeight
            if (height === 0) {
                console.warn('handle resize is 0')
                // alert('height is 0')
                return
            }

            setHeight(() => height);
            setImgWidth(() => mediaRef.current.clientWidth);



        }

    };

    // useEffect(() => {
    //     const timeoutId = setInterval(() => { 
    //         const newHeight = mediaRef.current.clientHeight;
    //         console.log('media refs here', mediaRefs)
    //         console.log(newHeight)
    //         if (newHeight > 0) {
    //             setHeight(newHeight);
    //         } else {
    //             console.warn("Height is still 0, retrying...");
    //         }
    //     }, 500); // Small delay to allow rendering
    // }, [tabKey])


    const handleColorChange = (color, index) => {

        const updated_resp_data = {
            ...image_data,
            [index]: {
                ...image_data[index],
                color: color

            }

        };
        handleUpdateRespData(mediaName, updated_resp_data);
    }


    //Delete below
    const handleImagePositionChange = (e, index) => {

        const updated_resp_data = {
            ...image_data,
            image: {
                ...image_data.image,
                background_position: e.target.value
            }

        };
        handleUpdateRespData(mediaName, updated_resp_data);

    }




    //Delete below
    const handleBackgroundRepeatChange = (e, index) => {
        const updated_resp_data = {
            ...image_data,
            image: {
                ...image_data.image,
                background_repeat: e.target.value
            }

        };
        handleUpdateRespData(mediaName, updated_resp_data);

    }

    const handleFontStyleChange = (e, index, style) => {
        const updated_resp_data = image_data[index][style] === true ?
            {
                ...image_data,
                [index]: {
                    ...image_data[index],
                    [style]: false
                }

            }
            :
            {
                ...image_data,
                [index]: {
                    ...image_data[index],
                    [style]: true
                }

            }


        handleUpdateRespData(mediaName, updated_resp_data);
    }

    const handleFontBackgroundColorChange = (e, index) => {


        const updated_resp_data = {
            ...image_data,
            [index]: {
                ...image_data[index],
                'background_color': e,
                background_opacity: 100,
            }

        }

        handleUpdateRespData(mediaName, updated_resp_data);
    }



    const handleMessageWidthChange = (e) => {
        e.preventDefault();
        setMessageWidth(e.target.value)
    }

    const handleEmojiUpdate = (e, message_id, mediaName) => {
        e.preventDefault();
        //TODO: you need to modify the handle Update resp data to update the context by removing the copy of hte previous state and only including the data key value pair that you need. 


        if (message_id === 'base') {

            // const updated_resp_data = {
            //     ...image_data,
            //     base_message: {
            //         ...image_data.base_message,
            //         message: image_data.base_message.message ? image_data.base_message.message + e.target.textContent : e.target.textContent
            //     }

            // };
            const data = {

                message: image_data.base_message.message ? image_data.base_message.message + e.target.textContent : e.target.textContent


            };
            handleUpdateBaseMessage(mediaName, data)
            // handleUpdateRespData(mediaName, updated_resp_data);
            const quill = quillRef.current.getEditor(); // Get the Quill editor instance
            const range = quill.getSelection(); // Get the current selection range

            const length = quill.getLength();

            // Get the last line and its starting index
            const [lastLine] = quill.getLine(length);
            const lastLineIndex = quill.getIndex(lastLine);
            quill.insertText(lastLineIndex + lastLine.length() - 1, e.target.textContent);


        }
        else {
            return;
        }
    }

    const handleIncludeNameChange = (e, index) => {



        const updated_resp_data = {
            ...image_data,
            [index]: {
                ...image_data[index],
                include_name: e.target.checked
            }

        };
        handleUpdateRespData(mediaName, updated_resp_data);
    }

    const handleAIImageSubmit = () => {

        return (async () => {
            const response = await api.post(`/new_campaign/new-ai-image`, JSON.stringify({
                ai_message: image_data.image.ai_message,
                ai_is_hd: image_data.image.ai_is_hd,
                ai_quality: image_data.image.ai_is_hd,
                ai_style: image_data.image.ai_style,
                ai_size: image_data.image.ai_size,
            }));
            if (response.ok) {
                // window.localStorage.setItem('openai-taskid', response.body.task_id)
                return response.body.task_id

            }
            else {

                if ('body' in response && 'errors' in response.body) {
                    flash(response.body.errors.json.message, 'danger')
                }
                else if (response.status === 401) {
                    flash("Must be logged in and purchase credits to generate AI images", 'danger');
                }
                else {
                    flash('Error with request', 'danger')
                }
                return false

            }

        })();


    }



    const handleAIMessageChange = (event, index, formField) => {

        if (formField === 'message') {
            const updated_resp_data = {
                ai_message: event.target.value
            }
            handleUpdateRespData(mediaName, updated_resp_data);

        }
        else if (formField === 'checkbox') {

            const updated_resp_data = {
                ai_is_hd: event.target.checked
            }

            handleUpdateRespData(mediaName, updated_resp_data);
        }
        else if (formField === 'style') {

            const updated_resp_data =
            {

                ai_style: event.target.value
            }

            handleUpdateRespData(mediaName, updated_resp_data);
        }
        else if (formField === 'size') {

            const updated_resp_data = {

                ai_size: event.target.value
            }

            handleUpdateRespData(mediaName, updated_resp_data);
        }
    }



    const handleOutlineChange = (e, index, is_blur = false, is_color = false) => {

        let updated_resp_data;
        if (is_blur) {
            updated_resp_data = {
                ...image_data,
                [index]: {
                    ...image_data[index],
                    outline_blur: e.target.value,
                    outline_width: 0,
                }
            };

        }
        else if (is_color) {
            updated_resp_data = {
                ...image_data,
                [index]: {
                    ...image_data[index],
                    outline_color: e,

                }
            };
        }
        else {

            updated_resp_data = {
                ...image_data,
                [index]: {
                    ...image_data[index],
                    outline_width: e.target.value,
                    outline_blur: 0,

                }
            };
        }

        handleUpdateRespData(mediaName, updated_resp_data);
    }

    const handleOutlineTypeChange = (e, index) => {

        let updated_resp_data;
        let outline_type = e.target.value;

        if (outline_type === 'none') {
            updated_resp_data = {
                ...image_data,
                [index]: {
                    ...image_data[index],
                    outline_type: e.target.value
                }
            };
        }
        else if (outline_type === 'shadow') {
            updated_resp_data = {
                ...image_data,
                [index]: {
                    ...image_data[index],
                    outline_type: e.target.value,
                    outline_width: 0,
                    outline_blur: 0.03,
                }
            };
        }
        else if (outline_type === 'outline') {
            updated_resp_data = {
                ...image_data,
                [index]: {
                    ...image_data[index],
                    outline_type: e.target.value,
                    outline_width: 0.03,
                    outline_blur: 0,
                }
            };
        }


        updated_resp_data && handleUpdateRespData(mediaName, updated_resp_data);
    }


    const handleTextAlignChange = (value, index) => {


        if (value === 'center' || value === 'left' || value === 'right') {


            const updated_resp_data = {
                ...image_data,
                [index]: {
                    ...image_data[index],
                    'text_align': value
                }
            };

            handleImageTypesChange(mediaName, updated_resp_data);
        }
    }



    const handleFontBackgroundColorOpacityChange = (e, index) => {
        let opacity = e.target.value;
        let updated_resp_data;
        let currentColor;
        let color;


        if (parseInt(opacity) <= 99) {

            if (opacity <= 9) {
                opacity = `0${opacity}`
            }


            if (image_data[index]['background_color'].length === 9) {
                currentColor = image_data[index]['background_color'].slice(0, 7); // Remove the alpha value
                color = currentColor + opacity

            }
            else {
                color = image_data[index]['background_color'] + `${opacity}`

            }

            updated_resp_data = {
                ...image_data,
                [index]: {
                    ...image_data[index],
                    background_opacity: opacity,
                    background_color: color
                }
            };

            handleUpdateRespData(mediaName, updated_resp_data);
        }
        if (parseInt(opacity) === 100) {
            currentColor = image_data[index]['background_color'].slice(0, 7); // Remove the alpha value
            color = currentColor;

            updated_resp_data = {
                ...image_data,
                [index]: {
                    ...image_data[index],
                    background_opacity: opacity,
                    background_color: color
                }
            };

            handleUpdateRespData(mediaName, updated_resp_data);

        }
    }




    const imageHandlers = {
        "handleImagePositionChange": handleImagePositionChange,
        "handleBackgroundRepeatChange": handleBackgroundRepeatChange,
        // "handleFullScreenChange": handleFullScreenChange,
        "defaultColors": defaultColors,
        "handleMessageWidthChange": handleMessageWidthChange,
        "handleImageMessageListChange": handleImageMessageListChange,
        "handleColorChange": handleColorChange,
    }

    const fontHandlers = {
        "handleImageMessageListChange": handleImageMessageListChange,
        "handleFontStyleChange": handleFontStyleChange,
        "handleFontSizeChange": handleFontSizeChange,
        "handleRangeChange": handleRangeChange,
        "handleColorChange": handleColorChange,
        "handleFontFamily": handleFontFamily,
        "handleFontBackgroundColorChange": handleFontBackgroundColorChange,
        "defaultColors": defaultColors,
        "handleMessageWidthChange": handleMessageWidthChange,
        "handleEmojiUpdate": handleEmojiUpdate,
        "handleOutlineChange": handleOutlineChange,
        "handleOutlineTypeChange": handleOutlineTypeChange,
        "handleFontBackgroundColorOpacityChange": handleFontBackgroundColorOpacityChange,
        "handleTextAlignChange": handleTextAlignChange,

    }

    const formHandlers = {
        'handleFileInputChange': handleFileInputChange,
        'handleMessageChange': handleMessageChange,
        "handleRemoveImage": handleRemoveImage,
        "handleIncludeNameChange": handleIncludeNameChange,
        "handleAIImageSubmit": handleAIImageSubmit,
        "handleAIMessageChange": handleAIMessageChange,


    }





    useEffect(() => {

        // Listen for window resize events to update the height
        // window.addEventListener('resize', () => handleResize(mediaRef));
        // Clean up the event listener when the component unmounts

        const observer = new ResizeObserver(() => handleResize(mediaRef));
        if (mediaRef.current) {
            addMediaRef(mediaRef);
            observer.observe(mediaRef.current);
            handleResize(mediaRef); // Update the height initially
        }



        if (quillRef.current) {

            const quill = quillRef.current.getEditor();
            quill.format('size', 'large');
            handleResize();

        }
        return () => {
            // window.removeEventListener('resize', handleResize);
            observer && observer.disconnect();
        };
    }, [tabKey]);




    const handleQuillChange = (content, _, __, editor) => {
        let contents = editor.getContents();
        let html = editor.getHTML();
        setValue(contents);


        handleUpdateBaseMessage(mediaName, { html: html });
    };





    const handleImageHover = (data) => {

        setImageHover(data);
        handleImgHoverUpdate(data);


    }

    const handleToggleEditMessage = (index, isImageMessage = true) => {

        if (isImageMessage === true) {
            if (index === false) {

                setActiveEditMessage(false);
            }
            else {


                setActiveEditMessage(index)
            }
        }
        else {
            setActiveEditMessage(false); //TODO d=add togle for editing imgs
            handleToggleImgEditDisplay()

        }
    }

    const mediaSimulationProps = {
        imgWidth: imgWidth, imageHover: imageHover, handleImageHover: handleImageHover, handleImagePositionChange: handleImageMessageListChange, minFontSize: minFontSize, mediaName: mediaName, mediaRef: mediaRef, ref: mediaRef, activeTab: tabKey, handleResize: handleResize, image_data: image_data, height: height, handleUpdateRespData: handleUpdateRespData, messageWidth: messageWidth, value: value, handleQuillChange: handleQuillChange,
    }

    const MEDIA_SIMULATION_CLASS = `bg-white  m-0 px-0 py-sm-0 py-xl-3`
    const MEDIA_SIMULATION_STYLES = { 'textAlign': "-moz-center", 'textAlign': 'center', textAlign: "-webkit-center" }
    // const MEDIA_SIMULATION_STYLES = { 'textAlign': "-moz-center", 'textAlign': 'center', textAlign: "-webkit-center" }





    return (


        <>


            <h1>{image_data['label']}</h1>

            <Col className={`mb-5`} xs={12}>
                {/* <Col className={`mb-5 ${activeEditMessage !== false ? 'hidden' : 'visible'} sidebar editorTransition`} xs={12}> */}

                <Tabs
                    id="controlled-tab-example"
                    activeKey={tabKey}
                    onSelect={(k) => setTabKey(k)}
                    // onSelect={(k) => {
                    //     setTimeout(()=>{
                    //         setTabKey(k);
                    //     },1000)
                    // }}

                    fill
                // mountOnEnter={true}
                // unmountOnExit={false} // Prevent 
                >


                    <Tab eventKey="externalUpload" title="Upload existing design">



                        <Col className={MEDIA_SIMULATION_CLASS} style={MEDIA_SIMULATION_STYLES} xs={12}>

                            <MediaSimulation {...mediaSimulationProps} componentTab="externalUpload" />

                        </Col>

                        <TabEditorBody mediaName={mediaName} image_data={image_data} handleFileInputChange={handleFileInputChange} imgEditDisplay={imgEditDisplay} >
                            <UploadMedia imageHandlers={imageHandlers} handleFileInputChange={formHandlers['handleFileInputChange']} image_data={image_data} mediaName={mediaName} />
                        </TabEditorBody>


                    </Tab>


                    <Tab onClick={() => handleImageHover(false)} eventKey="dragDrop" title="Drag & Drop">

                        <Col className={MEDIA_SIMULATION_CLASS} style={MEDIA_SIMULATION_STYLES} xs={12}>

                            <MediaSimulation {...mediaSimulationProps} componentTab="dragDrop" />

                        </Col>


                        <TabEditorBody mediaName={mediaName} image_data={image_data} handleFileInputChange={handleFileInputChange} imgEditDisplay={imgEditDisplay} >


                            <Collapse in={(activeEditMessage !== false && activeEditMessage !== undefined )? true : false} timeout={400} >

                                <div className="m-0 p-0" >

                                    {activeEditMessage !== false && activeEditMessage !== undefined ?
                                        <Col xs={12} lg={12} className={`d-flex justify-content-center mb-5`}>
                                            <Row className="d-flex justify-content-center">
                                                <FontEditorDisplay handleToggleEditMessage={handleToggleEditMessage} handleImageHover={handleImageHover} mediaName={mediaName} handleUpdateRespData={handleUpdateRespData} mediaRef={mediaRef} message_index="top_message" fontHandlers={fontHandlers} image_data={image_data} msgData={image_data['image_messages'][activeEditMessage]} height={height} index={activeEditMessage} />
                                                <Col className="my-4" xs={12}>
                                                    <TextAreaField
                                                        placeholder={"Enter your message"}
                                                        // label={v['label']}
                                                        onChange={(e) => { handleImageHover(true); handleMessageChange(e, mediaName, activeEditMessage) }}
                                                        // onChange={(e) => handleMessageChange(e, mediaName, index)}
                                                        formText={'Optional message'}
                                                        value={!image_data['image_messages'][activeEditMessage]["message"] ? '' : image_data['image_messages'][activeEditMessage]["message"]}
                                                        onFocus={(e) => { e.preventDefault(); handleImageHover(true) }}
                                                        onBlur={(e) => { e.preventDefault(); handleImageHover(false) }}
                                                    />
                                                </Col>

                                            </Row>
                                            {/* <GoSidebarExpand size={32} /> */}
                                        </Col>

                                        :
                                        ' '
                                    }
                                </div>


                            </Collapse>


                            <Collapse in={(activeEditMessage !== false && activeEditMessage !== undefined )? false : true} timeout={400} >
                                <div className="m-0 p-0">
                                    {
                                        !activeEditMessage &&

                                        <div>
                                            <CustomMedia handleToggleEditMessage={handleToggleEditMessage} handleImageHover={handleImageHover} image_data={image_data} mediaName={mediaName} imageHandlers={imageHandlers} fontHandlers={fontHandlers} handleImageMessageListChange={handleImageMessageListChange} />
                                            <NewCampaignImageBackground
                                                image_data={image_data}
                                                mediaName={mediaName}
                                                imageHandlers={imageHandlers}
                                                handleUpdateRespData={handleUpdateRespData}
                                                handleFileInputChange={handleFileInputChange}
                                                message_index="base_message"
                                            />
                                        </div>

                                    }
                                </div>

                            </Collapse>

                        </TabEditorBody>

                    </Tab>



                    <Tab eventKey="splitPost" title="Post a message">

                        <Col className={MEDIA_SIMULATION_CLASS} style={MEDIA_SIMULATION_STYLES} xs={12}>

                            <MediaSimulation {...mediaSimulationProps} componentTab="splitPost" />

                        </Col>

                        <TabEditorBody mediaName={mediaName} image_data={image_data} handleFileInputChange={handleFileInputChange} imgEditDisplay={imgEditDisplay} >


                            <Row className="">
                                <Col className="" xs={12}>

                                    <PostMessageMedia handleEmojiUpdate={handleEmojiUpdate} image_data={image_data} imageHandlers={imageHandlers} fontHandlers={fontHandlers} formHandlers={formHandlers} mediaName={mediaName} height={height} handleImageHover={handleImageHover} minFontSize={minFontSize} handleQuillChange={handleQuillChange} value={value} quillRef={quillRef} handleResize={handleResize} isMounted={isMounted} imgWidth={imgWidth} messageWidth={messageWidth}/>

                                </Col>
                            </Row>
                        </TabEditorBody>

                    </Tab>


                    <Tab eventKey="aiImage" title={<span>Generate background with AI <FcPanorama /></span>}>

                        <Col className={MEDIA_SIMULATION_CLASS} style={MEDIA_SIMULATION_STYLES} xs={12}>

                            <MediaSimulation {...mediaSimulationProps} componentTab="aiImage" />

                        </Col>

                        <TextToImage

                            tabKey={tabKey}
                            handleAIMessageChange={handleAIMessageChange}
                            mediaName={mediaName}
                            handleAIImageSubmit={handleAIImageSubmit}
                            image_data={image_data}
                            imageHandlers={imageHandlers}
                            handleFileAIInputChange={handleFileAIInputChange}
                            formHandlers={formHandlers}

                        />
                    </Tab>


                </Tabs>


            </Col >





            {/* /* {
                showThumbnail &&
                <div>

                    <Col className="d-flex align-items-center mt-4">
                        <div>
                            <Form.Check // prettier-ignore
                                type={'checkbox'}
                                id={`default-`}
                                label={<strong>Set as thumbnail</strong>}
                                reverse
                                onChange={() => handleThumbnailChange(mediaName)}
                            // checked={image_data.image.thumbnail}
                            />
                        </div>
                    </Col>
                    <hr />
                </div>
            } */ }

        </ >







    )
}

export default CampaignMediaComponent


