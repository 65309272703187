import React from 'react'

function CrowdPostLogo({ responsive }) {
    return (
        !responsive ?
            <h3 className={`fw-bolder gradient-text`} >CrowdPost</h3>
            :
            <span className={`fw-bolder gradient-text`} style={{
                fontSize: responsive
            }}>CrowdPost</span>

    )
}

export default CrowdPostLogo
