import React, { useState, useEffect, useRef, useCallback } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Campaign from "./Campaign"

import { useApi } from '../contexts/ApiProvider';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import { useNavigate } from 'react-router-dom';

import Card from 'react-bootstrap/Card';
import PlaceholderButton from 'react-bootstrap/PlaceholderButton';
import Placeholder from 'react-bootstrap/Placeholder';
import MapPopup from './MapPopup';
import ReactDOM from 'react-dom/client';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import Dropdown from 'react-bootstrap/Dropdown'
import Form from 'react-bootstrap/Form';
import FormButtonBlock from './FormButtonBlock';
import RadioComponent from './RadioComponent';
// import { useTheme } from '../contexts/ThemeProvider';
import PaginationNavV2 from './PaginationNavV2';
import { useFlash } from '../contexts/FlashProvider';
import Spinner from 'react-bootstrap/Spinner';



mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_KEY


function CampaignMap({ routeName, campaigns, setCampaigns, bbox, setBbox, isLoading, setIsLoading, showMap, }) {
    const paginationStyle = { 'display': 'flex', 'justifyContent': 'end' }

    const flash = useFlash()

    const mapContainer = useRef(null);
    const map = useRef(null);

    // const [filterBy, setFilterBy] = useState({

    //     'Draft': false,
    //     'Accepting funding': false,
    //     'Awaiting decision': false,
    //     'Accepted': false,
    //     'Denied': false,
    //     'Active': false,
    //     'Expired': false,
    //     'Deactivated': false,
    //     'Closed': false


    // });
    const [numFilters, setNumFilters] = useState(0)

    const [filterBy, setFilterBy] = useState({
        all: false,
        active: false,
        submitted: false,
        accepted: false,
        denied: false,
        published: false,

    });

    useEffect(() => {
        const numBooleans = Object.values(filterBy).filter(value => value === true).length;
        setNumFilters(numBooleans)

    }, [filterBy])


    const [markers, setMarkers] = useState() //contains markers for the map. 
    const [paginationData, setPaginationData] = useState() //the active page, if theres a next/prev page. 
    const api = useApi();
    const navigate = useNavigate();

    const [isProcessing, setIsProcessing] = useState(false);
    const [sortBy, setSortBy] = useState(false);

    let getCampaign = useCallback((id, payment = false) => {
        payment ?

            navigate(`/campaign-payment/${id}`, { replace: true })
            :
            navigate(`/campaign/${id}`, { replace: true })
    }, [navigate])


    const sortByFields =
    {
        fields: [
            { label: 'End date: nearest to furthest', value: 'end_date_closest' },
            { label: 'End date: furthest to nearest', value: 'end_date_furthest' },
            { label: 'Amount Raised: high to low', value: 'amount_raised_descending' },
            { label: 'Amount Raised: low to high', value: 'amount_raised_ascending' },
        ]
    }

    const filterByFields = {
        //     'Draft': false,
        //     'Accepting funding': false,
        //     'Awaiting decision': false,
        //     'Accepted': false,
        //     'Denied': false,
        //     'Active': false,
        //     'Expired': false,
        //     'Deactivated': false,
        //     'Closed': false
        fields: [
            { label: 'All', value: 'all' },
            { label: 'Accepting Funding', value: 'active' },
            { label: 'Awaiting decision', value: 'submitted' },
            { label: 'Accepted', value: 'accepted' },
            { label: 'Denied', value: 'denied' },
            { label: 'Active', value: 'published' },
            { label: 'Underfunded', value: 'underfunded' },
            { label: 'Complete', value: 'complete' },
        ]
    }

    const handleClick = (ev) => {
        ev.preventDefault()
        setIsLoading(true)
        setIsProcessing(true)
        let current_zoom = map.current.getZoom()
        let coords = map.current.getBounds();
        let page = 1;
        if (paginationData && 'page' in paginationData) {
            page = paginationData['page']
        }
        (async () => {
            var search_param_dict = {
                sw_long: coords._sw.lng,
                sw_lat: coords._sw.lat,
                ne_long: coords._ne.lng,
                ne_lat: coords._ne.lat,
                zoom: current_zoom,
                sortBy: sortBy,
                filterBy: JSON.stringify(filterBy),
                page: page,

            }
            const last_search_params = new URLSearchParams(search_param_dict)
            const response = await api.get(`/${routeName}?` + last_search_params);

            if (response.ok) {

                setCampaigns(response.body.data)
                setIsProcessing(false)
                setCampaigns(() => response.body.data);

                response.body.pagination_data && response.body.pagination_data.pages.length > 0 && setPaginationData(response.body.pagination_data)
                setBbox(() => response.body.bbox);
                setIsLoading(false)
                search_param_dict['center'] = response.body.default_map_loc
                window.localStorage.setItem('last_search', JSON.stringify(search_param_dict))
            }
            else {

                if (response && 'errors' in response.body) {
                    flash(response.body.errors.json.message, 'danger')
                }
                setIsProcessing(false)

                setCampaigns([]);
                // setCampaigns(() => null);
                setIsLoading(false)
            }
        })();

    };


    const handleFilterByChange = (ev, value) => {

        setFilterBy((prevState) => ({
            ...prevState,
            [value]: ev.target.checked,
        }));
    };



    const handleSortByChange = (value) => {
        setSortBy(value);
    };



    const getCampaigns = (i) => {
        setIsLoading(true);
        try {

            (async () => {
                var search_param_dict = {
                    sw_long: bbox.sw.lng,
                    sw_lat: bbox.sw.lat,
                    ne_long: bbox.ne.lng,
                    ne_lat: bbox.ne.lat,
                    page: i,
                    zoom: bbox.zoom,
                    filterBy: filterBy,
                    sortBy: sortBy,

                }
                const last_search_params = new URLSearchParams(search_param_dict)
                const response = await api.get(`/${routeName}?` + last_search_params);

                if (response.ok) {

                    markers && markers.map((i, v) => i.remove());
                    setCampaigns(() => response.body.data);
                    // setMarkers(() => response.body.data);
                    setPaginationData(() => response.body.pagination_data);
                    setBbox(() => response.body.bbox);
                    window.localStorage.setItem('last_search', JSON.stringify(search_param_dict))
                }

                else {
                    flash('error', 'danger')
                }
                setIsLoading(false)
            })();
        }
        catch {
            setIsLoading(false)
        }

    }

    const popover =
        <Popover style={{ minWidth: "350px" }} className="m-3" id="popover-basic">
            <Popover.Header className="p-3" as="h4">Sorty By</Popover.Header>
            <Popover.Body className="d-inline">
                <Form onSubmit={handleClick} className="p-2">
                    <div className="custom-radio-container">
                        {sortByFields['fields'].map((option) => (
                            <div key={option.value} className="">
                                <RadioComponent label={option.label} value={option.value} checked={sortBy === option.value} onChangeFunc={handleSortByChange} />
                            </div>
                        ))}
                    </div>
                    <hr />
                    <FormButtonBlock isProcessing={isProcessing} text={'Apply'} />
                </Form>
            </Popover.Body>
        </Popover>
        ;


    const popover2 = (
        <Popover className="m-3" style={{ minWidth: "350px" }} id="popover-basic2">
            <Popover.Header className="p-3" as="h4">Filter By</Popover.Header>
            <Popover.Body >

                <Form onSubmit={handleClick} className="p-3">

                    {filterByFields['fields'].map((option) =>


                        <div key={option.value} className="d-flex align-items-center">
                            <Form.Check
                                disabled={isProcessing}
                                type='checkbox'
                                id={option.label}
                                checked={filterBy[option.value]}
                                onChange={ev => handleFilterByChange(ev, option.value)}
                                label={<p className="fs-5"><strong>{option.label}</strong></p>}
                            />
                        </div>




                    )}
                    <hr />
                    <Dropdown.Divider />
                    <FormButtonBlock isProcessing={isProcessing} text={'Apply'} />
                </Form>
            </Popover.Body>
        </Popover>
    );


    useEffect(() => {
        map.current && map.current.resize()
    }, [showMap])



    useEffect(() => {

        if (map.current) return; // initialize map only once\


        var bbox = JSON.parse(localStorage.getItem('last_search'))
        var starting_loc = bbox && [[bbox['sw_long'], bbox['sw_lat']], [bbox['ne_long'], bbox['ne_lat']]]


        if (!starting_loc) {
            // if (starting_loc && starting_loc[0].includes(undefined)) {

            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: process.env.REACT_APP_MAPBOX_CAMPAIGN_PAGE_MAP,
                bounds: [[-119.40032246093713, 33.560474551161235], [-117.82927753906199, 34.93511162625282]],
                // center: [44,74],
                // // center: [defaultLoc[0], defaultLoc[1]],
                // zoom: 9,
                projection: 'mercator',
            })



        } else {
            map.current = new mapboxgl.Map({
                container: mapContainer.current,
                style: process.env.REACT_APP_MAPBOX_CAMPAIGN_PAGE_MAP,
                // center: [74, 44],
                bounds: starting_loc,
                // center: [defaultLoc[0], defaultLoc[1]],
                // zoom: 9,
                projection: 'mercator',
            })
        }

        map.current.on("load", () => {
            map.current.addControl(new mapboxgl.FullscreenControl(), 'top-left')
        })



    }, [map]);


    // Function to validate the parsed state
    const validateState = (parsedState, initialState) => {
        // Check if all keys in the initial state are present in the parsed state
        // console.log(parsedState, initialState)
        return Object.keys(initialState).every(
            key => key && key in parsedState && typeof parsedState[key] === typeof initialState[key]
        );
    };



    useEffect(() => {
        var response;
        (async () => {
            var last_search = JSON.parse(window.localStorage.getItem('last_search'))
            // last_search['filterBy'] = JSON.parse(window.localStorage.getItem('filterBy'))
            if (!last_search) {

                response = await api.get(`/${routeName}`);
                // alert(1)
            }
            else {
                setSortBy(last_search['sortBy'])

                let last_filter_by;
                if (typeof last_search['filterBy'] === 'string') {

                    last_filter_by = JSON.parse(last_search['filterBy'])
                }
                else {

                    last_filter_by = last_search['filterBy'];
                }





                const isValid = validateState(last_filter_by, filterBy);
                if (isValid) {
                    // console.log(isValid)
                    setFilterBy(last_filter_by);
                }


                var search_params = {
                    sw_long: last_search.sw_long,
                    sw_lat: last_search.sw_lat,
                    ne_long: last_search.ne_long,
                    ne_lat: last_search.ne_lat,
                    zoom: last_search.zoom,
                    filterBy: JSON.stringify(last_search['filterBy']),
                    sortBy: last_search['sortBy']
                }
                response = await api.get(`/${routeName}?` +
                    new URLSearchParams(search_params)
                );

            }
            if (response.ok) {

                setCampaigns(() => response.body.data)
                setIsProcessing(false)

                setPaginationData(() => response.body.pagination_data)
                setBbox(response.body.bbox)


                map.current.setZoom(response.body.bbox['zoom'])


            }
            else {


                if (response && 'body' in response && 'errors' in response.body) {
                    flash(response.body.errors.json.message, 'danger')
                }



                setCampaigns(null)
                setIsProcessing(false)
                setIsLoading(false)

            }
        })()


    }, [])


    useEffect(() => {

        if (campaigns) {
            markers && markers.map(i => i.remove())
            var newMarkers = []

            for (let i = 0; i <= campaigns.length - 1; i++) {
                let campaign = campaigns[i]

                for (let j = 0; j <= campaign.locations.length - 1; j++) {
                    var loc = campaign.locations[j]
                    var popup = new mapboxgl.Popup({ offset: 25 }).setLngLat([loc['longitude'], loc['latitude']]);

                    let marker = new mapboxgl.Marker().setLngLat([loc['longitude'], loc['latitude']]).setPopup(popup).addTo(map.current)
                    const popupContent = document.createElement('div');
                    ReactDOM.createRoot(popupContent).render(<MapPopup getCampaign={getCampaign} campaign={campaign} />);

                    popup.setDOMContent(popupContent);
                    newMarkers.push(marker)

                }
            }
            setMarkers(() => newMarkers)

        }


        // setMarkers(() => newMarkers)

        return () => {
            markers && markers.map(i => i.remove())
        }


    }, [campaigns])




    return (
        <>

            <Col xs={12}>

                <Row >
                    <Col xs={'auto'} className="mx-2">
                        {
                            campaigns === undefined ?
                                <PlaceholderButton variant='outline-light' xs={12} >Sort By</PlaceholderButton>
                                :
                                <OverlayTrigger placement="bottom" rootClose={true} trigger="click" overlay={popover}>
                                    <Button variant="outline-light">Sort By</Button>
                                </OverlayTrigger>
                        }
                    </Col>
                    <Col xs={'auto'}>
                        {
                            campaigns === undefined ?
                                <PlaceholderButton variant='outline-light' xs={12} >Filter By </PlaceholderButton>
                                :
                                <OverlayTrigger placement="bottom" rootClose={true} trigger="click" overlay={popover2}>
                                    <Button variant="outline-light">
                                        {
                                            numFilters && parseInt(numFilters) > 0 ?
                                                <span>
                                                    Filter By
                                                    <span style={{ marginLeft: '.35rem' }} className="text-muted">
                                                        ({numFilters})
                                                    </span>
                                                </span>
                                                :
                                                <span >
                                                    Filter By
                                                </span>
                                        }
                                    </Button>
                                </OverlayTrigger>
                        }
                    </Col>
                </Row>

                {/* 
                <Row className="d-lg-none my-2">
                    {showMap && <Col xs={12}>
                        <MapLegend modes={modes} />
                    </Col>}
                </Row> */}
            </Col>
            <Col className={showMap ? "d-none d-lg-block" : 'd-block d-lg-block'} style={{ paddingBottom: "100px", 'height': '100vh', 'overflowY': 'scroll' }} lg={6}>

                {
                    // !isLoading ?
                    campaigns && campaigns.length === 0 ?
                        <p className="mt-3" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            className: 'my-5',
                        }}>There are no campaigns in this area meeting your criteria.</p>
                        :
                        // <Row className="campaignMapRowStyle">
                        <Row className="">

                            <div style={paginationStyle}>

                                <PaginationNavV2 paginationData={paginationData} onPageChange={getCampaigns} />
                            </div>
                            {/* <Col xs={12}>

                                <Row style={{ '--bs-gutter-x': '0px' }} > */}

                            {
                                campaigns !== undefined ?
                                    campaigns.map((campaign, index) =>
                                        <Campaign key={campaign.id} campaign={campaign} map={map} isProcessing={isLoading} />
                                    )
                                    :
                                    [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(i =>
                                        <Col key={i} className="mt-3" xs={12} md={12}>
                                            <Card >

                                                <Placeholder className="rounded" style={{ width: '100%', height: '300px', backgroundColor: '#e7e7e7' }} size="lg" as={Card.Img} animation="glow" />

                                                <Card.Body>
                                                    <Placeholder as={Card.Title} animation="glow">
                                                        $ <Placeholder className="rounded" xs={3} /> raised
                                                    </Placeholder>
                                                    <Placeholder className="rounded" as={Card.Text} animation="glow">
                                                        Posted <Placeholder xs={4} className="rounded" /> ago by <Placeholder xs={4} className="rounded" />

                                                    </Placeholder>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    )
                            }
                            {/* </Row>

                            </Col> */}

                            <div style={paginationStyle}>
                                <PaginationNavV2 paginationData={paginationData} onPageChange={getCampaigns} />
                            </div>

                        </Row>

                }

            </Col >


            <Col className={showMap ? "px-0 mx-0 d-block d-lg-block" : 'px-0 mx-0 d-none d-lg-block'} lg={6}>
                {/* //switch this to a absolute component. display the button in the top right. show overlay over map when loading making it opaque. see ai images. */}
                <div style={{ height: '100vh', width: '100%', position: 'relative', }}>
                    <div ref={mapContainer} style={{ 'height': '100%', width: '100%', zIndex: '1' }} />
                    <div
                        style={{
                            position: "absolute",
                            top: "0px",
                            right: "0px",
                            zIndex: "2",
                            margin: ".5rem",
                            paddingLeft: '1.5rem',
                            paddingRight: '1.5rem'
                        }}
                    >
                        <Button
                            variant="dark"
                            onClick={handleClick}
                            // style={{
                            //     position: "absolute",
                            //     top: "0px",
                            //     right: "0px",
                            //     zIndex: "1",
                            //     margin: ".5rem",
                            //     paddingLeft: '1.5rem',
                            //     paddingRight: '1.5rem'
                            // }}
                            className="shadow"
                        >
                            <strong>Fetch Data</strong>
                        </Button >
                    </div>

                    {isLoading &&
                        <div
                            style={{
                                height: '100%',
                                position: "absolute",
                                left: '0%',
                                top: '0%',
                                width: '100%',
                                // textAlign: 'center',
                                // alignContent: 'center',
                                backgroundColor: isLoading ? 'black' : 'none',
                                pointerEvents: isLoading ? 'auto' : 'none',
                                opacity: isLoading ? 0.6 : 1, // Optionally reduce opacity for a visual effect
                                zIndex: 3,

                                // filter: isLoading ? 'blur(2px)' : 'none',
                                // pointerEvents: isLoading ? 'none' : 'auto', // Disable interaction when loading
                            }}
                        >
                            <div style={{
                                // width: '100%',
                                // height:'100%',
                                // justifyContent: 'center',
                                // alignContent: 'center',
                                // display: 'flex',

                                textAlign: 'center',
                                display: 'flex',
                                flexWrap: 'wrap',
                                justifyContent: 'center',
                                alignContent: 'center', // This will work in most browsers (e.g., Firefox, Chrome)
                                WebkitAlignContent: 'center', // Safari prefix for compatibility
                                height: '100%', // Ensuring the height is set


                            }}>
                                <Spinner />
                            </div>

                        </div>

                    }



                </div>
                {/* <div className="d-none d-lg-block bg-dark rounded" style={{
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        zIndex: "1",
                        margin: "1rem",
                        padding: '1rem',
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center"
                    }} >

                        <MapLegend modes={modes} />

                    </div> */}


            </Col >



        </ >

    )
}

export default CampaignMap
