import React from 'react'
import Stack from 'react-bootstrap/Stack';
import Sidebar from './Sidebar';
import DesktopSidebar from './DesktopSidebar';
import Container from 'react-bootstrap/Container';
import FlashMessage from "../components/FlashMessage"
import Footer from './Footer';
import AboutSidebar from './AboutSidebar';


function Body({ sidebar, children, classStr, footer, aboutSidebar, faqs, scrollable, fullwidth }) {

    return (
        <>
            {/* <div className="pb-5"> */}
            <Container fluid>

                <FlashMessage />

            </Container>

            <Container fluid>
                <div style={scrollable ? { height: '90vh', overflowY: 'scroll' } : classStr ? classStr : null}  >
                    {/* <Container fluid style={scrollable ? { height: '90vh', overflowY: 'scroll' } : classStr ? classStr : null}  > */}
                    {/* <Container fluid style={classStr ? classStr : null}  > */}
                    <div className="mx-3">{sidebar && <Sidebar />}</div>
                    <Stack style={{ alignItems: 'baseline' }} direction="horizontal">
                        {sidebar && <DesktopSidebar />}
                        {aboutSidebar && <AboutSidebar faqs={faqs} />}
                        <div className={fullwidth || sidebar ? "container-fluid p-0 m-0 px-lg-3" : 'container-md'}>
                            {/* {newCampaign && <NewCampaignBreadcrumb currentStep={currentStep} campaignId={campaignId} />} */}
                            {children}
                        </div>
                    </Stack>
                </div>
            </Container>
            <div style={{paddingTop:'15rem'}}>
                {
                    footer && <Footer />
                }
            </div>
            {/* {
                footer && <Footer />
            } */}
        </>
    );
}

export default Body;