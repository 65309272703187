import React from 'react';

import ImageBuilderProvider from '../../contexts/ImageBuilderProvider';

import NewCampaignImage from '../../components/NewCampaignImage';



const NewCampaignImagePage = () => {





    return (
        <ImageBuilderProvider >



            <NewCampaignImage />



        </ImageBuilderProvider>
    );
};

export default NewCampaignImagePage;
