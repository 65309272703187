import React, { useState, useRef, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import Row from 'react-bootstrap/Row';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Placeholder from 'react-bootstrap/Placeholder';
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
// import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { useApi } from '../../contexts/ApiProvider';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'
import FlashMessage from "../../components/FlashMessage"
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';
import { useFlash } from '../../contexts/FlashProvider';
import { v4 as uuidv4 } from 'uuid';
import DynamicForm from '../../components/DynamicForm';
import NewCampaignBreadcrumb from '../../components/NewCampaignBreadcrumb';
import POIRadius from '../../components/POIRadius';
import MapLegend from '../../components/MapLegend';
import { initMapboxDraw, addBillboardCluster, handleShowBillboardLayer } from '../../utils/ShowBillboardLayers'
import Stack from 'react-bootstrap/esm/Stack';
import NewCampaignMapDescription from '../../components/NewCampaignMapDescription';
import NewCampaignMapHelpModal from '../../components/modals/NewCampaignMapHelpModal';
import RemoveMarker from '../../components/RemoveMarker';
import { createRoot } from 'react-dom/client';
import PolygonPOIList from '../../components/PolygonPOIList';

//TODO: submit radius or coordinates of polygon. If radius, create GEOJSON with point value and radius, the zoom effect wont matter since its a static map, else make it a polygon. 



mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_KEY


function NewCampaignMap() {

    const TRUCK_STRING_MATCH = 'truck'


    const [isGetAvgImpressionLoading, setIsGetAvgImpressionLoading] = useState(false);


    const drawModeRef = useRef(null)
    const mobileRef = useRef(null)
    const [disabled, setDisabled] = useState(false);
    const [modeName, setModeName] = useState(false);

    const [editNameIndex, setEditNameIndex] = useState(false);
    //TODO:
    const [legend, setLegend] = useState();
    const api = useApi();
    const [drawMode, setDrawMode] = useState(false);

    const navigate = useNavigate();
    const [markers, setMarkers] = useState()

    const [polygon, setPolygon] = useState()
    const removeMarkerInstancesRef = useRef([]); // Ref to store marker instances
    const geocoderContainerRef = useRef(null);
    // const mapContainer = useRef(null);
    // const fullScreenMapContainer = useRef(null);
    // const map = useRef(null);
    const [lng, setLng] = useState(-103.8521);
    const [lastCircle, setLastCircle] = useState();
    const [campaignId, setCampaignId] = useState(false);
    const [lat, setLat] = useState(44.6714);
    const [zoom, setZoom] = useState(4);
    const [featureCollection, setFeatureCollection] = useState();
    const [locationNumber, setLocationNumber] = useState(0);
    const mainRef = useRef(null);
    const fullscreenRef = useRef(null);
    const [formErrors, setFormErrors] = useState({})
    const [showHelpModal, setShowHelpModal] = useState(false);
    const [radius, setRadius] = useState(.25);
    const handleSetRadius = () => { setLastCircle(); setShowRadius(false) };
    const [showRadius, setShowRadius] = useState(false);
    const [center, setCenter] = useState()
    const MAX_RADIUS = 50;
    const MIN_RADIUS = .25;
    const [showLocations, setShowLocation] = useState(false);

    const handleHideLocationModal = () => setShowLocation(false);
    const handleShowLocationModal = () => setShowLocation(true);

    const [isFullScreen, setIsFullScreen] = useState(false);
    const flash = useFlash();

    const popupRef = useRef(new mapboxgl.Popup({ closeButton: true }));
    const mobilePopupRef = useRef(new mapboxgl.Popup({ closeButton: true }));
    const popupRefs = { 'main': popupRef, 'fullscreen': mobilePopupRef }
    const mobileBreakpoint = 991
    const mapRefs = {
        'main': { map: useRef(null), container: mainRef, geocoder: useRef(null) },
        'fullscreen': { map: useRef(null), container: fullscreenRef, geocoder: useRef(null) },

    }
    const [draw, setDraw] = useState({
        'main': null,
        'fullscreen': null
    })


    const handleHideHelpModal = () => setShowHelpModal(false);

    const handleExpandClick = () => {
        setIsFullScreen(!isFullScreen);
    };

    const isMobileDevice = () => window.matchMedia(`(max-width: ${mobileBreakpoint}px)`).matches;


    const handleUpdateFeatureCollection = (data) => {

        setFeatureCollection(data);
        Object.values(draw).map((drawInstance) => drawInstance && drawInstance.set(data));

    };

    const checkVisibility = () => {
        //Checks to see if the mobile component is visible so it can disable scroll in mobile view else enable the scroll. 


        if (window.innerWidth > mobileBreakpoint) {

            document.body.style.overflow = 'auto';


        }
        else if (mobileRef.current && mobileRef.current.offsetHeight > 0) {

            // Mobile view is visible
            window.scrollTo(0, 0);
            document.body.style.overflow = 'hidden';
        } else {
            // Desktop view is active, or mobile is hidden
            document.body.style.overflow = 'auto';

        }
    };



    const handleSetMapboxDraw = (key, drawInstance) => {

        if (key) {


            mapRefs[key]['map'].current && mapRefs[key]['map'].current.addControl(drawInstance, 'top-right');

            function updateArea(e, drawInstance) {

                if (e.type === 'draw.delete') {
                    // setFeatureCollection(null); 
                    // console.log(e, 'delete event, find a way to remove the polygon in question from the polygon state')
                    return true
                }
                if (e.type === 'draw.create') {

                    setDrawMode(false)
                }
                if (e.type === 'draw.update') {

                    setDrawMode(false)
                }

                setShowRadius(false);


                var data = drawInstance.getAll();

                let counter = 1
                var name
                for (let i in data.features) {
                    i = data.features[i]
                    if ('poi_name' in i.properties && i.properties.poi_name) {

                        name = i.properties.name
                    }
                    else {
                        if ('name' in i.properties) {

                            name = i.properties.name
                        }
                        else {

                            name = `Custom area ${counter}`
                        }
                        counter++
                    }
                    i.properties.name = name

                }

                setFeatureCollection(data)
                // drawInstance.set(data);
                // Object.values(draw).map((draw) => draw.set(data))




            }



            if (mapRefs[key]['map'].current) {
                mapRefs[key]['map'].current.on('draw.create', (e) => updateArea(e, drawInstance));
                mapRefs[key]['map'].current.on('draw.delete', (e) => updateArea(e, drawInstance));
                mapRefs[key]['map'].current.on('draw.update', (e) => updateArea(e, drawInstance));
                mapRefs[key]['map'].current.on('draw.selection_change', (e) => updateArea(e, drawInstance));
                mapRefs[key]['map'].current.on('draw.mode_change', (e) => updateArea(e, drawInstance));
            }

            var geocoder = new MapboxGeocoder({
                accessToken: mapboxgl.accessToken,
                mapboxgl: mapboxgl,
                marker: false,
                clearAndBlurOnEsc: false,
            });

            geocoderContainerRef.current = geocoder.onAdd(mapRefs[key]['map'].current);

            if (isMobileDevice()) {

                geocoderContainerRef.current.style.display = 'none';
            }
            // geocoderContainerRef.current.style.marginTop = '-.65rem'
            // geocoderContainerRef.current.style.marginLeft = '.25rem'

            // Append geocoder to the map's container
            mapRefs[key]['map'].current && mapRefs[key]['map'].current.getContainer().appendChild(geocoderContainerRef.current);




            // mapRefs[key]['map'].current && mapRefs[key]['map'].current.addControl(geocoder, 'top-left');


            const handleGeocoderResult = (ev) => {

                // Object.values(mapRefs).map((obj) => {
                // let map = obj['map']; //TODO:may need to pass map instance to this
                if (mapRefs[key]['map'].current) {
                    var marker = new mapboxgl.Marker()
                        .setLngLat(ev.result.center)
                        .addTo(mapRefs[key]['map'].current);
                    if (markers) {
                        setMarkers([...markers, marker])
                    }
                    else {
                        setMarkers([marker])

                    }

                }




                setCenter(ev.result.center);

                Object.values(mapRefs).map((obj) => obj['map'].current && obj['map'].current.flyTo({
                    center: ev.result.center,
                    zoom: 12
                }))

                // map.current.flyTo({
                //     center: ev.result.center,
                //     zoom: 12
                // });

                setShowRadius(true)


                let coords = [generateCoordinates(ev.result.center, radius)]

                let lastCircleId = uuidv4();


                setLastCircle(lastCircleId)

                const newCircle = {
                    id: lastCircleId,
                    // id: ev.result.id,
                    type: 'Feature',
                    name: ev.result.place_name,
                    geometry: {
                        type: 'Polygon',
                        coordinates: coords
                    },
                    properties: {
                        id: lastCircleId,
                        poi_name: ev.result.place_name,
                        name: ev.result.place_name,
                        poi_radius: radius,
                    }
                };


                var featureCollection = drawInstance.getAll();

                featureCollection.features.push(newCircle);
                // draw.set(featureCollection);
                // Object.values(draw).map((drawInst) => drawInst && drawInst.set(featureCollection))

                setFeatureCollection(featureCollection)


                // poi.length === 0 ?
                //     setPoi({ [lastCircleId]: { name: ev.result.place_name, marker: marker, radius: radius } })
                //     :
                //     setPoi(prevState => ({ ...prevState, [lastCircleId]: { name: ev.result.place_name, marker: marker, radius: radius } }));


                // setFeatureCollection(() => coords);


            };

            geocoder.on('result', function (ev) {
                handleGeocoderResult(ev);

            });


            setDraw((prev) => ({
                ...prev,
                [key]: drawInstance,
            }));

        }

    }



    useEffect(() => {

        (async () => {

            const response = await api.get("/pricing-legend")

            if (response.ok) {
                setLegend(response.body)
            }
            else {
                setLegend(false)
            }





        })();



        window.addEventListener('resize', checkVisibility);

        (async () => {

            const response = await api.get("/new_campaign/location")

            if (response.ok) {


                setFeatureCollection(response.body.polygons);

                if ('mode_name' in response.body) {

                    handleInitMap('main', response.body.mode_name);

                    setModeName(response.body.mode_name);


                    setCampaignId(response.body.campaign_id);

                    if ('polygons' in response.body) {
                        // setPolygon(response.body.polygons)
                        setFeatureCollection(response.body.polygons)
                        // if (Object.keys(response.body.polygons).length > 0) {
                        //     // draw.set(response.body.polygons)
                        //     // draw['main'].set(response.body.polygons)
                        // }
                    }
                    else {
                        setFeatureCollection(null)
                    }




                }
                else {
                    flash('Select a mode first', 'danger')
                    navigate('/new_campaign/campaign_type', { replace: true })
                }




            }
            else {
                setCampaignId(null);


                setPolygon(null)
                'errors' in response.body ?
                    flash(response.body.errors.json.message, 'danger')
                    :
                    flash("Error with request", 'danger')

                navigate(`/new_campaign/campaign_type`);
            }



        })();



        return () => {
            document.body.style.overflow = 'auto'; // Re-enable scrolling on unmount
            window.removeEventListener('resize', checkVisibility); // Cleanup
        };
    }, []);




    useEffect(() => {

        if (isFullScreen) {
            if (mapRefs['fullscreen']['map'].current) {
                return
            }
            handleInitMap("fullscreen", modeName)
            // if (draw['fullscreen'] && featureCollection) {
            //     draw['fullscreen'].set(featureCollection)
            // }
        }
        else {
            checkVisibility();
            if (mapRefs['fullscreen']['map'].current) {
                mapRefs['fullscreen']['map'].current.remove()
                mapRefs['fullscreen']['map'].current = null
                handleSetMapboxDraw('main', null)
            }
        }

        //TODO initialize map here

    }, [isFullScreen])




    // const toggleMap = () => showMap ? setShowMap(false) : setShowMap(true)

    const showPopup = (id) => {
        let counter = 1
        var name;
        var s;

        if (isFullScreen) {
            s = draw['fullscreen'].getAll()
        }
        else {
            s = draw['main'].getAll()
        }
        s = s.features
        for (let i in s) {
            i = s[i]
            var coordinates;
            if (i.id === id && 'poi_name' in i.properties && i.properties.poi_name) {

                name = i.properties.name
                coordinates = i.geometry.coordinates[0][0]

            }
            else if (i.id === id && 'name' in i.properties && i.properties.name !== null) {

                // counter++
                name = `${i.properties.name}`
                coordinates = i.geometry.coordinates[0][0]

            }
            else if (i.id === id) {

                // counter++
                name = `Custom area ${counter}`
                coordinates = i.geometry.coordinates[0][0]

            }
            else {
                counter++
            }

        }

        if (name) {
            const popupContent = `<p class="fw-bold" >${name}</p>`

            if (isFullScreen) {
                mapRefs['fullscreen']['map'].current.flyTo({
                    center: coordinates,
                    essential: true, // this animation is considered essential with respect to prefers-reduced-motion,

                });

                popupRefs['fullscreen'].current.setLngLat(coordinates).setHTML(popupContent).addTo(mapRefs['fullscreen']['map'].current);
            } {
                mapRefs['main']['map'].current.flyTo({
                    center: coordinates,
                    essential: true, // this animation is considered essential with respect to prefers-reduced-motion,

                });

                popupRefs['main'].current.setLngLat(coordinates).setHTML(popupContent).addTo(mapRefs['main']['map'].current);
            }
        }

    }





    const generateCoordinates = (center, radius) => {
        const coordinates = [];
        const degreesPerRadian = 57.29577951308232;
        const r = radius / 3963.2; // earth's radius in miles

        for (let i = 0; i < 360; i++) {
            const brng = i * Math.PI / 180;
            const latitude = Math.asin(
                Math.sin(center[1] * Math.PI / 180) * Math.cos(r) +
                Math.cos(center[1] * Math.PI / 180) * Math.sin(r) * Math.cos(brng)
            );
            const longitude =
                center[0] * Math.PI / 180 +
                Math.atan2(
                    Math.sin(brng) * Math.sin(r) * Math.cos(center[1] * Math.PI / 180),
                    Math.cos(r) - Math.sin(center[1] * Math.PI / 180) * Math.sin(latitude)
                );

            coordinates.push([longitude * degreesPerRadian, latitude * degreesPerRadian]);
        }

        coordinates.push(coordinates[0]);

        return coordinates;
    };




    const handleRadiusChange = (radius) => {
        // let coords = [generateCoordinates(center, value.target.valueAsNumber)]
        // const newCircle = {
        //     id: lastCircle,
        //     type: 'Feature',
        //     geometry: {
        //         type: 'Polygon',
        //         coordinates: coords
        //     },
        //     properties: {
        //         poi_radius: radius
        //     }
        // };
        // var feature = draw.get(lastCircle);
        // setShowRadius(radius)
        // var featureCollection = draw.getAll()
        // featureCollection.features.push(newCircle);
        // draw.set(featureCollection);
        // setRadius(value.target.valueAsNumber)
        // var p = poi
        // poi[lastCircle]['radius'] = value.target.valueAsNumber
        // setPoi(p)

        // TODO: change this so that the 
        // const radius = value.target.valueAsNumber
        setRadius(radius)


        if ((radius) && (radius.length > 0) && (parseFloat(radius) >= MIN_RADIUS) && (parseFloat(radius) <= MAX_RADIUS)) {

            let coords = [generateCoordinates(center, radius)]

            var feature = draw['main'].get(lastCircle);

            feature['geometry']['coordinates'] = coords
            feature['properties']['poi_radius'] = radius
            setShowRadius(true)
            // setRadius(radius)    

            var featureCollection = draw['main'].getAll()
            let uniqueFeatures = featureCollection.features.filter((i) => i.id !== lastCircle);
            uniqueFeatures.push(feature);
            featureCollection.features = uniqueFeatures



            // draw.set(featureCollection);
            // Object.values(draw).map((drawInstance) => drawInstance.set(featureCollection))

            Object.values(draw).map((drawInstance) => drawInstance && drawInstance.set(featureCollection));

            setFeatureCollection(featureCollection)
        }

    };


    const handleSubmit = (e) => {
        e.preventDefault();
        setFormErrors({});
        setDisabled(true);
        (async () => {
            const response = await api.post(`/new_campaign/location`, JSON.stringify({ polygon: draw['main'].getAll() }));
            // const response = await api.post(`/new_campaign/location`, JSON.stringify({ polygon: draw['main'].getAll(), poi: newObject }));
            if (response.ok) {
                if ('errors' in response.body) {

                    flash(response.body.errors.json.message, 'danger')
                    setDisabled(false)
                    return false
                }
                //TODO:REDIRECT TO NEXT STEP IN PROCESS
                // flash('Campaign location is uploading', 'warning')
                setDisabled(false)
                // window.localStorage.setItem('taskID', response.body.task_id)

                flash('Your campaign location was submitted', 'success')
                return navigate(`/new_campaign/image`);
            }
            else if ('errors' in response.body) {

                setFormErrors(response.body.errors.json)
                flash(response.body.errors.json.message, 'danger')
                setDisabled(false)
                return true
            }
            else {
                setFormErrors({ message: 'There was an error posting your campaign location' })

                flash(response.status, 'danger')
                setDisabled(false)
                return false

            }
        })();
        setDisabled(false)

    }

    const handleGetAvgImpressions = () => {

        setFormErrors({});
        setIsGetAvgImpressionLoading(true);
        (async () => {
            const response = await api.post(`/new_campaign/avg-impressions`, JSON.stringify({ polygon: draw['main'].getAll() }));

            if (response.ok) {
                if ('errors' in response.body) {

                    flash(response.body.errors.json.message, 'danger')
                    setIsGetAvgImpressionLoading(false)
                    return false
                }
                //TODO:REDIRECT TO NEXT STEP IN PROCESS
                // flash('Campaign location is uploading', 'warning')
                setIsGetAvgImpressionLoading(false)
                console.log(response.body)
                setFeatureCollection(response.body)
            }
            else if ('errors' in response.body) {
                setFormErrors(response.body.errors.json)
                flash(response.body.errors.json.message, 'danger')
                setIsGetAvgImpressionLoading(false)
                return true
            }
            else {
                setFormErrors({ message: 'There was an error posting your campaign location' })
                flash(response.status, 'danger')
                setIsGetAvgImpressionLoading(false)
                return false
            }
        })();


    }

    // useEffect(() => {
    //     console.log('DRAW INSTANCE', draw)
    //     Object.keys(mapRefs).map((key) => {
    //         if (mapRefs[key]['map'] && draw[key]) {
    //             (featureCollection && featureCollection.length > 0) && draw[key].set(featureCollection)
    //         }
    //     })
    // }, [draw]);


    const handleInitMap = (key, mode) => {

        let map = mapRefs[key]['map'];
        if (map.current) {
            return map
        } else {

            map.current = new mapboxgl.Map({
                container: mapRefs[key]['container'].current,
                style: process.env.REACT_APP_MAPBOX_NEW_CAMPAIGN_LOCATION_V1,
                // style: "mapbox://styles/mesand/clzufyn8h00hu01r89kya1p26/draft",
                center: [lng, lat],
                zoom: zoom,
                projection: 'mercator',
                minZoom: 4,
            });


            map.current.on("load", () => {



                // map.current.addSource('custom-source-id', {
                //     type: 'geojson',
                //     data: data,
                // });
                // map.current.addLayer({
                //     id: 'custom-layer-id',
                //     type: 'fill',
                //     source: 'custom-source-id',
                //     paint: {
                //         // 'fill-color': '#ff0000',
                //         'fill-opacity': .15,
                //     },
                // })

                var resp = addBillboardCluster(map, drawModeRef);
                // var resp = addBillboardCluster(map, drawMode);
                if (!resp) {
                    return
                }
                var layerId;
                if (mode.includes(TRUCK_STRING_MATCH)) {
                    handleShowBillboardLayer(map, false);
                }
                else {
                    layerId = 'truck-area'
                    if (map.current && map.current.getLayer(layerId)) {
                        map.current.setLayoutProperty(layerId, 'visibility', 'none');
                    }
                }
                // setDraw(r);
                if (!draw[key]) {
                    let drawInstance = initMapboxDraw();

                    handleSetMapboxDraw(key, drawInstance);
                    // drawInstance.set(featureCollection);
                }
            });
            return map
        }
    }


    // useEffect(() => {

    //     handleInitMap('main');


    //     (async () => {

    //         const response = await api.get("/new_campaign/location")

    //         if (response.ok) {
    //             if ('mode_name' in response.body) {
    //                 var layerId

    //                 setModeName(response.body.mode_name)
    //                 if (response.body.mode_name.includes('Mobile')) {
    //                     handleShowBillboardLayer(map, false);

    //                     // layerId = 'billboard-points';

    //                     // if ((map.current.getLayer(layerId))) {
    //                     //     map.current.setLayoutProperty(layerId, 'visibility', 'none');

    //                 }

    //                 else {
    //                     layerId = 'truck-area'

    //                     let map = mapRefs['main']['map'];

    //                     if (map.current && map.current.getLayer(layerId)) {
    //                         map.current.setLayoutProperty(layerId, 'visibility', 'none');
    //                     }

    //                 }
    //             }
    //             else {
    //                 flash('Select a mode first', 'danger')
    //                 navigate('/new_campaign/campaign_type', { replace: true })
    //             }


    //             setCampaignId(response.body.campaign_id);

    //             if ('polygons' in response.body) {
    //                 // setPolygon(response.body.polygons)
    //                 setFeatureCollection(response.body.polygons)
    //                 if (Object.keys(response.body.polygons).length > 0) {
    //                     // draw.set(response.body.polygons)
    //                     draw['main'].set(response.body.polygons)
    //                 }
    //             }
    //             else {
    //                 setFeatureCollection(null)
    //             }



    //             // response.body.polygons && Object.keys(response.body.polygons).length > 0 && draw.set(response.body.polygons)

    //             // // response.body.poi && setPoi(response.body.poi)
    //             // response.body.polygons ? setFeatureCollection(response.body.polygons) : setPolygon(null)

    //             // 'poi' in response.body && Object.keys(response.body.poi).length > 0 ? setPoi(response.body.poi) : setPoi(null)

    //         }
    //         else {
    //             setCampaignId(null);
    //             setPoi(null)
    //             setPolygon(null)
    //             'errors' in response.body ?
    //                 flash(response.body.errors.json.message, 'danger')
    //                 :
    //                 flash("Error with request", 'danger')

    //             navigate(`/new_campaign/campaign_type`);
    //         }

    //         if (map.current) {
    //             map.current.addSource('custom-source-id', {
    //                 type: 'geojson',
    //                 data: response.body.polygons,
    //             });

    //             map.current.addLayer({
    //                 id: 'custom-layer-id',
    //                 type: 'fill',
    //                 source: 'custom-source-id',
    //                 paint: {
    //                     // 'fill-color': '#ff0000',
    //                     'fill-opacity': .15,
    //                 },
    //             })
    //         }


    //         // map.current.on('click', 'custom-layer-id', (e) => {

    //         //     // const feature = e.features[0];
    //         //     const coordinates = e.lngLat;
    //         //     const features = map.current.queryRenderedFeatures(e.point, {
    //         //         layers: ['gl-draw-polygon-fill.cold'],
    //         //         filter: ['==', '$type', 'Polygon'], // Filter only polygon features
    //         //     });
    //         //     console.log(features, 'FEATURES')
    //         //     // let name = featureCollection.features.forEach( (i) =>  i['id'] ===  features[0].properties.id && i['name'] )
    //         //     // console.log(name,'name')


    //         //     var name
    //         //     var s = draw.getAll()
    //         //     s = s.features
    //         //     for (let i in s) {
    //         //         i = s[i]
    //         //         console.log('clicked', i, features[0].id)
    //         //         if ('type' in i && i.type === "Feature" && i.id === features[0].properties.id) {

    //         //             // counter++
    //         //             name = i.properties.name
    //         //         }

    //         //     }

    //         //     const popupContent = `<p>${name}</p>`;
    //         //     popupRef.current.setLngLat(coordinates).setHTML(popupContent).addTo(map.current);
    //         // });




    //     })();




    //     // console.log(mapRefs[obj]['container'].current, 'hi');
    //     // map.current = new mapboxgl.Map({
    //     //     container: mapRefs[obj]['container'].current,
    //     //     style: process.env.REACT_APP_MAPBOX_PRICING_MAP,
    //     //     center: [lng, lat],
    //     //     zoom: zoom,
    //     //     projection: 'mercator',
    //     // });


    //     // map.current.on("load", () => {

    //     //     let r = new MapboxDraw({
    //     //         displayControlsDefault: false,
    //     //         // Select which mapbox-gl-draw control buttons to add to the map.
    //     //         controls: {
    //     //             // polygon: true,
    //     //             // trash: true
    //     //         },
    //     //         styles: [
    //     //             // ACTIVE (being drawn)
    //     //             // line stroke
    //     //             {
    //     //                 "id": "gl-draw-line",
    //     //                 "type": "line",
    //     //                 "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
    //     //                 "layout": {
    //     //                     "line-cap": "round",
    //     //                     "line-join": "round"
    //     //                 },
    //     //                 "paint": {
    //     //                     "line-color": "yellow",
    //     //                     "line-dasharray": [0.2, 2],
    //     //                     "line-width": 2
    //     //                 }
    //     //             },
    //     //             // polygon fill
    //     //             {
    //     //                 "id": "gl-draw-polygon-fill",
    //     //                 "type": "fill",
    //     //                 "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    //     //                 "paint": {
    //     //                     "fill-color": "yellow",
    //     //                     "fill-outline-color": "yellow",
    //     //                     "fill-opacity": 0.15
    //     //                 }
    //     //             },
    //     //             // polygon mid points
    //     //             {
    //     //                 'id': 'gl-draw-polygon-midpoint',
    //     //                 'type': 'circle',
    //     //                 'filter': ['all',
    //     //                     ['==', '$type', 'Point'],
    //     //                     ['==', 'meta', 'midpoint']],
    //     //                 'paint': {
    //     //                     'circle-radius': 3,
    //     //                     'circle-color': '#fbb03b'
    //     //                 }
    //     //             },
    //     //             // polygon outline stroke
    //     //             // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    //     //             {
    //     //                 "id": "gl-draw-polygon-stroke-active",
    //     //                 "type": "line",
    //     //                 "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
    //     //                 "layout": {
    //     //                     "line-cap": "round",
    //     //                     "line-join": "round"
    //     //                 },
    //     //                 "paint": {
    //     //                     "line-color": "yellow",
    //     //                     "line-dasharray": [0.2, 2],
    //     //                     "line-width": 2
    //     //                 }
    //     //             },
    //     //             // vertex point halos
    //     //             {
    //     //                 "id": "gl-draw-polygon-and-line-vertex-halo-active",
    //     //                 "type": "circle",
    //     //                 "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    //     //                 "paint": {
    //     //                     "circle-radius": 5,
    //     //                     "circle-color": "#FFF"
    //     //                 }
    //     //             },
    //     //             // vertex points
    //     //             {
    //     //                 "id": "gl-draw-polygon-and-line-vertex-active",
    //     //                 "type": "circle",
    //     //                 "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
    //     //                 "paint": {
    //     //                     "circle-radius": 3,
    //     //                     "circle-color": "yellow",
    //     //                 }
    //     //             },

    //     //             // INACTIVE (static, already drawn)
    //     //             // line stroke
    //     //             {
    //     //                 "id": "gl-draw-line-static",
    //     //                 "type": "line",
    //     //                 "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
    //     //                 "layout": {
    //     //                     "line-cap": "round",
    //     //                     "line-join": "round"
    //     //                 },
    //     //                 "paint": {
    //     //                     "line-color": "#000",
    //     //                     "line-width": 3
    //     //                 }
    //     //             },
    //     //             // polygon fill
    //     //             {
    //     //                 "id": "gl-draw-polygon-fill-static",
    //     //                 "type": "fill",
    //     //                 "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    //     //                 "paint": {
    //     //                     "fill-color": "#000",
    //     //                     "fill-outline-color": "#000",
    //     //                     "fill-opacity": 0.15
    //     //                 }
    //     //             },
    //     //             // polygon outline
    //     //             {
    //     //                 "id": "gl-draw-polygon-stroke-static",
    //     //                 "type": "line",
    //     //                 "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
    //     //                 "layout": {
    //     //                     "line-cap": "round",
    //     //                     "line-join": "round"
    //     //                 },
    //     //                 "paint": {
    //     //                     "line-color": "#000",
    //     //                     "line-width": 3
    //     //                 }
    //     //             }
    //     //         ]
    //     //     });
    //     //     // setDraw(r);
    //     //     handleSetMapboxDraw(obj, r);

    //     //     var resp = addBillboardCluster(map);
    //     //     // var resp = addBillboardCluster(map, drawMode);
    //     //     if (!resp) {
    //     //         return
    //     //     }
    //     // })




    //     // const sourceId = 'billboard-point-source'; // Replace with the actual source ID from Mapbox Studio



    //     // map.current.on('click', 'clusters', (e) => {

    //     //     if (!drawMode) {
    //     //         const features = map.current.queryRenderedFeatures(e.point, {
    //     //             layers: ['clusters']
    //     //         });
    //     //         const clusterId = features[0].properties.cluster_id;
    //     //         map.current
    //     //             .getSource(sourceId)
    //     //             .getClusterExpansionZoom(clusterId, (err, zoom) => {
    //     //                 if (err) return;

    //     //                 map.current.easeTo({
    //     //                     center: features[0].geometry.coordinates,
    //     //                     zoom: zoom
    //     //                 });
    //     //             });
    //     //     }
    //     // });









    //     // return () =>
    //     //     // setShowMap(false);

    // }, []); //radius was here

    const onRemove = (featureId, deletePrevious = false) => {

        let removes = removeMarkerInstancesRef.current && removeMarkerInstancesRef.current.filter((i, index) => i['id'] === featureId);
        if (deletePrevious) {
            if (removes) {

                removes && removes.forEach(i => i['marker'].remove());
            }
        }
        else {
            if (removes && removes.length > 0) {
                removePolygon(featureId)
                removes && removes.forEach(i => i['marker'].remove());
            }
        }


    };




    useEffect(() => {

        if (featureCollection && 'features' in featureCollection) {
            console.log(featureCollection)
            // Object.values(draw).map((drawInstance) => drawInstance && drawInstance.set(featureCollection));
            setLocationNumber(featureCollection.features.length)
            if (featureCollection.features.length === 0) {
                setDisabled(true)
            }
            else {
                setDisabled(false)
            }
        }
        else {
            setDisabled(true)
        }




        if (featureCollection && Object.keys(featureCollection).length !== 0) {



            // var featureCollection = drawVal.getAll();

            let counter = 1
            featureCollection['features'].forEach((i) => {

                if ('poi_name' in i.properties) {

                    i['properties']['name'] = i['properties']['poi_name'];
                    i['name'] = i['properties']['poi_name'];
                    // i['properties']['name'] = i['properties']['name'];
                    // i['name'] = i['properties']['name'];
                }
                else {
                    if (!('name' in i.properties)) {
                        i['properties']['name'] = `Custom area ${counter}`;
                    }
                    i['name'] = `Custom area ${counter}`;
                    counter++;
                }


                const newEl = document.createElement('div');
                const root = createRoot(newEl);
                root.render(
                    <RemoveMarker
                        imageId={i.id}
                        onRemove={() => onRemove(i.id)}

                    />
                );
                onRemove(i.id, true)
                Object.keys(mapRefs).forEach(key => {
                    if (mapRefs[key]['map'].current && draw[key]) {
                        // onRemove(i.id, true)
                        // onRemove(i.id);

                        var markerInstance = new mapboxgl.Marker(newEl)
                            .setLngLat(i.geometry.coordinates[0][0])
                            .addTo(mapRefs[key]['map'].current);

                        removeMarkerInstancesRef.current.push({ id: i.id, marker: markerInstance });

                    }
                })

                // Store the marker instance in the ref array
                //TODO:add delete markers here

            })

            var poi_features = featureCollection.features.filter((item) => ("poi_name" in item.properties && item.properties['poi_name'] !== null));

            var newPOI = {}
            poi_features.map((i) => {
                newPOI[i.properties.id] = { radius: i.properties.poi_radius, name: i.properties.name, poi_name: i.properties.poi_name }
            }
            )

            var polygon_features = featureCollection.features.filter((item) => !("poi_radius" in item.properties) || item.properties['poi_radius'] === null);
            setPolygon(polygon_features);
            // setPolygon({ type: 'FeatureCollection', features: polygon_features });
            // setPolygon({ type: 'FeatureCollection', features: polygon_features });



            // draw.set(featureCollection)


            // console.log(draw['main'], mapRefs['main']['map'].current.getAll());
            // drawVal.set(featureCollection);


            // mapRefs['main']['map'].current && mapRefs['main']['map'].current.getSource('custom-source-id').setData(featureCollection)
            // Object.keys(mapRefs).map((key) => {
            //     console.log(mapRefs[key]['map']);
            // })

            // map.current.getSource('custom-source-id').setData(featureCollection)
            // map.current.getSource('custom-source-id').setData(featureCollection)
            // setPoi({ type: 'FeatureCollection', features: poi_features });

        }
        else {
            setPolygon(false);
        }

        if (featureCollection && 'features' in featureCollection) {

            Object.values(draw).map((drawInstance) => drawInstance && drawInstance.set(featureCollection));
        }



    }, [featureCollection, draw])





    const removePolygon = (id) => {


        let removes = removeMarkerInstancesRef.current && removeMarkerInstancesRef.current.filter((i, index) => i['id'] === id);


        removes && removes.forEach(i => i['marker'].remove());

        if (markers) {
            markers.forEach((i) => i.remove());
            // markers.remove()
            setMarkers(null)
        }
        if (popupRef.current) {
            popupRef.current.remove()
        }
        if (mobilePopupRef.current) {
            mobilePopupRef.current.remove()
        }


        // var prevFeatureCollection;
        // if (isFullScreen) {
        //     prevFeatureCollection = draw['fullscreen'].getAll();
        // }
        // else {
        //     prevFeatureCollection = draw['main'].getAll();
        // }


        var new_features = featureCollection.features.filter((item) => item.id !== id);
        var new_feauture_collection

        if ((!new_features) || (new_features.length === 0)) {
            new_feauture_collection = { type: 'FeatureCollection', features: [] }
        }
        else {

            new_feauture_collection = { type: 'FeatureCollection', features: new_features }
        }
        // draw.set(new_feauture_collection)

        // Object.values(draw).map((drawInstance) => drawInstance && drawInstance.set(new_feauture_collection))



        setFeatureCollection(new_feauture_collection);

        if (showRadius && isMobileDevice()) {
            setShowRadius(false);
            setLastCircle(false);
        }
        // geocoder.clear()
        // map.current.removeLayer(id)
    }


    const toggleDrawMode = () => {

        drawMode === true ? setDrawMode(false) : setDrawMode(true)
    };


    const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            if (drawMode) {
                setDrawMode(false)
            }
            // Handle Escape key press
        }
    };

    useEffect(() => {
        drawModeRef.current = drawMode
        let map;
        let drawInstance;

        if (isFullScreen) {
            map = mapRefs['fullscreen']['map'];
            drawInstance = draw['fullscreen'];
        }
        else {
            map = mapRefs['main']['map'];
            drawInstance = draw['main'];
        }

        if (drawInstance && map.current) {



            if (!drawMode) {

                drawInstance.changeMode('simple_select');
                map.current.off('dblclick');



            } else {
                map.current.off('click')
                map.current.on('dblclick', (event) => {

                    // Deactivate draw mode on double click if drawMode is on
                    if (drawMode) {
                        setDrawMode(false)
                    }
                });


                // Attach the keydown event listener to the document
                document.addEventListener('keydown', handleKeyDown);
                drawInstance.changeMode('draw_polygon');
            }

            // setDrawMode(!isDrawingMode);
        }



        return () => {
            map.current && map.current.off('dblclick');
            map.current && map.current.off('click');
            if (handleKeyDown) {

                document.removeEventListener('keydown', handleKeyDown);
            }
        }
    }, [drawMode, draw]);

    useEffect(() => {
        // Toggle visibility of the geocoder DOM element

        if (isMobileDevice() && geocoderContainerRef.current) {
            geocoderContainerRef.current.style.display = showRadius ? 'block' : 'none';
        }
    }, [showRadius]);


    return (

        <>
            <Modal fullscreen show={isFullScreen} onHide={() => handleExpandClick()}>
                <Modal.Body className="m-0 p-0">
                    <div ref={fullscreenRef} style={{ 'height': '100%' }} >
                        <div className="p-2">
                            {/* <div className="d-none d-lg-block"> */}
                            {!draw ?
                                <Placeholder className="rounded" animation="glow">
                                    <Placeholder.Button style={{
                                        position: "absolute",
                                        top: "0px",
                                        right: "0px",
                                        zIndex: "1",
                                        margin: ".5rem",
                                        paddingLeft: '4rem',
                                        paddingRight: '4rem'
                                    }} variant="dark" />
                                </Placeholder>
                                :
                                <div style={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "0px",
                                    zIndex: "1",
                                    margin: ".5rem",
                                    paddingLeft: '2rem',
                                    paddingRight: '2rem',
                                    textAlign: 'right',
                                }}>
                                    <Button
                                        // variant="dark"
                                        onClick={toggleDrawMode}
                                        // style={{
                                        //     position: "absolute",
                                        //     top: "0px",
                                        //     right: "0px",
                                        //     zIndex: "1",
                                        //     margin: ".5rem",
                                        //     paddingLeft: '2rem',
                                        //     paddingRight: '2rem'
                                        // }}
                                        className={`drawButton btn btn-${drawMode ? 'light' : 'dark'} `}
                                    >
                                        <strong> {
                                            drawMode ? 'Exit draw mode' : 'Draw'
                                        }
                                        </strong>
                                    </Button  >
                                    {
                                        drawMode &&
                                        <div
                                            // style={{
                                            //     position: "absolute",
                                            //     top: "40px",
                                            //     right: "0px",
                                            //     zIndex: "1",
                                            //     margin: ".5rem",
                                            //     backgroundColor: 'white',


                                            // }}
                                            className="rounded drawButton text-primary p-2">
                                            <strong>Double click the map</strong> or <strong>click 'Exit draw mode'</strong> to complete drawing
                                        </div>
                                    }
                                </div>
                            }

                        </div>
                    </div>
                </Modal.Body>
            </Modal>

            <Modal size="lg" show={showLocations} onHide={() => handleHideLocationModal()}>
                <Modal.Body>

                    <PolygonPOIList
                        featureCollection={featureCollection}
                        handleUpdateFeatureCollection={handleUpdateFeatureCollection}
                        // poi={poi}
                        polygon={polygon}
                        showPopup={showPopup}
                        removePolygon={removePolygon}
                        editNameIndex={editNameIndex}
                        handleGetAvgImpressions={handleGetAvgImpressions}
                        isGetAvgImpressionLoading={isGetAvgImpressionLoading}
                    />

                </Modal.Body >
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => handleHideLocationModal()}
                    >
                        Close
                    </Button>
                </Modal.Footer>
            </Modal >

            <NewCampaignMapHelpModal
                show={showHelpModal}
                hide={handleHideHelpModal}
            />

            <FlashMessage />
            <div className="mx-2 d-lg-block">

                {<NewCampaignBreadcrumb currentStep={0} campaignId={campaignId} />}

            </div>

            <DynamicForm
                // buttonText={'Submit Location'}
                disabled={disabled}
                handleSubmit={handleSubmit}>

                <Row className="px-0 mx-0">

                    <div className="w-100 d-lg-none mb-0 d-flex justify-content-between" >


                        <h1 className="fw-bolder">Campaign Location</h1>



                        <div className="d-flex justify-content-end"><Button className="d-block d-lg-none rounded-pill px-3 py-0" onClick={() => setShowHelpModal(true)} variant='primary' size='sm'><strong>Help</strong></Button></div>

                        <div className="d-none d-lg-flex">
                            <NewCampaignMapDescription />
                        </div>


                        <hr className="d-none d-lg-block" />
                    </div>

                    <Col className="d-grid gap-2 mb-2" xs={12}>

                        {
                            drawMode &&
                            <div style={{

                            }}
                                className="mobileDrawButton text-secondary">
                                <strong >Tap the map around your desired campaign location. Double tap the map</strong> or tap <strong>Save drawing</strong> to complete drawing
                            </div>
                        }
                    </Col>
                    <Col className='campaignMap px-0 mx-0' lg={8} xs={12} style={{ 'height': '100vh', position: 'relative' }}>
                        <div ref={mainRef} style={{ 'height': '100%', "width": '100%' }} />
                        <div className="p-2"
                            style={{
                                position: "absolute",
                                top: "0px",
                                right: "0px",
                                zIndex: "1",
                                padding: ".5rem",
                                textAlign: 'right',
                            }}>
                            {/* <div className="d-none d-lg-block"> */}
                            {!draw['main'] ?
                                <div>

                                    <Placeholder className="d-none d-lg-block rounded" animation="glow">
                                        <Placeholder.Button style={{
                                            position: "absolute",
                                            top: "0px",
                                            right: "0px",
                                            zIndex: "1",
                                            margin: ".5rem",
                                            paddingLeft: '4rem',
                                            paddingRight: '4rem'
                                        }} variant="dark" />
                                    </Placeholder>
                                </div>
                                :
                                <div
                                // style={{
                                //     position: "absolute",
                                //     top: "0px",
                                //     right: "0px",
                                //     zIndex: "1",
                                //     margin: ".5rem",
                                //     textAlign: 'right',



                                // }}
                                >
                                    <Button
                                        variant="dark"
                                        onClick={toggleDrawMode}
                                        style={{





                                            paddingLeft: '2rem',
                                            paddingRight: '2rem'
                                        }}
                                        className={`drawButton btn btn-${drawMode ? 'light' : 'dark'} `}
                                    >
                                        <strong> {
                                            drawMode ? 'Exit draw mode' : 'Draw'
                                        }
                                        </strong>
                                    </Button  >
                                    {
                                        drawMode &&
                                        <div
                                            style={{
                                                // position: "absolute",
                                                // top: "50px",
                                                // right: "0px",
                                                // zIndex: "1",
                                                marginTop: ".25rem",
                                                backgroundColor: 'white',



                                            }}
                                            className="rounded drawButton text-secondary p-2">
                                            <strong>Double click the map</strong> or <strong>click 'Exit draw mode'</strong> to complete drawing
                                        </div>
                                    }
                                </div>
                            }

                        </div>
                        {modeName && modeName.includes(TRUCK_STRING_MATCH) &&
                            <div className="p-2 d-block d-lg-none"
                                style={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "0px",
                                    // zIndex: "1",
                                    padding: ".5rem",
                                    textAlign: 'right',



                                }}>
                                <MapLegend legend={legend} />
                            </div>
                        }

                        {!showHelpModal &&
                            <div ref={mobileRef} className={`d-flex ${(!isFullScreen) && 'd-lg-none'} fixed-bottom p-0`} style={{ zIndex: '100000' }} >
                                <Stack direction="vertical">
                                    {
                                        showRadius ?

                                            <div className="bg-dark p-2 w-100">
                                                <POIRadius showRadius={showRadius} radius={radius} handleRadiusChange={handleRadiusChange} confirm handleSetRadius={handleSetRadius} lastCircle={lastCircle} />
                                            </div>
                                            :
                                            <>

                                                <ButtonGroup size="sm" className="w-100">
                                                    <Button
                                                        onClick={() => setShowRadius(true)}
                                                        variant={'dark'}
                                                        style={{
                                                            borderRadius: '0px',
                                                            border: '1px solid white'
                                                        }}
                                                        className="p-2"
                                                    >
                                                        <strong className="d-inline-flex">Search</strong>

                                                    </Button>

                                                    <Button
                                                        onClick={() => handleExpandClick()}
                                                        variant={'dark'}
                                                        style={{
                                                            borderRadius: '0px',
                                                            border: '1px solid white'
                                                        }}
                                                        className="p-2"
                                                    >
                                                        <strong className="d-inline-flex">{isFullScreen ? 'Exit Full Screen' : 'Fullscreen'} </strong>

                                                    </Button>

                                                    <Button
                                                        onClick={() => handleShowLocationModal()}
                                                        variant={'dark'}
                                                        style={{
                                                            borderRadius: '0px',
                                                            border: '1px solid white'
                                                        }}
                                                        className="p-1"
                                                    >
                                                        <strong className="d-inline-flex">View/Edit Locations <span className="text-secondary" style={{ marginLeft: '.2rem' }}> ({locationNumber})</span>  </strong>

                                                    </Button>

                                                    <Button
                                                        onClick={() => toggleDrawMode()}
                                                        variant={'dark'}
                                                        style={{
                                                            borderRadius: '0px',
                                                            border: '1px solid white'
                                                        }}
                                                        className="p-2"
                                                    >
                                                        <strong className="d-inline-flex"> {
                                                            drawMode ? <span className="text-primary">Save drawing</span> : 'Draw'
                                                        }</strong>

                                                    </Button>
                                                </ButtonGroup>
                                                <div className="bg-primary w-100" style={{
                                                    border: '1px solid white'
                                                }}>

                                                    <Button
                                                        onClick={(e) => handleSubmit(e)}
                                                        variant={'primary'}
                                                        style={{
                                                            borderRadius: '0px',
                                                        }}
                                                        className="w-100"
                                                    ><strong className="d-inline-flex">Continue</strong></Button>
                                                    <div className="text-center text-danger w-100 bg-white">
                                                        {formErrors && 'message' in formErrors && <strong>{formErrors.message}</strong>}
                                                    </div>
                                                </div>
                                            </>
                                    }








                                </Stack>
                            </div>

                        }
                    </Col>
                    <Col className="d-none d-lg-block" lg={4}>

                        {/* <POIRadius showRadius={showRadius} radius={radius} handleRadiusChange={handleRadiusChange} /> */}

                        <Row className="campaignMap d-flex mt-3 w-100">


                            <Col xs={12}>

                                <div className="d-none d-lg-block" >
                                    <h1 className="fw-bolder">Campaign Location</h1>

                                    <NewCampaignMapDescription mode={modeName} />

                                    <hr />
                                    {modeName && modeName.includes(TRUCK_STRING_MATCH) &&
                                        < div className="d-none d-lg-block">
                                            <h5>Estimated Pricing Boundaries</h5>
                                            <MapLegend legend={legend} />
                                            <hr />
                                        </div>}
                                </div>



                                <Row className="d-none d-lg-block">
                                    <Col>
                                        <POIRadius confirm showRadius={showRadius} radius={radius} handleRadiusChange={handleRadiusChange} handleSetRadius={handleSetRadius} lastCircle={lastCircle} />
                                    </Col>
                                </Row>
                                <hr className="d-none d-lg-block" />
                                <PolygonPOIList
                                    featureCollection={featureCollection}
                                    handleUpdateFeatureCollection={handleUpdateFeatureCollection}

                                    polygon={polygon}
                                    showPopup={showPopup}
                                    removePolygon={removePolygon}
                                    editNameIndex={editNameIndex}
                                    handleGetAvgImpressions={handleGetAvgImpressions}
                                    isGetAvgImpressionLoading={isGetAvgImpressionLoading}

                                />


                            </Col>



                            <Col className="mt-5 align-items-end" xs={12}>
                                <Row className="d-flex justify-content-end">
                                    <Col className="d-flex justify-content-end" lg={6}>

                                        <Button onClick={handleSubmit} disabled={disabled} className="rounded shadow py-3 px-5 mr-3 mb-5" variant="light" type="button" size="lg"><strong>Continue</strong></Button>

                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                    </Col>
                </Row >

            </DynamicForm >

        </>
    )
}



export default NewCampaignMap
