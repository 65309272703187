import React, { useState, useRef } from 'react'
import Body from '../components/Body'
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';

import InputField from '../components/InputField';
import FormButtonBlock from '../components/FormButtonBlock';
import TextAreaField from '../components/TextAreaField';


import CheckboxComponent from '../components/CheckboxComponent';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';




function DMCACounter() {
    const [formErrors, setFormErrors] = useState({});

    const isCopyrightHolderField = useRef();
    const makeChangesField = useRef();
    const natureOfOwnershipField = useRef();
    const filesToTakedownField = useRef();
    const whyRemovedField = useRef();
    const contactField = useRef();
    const jurisdictionField = useRef();
    const perjuryField = useRef();
    const eSignField = useRef();
    const flash = useFlash();

    const api = useApi();

    const onSubmit = (ev) => {
        ev.preventDefault();


        const whyRemoved = whyRemovedField.current.value;
        const makeChanges = makeChangesField.current.value;
        const isCopyrightHolder = isCopyrightHolderField.current.value;
        const natureOfOwnership = natureOfOwnershipField.current.value;
        const filesToTakedown = filesToTakedownField.current.value;
        const contact = contactField.current.value;
        const perjury = perjuryField.current.checked;
        const jurisdiction = jurisdictionField.current.checked;
        const eSign = eSignField.current.value;



        (async () => {
            setFormErrors({})

            const response = await api.post(`/dmca-counter`, {
                makeChanges: makeChanges,
                whyRemoved: whyRemoved,
                isCopyrightHolder: isCopyrightHolder,
                natureOfOwnership: natureOfOwnership,
                filesToTakedown: filesToTakedown,
                contact: contact,
                perjury: perjury,
                jurisdiction: jurisdiction,
                eSign: eSign,
            });

            if (response.ok) {

                flash("Your request was submmited", 'success');

            }
            else {


                if ('errors' in response.body) {
                    flash(response.body.errors.json.message, 'danger');
                    setFormErrors(response.body.errors.json);

                }
                else {

                    flash('Error with your request', 'danger')
                }


            }
        })();

    }


    return (
        <Body classStr={{height:"100%"}} footer>
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '3rem', marginBottom: '5rem' }}>
                <Col className="text-center mb-5" xs={12}>
                    <h2 className="fw-bolder">
                        DMCA Counter Request
                    </h2>
                </Col>

                <Col className="border-black" sm={"5"} lg={"4"} xxl={"8"}  >
                    <Form onSubmit={onSubmit}>


                        <Form.Group>
                            <Form.Label>
                                <strong>
                                    Are you the copyright holder or authorized to act on the copyright owner's behalf?
                                </strong>
                            </Form.Label>

                            <Form.Select ref={isCopyrightHolderField} className="mb-3" aria-label="Default select example">
                                <option value="1">Yes, I am the copyright holder</option>
                                <option value="2">Yes, I am authorized to act on the copyright owner's behalf.</option>
                                <option value="no">No.</option>
                            </Form.Select>
                            <div><Form.Text className="text-danger">{formErrors.isCopyrightHolder}</Form.Text></div>
                        </Form.Group>




                        <TextAreaField

                            formLabel={<strong>Please describe the nature of your content ownership or authorization to act on the owner's behalf.</strong>}
                            name="authorization_to_act"
                            fieldRef={natureOfOwnershipField}
                            error={formErrors.natureOfOwnership}

                        />



                        <TextAreaField

                            formLabel={<strong>What files were taken down? Please provide URLs for each file, or if the entire repository, the repository’s URL.</strong>}
                            name="authorization_to_act"
                            fieldRef={filesToTakedownField}
                            error={formErrors.filesToTakedown}

                        />
                        <Form.Group>
                            <Form.Label>
                                <strong>Do you want to make changes to your repository or do you want to dispute the notice?</strong>
                            </Form.Label>
                            <Form.Select ref={makeChangesField} className="mb-3" aria-label="Default select example">
                                <option >Select a response</option>
                                <option value="1">Make changes</option>
                                <option value="2">Dispute the notice</option>
                            </Form.Select>
                            <div><Form.Text className="text-danger">{formErrors.makeChanges}</Form.Text></div>


                        </Form.Group>


                        <TextAreaField

                            formLabel={<strong>Is there anything else you think we should know about why you believe the material was removed as a result of a mistake?</strong>}
                            name="authorization_to_act"
                            fieldRef={whyRemovedField}
                            error={formErrors.whyRemoved}

                        />

                        <TextAreaField

                            formLabel={<strong>So that the complaining party can get back to you, please provide both your telephone number and physical address.</strong>}
                            name="authorization_to_act"
                            fieldRef={contactField}
                            error={formErrors.contact}

                        />



                        <CheckboxComponent
                            formLabel="I swear, under penalty of perjury, that I have a good-faith belief that the material was removed or disabled as a result of a mistake or misidentification of the material to be removed or disabled."
                            name="goodFaith"
                            fieldRef={perjuryField}
                            error={formErrors.perjury}
                        />
                        <CheckboxComponent
                            formLabel="I consent to the jurisdiction of Federal District Court for the judicial district in which my address is located (if in the United States, otherwise the Northern District of California where GitHub is located), and I will accept service of process from the person who provided the DMCA notification or an agent of such person."
                            name="perjury"
                            fieldRef={jurisdictionField}
                            error={formErrors.jurisdiction}
                        />


                        <InputField
                            fieldRef={eSignField}
                            formLabel={<strong>Please type your full legal name below to electronically sign this request. (Must match full name field)</strong>}
                            label="Full name"
                            error={formErrors.eSign}

                        />
                        <FormButtonBlock text={"Send request"} />
                    </Form>

                </Col>
            </Row>

        </Body >
    )
}

export default DMCACounter
