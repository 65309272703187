import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import { Link } from 'react-router-dom';
import { badBillboardExamples } from '../utils/staticUrls'


function WhatCanBePostedModal({ showModal, setShowModal }) {
    // const { darkTheme } = useTheme()
    const darkTheme = true

    const imageStyle = {
        maxHeight: "300px",
        width: "100%",
        objectFit: 'contain',
    }
    return (
        <Modal size="lg" centered show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Tips on how to contribute</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${darkTheme ? 'bg-dark' : 'bg-light'}`}>
                It is important to use your judgement as to whether or not the message you are contributing too has a message that has a good chance of getting accepted by our media partners. See <Link to="/what-can-be-poste">What can be posted?</Link> page for more details on what kind of media is allowed. While the goal is to publish your message, showing what messages are kept out of public discourse can be just as effective to highlight your point.

                <hr />

                Contribute to media that is not messy and does not violate our terms. If the media violates our terms or our media partners terms, the campaign will be stopped and you will not be charged.

                <hr />

                <Container>
                    <Stack gap={3} direction="vertical" className="text-center">
                        <div>
                            <Image style={imageStyle} fluid src={badBillboardExamples.billboard1} />
                            Make sure images and text have adequate contrast with the background
                        </div>
                        <div>
                            <Image style={imageStyle} fluid src={badBillboardExamples.billboard2} />
                            Cluttered billboards are harder to read
                        </div>
                        <div>
                            <Image style={imageStyle} fluid src={badBillboardExamples.billboard3} />
                            Avoid boards with too much text
                        </div>
                    </Stack>
                </Container>
            </Modal.Body>
            <Modal.Footer className={`${darkTheme ? 'bg-dark' : 'bg-light'}`}>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default WhatCanBePostedModal
