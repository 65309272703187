import React from 'react'
import Accordion from 'react-bootstrap/Accordion';


function NewCampaignInstructionsAccordion({ children, helpHeader }) {
    return (

        <Accordion  >
            <Accordion.Item eventKey="0" >
                <Accordion.Header >
                    {helpHeader}
                </Accordion.Header>
                <Accordion.Body style={{ padding: 0 }}>
                    {children}
                </Accordion.Body>
            </Accordion.Item>
        </Accordion>



    )
}

export default NewCampaignInstructionsAccordion
