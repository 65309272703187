import React, { useState } from 'react'
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useNavigate, Link } from 'react-router-dom';
import { useUser } from '../contexts/UserProvider';
import LoginModal from './LoginModal';



function NewCampaignBreadcrumb({ currentStep, taskID }) {



    const navigate = useNavigate();
    const user = useUser()
    const [showModal, setShowModal] = useState(false)



    const handleClose = () => setShowModal(false)


    // useEffect(() => {
    //     let intervalId;
    //     let storedTaskId = localStorage.getItem('taskID');
    //     if (storedTaskId) {
    //         console.log('send req to get tstatus and remove if success', storedTaskId);

    //         const checkStatus = (async () => {
    //             const response = await api.get(`/new_campaign/location/status/${storedTaskId}`);
    //             if (response.ok) {
    //                 console.log(response.body)
    //                 if ("errors" in response.body) {
    //                     flash(response.body.errors.json.message, 'danger')
    //                     clearTimeout(intervalId)
    //                     window.localStorage.removeItem("taskID")
    //                     return navigate(`/new_campaign`);
    //                 }
    //                 else if ('data' in response.body && response.body.data === true) {
    //                     flash("Campaign location was uploaded successfully", 'success');
    //                     clearTimeout(intervalId);
    //                     window.localStorage.removeItem("taskID");

    //                 }
    //             }
    //             else {
    //                 flash("error with request", "danger")
    //             }
    //         })();

    //         intervalId = setTimeout(checkStatus, 1000);

    //     }
    //     return () => {
    //         clearTimeout(intervalId);
    //     };
    // }, [taskID])


    return (
        <div >
            {
                user && user.user !== null ?
                    <div></div>
                    :
                    currentStep === 5 ?

                        <p className="text-light bg-danger p-2 text-center rounded">
                            <strong>Must be signed in to save and submit your progress. <Link to="/login">Login here</Link></strong> | <strong><Link to="/register">Sign up here</Link></strong>
                        </p>
                        :
                        <p className="text-danger">
                            Must be signed in to save and submit your progress. <Link to="/login">Login here</Link>
                        </p>
            }
            {
                showModal && <LoginModal handleClose={handleClose} showModal={showModal} setShowModal={setShowModal} />

            }
            <Breadcrumb style={{

            }}>

                {currentStep === 2 ? <Breadcrumb.Item active>Type</Breadcrumb.Item> : <Breadcrumb.Item onClick={() => navigate(`/new_campaign/campaign_type`)}> Type </Breadcrumb.Item>}

                {currentStep === 0 ? <Breadcrumb.Item active>Location</Breadcrumb.Item> : <Breadcrumb.Item onClick={() => navigate(`/new_campaign/location`)}>Location</Breadcrumb.Item>}

                {currentStep === 1 ? <Breadcrumb.Item active>Image</Breadcrumb.Item> : <Breadcrumb.Item onClick={() => navigate(`/new_campaign/image`, { replace: true })}  >Image </Breadcrumb.Item>}


                {currentStep === 3 ? <Breadcrumb.Item active>Fundraising Period</Breadcrumb.Item> : <Breadcrumb.Item onClick={() => navigate(`/new_campaign/start_date`)} >Fundraising Period</Breadcrumb.Item>}

                {currentStep === 4 ? <Breadcrumb.Item active>Description</Breadcrumb.Item> : <Breadcrumb.Item onClick={() => navigate(`/new_campaign/description`)} >Description </Breadcrumb.Item>}

                {currentStep === 5 ? <Breadcrumb.Item active>Confirmation</Breadcrumb.Item> : <Breadcrumb.Item onClick={() => navigate(`/new_campaign/confirmation`)}>Confirmation </Breadcrumb.Item>}
                {/* {currentStep === 5 ? <Breadcrumb.Item active>Confirmation</Breadcrumb.Item> : <Breadcrumb.Item onClick={() => handleConfirmation()}>Confirmation </Breadcrumb.Item>} */}



            </Breadcrumb>
        </div >
    )
}

export default NewCampaignBreadcrumb
