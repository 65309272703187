import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
// import { useTheme } from '../contexts/ThemeProvider'
import ShareComponents from './ShareComponents';


function ShareModal({ show, hide, campaignUrl }) {
    // const { darkTheme } = useTheme()
    const darkTheme = true

    return (
        <Modal size="lg" show={show} onHide={() => hide(false)}>
            <Modal.Header className={`${darkTheme ? 'bg-dark' : 'bg-light'}`} data-bs-theme="dark" closeButton>
                <Modal.Title>Share Campaign</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${darkTheme ? 'bg-dark' : 'bg-light'}`}>
                <ShareComponents campaignId={campaignUrl} />
            </Modal.Body>
            <Modal.Footer className={`${darkTheme ? 'bg-dark' : 'bg-light'}`}>
                <Button variant="secondary" onClick={() => hide(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default ShareModal
