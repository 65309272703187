import React, { useState, useRef } from 'react'
import YoutubeComponent from './YoutubeComponent'
import Image from 'react-bootstrap/Image';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { useUser } from '../contexts/UserProvider';
import UpdateDescriptionModal from './modals/UpdateDescriptionModal';

import ImageModal from './ImageModal';

const MAX_HEIGHT = 500

function DescriptionComponent({ description, username, campaignId, handleDescriptionUpdate }) {


    const descriptionHeightRef = useRef()
    const [descriptionValue, setDescriptionValue] = useState(description.description);
    const [youtubeUrlValue, setYoutubeUrlValue] = useState(description.youtube_url);




    const { user } = useUser();

    const [showModal, setShowModal] = useState(false);

    const [modalImage, setModalImage] = useState(false);

    const [showImageModal, setShowImageModal] = useState(false);

    const handlHideImageModal = () => {
        setModalImage();
        setShowImageModal(false)

    }
    const handlShowImageModal = (image) => {


        setModalImage(description.images[image]);
        setShowImageModal(true)

    }

    const handleCloseModal = () => setShowModal(false);


    const handleDescriptionChange = (data) => {

        if ('description' in data) {
            alert(data['description'])
            setDescriptionValue(data['description']);
        }

        if ('youtube_url' in data) {
            setYoutubeUrlValue(data['youtube_url']);

        }
    }




    return (
        <div>
            <ImageModal image={modalImage} show={showImageModal} hide={handlHideImageModal} />

            <UpdateDescriptionModal
                handleDescriptionChange={handleDescriptionChange}
                campaignId={campaignId}
                description={description}
                showModal={showModal}
                handleCloseModal={handleCloseModal}
                handleDescriptionUpdate={handleDescriptionUpdate}
            />




            {
                user && user.username === username &&
                <div className="text-end"><Button onClick={() => setShowModal(true)} className="rounded-pill" variant="light"><div className="px-2">Edit Description</div></Button></div>
            }

            <div style={{
                maxHeight: '600px',
                // maxHeight: isExpanded ? "none" : '600px',
                overflowY: 'scroll',
                overflowX: 'hidden',
                // overflow: 'hidden',

            }}
                className="mb-1"
            >


                <div ref={descriptionHeightRef}>
                    <Row>


                        <Col>

                            {

                                <YoutubeComponent video_id={description.youtube_id} />
                            }

                        </Col>
                        <Col xs={12}>
                            {description.images.map((image, index) => (
                                <Col key={index}>
                                    <div style={{ position: 'relative', display: 'inline-block', margin: '10px' }}>
                                        {/* <img onClick={() => handlShowImageModal(image)} src={image} alt={`uploaded`} style={{ maxWidth: '200px' }} /> */}
                                        <Image
                                            src={image}
                                            style={{
                                                width: "100%",
                                                height: "200px",
                                                objectFit: 'contain',
                                                cursor: 'pointer',
                                            }}
                                            onClick={() => handlShowImageModal(index)}

                                        />
                                    </div>
                                </Col>
                            ))}
                        </Col>
                    </Row>


                    <div dangerouslySetInnerHTML={{ __html: description.description }} />
                                            
                </div>
            </div>

            {/* <div >
                {
                    showAllButton &&
                    <Button className="mt-1 rounded-pill" variant="light" onClick={() => isExpanded ? setIsExpanded(false) : setIsExpanded(true)}><strong>{isExpanded ? "Show less" : "Show all..."}</strong></Button>
                }

            </div> */}
        </div >
    )
}

export default DescriptionComponent
