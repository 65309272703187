import React from 'react'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import ElapsedTime from './ElapsedTime';
import GetPrice from './GetPrice';



function MapPopup({ campaign, getCampaign }) {




    return (
        <Row style={{ zIndex: '500' }} >
            <Col xs="12" >
                <Row className=" gap-1">
                    <Col xs={12}>

                        {
                            campaign['image_url'] ?
                                <Image style={{ cursor: 'pointer' }} onClick={() => getCampaign(campaign.url)} fluid src={campaign['image_url'][0]['image']} rounded />
                                :
                                <div>No image</div>
                        }

                    </Col>
                    <Col xs={12}>
                        Posted by: <strong>{campaign['username']}</strong>

                    </Col>
                    <Col xs={12}>
                        Campaign type: <strong>{campaign['campaign_mode']}</strong>
                    </Col>
                    <Col xs={12}>
                        Status: <strong>{campaign['status']}</strong>
                    </Col>
                    <Col xs={12}>
                        <GetPrice priceInCents={campaign['current_amount']} />
                    </Col>
                    <hr />
                    <ElapsedTime mobile={true} endDate={campaign.end_date} startDate={campaign.start_date} createDate={campaign.create_date} daysLeft={campaign.days_left} />
                    <hr />


                    <div className="d-grid gap-2">
                        <Button variant="primary" onClick={() => getCampaign(campaign.url)
                        } size="sm">View Campaign</Button>
                    </div>
                    {
                        campaign['status'] === 'Accepting Contributions' &&
                        <div className="d-grid gap-2">
                            <Button variant="success" onClick={() => getCampaign(campaign.url, true)
                            } size="sm">Contribute</Button>
                        </div>
                    }
                </Row>
            </Col >
        </Row>
    )
}

export default MapPopup
