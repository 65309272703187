import { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { useApi } from './ApiProvider';
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button'
import { Link, useNavigate } from 'react-router-dom'
import { useFlash } from './FlashProvider';

const UserContext = createContext();

export default function UserProvider({ children }) {
  const [user, setUser] = useState();
  const [profilePage, setProfilePage] = useState();
  const api = useApi();
  const [showModal, setShowModal] = useState(false);
  const flash = useFlash()
  const navigate = useNavigate();

  useEffect(() => {

    (async () => {
      if (api.isAuthenticated()) {

        const response = await api.get('/me');

        setUser(() => response.ok ? response.body : null);

        // response.ok ? console.log("USERPROVIDER: ", response.body) : console.log("No user logged in")

        // var anonymous_id = window.localStorage.getItem("campaignID");

        // if (anonymous_id && response.ok) {
        //   window.localStorage.removeItem('campaignID')
        // }


      }
      else {
        setUser(null);
      }
    })();

  }, [api]);

  const login = useCallback(async (username, password) => { //TODO: FIGURE OUT WHAT TO DO WITHT HE ME ROUTE. IT IS BEING SENT TO BACKEND. 
    try{
      const result = await api.login(username, password);
  
      if (result['resp'] === 'ok') {
        // console.log(result, 'CHECK THIS')
        if ('new_user_greeting' in result && result['new_user_greeting'] === true) {
          flash(<span>Welcome to <strong>CrowdPost!</strong></span>, 'success')
        }
  
        const response = await api.get('/me');
        setUser(response.ok ? response.body : null);
  
        return response.ok;
      }
      // console.log(result, 'CHECK THIS')
  
      return result;

    }
    catch{
      return {success:'fail', errror:{'message':'Login error'}};
    }
  }, [api])


  const logout = useCallback(async () => {
    await api.logout();
    setUser(() => null);

  }, [api])



  const createCampaign = useCallback(async (create_new_override = false) => {

    const anonymous_id = localStorage.getItem('campaignID');

    const response = anonymous_id ? await api.post('/new_campaign/create-campaign', {
      campaign_id: anonymous_id,
      create_new_override: create_new_override
    })
      :
      await api.post('/new_campaign/create-campaign', { create_new_override: create_new_override })

    // const response = await api.get('/new_campaign/create-campaign');


    //The response 
    if (response.ok) {

      if ('existing_campaign' in response.body && response.body.existing_campaign === true) {
        setShowModal(true)
        return false;
      }
      if ('campaign_id' in response.body) {
        localStorage.setItem('campaignID', response.body.campaign_id);
      }

      // if (!user) {
      //   console.log(user)
      // }
      // else {
      //   localStorage.removeItem('campaignID');
      // }
      setShowModal(false);
      navigate('/new_campaign/campaign_type',{replace:true})

      return true
      // return { campaignID: response.body.anonymous_id, modes: response.body.modes };
    }
    else {

      return false
    }


  }, [api])






  return (
    <UserContext.Provider value={{ user, setUser, login, logout, profilePage, setProfilePage, createCampaign }}>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>
            <strong>You have existing draft campaigns</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Would you like to edit an existing draft or create a new campaign?
        </Modal.Body>
        <Modal.Footer>
          <Link onClick={() => setShowModal(false)} to={{ pathname: '/my_campaigns' }} >
            <Button>
              View existing drafts
            </Button>
          </Link>

          <Button onClick={() => createCampaign(true)} variant="success">
            Create new
          </Button>

        </Modal.Footer>
      </Modal>
      {

        children
      }
    </UserContext.Provider>
  );
}

export function useUser() {
  return useContext(UserContext);
}