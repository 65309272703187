import React from 'react'
import Image from 'react-bootstrap/Image';
import Badge from 'react-bootstrap/Badge';







function BillboardImage({ imageType, handleShowAll, showAllButton, image, statusLabel }) {

    return (

        imageType === 'map' ?

            <div style={{ position: "relative", display: 'inline-block' }}>
                <Image className="shadow rounded" src={image[0] === '/' ? `${image}` : `/${image}`} fluid />
                {/* <Image className="shadow rounded" src={image[0] === '/' ? `${image}` : `/${image}`} fluid /> */}
                {
                    showAllButton &&
                    <Badge pill onClick={() => handleShowAll('map')} className="shadow p-2 m-3" style={{ cursor: 'pointer', zIndex: '100', position: 'absolute', bottom: 0, right: 0, }} bg="dark">Show all locations...</Badge>

                }

            </div>

            :

            <div>adfkdkd</div>

    )

}

export default BillboardImage
