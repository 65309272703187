import React from 'react'


function FAQ({ faqs, activeSection }) {
    return (

        faqs.map((v, i) =>

            <div key={i}>
                {v.label !== "How it works" && v.label !== "What we do" &&
                    <>
                        <div id={`sidebar_${i}`} className="mb-5">
                            <div>
                                {
                                    activeSection === `sidebar_${i}` ?
                                        <h3 className="fw-bolder text-primary">{v.label}</h3>
                                        :
                                        <h3 className="fw-bolder">{v.label}</h3>
                                }
                            </div>
                            <div className="fs-5">
                                {
                                    activeSection === `sidebar_` ?
                                        <div className="text-white">
                                            {v.a}
                                        </div>
                                        :
                                        <div className="text-muted">
                                            {v.a}
                                        </div>
                                }
                            </div>

                        </div>
                        <hr />
                    </>


                }

            </div>

        )

    );
}
export default FAQ;
