import React from 'react'
// import { FaFacebookSquare } from "react-icons/fa";
import SocialMediaIcon from './SocialMediaIcon';
import { facebookIcon   } from '../utils/staticUrls'

function FacebookShare({ campaignId }) {
  var url = "https://facebook.com/sharer/sharer.php?"
  let u = `${process.env.REACT_APP_BASE_API_URL}/campaign/${campaignId}`

  // url += encodeURIComponent('u') + '=' + encodeURIComponent(u)

  // url += encodeURIComponent('quote') + '=' + encodeURIComponent('ab')

  const shareLink = `https://facebook.com/sharer/sharer.php?${url}`

  const facebookShareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
    u
  )}&quote=${encodeURIComponent("Contribute to this CrowdPost campaign")}`;


  return (


    <div>
      <a href={facebookShareUrl} target="_blank" rel="noopener noreferrer" aria-label="">
        {/* <FaFacebookSquare size={50} color="white" /> */}
        <SocialMediaIcon iconPath={facebookIcon} size={50}/>
      </a>
    </div>



  )
}

export default FacebookShare
