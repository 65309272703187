import React from 'react'

function CircleIcon({ children, color }) {
    return (

        <span className="shadow" style={{ background: `${color}`, padding: '.7rem', borderRadius: '50%' }}>

            {children}
        </span>

    )
}

export default CircleIcon
