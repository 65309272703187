import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import Body from '../components/Body'
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../contexts/UserProvider';
import { useFlash } from '../contexts/FlashProvider';
import { useApi } from '../contexts/ApiProvider';

import TimeAgo from '../components/TimeAgo'
import FollowTab from '../components/FollowTab';
import Campaign from '../components/Campaign';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProfilePhoto from '../components/ProfilePhoto';
import Container from 'react-bootstrap/Container';



function UserPage() {

    const api = useApi();
    const { username } = useParams()
    const [isFollower, setIsFollower] = useState();
    const [campaigns, setCampaigns] = useState();
    const [user, setUser] = useState();
    const { user: loggedInUser } = useUser();
    const flash = useFlash();
    const navigate = useNavigate();


    useEffect(() => {

        (async () => {
            const response = await api.get('/users/' + username).catch(() => navigate('/', { replace: true }));

            if (response.ok) {

                setUser(response.body);

                if (loggedInUser && response.body.username !== loggedInUser.username) {

                    const follower = await api.get(
                        '/me/following/' + response.body.id);
                    console.log(follower);
                    if (follower.status === 204) {
                        setIsFollower(true);
                    }
                    else if (follower.status === 404) {
                        setIsFollower(false);
                    }
                    else {
                        alert(follower.status)
                    }

                }
                else if (loggedInUser && response.body.username === loggedInUser.username) {

                    setIsFollower(null);

                }
            }
            else {
                flash('error', 'danger')

            }
        })();
    }, [username, api, loggedInUser, flash]);

    const edit = () => {
        navigate('/edit');
    };

    const follow = async () => {
        const response = await api.post('/me/following/' + user.id);
        if (response.ok) {
            flash(
                <>
                    You are now following <b>{user.username}</b>.
                </>, 'success'
            );
            setIsFollower(true);
        }
    };

    const unfollow = async () => {
        const response = await api.delete('/me/following/' + user.id);
        if (response.ok) {
            flash(
                <>
                    You have unfollowed <b>{user.username}</b>.
                </>, 'success'
            );
            setIsFollower(false);
        }
    };

    useEffect(() => {

        username && (async () => {
            const response = await api.get(`/users/${username}/campaigns`);
            if (response.ok) {
                setCampaigns(() => response.body);

            }
            else {
                setCampaigns(null);
            }
        })();

    }, [api, username]);



    return (

        <Body scrollable sidebar >
            {user === undefined ?

                <div className="mt-5 d-flex justify-content-center"><Spinner /></div>

                :
                <>
                    {user === null ?
                        <p>User not found.</p>
                        :
                        <div className="">


                            <Row className="mb-3">

                                <Col xs="auto">




                                    <ProfilePhoto
                                        avatar_url={user.avatar_url}
                                        size={3}
                                    />



                                </Col>


                                <Col xs="auto">

                                    <div>
                                        <h3>
                                            <strong>{user.account_name}</strong>
                                        </h3>
                                    </div>
                                    <div>
                                        <h3>
                                            <strong>{user.username}</strong>
                                        </h3>
                                    </div>
                                    <div className="my-1" style={{ display: 'flex' }}><strong style={{ paddingRight: ".25rem" }}>Member since: </strong> <TimeAgo isoDate={user.first_seen} /></div>


                                    <div>{user.about_me}</div>
                                </Col>
                                <Stack direction="horizontal" gap={4} className="mt-3">
                                    {/* <Image src={user.avatar_url + '&s=128'} roundedCircle /> */}
                                    <div>
                                        {isFollower === null &&
                                            <Button variant="outline-primary" onClick={edit}>
                                                Edit Profile
                                            </Button>
                                        }
                                        {isFollower === false &&
                                            <Button variant="outline-primary" onClick={follow}>
                                                Follow
                                            </Button>
                                        }
                                        {isFollower === true &&
                                            <Button variant="outline-primary" onClick={unfollow}>
                                                Unfollow
                                            </Button>
                                        }
                                    </div>
                                </Stack>

                            </Row>

                            <Container>

                                {isFollower === null &&
                                    <Row>
                                        <Col className="mt-5" xs={12} lg={12}>
                                            <FollowTab />
                                        </Col>
                                    </Row>
                                }
                                <Row>
                                    <hr className="mt-5" />
                                    {
                                        campaigns && campaigns.length > 0 ?

                                            <Col xs={12}>
                                                <h3 >Campaigns</h3>
                                                {campaigns.map((campaign, index) => <div key={index}>
                                                    <Campaign key={campaign.id} campaign={campaign} isLoading={false} />
                                                </div>)}
                                            </Col>

                                            :
                                            <div className="mt-5 d-flex justify-content-center"><h4>You have no campaigns yet   </h4></div>
                                    }
                                </Row>
                            </Container>

                        </div>
                    }
                </>

            }
        </Body>

    )
}

export default UserPage