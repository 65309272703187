import React, { useState, useRef, useEffect } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Placeholder from 'react-bootstrap/Placeholder';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';

import MapboxDraw from "@mapbox/mapbox-gl-draw";

import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css'

import GetPrice from './GetPrice';
import Badge from 'react-bootstrap/Badge';
import ImpressionValue from './ImpressionValue';




mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_PUBLIC_KEY


function CampaignPageMap({ location, campaign_mode, isProcessing, isMobile }) {
    const mapContainer = useRef(null);
    const map = useRef(null);


    const [poi, setPoi] = useState(location.poi)
    const popupRef = useRef(new mapboxgl.Popup({ closeButton: true }));
    // const [polygon, setPolygon] = useState()

    const [avgTotalImp, setAvgTotalImp] = useState(false)

    const [featureCollection, setFeatureCollection] = useState();



    const [draw, setDraw] = useState(null)

    const toggleDrawMode = () => map.current.fitBounds(location.bbox)


    const showPopup = (id) => {
        let counter = 1

        var name
        var s = draw.getAll()
        s = s.features

        for (let i in s) {
            i = s[i]

            var coordinates;
            if (i.id === id && 'poi_name' in i.properties && i.properties.poi_name) {

                name = i.properties.name
                coordinates = i.geometry.coordinates[0][0]

            }
            else if (i.id === id) {

                // counter++
                // name = `Custom area ${counter}`
                name = i.properties.name
                coordinates = i.geometry.coordinates[0][0]

            }
            else {
                counter++
            }

        }
        if (name) {
            const popupContent = `<p>${name}</p>`;
            popupRef.current.setLngLat(coordinates).setHTML(popupContent).addTo(map.current);
            map.current.flyTo({
                center: coordinates,
                zoom: 9,
                essential: true // this animation is considered essential with respect to prefers-reduced-motion
            });
        }

    }


    useEffect(() => {

        if (map.current) return; // initialize map only once


        map.current = new mapboxgl.Map({
            container: mapContainer.current,
            style: process.env.REACT_APP_MAPBOX_CAMPAIGN_PAGE_MAP,
            bounds: location.bbox,
            projection: 'mercator',
            cooperativeGestures: true,
        });
        map.current.on("load", () => {

            // polygon.length === 0 &&
            //     map.current.removeLayer('polygon');
            // map.current.removeSource('polygon');
            let r = new MapboxDraw({
                displayControlsDefault: false,
                // Select which mapbox-gl-draw control buttons to add to the map.
                controls: {
                    // polygon: true,
                    // trash: true
                },
                styles: [
                    // ACTIVE (being drawn)
                    // line stroke
                    {
                        "id": "gl-draw-line",
                        "type": "line",
                        "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                        "layout": {
                            "line-cap": "round",
                            "line-join": "round"
                        },
                        "paint": {
                            "line-color": "#336699",
                            "line-dasharray": [0.2, 2],
                            "line-width": 2
                        }
                    },
                    // polygon fill
                    {
                        "id": "gl-draw-polygon-fill",
                        "type": "fill",
                        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                        "paint": {
                            "fill-color": "#336699",
                            "fill-outline-color": "#336699",
                            "fill-opacity": 0.1
                        }
                    },
                    // polygon mid points
                    {
                        'id': 'gl-draw-polygon-midpoint',
                        'type': 'circle',
                        'filter': ['all',
                            ['==', '$type', 'Point'],
                            ['==', 'meta', 'midpoint']],
                        'paint': {
                            'circle-radius': 3,
                            'circle-color': '#fbb03b'
                        }
                    },
                    // polygon outline stroke
                    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
                    {
                        "id": "gl-draw-polygon-stroke-active",
                        "type": "line",
                        "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                        "layout": {
                            "line-cap": "round",
                            "line-join": "round"
                        },
                        "paint": {
                            "line-color": "#336699",
                            "line-dasharray": [0.2, 2],
                            "line-width": 2
                        }
                    },
                    // vertex point halos
                    {
                        "id": "gl-draw-polygon-and-line-vertex-halo-active",
                        "type": "circle",
                        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                        "paint": {
                            "circle-radius": 5,
                            "circle-color": "#FFF"
                        }
                    },
                    // vertex points
                    {
                        "id": "gl-draw-polygon-and-line-vertex-active",
                        "type": "circle",
                        "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                        "paint": {
                            "circle-radius": 3,
                            "circle-color": "#336699",
                        }
                    },

                    // INACTIVE (static, already drawn)
                    // line stroke
                    {
                        "id": "gl-draw-line-static",
                        "type": "line",
                        "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
                        "layout": {
                            "line-cap": "round",
                            "line-join": "round"
                        },
                        "paint": {
                            "line-color": "#000",
                            "line-width": 3
                        }
                    },
                    // polygon fill
                    {
                        "id": "gl-draw-polygon-fill-static",
                        "type": "fill",
                        "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                        "paint": {
                            "fill-color": "#000",
                            "fill-outline-color": "#000",
                            "fill-opacity": 0.1
                        }
                    },
                    // polygon outline
                    {
                        "id": "gl-draw-polygon-stroke-static",
                        "type": "line",
                        "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                        "layout": {
                            "line-cap": "round",
                            "line-join": "round"
                        },
                        "paint": {
                            "line-color": "#000",
                            "line-width": 3
                        }
                    }
                ]
            });

            setDraw(r);
            map.current.addControl(r, 'top-right');

            if (location && 'polygons' in location && 'features' in location.polygons && location.polygons.features.length > 1) {
                var sum = location.polygons.features.reduce((acc, value) => {
                    if (value.hasOwnProperty('properties') && value['properties'].hasOwnProperty('imp')) {
                        return acc + value['properties']['imp']
                    }
                    return acc
                }, 0);

                setAvgTotalImp(sum)
            }
            r.set(location.polygons);

            map.current.addControl(new mapboxgl.FullscreenControl(), 'top-left');
        })

        return () => {
            if (map.current) {
                map.current.remove();
                map.current = null;
            }
        }



    }, [map, location.bbox, location.polygons]); //radius was here


    useEffect(() => {
        if (draw && location && campaign_mode) {

            var layerId
            if (campaign_mode.includes('Mobile')) {
                layerId = 'ca-polygon'
                if (map.current.getLayer(layerId)) {
                    map.current.setLayoutProperty(layerId, 'visibility', 'none');
                }
            }
            else {
                layerId = 'truck-area'
                if (map.current.getLayer(layerId)) {
                    map.current.setLayoutProperty(layerId, 'visibility', 'none');
                }
            }


            if ('polygons' in location) {
                // setPolygon(response.body.polygons)
                setFeatureCollection(location.polygons)
                map.current.addSource('custom-source-id', {
                    type: 'geojson',
                    data: location.polygons,
                });

                map.current.addLayer({
                    id: 'custom-layer-id',
                    type: 'fill',
                    source: 'custom-source-id',
                    paint: {
                        // 'fill-color': '#ff0000',
                        'fill-opacity': 0,
                    },
                });



            }
            else {
                setFeatureCollection(null)
            }
        }
        else {
            setPoi(null)
            // setPolygon(null)
            setFeatureCollection(null)

        }



    }, [draw, campaign_mode, location])





    useEffect(() => {


        if (draw) {

            // const sourceIds = Object.keys(sourceCaches);


            if (featureCollection && Object.keys(featureCollection).length === 0) {
                // if ((featureCollection) && (Object.keys(featureCollection).length !== 0)) {


                var currentFeatureCollection = draw.getAll();

                let counter = 1
                currentFeatureCollection['features'].forEach((i) => {
                    if ('poi_name' in i.properties) {

                        i['name'] = i['poi_name'];
                    }
                    else {
                        i['name'] = `Custom area ${counter}`;
                        counter++;
                    }
                })

                var poi_features = currentFeatureCollection.features.filter((item) => ("poi_name" in item.properties && item.properties['poi_name'] !== null));

                var newPOI = {}
                for (let i = 0; i < poi_features.length; i++) {
                    var _feature = poi_features[i];
                    newPOI[_feature.properties.id] = {
                        radius: _feature.properties.poi_radius,
                        name: _feature.properties.poi_name
                    };
                }
                // poi_features.map((i) => {
                //     newPOI[i.properties.id] = { radius: i.properties.poi_radius, name: i.properties.poi_name }
                //     }
                // )


                // var polygon_features = currentFeatureCollection.features.filter((item) => !("poi_name" in item.properties) || item.properties['poi_name'] === null);
                // setPolygon({ type: 'FeatureCollection', features: polygon_features });
                setPoi(() => newPOI)



                // map.current.getSource('custom-source-id').setData(featureCollection)
                // setPoi({ type: 'FeatureCollection', features: poi_features });
            }
        }



    }, [featureCollection, draw])



    return (
        <Row>

            <Col className='d-block d-lg-block px-0 mx-0' xs={12}>
                {/* <Col className={showMap ? 'vh-100 d-block px-0 mx-0' : 'vh-100 d-none px-0 mx-0'} lg={9} xs={12}> */}


                <div ref={mapContainer} style={{ 'height': '400px' }} >

                    <div className="d-block">
                        {draw &&
                            <Button
                                // variant="dark"
                                onClick={toggleDrawMode}
                                style={{
                                    position: "absolute",
                                    top: "0px",
                                    right: "0px",
                                    zIndex: "1",
                                    margin: ".5rem",
                                    paddingLeft: '2rem',
                                    paddingRight: '2rem'
                                }}
                                className={`btn btn-dark`}
                            >
                                <strong> Re-center
                                </strong>
                            </Button  >
                        }
                    </div>


                </div>




            </Col>

            <Col className="d-flex mt-3" xs={12} >
                <Stack gap={3}>

                    <div>
                        {
                            location === undefined || isProcessing === true ?
                                <div>
                                    <div className="my-2">
                                        <Placeholder className="rounded" animation="glow">
                                            <Placeholder xs={4} className="rounded" />
                                        </Placeholder>
                                    </div>
                                    <div className="my-2">
                                        <Placeholder className="rounded" animation="glow">
                                            <Placeholder xs={4} className="rounded" />
                                        </Placeholder>
                                    </div>
                                    <div className="my-2">
                                        <Placeholder className="rounded" animation="glow">
                                            <Placeholder xs={4} className="rounded" />
                                        </Placeholder>
                                    </div>
                                </div>
                                :
                                <div>
                                    {/* <h5 className="fw-bolder">Custom Areas</h5> */}
                                    {(location.polygons && 'features' in location.polygons && location.polygons.features.length !== 0) &&
                                        location.polygons.features.map((i, index) =>
                                            <Row key={index}>

                                                <div className="d-flex align-items-center justify-content-between py-2">
                                                    <div>
                                                        <div>
                                                            {
                                                                'properties' in i && 'poi_name' in i.properties && i.properties.poi_name ?
                                                                    <span >{i.properties.name} <Badge className="mx-2 bg-primary rounded-pill">{i.properties.poi_radius} mile radius</Badge> </span>
                                                                    :
                                                                    <span >{i.properties.name}</span>
                                                            }
                                                        </div>

                                                        <div className="text-muted">
                                                            {
                                                                'properties' in i && 'imp' in i['properties'] &&
                                                                <ImpressionValue impression={i['properties']['imp']} />
                                                            }

                                                        </div>
                                                    </div>

                                                    <div className="">

                                                        <Badge pill bg="success" onClick={() => showPopup(i.id)} style={{ marginRight: ".4rem", cursor: 'pointer', fontSize: '1rem' }} >Show</Badge>
                                                    </div>

                                                </div>



                                            </Row>
                                        )}
                                </div>


                        }
                    </div>
                    <Row>
                        <Col xs={12} className="d-flex justify-content-between">
                            <span>
                                {avgTotalImp && parseInt(avgTotalImp) > 0 ?
                                    <span className="d-flex">
                                        <span><strong>Est. avg weekly impressions per billboard: </strong> </span>
                                        <span className="mx-1">
                                            <ImpressionValue label={" "} impression={avgTotalImp} />
                                        </span>

                                    </span>
                                    :
                                    <div></div>
                                }
                            </span>

                        </Col>
                    </Row>

                    {'full_estimated_min_price' in location && location.full_estimated_min_price &&
                        <div>
                            <span>Estimated minimum for media all areas:<GetPrice priceInCents={location.full_estimated_min_price}> estimated minimum</GetPrice> </span>
                        </div>}
                    <div>
                        {location.partial_estimated_min_price && <span>Estimated minimum to get the campaign posted:<GetPrice priceInCents={location.partial_estimated_min_price}> estimated minimum</GetPrice></span>}
                    </div>


                </Stack>

            </Col>


        </Row>
    )
}

export default CampaignPageMap
