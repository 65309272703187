import React from 'react'
import Body from '../components/Body'
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



function PrivacyPolicy() {

    const page_info = [
        {
            label: "1. Introduction",
            text:
                < p >
                    Welcome to CrowdPost ("LLC", "we", "our", "us"). We are committed to protecting your personal information and your right to privacy. This privacy policy explains how we collect, use, disclose, and safeguard your information when you visit our website https://crowdpost.com, use our services, or interact with us.
                </p >
        },
        {
            label: "2. Information We Collect",
            text:
                < p >
                    Personal Data: CrowdPost respects our users privacy and does not sell any user information.  We may collect personal information that you voluntarily provide to us when you register on the website, express an interest in obtaining information about us or our products and services, or otherwise when you contact us. This can include:
                    <br />
                    <br />
                    <ul>

                        <li>Name</li>
                        <li>Email address</li>
                    </ul>
                    Non-Personal Data: We may collect non-personal information about you whenever you interact with our website. This may include your browser name, the type of computer, and technical information about your means of connection to our website, such as the operating system and the Internet service providers utilized.
                </p >
        },
        {
            label: "3. Use of Information",
            text: <p>
                We use the information we collect for various purposes, including:
                <ul>
                    <li>To provide, operate, and maintain our website and services</li>
                    <li>To improve, personalize, and expand our website and services</li>
                    <li>To understand and analyze how you use our website</li>
                    <li>To develop new products, services, features, and functionality</li>
                    <li>To communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes</li>
                    <li>To send you transactional and or marketing emails</li>
                    <li>When posting campaigns crowdpost visitors can contact you via email through the platform. Your email is not public, our platform transacts the email to protect your privacy. CrowdPost takes reasonable measures to prevent spam and abuse through this channel </li>
                </ul>
            </p>
        },
        {
            label: "4. Sharing of Information",
            text: <p>
                We do not sell or trade your personal information to third parties. However, we may share information with third parties who perform services for us or on our behalf, such as payment processing, map services, and email delivery.
                <br />
                <br />
                Third-Party Service Providers:
                <br />

                <ul>
                    <div>
                        Payment Processors: We use Stripe to handle payments securely. Your payment information is processed by Stripe in accordance with their privacy policy.
                        Email Services: We use Twilio Sendgrid to send transactional emails and newsletters. Your email address and any other required information are processed by Twilio Sendgrid's in accordance with their privacy policy.
                    </div>
                </ul>
            </p>
        },
        {
            label: "5. Cookies and Tracking Technologies",
            text: <p>
                We do not use cookies to track users. Functional cookies necessary for the website to function properly may be used. These cookies do not collect personal data and are essential for basic functionalities such as security, network management, and accessibility.
            </p>
        },
        {
            label: "6. Data Security",
            text: <p>
                We implement appropriate security measures to ensure the safety of the information processed through our website and third-party services. Your email address is encrypted on our servers. However, no method of transmission over the internet or electronic storage is 100% secure.
            </p>
        },
        {
            label: "7. User Rights",
            text: <p>
                Depending on your location, you may have the following rights regarding your personal data:
                <ul>
                    <li>The right to access – You have the right to request copies of your personal data.</li>
                    <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate or complete information you believe is incomplete.</li>
                    <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
                    <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                    <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
                    <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                </ul>

                If you make a request, we have one month to respond to you. If you would like to exercise any of these rights, please contact us at our email: info@crowdpost.com.
            </p>
        },
        {
            label: "8. Data Retention",
            text: <p>

                We will retain your personal information only for as long as is necessary for the purposes set out in this privacy policy. We will retain and use your information to the extent necessary to comply with our legal obligations, resolve disputes, and enforce our policies.
            </p>
        },
        {
            label: "9. Social Media Logins",
            text: <p>
                You can log in to our website using Google OAuth. When you log in using your social media account, we may collect personal information that is already associated with your Google account, such as your name and email address. You may also have the option of sharing additional information with us through your Google account. If you provide such information and data during registration, it will only be used in accordance with this privacy policy.
            </p>
        },
        {
            label: "10. Children's Privacy",
            text: <p>
                Our website and services are not intended for children under the age of 13. We do not knowingly collect any personal data from children. If we become aware that we have inadvertently received personal data from a visitor under the age of 13, we will delete the information from our records.
            </p>
        },
        {
            label: "11. Changes to This Privacy Policy",
            text: <p>

                We may update this privacy policy from time to time. We will notify you of any changes by posting the new privacy policy on this page and updating the "Effective Date" at the top. You are advised to review this privacy policy periodically for any changes.
            </p>
        },
        {
            label: "12. Contact Us",
            text: <p>


                If you have any questions or concerns about this privacy policy, please contact us:

                <ul>
                    <div>By email: info@crowdpost.com</div>
                    <div>By visiting this page on our website: https://crowdpost.com/contact</div>
                    <div>By mail: [Your Physical Address]</div>
                </ul>
            </p>
        }

    ]

    // link to dedicated DMCA page. 
    // add a privacy agreement. 
    // clean up the terms of service, privacy. 

    return (
        <Body aboutSidebar={true} faqs={page_info} footer>

            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '3rem', marginBottom: '5rem' }}>
                <Col className="text-center mb-5" xs={12}>
                    <h2 className="fw-bolder">
                        Privacy Policy
                    </h2>
                </Col>

                <Col lg={10}>

                    <Stack gap={4}>

                        <p>
                            Effective Date: 05/21/2023
                        </p>

                        {
                            page_info.map(((v, i) =>
                                <>
                                    <hr />
                                    <h3 id={`sidebar_${i}`} className="fw-bolder">{v['label']}</h3>
                                    <p>{v['text']}</p>
                                </>
                            ))
                        }


                    </Stack>


                </Col>
            </Row>
        </Body >
    )
}

export default PrivacyPolicy
