import React from 'react'
import Form from 'react-bootstrap/Form';



function CustomRangeField({ small, disabled, min, max, step, value, onChange, rangeStyle, inputStyle, children, hideValue }) {



    return (

        <div>

            {/* <div className="d-block d-lg-none">
                <InputGroup size={small && 'sm'}>
                    <Form.Control
                        disabled={disabled ? true : false}

                        type="number"
                        min={min}
                        max={max}
                        step={step}
                        value={value}
                        onChange={onChange && onChange}
                        style={inputStyle ? inputStyle : { textAlign: 'center', minWidth: '100px', maxWidth: '350px' }}
                    />
                    {
                        children && <InputGroup.Text>
                            {children}
                        </InputGroup.Text>
                    }
                </InputGroup>
            </div> */}
            <div className="">
                {/* <div className="d-none d-lg-block"> */}

                { !disabled && <div className="text-center mb-1">{!hideValue && value}{children && children}</div>}
                {/* {((!disabled) || (!hideValue)) && <div className="text-center mb-1">{value}{children && children}</div>} */}
                <Form.Range
                    disabled={disabled ? true : false}
                    min={min}
                    max={max}
                    step={step}
                    value={value}
                    onChange={onChange}
                    // onTouch={handleRadiusChange}
                    style={rangeStyle ? rangeStyle : { textAlign: 'center' }}
                    // style={{
                    //     background: '#fff',
                    //     height: '40px',
                    //     borderRadius: '20px'
                    // }}
                    className="custom-range"
                    list="tickMarks"
                />
            </div>
        </div>
    )
}

export default CustomRangeField
