import React, { useState, useEffect } from 'react'

import Table from 'react-bootstrap/Table';
import Placeholder from 'react-bootstrap/Placeholder';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import GetPrice from "./GetPrice";

function PricingTable() {
    const api = useApi();
    const flash = useFlash();
    const [isLoading, setIsLoading] = useState(false)
    const [pricing, setPricing] = useState()


    useEffect(() => {
        setIsLoading(true);
        try {


            (async () => {
                const response = await api.get(`/ai-img-pricing`);
                if (response.ok) {
                    setIsLoading(false);

                    setPricing(response.body.data)

                }
                else {
                    setPricing(false)
                    setIsLoading(false);
                    if ("errors" in response.body) {
                        flash(response.body.errors.json.message, 'danger');
                    }
                    else {
                        flash('There was an error sending your message', 'danger');
                    }
                }
            })();

        } catch (error) {
            setIsLoading(false);
            setPricing(false);
        }
    }, [api])

    return (
        <Table striped bordered hover variant="dark">
            <thead>
                <tr>
                    <th><h3>Price</h3></th>
                    <th><h4># of Images</h4></th>
                </tr>
            </thead>
            <tbody>


                {
                    pricing === undefined ?
                        [1, 2, 3].map((i, _i) =>
                            <tr key={_i}>
                                <td>
                                    <Placeholder as="p" animation="glow">
                                        <Placeholder xs={3} />
                                    </Placeholder>
                                </td>
                                <td>
                                    <Placeholder as="p" animation="glow">
                                        <Placeholder xs={3} />
                                    </Placeholder>
                                </td>
                            </tr>
                        )
                        :
                        pricing === false ?
                            <tr >
                                <td>None</td>
                                <td>None</td>
                            </tr>
                            :
                            pricing.map((i, _i) =>
                                <tr key={_i}>
                                    <td><GetPrice priceInCents={i['price']} /> </td>
                                    <td>{i['image_quantity']}</td>
                                </tr>
                            )
                }

            </tbody>
        </Table >
    );
}

export default PricingTable;