import React from 'react'
import Image from 'react-bootstrap/Image'

function ProfilePhoto({ avatar_url, fieldRef, size = 1 }) {
    return (
        <Image
            height={40 * size}
            width={40 * size}
            src={avatar_url}
            roundedCircle
            referrerPolicy="no-referrer"
            ref={fieldRef}
            style={{ objectFit: 'cover' }}
        />

    )
}

export default ProfilePhoto
