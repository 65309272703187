import React from 'react'
import FollowerRow from './FollowRow'
import Spinner from 'react-bootstrap/Spinner';



function Followers({followers,handleRefresh}) {


    return (
        <div>
            {
                followers === undefined ?
                    <Spinner />
                    :
                    followers === null ?
                        <p>No followers</p>
                    :
                    followers.map(follower => <FollowerRow  key={follower.url} handleRefresh={handleRefresh} username={follower.username} isFollowing={follower.isFollowing} url={follower.url}/> )
        }
        </div>
    )
}

export default Followers
