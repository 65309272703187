import React from 'react';
import Pagination from 'react-bootstrap/Pagination';
import Container from 'react-bootstrap/Container';

const PaginationNavV2 = ({ totalPages, onPageChange, paginationData }) => {


    const handlePageChange = (e, pageNumber) => {
        e.preventDefault();
        onPageChange(pageNumber);
    };




    return (
        paginationData && 'pages' in paginationData && paginationData['pages'].length > 1 &&
        <Container className="d-flex justify-content-center mt-4" >


            <Pagination>
                {
                    'prev_url' in paginationData && paginationData['prev_url'] !== false &&
                    <Pagination.Prev
                        onClick={(e) => handlePageChange(e, paginationData['page'] - 1)}
                        disabled={paginationData['page'] === 1}
                    />

                }

                {
                    paginationData['pages'].map((val, index) =>
                        <Pagination.Item
                            key={index}
                            active={val === paginationData['page']}
                            onClick={(e,) => handlePageChange(e, val)}
                        >
                            {val}
                        </Pagination.Item>

                    )

                }

                {
                    'next_url' in paginationData && paginationData['next_url'] !== false &&
                    <Pagination.Next
                        onClick={(e) => handlePageChange(e, paginationData['page'] + 1)}
                        disabled={paginationData['page'] === totalPages}
                    />
                }
            </Pagination>
        </Container >
    );
};

export default PaginationNavV2;
