import React from "react";

import '../styles/CloudAnimation.css';

const CloudAnimation = () => {
    return (
        <div className="frame">
            <div className="plane-container">
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="1131.53px"
                    height="379.304px"
                    viewBox="0 0 1131.53 379.304"
                    className="plane"
                >
                    <polygon
                        fill="#D8D8D8"
                        points="72.008,0 274.113,140.173 274.113,301.804 390.796,221.102 601.682,367.302 1131.53,0.223"
                    />
                    <polygon
                        fill="#C4C4C3"
                        points="1131.53,0.223 274.113,140.173 274.113,301.804 390.796,221.102"
                    />
                </svg>
            </div>
            <div className="clouds">
                <Cloud className="cloud big front slowest" />
                <Cloud className="cloud distant smaller" />
                <Cloud className="cloud small slow" />
                <Cloud className="cloud distant super-slow massive" />
                <Cloud className="cloud slower" />
            </div>
        </div>
    );
};

const Cloud = ({ className }) => (
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        width="762px"
        height="331px"
        viewBox="0 0 762 331"
        className={className}
    >
        <path
            fill="#FFFFFF"
            d="M715.394,228h-16.595c0.79-5.219,1.201-10.562,1.201-16c0-58.542-47.458-106-106-106
          c-8.198,0-16.178,0.932-23.841,2.693C548.279,45.434,488.199,0,417.5,0c-84.827,0-154.374,65.401-160.98,148.529
          C245.15,143.684,232.639,141,219.5,141c-49.667,0-90.381,38.315-94.204,87H46.607C20.866,228,0,251.058,0,279.5
          S20.866,331,46.607,331h668.787C741.133,331,762,307.942,762,279.5S741.133,228,715.394,228z"
        />
    </svg>
);

export default CloudAnimation;
