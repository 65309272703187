import React, { useState } from 'react'
import Col from 'react-bootstrap/Col';
import Stack from 'react-bootstrap/Stack';
import Button from 'react-bootstrap/Button';
import TimeAgo from './TimeAgo';
import CampaignImageList from './CampaignImageList';
import { Link } from 'react-router-dom';
import GetPrice from './GetPrice';
import CampaignOrganizer from './CampaignOrganizer';
import CampaignUpdates from './CampaignUpdates';
import CampaignComments from './CampaignComments';
import Favorite from './Favorite'
import PricingComponent from './PricingComponent';
import DescriptionComponent from './DescriptionComponent';
import CampaignPageMap from './CampaignPageMap';
import ShareCanvas from './ShareCanvas';
import TimeLeft from './TimeLeft';



function CampaignPageMobileStack({ campaign, comments, modalImages, handleShowAll, showAll, user, setShowPostUpdateModal, setShowContactModal, showPostUpdateModal, campaignUpdates, handleDeleteMessage, getUpdates, rowStyle, getComments, setShowModal, handleCampaignUpdates, handleDescriptionUpdate, handleWhatCanBePostedModal }) {


    const [showShareCanvas, setShowShareCanvas] = useState(false);

    const handleShowShareCanvas = (data) => setShowShareCanvas(data);



    return (
        <Col className="d-block d-lg-none" xs={12}>
            <Stack className="" gap={3}>
                <div className="mt-3">
                    {
                        campaign && campaign.denial_message &&
                        <>
                            <Col>
                                <div>
                                    <h4 className="fw-bolder text-danger">Reason for campaign denial</h4>
                                    {campaign.denial_message}

                                </div>
                            </Col>
                            <hr />
                        </>
                    }
                    <div>
                        <h3 className="text-center fw-bolder">{campaign.description.title}</h3>

                    </div>
                    <div>
                        {
                            campaign && campaign.images && <>
                                {/* <h3 className="fw-bolder">Campaign Media</h3> */}
                                <CampaignImageList modalImages={modalImages} imageType={'image'} handleShowAll={() => handleShowAll('image')} showAll={showAll} status={campaign.status} images={campaign.images} isLiked={campaign.is_liked} campaign_id={campaign.id} />
                            </>
                        }
                    </div>

                    {'adjusted_amount' in campaign &&
                        <div className="mb-2 d-inline-flex">
                            <span className="text-secondary fw-bold">Final charge: </span>
                            <GetPrice priceInCents={campaign.adjusted_amount} >
                                with <strong>{campaign.num_payments} {campaign.num_payments === 1 ? 'payment' : 'payments'}</strong>
                            </GetPrice>
                        </div>
                    }
                    <div className="mt-3 d-grid gap-2">

                        {campaign.status === 'active' &&

                            <Link to={{ pathname: '/campaign-payment/' + campaign.url }}
                                state={{ campaign: campaign }}>
                                <Button variant="success" className="rounded-pill p-2 mb-3 fs-5 w-100"><strong>Contribute</strong></Button>
                            </Link>
                        }

                        {
                            campaign && campaign.url &&
                            <div>
                                <Button onClick={() => handleShowShareCanvas(true)} variant="primary" className="rounded-pill p-2 mb-3 fs-5 w-100"><strong>Share</strong></Button>
                                <ShareCanvas show={showShareCanvas} handleShowUpdate={handleShowShareCanvas} campaignId={campaign.url} />
                            </div>
                        }

                        {/* <Button onClick={() => setShowModal(true)} className="fw-bolder" variant="primary" size="lg">
                            Share
                        </Button> */}
                        <div className="mt-3">
                            <Favorite campaignId={campaign.id} isLiked={campaign.is_liked} />
                        </div>
                        <div>
                            <GetPrice priceInCents={campaign.current_amount} >
                                with <strong>{campaign.num_payments} {campaign.num_payments === 1 ? 'payment' : 'payments'}</strong>
                                <span>
                                    <TimeLeft endDate={campaign.start_date} isoDate={campaign.start_date} endText={<span className=""> left to contribute</span>} />
                                </span>
                            </GetPrice>
                            <div><strong>Campaign type:</strong> <span className="text-muted">{campaign.campaign_mode}</span></div>
                            <div><strong>Status:</strong> <span className="text-muted">{campaign && campaign.status_label}</span></div>
                            {/* <div><strong>Campaign type:</strong> {campaign.campaign_mode}</div> */}
                            {/* 
                            <div className="d-inline-flex">
                                <h5 className="fw-bolder">Campaign type: </h5><span style={{ paddingLeft: "5px" }}> {campaign.campaign_mode}</span>
                            </div> */}
                            {/* <div className="d-flex">
                                <h5 className="fw-bolder">Status: </h5><span style={{ paddingLeft: "5px" }}> {campaign.status_label}</span>
                            </div> */}
                        </div>
                        {/* {campaign.status === 'active' &&
                            <>

                                <div className="fw-bold">Fundraising Period</div>
                                <ElapsedTime endDate={campaign.end_date} startDate={campaign.start_date} createDate={campaign.create_date} daysLeft={campaign.days_left} mobile={true} />
                            </>
                        } */}


                    </div>
                </div>
                {campaign.status === 'active' && campaign.partial_estimated_min_price &&

                    <PricingComponent
                        partial_estimated_min_price={campaign.partial_estimated_min_price}
                        full_estimated_min_price={campaign.full_estimated_min_price}
                    />

                }
                <hr />

                {
                    campaign && campaign.last_status_message &&
                    <>
                        <Col>
                            <div>
                                <h4>Last Status Update</h4>
                                {campaign.last_status_message.message}
                                <TimeAgo isoDate={campaign.last_status_message.timestamp} >Updated: </TimeAgo>
                            </div>
                        </Col>

                    </>
                }

                <>
                    <h3 ><strong>Campaign Location</strong></h3>

                    <CampaignPageMap isMobile location={campaign.location} campaign_mode={campaign.campaign_mode} />
                </>

                <hr />
                <div>



                    <DescriptionComponent
                        description={campaign.description}


                        username={campaign.username}

                        campaignId={campaign.id}
                        handleDescriptionUpdate={handleDescriptionUpdate}

                    />
                </div>

                <CampaignOrganizer
                    campaign={campaign}
                    user={user}
                    setShowContactModal={setShowContactModal}
                />

                <CampaignUpdates
                    user={user}
                    campaignUpdates={campaignUpdates}
                    setShowPostUpdateModal={setShowPostUpdateModal}
                    handleDeleteMessage={handleDeleteMessage}
                    getUpdates={getUpdates}
                    campaignId={campaign.id}
                    handleCampaignUpdates={handleCampaignUpdates}
                    showPostUpdateModal={showPostUpdateModal}
                    username={campaign.username}


                />



                <CampaignComments
                    comments={comments}
                    getComments={getComments}
                    rowStyle={rowStyle}
                />


            </Stack>
        </Col>
    )
}

export default CampaignPageMobileStack
