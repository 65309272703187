import React, { useState } from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useUser } from './contexts/UserProvider';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Spinner from 'react-bootstrap/Spinner';
// import { useTheme } from './contexts/ThemeProvider'
import CrowdPostLogo from './components/CrowdPostLogo'
import Offcanvas from 'react-bootstrap/Offcanvas';
import ProfilePhoto from './components/ProfilePhoto'



function Header() {

    const { user, logout, createCampaign } = useUser();


    const [isOffcanvasOpen, setIsOffcanvasOpen] = useState(false);
    const darkTheme = 'dark'
    // const { darkTheme } = useTheme()
    const location = useLocation();
    const isCurrentPage = (pathname) => {
        return location.pathname === pathname;
    };

    const handleOffcanvasToggle = () => {
        setIsOffcanvasOpen(!isOffcanvasOpen);
    };

    const handleOffcanvasClose = () => {
        setIsOffcanvasOpen(false);
    };


    const sideBarLinkStyle = {
        fontSize: "1.5rem",
        fontWeight: "400",
        letterSpacing: "0",
        lineHeight: "1.2",
        paddingLeft: '0px!important',
    }
    const sideBarSubStyle = "text-secondary fs-6"

    const sideBarButton = {
        width: '100%',
        fontSize: '1.5rem',
        marginBottom: '.5rem',
        borderRadius: '1rem',
        color: 'white'
    }
    const navigate = useNavigate();

    const getClass = (name) => `hoverItem ${darkTheme ? 'text-white' : 'text-dark'} ${isCurrentPage(name) && 'is_active'}`



    const handleNewCampaign = async () => {

        const x = await createCampaign();
        if (x === true) {


            navigate('/new_campaign/campaign_type', { replace: true })

        }
    }

    // useEffect(() => {
    //     setIsOffcanvasOpen(false);
    // }, [location.pathname]);


    return (
        <Navbar collapseOnSelect={true} expand="lg" fixed='sticky' >
            <Container fluid>

                <Navbar.Brand ><Nav.Link as={NavLink} to="/"><CrowdPostLogo /></Nav.Link></Navbar.Brand>
                <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleOffcanvasToggle} />
                <Navbar.Offcanvas
                    id={`offcanvasNavbar-expand`}
                    aria-labelledby={`offcanvasNavbarLabel-expand`}
                    placement="end"
                    show={isOffcanvasOpen}
                    onHide={handleOffcanvasClose}


                >
                    <Offcanvas.Header closeButton>
                        <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
                            <h3 className="gradient-text"><strong>CrowdPost</strong></h3>
                        </Offcanvas.Title>
                    </Offcanvas.Header>


                    <Offcanvas.Body className=''>

                        <Nav className="me-auto d-flex align-items-center">

                            <Nav.Item className="learn_more_header hoverItem" style={isOffcanvasOpen ? { width: '100%', } : { padding: 0 }} >
                                <NavDropdown
                                    style={{}}
                                    active
                                    // className={isOffcanvasOpen ? getClass('/how-it-works') : ''}

                                    title={<span style={isOffcanvasOpen ? sideBarLinkStyle : {}}  >Learn more</span>}
                                    id="basic-nav-dropdown"
                                // onMouseEnter={handleMouseEnter}
                                // onMouseLeave={handleMouseLeave}
                                // Pass isOpen state to the show prop
                                >
                                    <NavDropdown.Item ><Nav.Link eventKey="8" as={NavLink} to="/common-questions">Common Questions</Nav.Link></NavDropdown.Item>
                                    <NavDropdown.Item ><Nav.Link eventKey="9" as={NavLink} to="/creating-campaigns">Creating campaings</Nav.Link></NavDropdown.Item>
                                    <NavDropdown.Item ><Nav.Link eventKey="9" as={NavLink} to="/ai-images">Generate AI images</Nav.Link></NavDropdown.Item>
                                    <NavDropdown.Item ><Nav.Link eventKey="10" as={NavLink} to="/contributing-to-campaigns">Contributing to campaigns</Nav.Link></NavDropdown.Item>
                                    <NavDropdown.Item ><Nav.Link eventKey="11" as={NavLink} to="/what-can-be-posted">What can be posted</Nav.Link></NavDropdown.Item>
                                    <NavDropdown.Item ><Nav.Link eventKey="12" as={NavLink} to="/pricing">Pricing</Nav.Link></NavDropdown.Item>
                                </NavDropdown>
                                {
                                    isOffcanvasOpen && <p className={sideBarSubStyle}>Contributing to and creating campaigns</p>
                                }
                            </Nav.Item>

                            <Nav.Item className={isOffcanvasOpen ? getClass('/how-it-works') : ''} style={isOffcanvasOpen ? { width: '100%' } : {}} >
                                <Nav.Link style={isOffcanvasOpen ? sideBarLinkStyle : {}} className={!isOffcanvasOpen ? getClass('/how-it-works') : ''} eventKey="0" as={NavLink} to="/how-it-works">How it works</Nav.Link>
                                {
                                    isOffcanvasOpen && <p className={sideBarSubStyle}>Contributing to and creating campaigns</p>
                                }
                            </Nav.Item>




                            <Nav.Item className={isOffcanvasOpen ? getClass('/explore') : ''} style={isOffcanvasOpen ? { width: '100%' } : {}} >
                                <Nav.Link eventKey="1" style={isOffcanvasOpen ? sideBarLinkStyle : {}} className={!isOffcanvasOpen ? getClass('/explore') : ''} as={NavLink} to="/explore">Explore</Nav.Link>
                                {
                                    isOffcanvasOpen && <p className={sideBarSubStyle}>Explore all CrowdPost campaigns</p>
                                }
                            </Nav.Item>


                            {user === undefined ?
                                <Nav.Item>
                                    <Spinner animation="border" />
                                </Nav.Item>
                                :
                                user !== null &&

                                <Nav.Item className={isOffcanvasOpen ? getClass('/feed') : ''} style={isOffcanvasOpen ? { width: '100%' } : {}}>
                                    <Nav.Link eventKey="2" style={isOffcanvasOpen ? sideBarLinkStyle : {}} className={!isOffcanvasOpen ? getClass('/feed') : ''} as={NavLink} to="/feed">Feed</Nav.Link>
                                    {
                                        isOffcanvasOpen && <p className={sideBarSubStyle}>Your CrowdPost campaign feed</p>
                                    }
                                </Nav.Item>

                            }
                            <Nav.Item className={isOffcanvasOpen ? getClass('/contact') : ''} style={isOffcanvasOpen ? { width: '100%' } : {}}>
                                <Nav.Link eventKey="7" style={isOffcanvasOpen ? sideBarLinkStyle : {}} className={!isOffcanvasOpen ? getClass('/contact') : ''} as={NavLink} to="/contact">Contact</Nav.Link>
                                {
                                    isOffcanvasOpen && <p className={sideBarSubStyle}>Technical support and help</p>
                                }
                            </Nav.Item>
                            {/* <Nav.Item ><Nav.Link eventKey="7" className={getClass('/feedback')} as={NavLink} to="/feedback">Feedback</Nav.Link></Nav.Item> */}
                        </Nav>


                        {
                            user === undefined ?
                                <Nav>
                                    <Nav.Item>
                                        <Spinner animation="border" />
                                    </Nav.Item>
                                </Nav>
                                :
                                user !== null ?

                                    <Nav className="d-flex align-items-center">

                                        {
                                            isOffcanvasOpen ?
                                                <>
                                                    <Nav.Item className="w-100">
                                                        <Nav.Link eventKey="5">
                                                            <Button

                                                                style={sideBarButton}
                                                                onClick={() => handleNewCampaign()}
                                                                variant="success"
                                                            // className={`${darkTheme && 'text-white'} rounded-pill d-flex align-items-center justify-content-center px-4 py-1`} // Apply the Flexbox styles here
                                                            ><strong>Start a Campaign</strong>

                                                            </Button>
                                                        </Nav.Link>
                                                    </Nav.Item>


                                                    <Nav.Item className="profileHeader">

                                                        <div className="w-100 text-center">
                                                            <NavDropdown
                                                                title={
                                                                    <span >
                                                                        <ProfilePhoto
                                                                            avatar_url={user.avatar_url}
                                                                        />
                                                                        <span className="mx-1 fw-bold fs-2">My Account</span>
                                                                    </span>
                                                                }
                                                                align="end"



                                                            >
                                                                <NavDropdown.Item eventKey="3" as={NavLink} to={'/user/' + user.username} >
                                                                    Profile
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Item as={NavLink} to={'/my_campaigns'} >
                                                                    My Campaigns
                                                                </NavDropdown.Item>

                                                                <NavDropdown.Item as={NavLink} to={'/account-balance'} >
                                                                    AI Images & Credits
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Item as={NavLink} to={'/supported_campaigns'} >
                                                                    Supported Campaigns
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Item as={NavLink} to={'/favorite_campaigns'} >
                                                                    Favorite Campaigns
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Item as={NavLink} to="/password">
                                                                    Manage Account
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Divider />
                                                                <NavDropdown.Item onClick={logout}>
                                                                    Logout
                                                                </NavDropdown.Item>
                                                            </NavDropdown>
                                                        </div>
                                                    </Nav.Item>


                                                </>
                                                :

                                                <>
                                                    <Nav.Item>

                                                        <Button


                                                            onClick={() => handleNewCampaign()}
                                                            variant="success"
                                                            className={`${darkTheme && 'text-white'} rounded-pill d-flex align-items-center justify-content-center px-4 py-1`} // Apply the Flexbox styles here
                                                        ><strong>Start a Campaign</strong>

                                                        </Button>

                                                    </Nav.Item>

                                                    <Nav.Item className="profileHeader">

                                                        <div>
                                                            <NavDropdown
                                                                title={
                                                                    <span >
                                                                        <ProfilePhoto
                                                                            avatar_url={user.avatar_url}
                                                                        />
                                                                        <span className="mx-1">My Account</span>
                                                                    </span>
                                                                }
                                                                align="end"


                                                            >
                                                                <NavDropdown.Item eventKey="3" as={NavLink} to={'/user/' + user.username} >
                                                                    Profile
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Item as={NavLink} to={'/my_campaigns'} >
                                                                    My Campaigns
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Item>

                                                                    <Button

                                                                        onClick={() => handleNewCampaign()}
                                                                        variant="outline-success"
                                                                        className={`${darkTheme && 'text-white'} rounded-pill d-flex align-items-center justify-content-center px-4 py-1`} // Apply the Flexbox styles here
                                                                    >Start a Campaign
                                                                    </Button>
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Item as={NavLink} to={'/account-balance'} >
                                                                    AI Images & Credits
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Item as={NavLink} to={'/supported_campaigns'} >
                                                                    Supported Campaigns
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Item as={NavLink} to={'/favorite_campaigns'} >
                                                                    Favorite Campaigns
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Item as={NavLink} to="/password">
                                                                    Manage Account
                                                                </NavDropdown.Item>
                                                                <NavDropdown.Divider />
                                                                <NavDropdown.Item onClick={logout}>
                                                                    Logout
                                                                </NavDropdown.Item>
                                                            </NavDropdown>
                                                        </div>
                                                    </Nav.Item>


                                                </>
                                        }
                                    </Nav>
                                    :
                                    <Nav className="d-flex align-items-center">
                                        {
                                            isOffcanvasOpen ?
                                                <>
                                                    <Nav.Item className="w-100">
                                                        <Nav.Link eventKey="5">
                                                            <Button

                                                                style={sideBarButton}
                                                                onClick={() => handleNewCampaign()}
                                                                variant="success"
                                                            // className={`${darkTheme && 'text-white'} rounded-pill d-flex align-items-center justify-content-center px-4 py-1`} // Apply the Flexbox styles here
                                                            ><strong>Start a Campaign</strong>

                                                            </Button>
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item className="w-100">
                                                        <Nav.Link eventKey="3" as={NavLink} to="/login">

                                                            <Button

                                                                style={sideBarButton}

                                                                variant="outline-primary"
                                                            // className={`${darkTheme && 'text-white'} rounded-pill d-flex align-items-center justify-content-center px-4 py-1`} // Apply the Flexbox styles here
                                                            ><strong>Login</strong>

                                                            </Button>
                                                        </Nav.Link>
                                                    </Nav.Item>
                                                </>
                                                :

                                                <>
                                                    <Nav.Item>
                                                        <Nav.Link eventKey="5">
                                                            <Button


                                                                onClick={() => handleNewCampaign()}
                                                                variant="success"
                                                                className={`${darkTheme && 'text-white'} rounded-pill d-flex align-items-center justify-content-center px-4 py-1`} // Apply the Flexbox styles here
                                                            ><strong>Start a Campaign</strong>

                                                            </Button>
                                                        </Nav.Link>
                                                    </Nav.Item>

                                                    <Nav.Item ><Nav.Link eventKey="3" className={getClass('/login')} as={NavLink} to="/login">Login</Nav.Link></Nav.Item>
                                                    <Nav.Item ><Nav.Link eventKey="9" className={getClass('/register')} as={NavLink} to="/register">Sign Up</Nav.Link></Nav.Item>

                                                </>
                                        }
                                    </Nav>
                        }



                    </Offcanvas.Body>

                </Navbar.Offcanvas>

            </Container>
        </Navbar >

    )
}

export default Header

