import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import FeedbackPage from '../../pages/FeedbackPage';



function FeedbackModal({ showModal, handleCloseModal }) {


    return (
        <Modal fullscreen onHide={() => handleCloseModal(false)} show={showModal}
            // dialogClassName="vw-100"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title><h3 className="fw-bold"></h3></Modal.Title>
            </Modal.Header>
            <Modal.Body className="">

                <FeedbackPage modal />
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => handleCloseModal(false)} variant="secondary">Close</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default FeedbackModal
