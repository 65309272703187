import React from 'react'
import Row from 'react-bootstrap/Row';
import Badge from 'react-bootstrap/Badge';
import GetPrice from './GetPrice';
import Col from 'react-bootstrap/Col';
import { RiUserVoiceLine } from 'react-icons/ri'


function DonorSummary({ donorSummary }) {


    return (
        <div className="my-3">

            {
                donorSummary && donorSummary !== false && <hr />
            }
            
            {
                donorSummary && donorSummary !== false && donorSummary.map((i, v) =>
                    <Row key={v} className="mt-4">
                        <Col xs={12}>

                            <Row className="" >
                                <Col xs={'auto'} className="m-0 p-0">
                                    <Badge bg='none' className="mx-2 rounded-circle p-3" style={{ backgroundColor: 'rgb(240, 240, 240)' }} ><RiUserVoiceLine size={23} color="green" /> </Badge>
                                </Col>
                                <Col style={{ display: 'flex', flexDirection: 'column' }}>

                                    <div className="fs-5">{i.user.charAt(0).toUpperCase() + i.user.slice(1)}</div>
                                    {/* <div className="fs-5">{i.user.charAt(0).toUpperCase() + i.user.slice(1)}</div> */}
                                    <div className="fs-6">
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <div style={{ marginRight: '10px' }}><strong>
                                                <GetPrice priceInCents={i.amount} />
                                            </strong></div>
                                            <div style={{alignItems:'center', marginRight: '10px', color: 'rgba(128, 128, 128, 0.6)' }}>•</div>
                                            <div style={{}}><u>{i.title}</u></div>
                                        </div>
                                    </div>

                                </Col>

                            </Row>


                        </Col>
                    </Row>

                )

            }

        </div>
    )
}

export default DonorSummary
