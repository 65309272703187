import React, { useRef, useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';

import { Link } from 'react-router-dom';
import InputField from './InputField';
import FormButtonBlock from './FormButtonBlock';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import TimeAgo from './TimeAgo';
import TextAreaField from './TextAreaField';


function CampaignOrganizer({ campaign, user }) {
    const [formErrors, setFormErrors] = useState({});
    const api = useApi();
    const flash = useFlash();
    const contactNameField = useRef();
    const contactEmailField = useRef();
    const contactMessageField = useRef();
    const [showContactModal, setShowContactModal] = useState(false);


    const submitContact = (ev) => {
        ev.preventDefault();


        const contactName = contactNameField.current.value;
        const contactEmail = contactEmailField.current.value;
        const contactMessage = contactMessageField.current.value;


        //client sid field validation
        const errors = {};
        if (!contactName) {
            errors.contactName = 'Must include a name';
        }
        if (!contactEmail) {
            errors.contactEmail = 'Must include a reply email';
        }
        if (!contactMessage) {
            errors.contactMessage = 'Must include a message.';
        }

        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        // end client side field validation 
        (async () => {
            const response = await api.post("/contact/" + campaign.url, {
                'contactName': contactName,
                'contactEmail': contactEmail,
                'contactMessage': contactMessage,
            });
            if (response.ok) {
                flash('Your message was sent', 'success');
            }
            else {
                flash('Error sending your message was sent', 'danger');
            }

        })();
        setShowContactModal(false)   //TODO:post it 
    };




    return (

        <Col xs={12}>
            <hr />
            <h3>
                <strong>Organizer</strong>
            </h3>
            {
                <Col xs={12}>
                    <div className="d-flex align-content-center">
                        {
                            campaign.username !== 'anonymous' &&
                            <div className="mx-3">
                                <Image height={50} width={50} src={campaign.avatar_url} roundedCircle />
                            </div>
                        }
                        <div >
                            {
                                campaign.username === 'anonymous' ?
                                    <div>
                                        <h5 className="">
                                            <strong>Posted by:</strong> anonymous
                                        </h5>
                                        <TimeAgo isoDate={campaign.create_date} >Created: </TimeAgo>
                                    </div>
                                    :
                                    <div>
                                        <h5>
                                            <strong>Posted by: </strong>
                                            <Link
                                                to={{ pathname: '/user/' + campaign.username }}
                                            >
                                                {campaign.username}
                                            </Link>
                                        </h5>
                                        <TimeAgo isoDate={campaign.create_date} >Created: </TimeAgo>
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="d-flex">
                        {((!user) || (user && user.username !== campaign.username)) &&
                            <div className="my-3"><Button className="rounded-pill fw-bold" variant="light" onClick={() => setShowContactModal(true)}>Send message</Button></div>

                        }

                    </div>
                </Col>
            }

            <Modal onHide={() => setShowContactModal(false)} show={showContactModal} >
                <Modal.Header closeButton>
                    <h3 className="fw-bold">Contact Organizer</h3>
                </Modal.Header>
                <Modal.Body className="m-4">
                    <Row className="mb-3">
                        <Col xs={12}>
                            <div className="d-flex align-content-center">
                                <div className="">
                                    <Image height={50} width={50} src={campaign.avatar_url} roundedCircle />
                                </div>
                                <div className="mx-3 d-flex align-content-center">
                                    {
                                        campaign.username === 'anonymous' ?
                                            <h5 className="">
                                                <strong>Posted by:</strong> anonymous
                                            </h5>
                                            :
                                            <h5>
                                                <strong>Posted by: </strong>
                                                <Link
                                                    to={{ pathname: '/user/' + campaign.username }}
                                                >
                                                    {campaign.username}
                                                </Link>
                                            </h5>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Form onSubmit={submitContact}>
                                <InputField fieldRef={contactNameField}
                                    name="contactName" label="Name"
                                    error={formErrors.contactName} />
                                <InputField fieldRef={contactEmailField}
                                    name="contactEmail" label="Email" type="email"
                                    error={formErrors.contactEmail} />
                                <TextAreaField
                                    fieldRef={contactMessageField}
                                    name="contactMessage" label="Message" type="textarea"
                                    error={formErrors.contactMessage}
                                    placeholder="Message"
                                    rows={8}
                                />
                                {/* <InputField fieldRef={contactMessageField}
                                    name="contactMessage" label="Message" type="textarea"
                                    error={formErrors.contactMessage} /> */}
                                <FormButtonBlock text={"Contact"} />
                            </Form>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={() => setShowContactModal(false)} variant='secondary'> Close</Button>
                </Modal.Footer>
            </Modal>

        </Col>


    )
}

export default CampaignOrganizer
