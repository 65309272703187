import React from 'react'
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Comment from './Comment';




function CampaignComments({ comments, getComments, rowStyle }) {

    return (
        <div>

            {comments && comments.items.length > 0 &&
                <Col xs={12}>
                    <div style={rowStyle} className="">
                        <hr />
                        <h3><strong>Comments from supporters</strong></h3>
                        <Stack gap={3}>
                            {comments.items.map((v, i) =>
                                <Comment key={i} message={v.message} donor={v.donor} timePosted={v.time_posted} amount={v.amount} />
                            )}
                        </Stack>
                        {
                            comments && comments._links.next && <Button className="rounded-pill mt-5" variant='light' onClick={() => getComments(comments._links.next)}>
                                <strong>Show More...</strong>
                            </Button>
                        }
                    </div>
                </Col>
            }
        </div>
    )
}

export default CampaignComments
