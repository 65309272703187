import React from 'react'

import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import Button from 'react-bootstrap/Button';
import { googleIcon } from '../utils/staticUrls';




function GoogleButton({ text, signUp, roundedPill, outline, }) {


    const api = useApi();
    const flash = useFlash();



    const handleGoogle = async () => {


        const response = await api.get('/register?provider=google');
        if (response.ok) {

            window.location.href = response.body.redirect_url

        }
        else {
            flash('Registration error', 'danger')
        }




    }





    return (
        <div className="googleButton d-grid gap-2">
            <Button
                variant={`${outline ? 'outline-light' : 'light'}`}
                onClick={handleGoogle}

                style={{
                    // backgroundColor: '#fff',
                    // border: '1px solid #dbdbdb',
                    // borderRadius: '4px',
                    // color: 'black',
                    // // display: 'flex',
                    // // alignItems: 'center',
                    // // justifyContent: 'center',
                    // // fontWeight: 500,
                    // padding: '6px 16px',
                    // // width: '100%',
                    // // maxWidth: '280px',
                    // // minHeight: '36px',
                    // fontSize: '1.2rem',
                    // // lineHeight: '1.5',
                    // cursor: 'pointer',
                    // marginBottom: '10px',

                }}
                className={`shadow p-2  btn-block fw-bolder fs-5 ${roundedPill && 'rounded-pill'}`}
            >
                <img
                    src={googleIcon}
                    // src="https://developers.google.com/identity/images/g-logo.png"
                    alt="Google Logo"
                    style={{
                        marginRight: '20px',
                        width: "30px",
                        height: '30px',
                    }}
                    className="rounded"
                />
                {text}
            </Button>
        </div>

    )
}

export default GoogleButton
