import React, { useState, useEffect } from 'react'
import { useApi } from '../contexts/ApiProvider';
import CampaignRow from '../components/CampaignRow';
import LoadingSpinner from '../components/LoadingSpinner';
import Body from '../components/Body';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Spinner from 'react-bootstrap/Spinner';


function MyCampaignsPage() {
    const [campaigns, setCampaigns] = useState();
    const api = useApi();


    useEffect(() => {

        (async () => {
            const response = await api.get('/my_campaigns');
            if (response.ok) {
                setCampaigns(() => response.body);
            }
            else {
                setCampaigns(null);
            }
        })();

    }, [api]);


    return (
        <Body sidebar>
            {/* <Body scrollable classStr={{ height: "100vh" }} sidebar> */}
            <h3 className="pricingSubHeader">My campaigns</h3>
            <Container>
                {
                    campaigns === undefined ?


                        <div className="mt-5 d-flex justify-content-center"><Spinner /></div>


                        :
                        campaigns === null ?
                            <div className="mt-5 d-flex justify-content-center"><h4>You haven't created any campaigns yet</h4></div>

                            :
                            <CampaignRow campaigns={campaigns} />

                }
            </Container>

        </Body>
    )
}

export default MyCampaignsPage
