import React, { useState, useEffect, memo, useRef } from 'react'

import { Link } from 'react-router-dom';
import Col from 'react-bootstrap/Col';
import Placeholder from 'react-bootstrap/Placeholder';
import Card from 'react-bootstrap/Card';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import GetPrice from './GetPrice';
import Favorite from './Favorite'
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import CampaignImageList from './CampaignImageList';
import TimeLeft from './TimeLeft';


export default memo(function Campaign({ campaign, map, isProcessing, handleShowModal, showRaised }) {


    const [marker, setMarker] = useState(null);
    const [animationId, setAnimationId] = useState(null);

    const contributionDate = useRef()

    useEffect(() => {

        const formattedDate = 'contribution' in campaign && new Date(Date.parse(campaign.payment_date));


        contributionDate.current = formattedDate && formattedDate.toLocaleDateString('en-US', { month: 'short', day: '2-digit', year: 'numeric' })


    }, [campaign])


    const bounceMarker = (e, locations) => {

        var newAnimations = []
        var newMarkers = []
        for (let i = 0; i < locations.length; i++) {
            let loc = locations[i]

            let newMarker = new mapboxgl.Marker({ color: 'blue' }).setLngLat([loc['longitude'], loc['latitude']]).addTo(map.current);
            // setMarker(newMarker);

            // Define the marker's animation
            const frequency = 8; // frequency of the bouncing motion
            // const animateMarker = (timestamp) => {
            //     const offset = amplitude * Math.sin(frequency * timestamp / 1000);
            //     newMarker.setLngLat([loc['longitude'], loc['latitude'] + offset]);
            //     setAnimationId([...animationId,requestAnimationFrame(animateMarker)]);
            // };
            const maxAmplitude = 1.5; // maximum amplitude of the bouncing motion

            const animateMarker = (timestamp) => {

                const zoom = map.current.getZoom();
                const amplitude = maxAmplitude / (2 ** zoom); // scale amplitude based on zoom level
                const offset = amplitude * Math.sin(frequency * timestamp / 1000);
                newMarker.setLngLat([loc['longitude'], loc['latitude'] + offset]);
                newAnimations.push(requestAnimationFrame(animateMarker))

            };
            newMarkers.push(newMarker)
            newAnimations.push(requestAnimationFrame(animateMarker))

            // Start the marker's animation
        }
        setAnimationId(newAnimations)
        setMarker(newMarkers)
    }

    const stopAnimation = () => {
        if (animationId) {
            animationId.map(i => cancelAnimationFrame(i))
            marker.map(i => i.remove())
        }
        // cancelAnimationFrame(animationId);

    };


    useEffect(() => {

        return () => {
            marker && marker.map(i => i.remove())
        }


    }, [marker])

    const handleClick = () => {
        //This sets the campaign ID into the localstorage campaignID field so that the newCampaign can be identified. 
        campaign.status === "Draft" && window.localStorage.setItem('campaignID', campaign.id);

    }

    return (
        isProcessing ?

            <Col className="mt-3" xs={12} xxl={6}>
                <Card >

                    <Placeholder className="rounded" style={{ width: '100%', height: '180px', backgroundColor: '#e7e7e7' }} size="lg" as={Card.Img} animation="glow" />

                    <Card.Body>
                        <Placeholder as={Card.Title} animation="glow">
                            $ <Placeholder className="rounded" xs={3} /> raised
                        </Placeholder>
                        <Placeholder className="rounded" as={Card.Text} animation="glow">
                            Posted <Placeholder xs={4} className="rounded" /> ago by <Placeholder xs={4} className="rounded" />

                        </Placeholder>
                    </Card.Body>
                </Card>
            </Col>
            :
            <Col onClick={handleClick} className="my-3 " onMouseLeave={(e) => map && stopAnimation(e)} onMouseEnter={(e) => map && bounceMarker(e, campaign.locations)} xs={12} xxl={`12`} >

                <div className="mb-2">
                    <Link
                        style={{ color: "black", textDecoration: "none" }}
                        to={{ pathname: campaign.status === "Draft" ? '/new_campaign/campaign_type' : '/campaign/' + campaign.url }}
                        state={{ campaign: campaign }}
                    >
                        <CampaignImageList
                            images={campaign.image_url}
                        />
                        {/* <CampaignImage status={campaign.status} image={campaign.image_url} description={campaign.description} campaign_id={campaign.id} isLiked={campaign.is_liked} /> */}
                    </Link>
                </div>


                <div className="mt-sm-2 mt-lg-0">
                    {/* <div className="px-3"> */}

                    <div>
                        <div>
                            <Favorite campaignId={campaign.id} isLiked={campaign.is_liked} />

                            <Row className="mt-2">
                                {
                                    'contribution' in campaign &&
                                    <Col xs={12}>

                                        <Row>
                                            <Col xs="auto" className="d-flex">
                                                <strong className="mx-1">Max contribution: </strong> <GetPrice priceInCents={campaign.contribution} userPayment={true} />
                                            </Col>

                                            {'adjusted_amount' in campaign && campaign['adjusted_amount'] !== false ?
                                                <>
                                                    |
                                                    <Col xs="auto" className="d-flex">
                                                        <strong className="mx-1">Amount Charged: </strong> <GetPrice priceInCents={campaign.adjusted_amount} userPayment={true} />
                                                    </Col>

                                                </>
                                                :
                                                <>
                                                    |
                                                    <Col xs="auto" className="d-flex">
                                                        <strong>Payment has not been processed yet</strong>
                                                    </Col>

                                                </>
                                            }
                                        </Row>
                                    </Col>

                                }
                                <Col xs="auto">
                                    <GetPrice priceInCents={campaign.current_amount} > <span>raised </span>

                                        <span>
                                        	<TimeLeft isoDate={campaign.start_date} endText={<span className=""> left to contribute</span>} hideCompleteMessage/>
                                        </span>
                                    </GetPrice>


                                </Col>
                                |
                                <Col xs="auto">
                                    <strong>Status:</strong> <span className="text-muted">{campaign.status}</span>
                                </Col>

                                <div>
                                    <strong>Type:</strong> <span className="text-muted">{campaign.campaign_mode ? `${campaign.campaign_mode} campaign` : 'Not set'}</span>
                                </div>
                                {
                                    campaign && campaign.status === "Draft" && <div>
                                        <Button onClick={() => handleShowModal(campaign.id)} className="mt-2" variant='danger'>
                                            <strong>Delete campaign</strong>
                                        </Button>
                                    </div>
                                }

                            </Row>
                            {/* <div className="d-inline">
                                <GetPrice priceInCents={campaign.current_amount} />
                                <strong>Status:</strong> {campaign.status}
                            </div> */}

                        </div>
                        <div>
                        </div>
                    </div>
                    <div>
                        {
                            contributionDate.current &&
                            <span > <strong>Contribution Date:</strong>  <span className="text-muted">{contributionDate.current}</span></span>

                        }
                    </div>

                    {
                        campaign.status !== "Draft" &&
                        < div style={{ display: "flex" }}>



                            {
                                campaign.username !== 'anonymous' ?
                                    <div className="">
                                        <span style={{ marginLeft: "3px", marginRight: "3px" }}><strong>Posted by: </strong></span>
                                        <Link
                                            to={{ pathname: '/user/' + campaign.username }}

                                        >
                                            {campaign.username}
                                        </Link>
                                    </div >
                                    :
                                    <span className="text-muted" style={{ marginLeft: "3px", marginRight: "3px" }}> by <strong>anonymous</strong></span>
                            }

                        </div>
                    }


                </div>



            </Col >


    )
});




