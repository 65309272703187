import React, { useRef, useEffect } from 'react'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
// import { FaGear } from "react-icons/fa6";


import EmojiPicker from './EmojiPicker';

import { useImageBuilder } from '../contexts/ImageBuilderProvider';
import { FaFaceSmile } from "react-icons/fa6";
import FontEditor from './FontEditor';
import ReactQuill, { Quill } from 'react-quill';
import NewCampaignImageBackground from './NewCampaignImageBackground';
import 'react-quill/dist/quill.snow.css'; // Import styles
import '../styles/QuillToolbar.css'
import ClickHoverPopover from './ClickHoverPopover';



function PostMessageMedia({ image_data, mediaName, formHandlers, imageHandlers, handleEmojiUpdate, height, fontHandlers, mediaRef, minFontSize, handleQuillChange, handleImageHover, value, quillRef, isMounted, imgWidth, messageWidth }) {

    const { handleFileInputChange } = formHandlers;

    const { handleUpdateRespData, handleUpdateBaseMessage } = useImageBuilder()
    const popover2 = useRef()
    const popover3 = useRef()

    const Font = Quill.import('formats/font');
    Font.whitelist = ['sans-serif', 'serif', 'monospace', 'courier-new', 'times-new-roman', 'georgia', 'tahoma', 'verdana', 'trebuchet-ms'];
    // const Size = Quill.import('attributors/style/size');
    const Size = ReactQuill.Quill.import('formats/size');
    const fontSizeArray = [];
    for (let i = 0.5; i <= 5; i += 0.25) {
        fontSizeArray.push(`${parseFloat(i.toFixed(2))}em`);
    }
    Size.whitelist = ['small', 'medium', 'large', 'xlarge', 'xxlarge', 'huge']; // Define the sizes you want

    Quill.register(Size, true);

    Quill.register(Font, true);




    const modules = {
        toolbar: {
            container: [
                [{ 'font': Font.whitelist }],
                [{ 'size': Size.whitelist }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'align': [] }],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                ['clean']
            ],
        }
    };

    const formats = [
        'font', 'size', 'bold', 'italic', 'underline', 'strike',
        'color', 'background', 'align', 'list', 'bullet', 'clean'
    ];

    const textShadow = `
    ${image_data['base_message']['outline_width']}em 0px ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    0px ${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    -${image_data['base_message']['outline_width']}em 0px ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    0px -${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    -${image_data['base_message']['outline_width']}em -${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    -${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    ${image_data['base_message']['outline_width']}em -${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
    ${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}
`

    // useEffect(() => {
    //     if (quillRef.current) {
    //       const editor = quillRef.current.getEditor(); // Get the Quill editor instance

    //       if (editor && editor.clipboard) {
    //         // Set HTML content when the component is loaded
    //         editor.clipboard.pasteHTML(image_data['base_message']['html']); // Set HTML content
    //       }
    //     }
    //   }, [image_data]);

    useEffect(() => {
        // Ensure the editor is fully initialized before setting HTML content
        // var timeout;

        if (isMounted && quillRef.current) {
            var editor = quillRef.current.getEditor(); // Get the Quill editor instance

            if (editor) {
                // timeout = setTimeout(() => {


                // const quill = quillRef.current.getEditor(); // Get the Quill editor instance
                // quill.format('size', 'Huge'); // Set the default font size to medium
                // console.log(quill)




                editor.format('size', 'medium'); // Set the default font size to medium
                // editor.root.style.caretColor = 'white'; // Set the cursor color to white
                // editor.root.style.textShadow = '0 0 2px #000000'; // Add a 
                editor.clipboard.dangerouslyPasteHTML(image_data['base_message']['html'])


                // }, 1000); // Delay of 100ms to ensure the editor is mounted
            }
        }

        // return () => clearTimeout(timeout); // Clear timeout if component unmounts


    }, [isMounted]); // Ensure effect runs only once when editor is ready


    const quillEditorStyle = image_data['image']['is_gradient'] === true ?

        {
            background: `linear-gradient(${image_data['image']['gradient_deg']}deg, ${image_data['image']['gradient_color_1']}, ${image_data['image']['gradient_color_2']})`,
            borderTopRightRadius: ".5em",
            borderTopLeftRadius: "1em",
            borderLeft: '.75px solid #ccc',
            borderRight: '.75px solid #ccc',
            // height: height + 60,
            height: height + 60,
            minHeight: '250px',
            color: `${image_data['base_message']['color']}`,
            width: imgWidth,
        }
        :
        {
            backgroundColor: image_data['image']['background_color'],
            borderTopRightRadius: ".5em",
            borderTopLeftRadius: "1em",
            borderLeft: '.75px solid #ccc',
            borderRight: '.75px solid #ccc',
            // height: height + 60,
            height: height + 60,
            minHeight: '250px',
            color: `${image_data['base_message']['color']}`,
            width: imgWidth,
        }


    return (

        <Form>
            <Row className="bg-white m-0 p-0 d-flex justify-content-center">
                <Col xs={12} className="d-flex justify-content-center">

                    { //Notes: isMounted delays creation of quill to prevent autofocus delay
                        isMounted &&

                        <Form.Group className="mb-5 ">
                            <Form.Label
                                className="m-3 text-dark"

                            ><strong>Your CrowdPost message</strong></Form.Label>





                            <div

                                style={{
                                    fontFamily: `${image_data['base_message']['font_family']}`,
                                    textShadow: textShadow,


                                    fontSize: minFontSize + 'px',
                                    position: 'relative',
                                    borderTopRightRadius: '.5rem',
                                    borderTopLeftRadius: '.5rem',
                                    // height: height,
                                    backgroundColor: image_data['image']['is_gradient'] === true || !image_data['image']['background_color'] ? 'white' : image_data['image']['background_color'],
                                    background: image_data['image']['is_gradient'] === true ? `linear-gradient(${image_data['image']['gradient_deg']}deg, ${image_data['image']['gradient_color_1']}, ${image_data['image']['gradient_color_2']})` : 'transparent',

                                    color: `${image_data['base_message']['color']}`,

                                }}>


                                <ReactQuill
                                    // value={value}
                                    // tabIndex={-1} // Control focusability
                                    onChange={handleQuillChange}
                                    ref={quillRef}
                                    modules={modules}
                                    formats={formats}
                                    style={quillEditorStyle}
                                />




                                <div className="d-flex justify-content-start bg-white"
                                    style={{
                                        borderBottomRightRadius: '.5rem',
                                        borderBottomLeftRadius: '.5rem',
                                        borderTop: 'rgb(230, 221, 221) solid 1px',
                                        position: "absolute",
                                        top: "100%", /* 100% from the top of the parent element */
                                        left: "0",
                                        width: "100%",
                                    }}
                                >
                                    <ClickHoverPopover color="outline-dark" tooltipMsg="Message settings">

                                        <FontEditor handleImageHover={handleImageHover} index={"base_message"} msgData={image_data['base_message']} mediaName={mediaName} handleUpdateRespData={handleUpdateRespData} mediaRef={mediaRef} fieldRef={popover3} message_index="base_message" fontHandlers={fontHandlers} image_data={image_data} height={height} handleMessageWidthChange={imageHandlers['handleMessageWidthChange']} messageWidth={messageWidth} />
                                    </ClickHoverPopover>
                                    {/* <OverlayTrigger rootClose={true} trigger="click" placement="right" overlay={<div>

                                        <FontEditor handleImageHover={handleImageHover} index={"base_message"} msgData={image_data['base_message']} mediaName={mediaName} handleUpdateRespData={handleUpdateRespData} mediaRef={mediaRef} fieldRef={popover3} message_index="base_message" fontHandlers={fontHandlers} image_data={image_data} height={height} handleMessageWidthChange={imageHandlers['handleMessageWidthChange']} messageWidth={messageWidth} />

                                    </div>}>
                                        <Button variant="light"><FaGear size={24} /></Button>
                                    </OverlayTrigger> */}

                                    <OverlayTrigger rootClose={true} trigger="click" placement="top" overlay={<div>
                                        <EmojiPicker ref={popover2} handleEmojiUpdate={handleEmojiUpdate} index={'base'} mediaName={mediaName} />
                                    </div>}>
                                        <Button className="mx-4" variant="outline-dark"><FaFaceSmile size={24} /></Button>
                                    </OverlayTrigger>
                                </div>


                            </div>







                        </Form.Group>

                    }
                </Col>




            </Row>
            <Row >
                <Col className="d-flex align-items-center my-4" xs={"12"}>
                    <Form.Check type={"checkbox"} id="include_name">
                        {/* <Form.Check onChange={(e) => handleUpdateRespData(mediaName, e, 'base_message')} checked={image_data.base_message.include_name} type={"checkbox"} /> */}
                        <Form.Check onChange={(e) => handleUpdateBaseMessage(mediaName, { include_name: e.target.checked })} checked={image_data.base_message['include_name']} type={"checkbox"} />
                        <Form.Check.Label>{<strong>{"Include name and username"}</strong>}</Form.Check.Label>
                    </Form.Check>
                </Col>
                <NewCampaignImageBackground
                    image_data={image_data}
                    mediaName={mediaName}
                    imageHandlers={imageHandlers}
                    handleUpdateRespData={handleUpdateRespData}
                    handleFileInputChange={handleFileInputChange}
                    message_index="base_message"
                />
            </Row>


        </Form >

    )
}

export default PostMessageMedia
