import React, { useState, useEffect, useRef } from 'react';

import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image';
import Button from 'react-bootstrap/Button';
import NewCampaignPage from './NewCampaignPage';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import { useNavigate } from 'react-router-dom';
import { toPng } from 'html-to-image';
import LoadingNewCampaignImage from '../pages/NewCampaign/LoadingNewCampaignImage'
import ImageBuilder from './ImageBuilder';
import { useImageBuilder } from '../contexts/ImageBuilderProvider';
import MockupButtons from './MockupButtons';
import ImageGuidelineModal from './modals/ImageGuidelineModal';
import useTaskTimer from '../utils/useTaskTimer'
import HelpPopover from './HelpPopover';


const NewCampaignImage = () => {

    const { respData, taskIds, handleUpdateTaskIds, handleRespDataChange, disabled, handleDisabledChange, isMockupLoading, handleMockupLoading, modalData, showModal, handleShowModalChange, handleShowModal } = useImageBuilder()



    const [mode, setMode] = useState()
    const api = useApi();

    const flash = useFlash();


    const navigate = useNavigate();


    const mediaRefs = useRef([]);

    const [showFAQModal, setShowFAQModal] = useState(false);
    const handleShowFAQModal = (data) => {
        setShowFAQModal(data)
    }

    const [isMounted, setIsMounted] = useState(false);


    const timer = useTaskTimer();


    useEffect(() => {
        // Delay rendering by 100ms (adjust as needed)
        document.body.style.overflow = 'auto';
        setTimeout(() => {
            setIsMounted(true);
        }, 1500);
    }, []);



    function handleSubmit(isSample) {
        try {
            // handleDisabledChange(true);

            isSample && handleMockupLoading(true);


            (async () => {
                handleDisabledChange(true);
                let finalState = { ...respData };

                for (const key of mediaRefs.current) {
                    if (key.current) {
                        // let node = document.getElementById(key.current.id);

                        // console.log(key.current.id, key, 'refs from handle submit');
                        let node = key.current;
                        await toPng(node);
                        await toPng(node);
                        await toPng(node);
                        let mediaKey = key.current.id
                        const dataUrl = await toPng(node, { includeQueryParams: true, cacheBust: true, backgroundColor: finalState['image_types'][mediaKey]['image']['background_color'], });


                        finalState = {
                            ...finalState,
                            'image_types': {
                                ...finalState['image_types'],
                                [mediaKey]: {
                                    ...finalState['image_types'][mediaKey],
                                    image: {
                                        ...finalState['image_types'][mediaKey]['image'],
                                        final_url: dataUrl
                                    }
                                }
                            }
                        };
                    }
                };



                const response = await api.post(isSample ? `/new_campaign/sample-mockup` : `/new_campaign/image`, JSON.stringify({ image_types: finalState.image_types }));
                if (response.ok) {
                    handleDisabledChange(false);
                    //FLASK SUCCESS
                    if ('task_id' in response.body && response.body.task_id) {
                        if (isSample) {
                            handleMockupLoading(true);
                            handleDisabledChange(false);
                            flash(`Your${mode && ` ${mode.toLowerCase()}`} preview is processing`, 'primary')
                            handleUpdateTaskIds([...taskIds, response.body])
                            // window.localStorage.setItem('sampleMockupTaskId', response.body.task_id)
                            // handleTaskTimer();

                        }
                        else {
                            flash(`Your${mode && ` ${mode.toLowerCase()}`} mockup is processing`, 'primary');
                            handleMockupLoading(false);
                            handleDisabledChange(false);
                            handleUpdateTaskIds([...taskIds, response.body])
                            // window.localStorage.setItem('currentMockupTaskId', response.body.task_id);
                            return navigate(`/new_campaign/start_date`, { replace: true })
                        }

                    }

                }
                else {
                    response.body && 'errors' in response.body ? flash(response.body.errors.json.message, 'danger') : flash("Error with request", 'danger')

                    handleDisabledChange(false);
                    handleMockupLoading(false);


                }


            })();





        }
        catch (error) {
            console.error('Oops, something went wrong!', error);
            handleMockupLoading(false);
            handleDisabledChange(false);

        }


    }



    useEffect(() => {
        if (taskIds) {

            let resp = timer();

        }
    }, [taskIds])




    useEffect(() => {

        (async () => {
            const response = await api.get(`/new_campaign/image`);
            if (response.ok) {

                'mode_name' in response.body && setMode(response.body.mode_name)

                handleRespDataChange({ data: { ...response.body } })
                handleUpdateTaskIds(response.body.task_ids)

            }
            else {

                'errors' in response.body ?
                    flash(response.body.errors.json.message, 'danger')
                    :
                    flash("Error with request", 'danger')
                navigate(`/new_campaign/campaign_type`);
            }
        })();

    }, [api, flash])




    const handleCloseModal = () => handleShowModalChange(false);

    const imagesPopover = <ul>
        <li ><strong>Use CrowdPost's AI to generate a background image from a text description</strong> (user account needed). </li>
        <li className="">
            <a href={"https://www.si.edu/OpenAccess"} target="_blank" rel="noopener noreferrer">
                Smithsonian historical images
            </a>

        </li>
        <li className="">
            <a href={"https://unsplash.com/"} target="_blank" rel="noopener noreferrer">
                Unsplash free use images
            </a>

        </li>
        <li className="">
            <a href={"https://pixabay.com/"} target="_blank" rel="noopener noreferrer">
                Pixabay free use images
            </a>

        </li>
        <li className="">
            <a href={"https://isorepublic.com/"} target="_blank" rel="noopener noreferrer">
                ISO Republic free use images
            </a>

        </li>
        <li className="">
            By agreeing to our terms of service when posting you agree that you are responsible for not using copywritten material and if you do make sure to have permission.
        </li>
    </ul>

    const header = "How to create your media:"
    const pageDescription = <div className="">
        <ul>
            <li><p>The canvas represents the display size of your media type.</p></li>

            <li><p>The <span className="text-success"><strong>preview mockup button</strong></span> will generate an example of how your design could look on a <span className="fw-bolder"> {mode && mode.toLowerCase()}</span>.</p></li>
            <li><p><strong>You must have permission to use or be the creator of the media you submit.</strong></p></li>

            <li>
                <HelpPopover popoverBody={imagesPopover}>
                    Where to find non-copywritten images
                </HelpPopover>
            </li>
            <li><p>For more information on image criteria click <Button className="" variant="link" onClick={() => setShowFAQModal(true)}>here</Button></p></li>
            {/* <li><p>For more information on image criteria click <Link to={'/what-can-be-posted'}>here</Link></p></li> */}
            <hr />
            <MockupButtons handleShowModal={handleShowModal} respData={respData} handleSubmit={handleSubmit} />

        </ul >

    </div >

    const HELP_HEADER = <h6>How to set your media</h6>



    const addMediaRef = (ref) => {
        // alert('ref change');
        if (ref.current && !mediaRefs.current.includes(ref)) {
            mediaRefs.current.push(ref);

        }
        if (ref.current && mediaRefs.current.includes(ref)) {
            const index = mediaRefs.current.indexOf(ref);
            mediaRefs.current[index] = ref;


        }



    };


    return (
        <NewCampaignPage helpHeader={HELP_HEADER} header={header} title={mode ? `Create media for your ${mode.toLowerCase()} campaign` : 'Submit media for your campaign'} description={pageDescription} handleSubmit={() => handleSubmit(false)} disabled={disabled || isMockupLoading ? true : false} currentStep={1} >



            {
                !respData ?
                    <LoadingNewCampaignImage />
                    :
                    <>
                        <Modal size="lg" show={showModal} onHide={handleCloseModal}>
                            <Modal.Header closeButton><strong>{modalData && modalData['header']}</strong></Modal.Header>
                            <Modal.Body>

                                {
                                    modalData ?
                                        modalData.image ?
                                            modalData.image.map((i, index) =>
                                                <Image key={index} fluid src={i.image} />
                                            )
                                            :
                                            <div>No imgs</div>
                                        :
                                        <div>None</div>
                                }

                            </Modal.Body>
                        </Modal>

                        <ImageGuidelineModal
                            showModal={showFAQModal}
                            setShowModal={handleShowFAQModal}

                        />

                        <ImageBuilder


                            handleShowModal={handleShowModal}
                            handleSubmit={handleSubmit}
                            mediaRefs={mediaRefs}
                            addMediaRef={addMediaRef}
                            isMounted={isMounted}
                        />

                        <div className="mb-5">

                        </div>
                    </>
            }
        </NewCampaignPage>

    );
};

export default NewCampaignImage;
