import React, { useState } from 'react'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Stack from 'react-bootstrap/Stack';
import { GoSidebarCollapse } from "react-icons/go";
import { GoSidebarExpand } from "react-icons/go";
import NewCampaignBreadcrumb from './NewCampaignBreadcrumb'
// import { useTheme } from '../contexts/ThemeProvider'
import { Helmet } from 'react-helmet';
import FlashMessage from "../components/FlashMessage"
import NewCampaignInstructionsAccordion from '../pages/NewCampaign/NewCampaignInstructionsAccordion';
import "../styles/NewCampaignSidebarLayout.css"

import FeedbackButton from './FeedbackButton';

function NewCampaignPage({ children, title, description, helpHeader, handleSubmit, disabled, campaignId, currentStep }) {

    const darkTheme = true
    // const { darkTheme } = useTheme()
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const handleShowFeedbackModal = (data) => setShowFeedbackModal(data);
    const [isSidebarVisible, setSidebarVisible] = useState(true);

    const toggleSidebar = () => {
        setSidebarVisible(!isSidebarVisible);
    };


    // /todo make a context with the state of the sidebar and the controls. accept it in the new campaign page and the image builder. maybe the confirmation comp to. 

    return (
        <>
            <Container fluid>
                <FlashMessage />
            </Container>
            <Container fluid className="m-sm-0 p-sm-0" style={{ overflowX: "hidden" }}>

                <Helmet>
                    <title>CrowdPost - New Campaign</title>
                    <meta property="og:title" content={`Let your voice reach the real town square`} />
                    <meta property="og:type" content="website" />
                    {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                    {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
                    <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." />

                </Helmet>



                <Row >
                    <Col
                        className="d-none d-xl-block py-3 mx-2"
                        xs={12}
                    // lg={4}
                    // xxl={3}
                    // style={{
                    //     // padding: "50px",
                    //     fontWeight: '400',
                    //     position: 'sticky',
                    //     top: 0,

                    // }}
                    >
                        {<NewCampaignBreadcrumb currentStep={currentStep} campaignId={campaignId} />}



                    </Col>


                    <Stack className="flex-sm-column flex-xl-row " direction="vertical">
                        {/* 
                <Stack className="flex-sm-column flex-lg-row" style={{ alignItems: 'baseline' }} direction="vertical"> */}

                        <Col
                            xl={3}
                            className={`sidebar d-none d-xl-flex ${isSidebarVisible ? 'd-block' : 'hidden'}`}>
                            <Col
                                xs={12}
                                className={`py-3 ${isSidebarVisible ? 'd-block' : 'hidden'} p-2`}

                                style={{
                                    // padding: "50px",
                                    fontWeight: '400',
                                    position: 'sticky',
                                    top: 0,
                                }}
                            >
                                {/* {<NewCampaignBreadcrumb currentStep={currentStep} campaignId={campaignId} />}
                                <hr /> */}
                                {isSidebarVisible &&
                                    <div className="d-flex justify-content-end">
                                        <div className="d-block ">
                                            <div className="d-flex justify-content-center">
                                                <GoSidebarExpand size={32} onClick={toggleSidebar} />
                                            </div>
                                            <div style={{ fontSize: '.75em' }} className="text-muted" > Close sidebar</div>
                                        </div>
                                    </div>
                                }
                                <h1 className="fw-bolder" style={{ fontWeight: '400', }}>{title}</h1>
                                <div className="fs-5 fw-light" style={{ marginTop: '1rem' }}>{description}</div>
                                <FeedbackButton />
                            </Col>
                        </Col>


                        <Col
                            xs={12}
                            style={{
                                // padding: "50px",
                            }}
                            className="d-sm-block d-xl-none mx-2"

                        >

                            {<NewCampaignBreadcrumb currentStep={currentStep} campaignId={campaignId} />}

                            {/* <h1 className="text-center fw-bolder" style={{ fontWeight: '400', }}>{title}</h1> */}



                            {/* {
                            <div>
                                <hr />
                                <h4>{description}</h4>

                            </div>
                        } */}
                        </Col>


                        <Col
                            xs={12}
                            // lg={8}
                            xl={isSidebarVisible ? { offset: 0, span: 9 } : 12}
                            className="vh-100 newCampaignLayout d-flex flex-column shadow main-content"
                            style={{
                                borderTopLeftRadius: "2%",
                                // overflow: currentStep === 1 ? 'none' : 'scroll',


                            }}>

                            {/* {!isSidebarVisible ?
                            <div className="d-flex justify-content-start">
                                <div className="d-block">
                                    <div className="d-flex justify-content-center">
                                        <GoSidebarCollapse size={32} onClick={() => toggleSidebar()} />
                                    </div>
                                    <div className="mb-5 sub-sup-font-size text-muted">Open sidebar</div>
                                </div>
                            </div>
                            :
                            <div style={{ paddingTop: '2rem' }} />
                        } */}
                            {/* <Button onClick={() => toggleSidebar()} >{isSidebarVisible ? 'close' : 'open'}</Button> */}
                            {/* {!isSidebarVisible &&
                            <div className="w-100 text-center mb-3">
                                <h1 className="fw-bolder" style={{ fontWeight: '400', }}>{title}</h1>
                                <hr />
                            </div>

                        } */}

                            {!isSidebarVisible ?
                                <div className="d-flex justify-content-start ">
                                    <div className="d-block">
                                        <div className="d-flex justify-content-center">
                                            <GoSidebarCollapse size={32} onClick={() => toggleSidebar()} />
                                        </div>
                                        <div style={{ fontSize: '.75em' }} className="mb-2 sub-sup-font-size text-muted">Open sidebar</div>
                                    </div>
                                </div>
                                :
                                <div style={{ paddingTop: '2rem' }} />
                            }
                            <h1 className="text-center fw-bolder  d-xl-none" style={{ fontWeight: '400', }}>{title}</h1>

                            <div className="d-block d-xl-none mb-3">
                                <NewCampaignInstructionsAccordion helpHeader={!helpHeader ? title : helpHeader}>
                                    {description}
                                    <FeedbackButton />

                                </NewCampaignInstructionsAccordion>
                            </div>
                            <div className="mt-3">

                            </div>
                            {children}

                            <Col className="" xs={12}>
                                <Row className="d-flex justify-content-end">

                                    {
                                        currentStep !== 5 && <Col className="d-flex justify-content-end m-4" xl={6}>

                                            <Button onClick={handleSubmit} disabled={disabled} className="rounded shadow py-3 px-5 mr-3 " variant={darkTheme ? 'light' : 'dark'} type="button" size="lg"><strong>Continue</strong></Button>

                                        </Col>
                                    }
                                </Row>
                            </Col>


                        </Col>




                    </Stack>


                </Row>





            </Container >
        </>
    )
}

export default NewCampaignPage
