import React, { useState, useEffect } from 'react'
import YoutubeVideo from '../components/YoutubeVideo'
import { useParams, useNavigate } from 'react-router'
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';
import LoadingSpinner from '../components/LoadingSpinner';
import Body from '../components/Body';


function SampleCampaignVideoPage() {
    const { campaignId } = useParams()
    const navigate = useNavigate();
    const flash = useFlash();
    const api = useApi();
    const [youtubeData, setYoutubeData] = useState()


    useEffect(() => {
        if (campaignId) {

            (async () => {

                const response = await api.get(`/youtube-sample/${campaignId}`);
                if (response.ok) {
                    setYoutubeData(response.body.data)
                }
                else {
                    if (response && 'errors' in response.body) {
                        flash(response.body.errors.json.message, 'danger')
                    }
                    navigate('/')

                }

            })();

        }
    }, [api])


    return (
        <Body>
            {
                !youtubeData ?

                    <LoadingSpinner />
                    :
                    <div>
                        {

                            'title' in youtubeData && youtubeData['title'] &&
                            <h1>{youtubeData['title']}</h1>
                        }
                        {

                            'message' in youtubeData && youtubeData['message'] &&
                            <span className="text-secondary fs-4">{youtubeData['message']}</span>
                        }
                        <div className="mb-5"></div>
                        <YoutubeVideo

                            url={youtubeData['youtube_url']}
                            title={youtubeData['title']}
                        />

                    </div>
            }
        </Body>
    )
}

export default SampleCampaignVideoPage
