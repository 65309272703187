import React from 'react';
import { instagramIcon } from '../utils/staticUrls'

const InstagramIcon = ({ size }) => {
    return (

        <img
            src={instagramIcon}
            alt="Instagram"
            style={!size ? { width: '28px', height: '28px' } : { width: size, height: size }}
        />

    );
};

export default InstagramIcon;
