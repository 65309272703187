import React from 'react';
import Body from '../components/Body';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';





function RefundPolicy() {


    const page_info = [
        {
            label: "CrowdPost Terms of Service",
            text:
                <div>
                    < p >
                        Effective date: Nov 1, 2024
                    </p >
                    <p>

                    </p>
                </div>
        },
        {
            label: "Policy",
            text:
                <div>
                    < p >
                        Refunds are accepted before the campaign contribution date. Reach out to info@crowdpost.com with "Refund" and the title of the campaign in the subject line. Include your max contribution in the email body.
                        <ol>
                            <li>Refunds for contributions after the fundraising period are not accepted</li>
                        </ol>
                    </p >
                    <p>Once the campaign is submitted at the end of the fundraising period, no refunds are accepted.</p>
                </div>
        }

    ]

    return (
        <Body footer>
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '3rem', marginBottom: '5rem' }}>
                <Col className="text-center mb-5" xs={12}>
                    <h2 className="fw-bolder">
                        Refund Policy
                    </h2>
                </Col>

                <Col lg={10}>

                    <Stack gap={4}>


                        <p>
                            Thank you for using CrowdPost! We're happy you're here. Please read this refund policy carefully before accessing or using CrowdPost. 
                        </p>

                        {
                            page_info.map(((v, i) =>
                                <>
                                    <h3 className="mb-0 pb-0 fw-bolder" id={`sidebar_${i}`}>{v['label']}</h3>
                                    <hr className="m-0 p-0" />
                                    <p>{v['text']}</p>
                                </>
                            ))
                        }


                    </Stack>


                </Col>
            </Row>
        </Body >
    )
}

export default RefundPolicy
