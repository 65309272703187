import React from 'react';

const XIcon = ({ size }) => {
    return (

        <img
            src="imgs/logo.svg"
            alt="X"
            style={!size ? { padding:"4px", width: '28px', height: '28px', backgroundColor:'black' } : { width: size, height: size, backgroundColor:'black', padding:'4px' }}
        />

    );
};

export default XIcon;
