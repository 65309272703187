import React from 'react'
import HelpPopover from './HelpPopover'

function NewCampaignMapDescription({ mode }) {

    const searchBody = <ol className="fs-6">
        <li> Search for points of interest using the <strong >search bar in the top left</strong> of the map.</li>
        <li>Then, use the<strong > radius slider</strong> to draw a circle around your point-of-interest in miles.</li>
    </ol>

    const drawBody = <ol className="fs-6">
        <li>Use the <strong >draw button in the top right</strong> to create custom areas on the map.</li>
        <li>Click on the areas around your desired campaign location.</li>
        <li>Double click or click <strong><i>Exit draw mode</i></strong> when done.</li>
    </ol>



    return (
        <div className="text-secondary">


            <ul>


                {mode ?
                    <li>
                        <p className="mb-0 pb-0 lead">
                            {
                                mode.includes("truck") ?
                                    <span>Campaign locations must be within the bounding areas</span>
                                    :
                                    <span>Campaign area must contain billboard points</span>
                            }
                        </p>
                    </li>

                    :
                    <li >
                        <p className="mb-0 pb-0 lead">Billboard campaigns must contains points and truck campaigns must be within the boundaries</p>
                    </li>}
                <li >
                    <p className="mb-0 pb-0 lead">Max 5 locations allowed</p>
                </li>
                <li>
                    <HelpPopover popoverHeader={'Search'} popoverBody={searchBody}>
                        <p className="mb-0 pb-0 lead">Search</p>
                    </HelpPopover>
                </li>
                <li>
                    <HelpPopover popoverHeader={'Draw'} popoverBody={drawBody}>
                        <p className="mb-0 pb-0 lead">Draw</p>
                    </HelpPopover>
                </li>

            </ul>
        </div>
    )
}

export default NewCampaignMapDescription
