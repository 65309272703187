// src/videoUrls.js
const youtubeUrls = {
    CREATING_CAMPAIGNS: 'https://www.youtube-nocookie.com/embed/kmS84NGOyBc?si=LCT1V7ijVMW8PIOr',
    CONTRIBUTING_CAMPAIGNS: 'https://www.youtube-nocookie.com/embed/JFowrz9wdrE?si=uaGWKXNKvX3wVT32',
    FAVORITE_FALLOW_CAMPAIGNS: "https://www.youtube-nocookie.com/embed/zB4Ata5k2vk?si=KXSIBYrtCiZ8Vpih",
    AI_IMGS_CAMPAIGNS: 'https://www.youtube-nocookie.com/embed/Kj2KlfTap68?si=k3HICF29je09R0pG',
    SET_LOCATION: 'https://www.youtube-nocookie.com/embed/boSEnUmqKzs?si=khZbvY_VHjzKp7GU',
    CREATING_MEDIA: 'https://www.youtube-nocookie.com/embed/9LjppMR5eo4?si=bB-E1sUQ8W3-L70P',
};

export default youtubeUrls;