import { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Body from '../components/Body';
import InputField from '../components/InputField';
import TextAreaField from '../components/TextAreaField';
import { useFlash } from '../contexts/FlashProvider';

import { useApi } from '../contexts/ApiProvider';
import { Helmet } from 'react-helmet';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';



import FormButtonBlock from '../components/FormButtonBlock';


function FeedbackPage({ modal }) {
    const api = useApi();

    const contactNameField = useRef();
    const contactEmailField = useRef();
    const contactMessageField = useRef();
    const [formErrors, setFormErrors] = useState({});

    const flash = useFlash();



    const onSubmit = (ev) => {
        ev.preventDefault()


        const contactName = contactNameField.current.value;
        const contactEmail = contactEmailField.current.value;
        const contactMessage = contactMessageField.current.value;


        //client sid field validation
        const errors = {};
        if (!contactName) {
            errors.contactName = 'Must include a name.';
        }
        if (!contactEmail) {
            errors.contactEmail = 'Must include an email.';
        }
        if (!contactMessage) {
            errors.contactMessage = 'Must include a contact message.';
        }

        setFormErrors(errors);
        if (Object.keys(errors).length > 0) {
            return;
        }

        // end client side field validation 

        (async () => {
            const response = await api.post("/feedback", {
                'contactName': contactName,
                'contactEmail': contactEmail,
                'contactMessage': contactMessage,
            });
            if (response.ok) {
                flash('Your message was sent', 'success');
            }
            else {
                flash('Error sending your message was sent', 'danger');
            }

        })();

        return true

    };




    return (

        <>
            <Helmet>
                <title>CrowdPost - Contact Us</title>
                <meta property="og:title" content={`Let your voice reach the real town square`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
                <meta property="og:description" content="CrowdPost feedback page. Give feedback and request features" />

            </Helmet>
            <Body footer={!modal ? true : undefined}     >
                <Row className="d-flex justify-content-center">
                    <Col xs={12} className="text-center">
                        <h1 className="page-header ">Submit Feedback</h1>
                    </Col>
                    <Col xs={12} md={modal ? 12 : 7}>
                        <h4 className="text-secondary mb-4">
                            Your feedback is appreciated and critical to the development of CrowdPost.
                        </h4>
                        <Form id="contact_form" onSubmit={onSubmit}>
                            <InputField fieldRef={contactNameField}
                                name="contactName" label="Name"
                                error={formErrors.contactName} />
                            <InputField fieldRef={contactEmailField}
                                name="contactEmail" label="Email" type="email"
                                error={formErrors.contactEmail} />
                            <TextAreaField

                                fieldRef={contactMessageField}
                                name="contactMessage"
                                label="Message"
                                placeholder="Message"
                                type="textarea"
                                error={formErrors.contactMessage}
                                rows={8}
                            />

                            <FormButtonBlock text={"Contact"} />
                        </Form>
                    </Col>
                </Row>


            </Body>
        </>

    )
}

export default FeedbackPage
