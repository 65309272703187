import React from 'react';

const YoutubeIcon = ({ size }) => {
    return (

        <img
            src="imgs/youtube_social_icon_red.png"
            alt="X"
            style={!size ? {  height: '26px',  } : { width: size, height: size,  }}
        />

    );
};

export default YoutubeIcon;
