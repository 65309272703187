import React, { useEffect, useState } from 'react';

const secondsTable = [
    ['day', 60 * 60 * 24],
    ['hour', 60 * 60],
    ['minute', 60],
    ['second', 1],
];

// function formatTimeLeft(seconds) {
//     let remainingSeconds = Math.max(seconds, 0); // Ensure no negative values

//     const timeLeft = secondsTable.map(([unit, unitSeconds]) => {
//         const value = Math.floor(remainingSeconds / unitSeconds);
//         remainingSeconds %= unitSeconds;
//         return value > 0 ? `${value}${unit.charAt(0)}` : '';
//     });

//     return timeLeft.filter(Boolean).slice(0, 2).join(' '); // Show top 2 non-empty units
// }


function formatTimeLeft(seconds) {
    let remainingSeconds = Math.max(seconds, 0); // Ensure non-negative

    const timeLeft = secondsTable.map(([unit, unitSeconds]) => {
        const value = Math.floor(remainingSeconds / unitSeconds);
        remainingSeconds %= unitSeconds;
        // Include seconds only if less than 60 seconds remain
        return value > 0 && (unit !== 'second' || seconds < 60) ? `${value}${unit.charAt(0)}` : '';
    });

    return timeLeft.filter(Boolean).slice(0, 2).join(' '); // Show top 2 non-empty units
}


function getTimeLeft(date) {
    const seconds = Math.round((date.getTime() - new Date().getTime()) / 1000);
    return Math.max(seconds, 0); // Ensure non-negative time left
}

function TimeLeft({ startText, endText, label, isoDate, endDate, hideCompleteMessage }) {
    const date = new Date(Date.parse(isoDate));
    const [timeLeft, setTimeLeft] = useState(getTimeLeft(date));
    const displayTime = formatTimeLeft(timeLeft);
    var end_date
    var end_time
    var endDateObj

    if (endDate) {
        endDateObj = new Date(Date.parse(endDate));
        end_date = endDateObj.toLocaleDateString('en-US', { month: 'short', day: '2-digit' })
        end_time = endDateObj.toLocaleTimeString('en-US', { hour: 'numeric', minute: '2-digit', timeZoneName: 'short' })
    }

    // Determine the class based on remaining time
    let timeClass = '';
    if (timeLeft <= 60) {
        timeClass = 'text-danger fw-bold'; // Less than a minute
    } else if (timeLeft <= 60 * 60) {
        timeClass = 'text-danger fw-bold'; // Less than an hour
    } else if (timeLeft <= 60 * 60 * 24) {
        timeClass = 'text-warning fw-bold'; // Less than a day
    }
    else {
        timeClass = "text-muted"
    }


    useEffect(() => {
        if (timeLeft <= 0) return; // Stop updating if time is up

        // Determine update interval based on remaining time
        const interval = timeLeft > 60 ? 60000 : 1000; // Update every minute if > 1 min, else every second
        console.log(timeLeft)
        const timerId = setInterval(() => {
            setTimeLeft(getTimeLeft(date));
        }, interval);

        return () => clearInterval(timerId);
    }, [timeLeft, date]);

    return (

        <span>
            {
                displayTime ?
                    <span>
                        {/* <strong>{label}</strong> */}
                        <span className="fw-bold"> · </span>
                        <span className={timeClass}>{startText}{displayTime}{endText} {endDate && <span className="text-secondary"> · ({end_date} {end_time})</span>}</span>
                    </span>
                    :

                    <span>

                        {
                            hideCompleteMessage ?
                                <span ></span>
                                :
                                <span> <strong>·</strong> <strong>Fundraising period complete</strong> · {endDate && <span className="text-secondary"> ({end_date} {end_time})</span>}</span>
                        }
                    </span>
            }
        </span>
    );
}

export default TimeLeft;
