import React, { useState } from 'react'
import { FaHeart } from 'react-icons/fa';

import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';

function Favorite({ isLiked, campaignId }) {

    const api = useApi();
    const [like, setLike] = useState(isLiked)

    const flash = useFlash()

    const handleClick = (e) => {
        e.preventDefault();
        (async () => {

            const response = await api.post(`/favorite_campaigns`, JSON.stringify({ campaign_id: campaignId }));
            if (response.ok) {

                // alert(2)
                response.body.data === true ?
                    setLike(() => true)
                    :
                    setLike(() => false)
            }
            else {
                console.log(response.status)

                'errors' in response.body ?
                    flash(response.body.errors.json.message, 'danger')
                    :
                    (('status' in response)) ?
                        flash("Must be logged in ", 'danger')
                        :
                        flash("Error with request", 'danger')


            }
        })();
        e.preventDefault();
    }

    return (

        <div >
            {
                like === true ?
                    <div
                        className="shadow-lg"
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 32,
                            height: 32,
                            borderRadius: '50%',
                            background: "white",
                            border: '1px solid black', // White border
                            cursor: 'pointer',
                            zIndex: 100,
                        }}
                        onClick={handleClick}
                    >
                        <FaHeart size={18} className="text-danger" />
                    </div> :
                    <div
                        // className="bg-success"
                        style={{
                            display: 'inline-flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 32,
                            height: 32,
                            borderRadius: '50%',
                            background: "transparent", // Set to opaque black
                            border: '2px solid white', // White border
                            cursor: 'pointer',
                            zIndex: 100,
                        }}
                        onClick={handleClick}
                    >
                        <FaHeart size={18} color="white" />
                    </div>

            }
        </div>
    )
}

export default Favorite
