import React from 'react'
import Badge from 'react-bootstrap/Badge';

function RadiusBadge({ radius }) {
    return (

        radius && <Badge className="bg-primary rounded-pill">{radius} {radius > 1 ? 'miles' : 'mile'}</Badge>

    )
}

export default RadiusBadge
