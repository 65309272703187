import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import FormButtonBlock from './FormButtonBlock';

function DynamicForm({ disabled, handleSubmit, children, buttonText }) {


  return (
    <Form  onSubmit={handleSubmit}>

      {children}
      <Row className="d-flex justify-content-center">
        <Col xs={12} lg={6}>

          {
            buttonText &&
            <div className="d-grid gap-2">
              <FormButtonBlock isProcessing={disabled} text={buttonText} />
              {/* <Button className="p-3" type="submit" disabled={disabled}>
              {
                !disabled ?
                  <strong>{buttonText}</strong>
                  :
                  <strong>Loading...</strong>
              }
            </Button> */}
            </div>}

        </Col>
      </Row>
    </Form>
  );
}

export default DynamicForm;
