import React, { useState } from 'react';
// import { FaCopy } from "react-icons/fa";
import { FcLink } from "react-icons/fc";


function CopyCampaign({ campaignId }) {
    const baseUrl = `${process.env.REACT_APP_BASE_API_URL}/campaign/${campaignId}`;
    const [copyStatus, setCopyStatus] = useState("Copy link");

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(baseUrl);
            setCopyStatus("Link copied");
        } catch (error) {
            console.error('Failed to copy:', error);
        }
    };

    return (
        <div>
            <div style={{ cursor: 'pointer', textAlign: 'center' }} onClick={handleCopyClick}>
                <FcLink size={50} color={copyStatus === "Link copied" ? "green" : "white"} />
            </div>


                {
                    copyStatus === "Link copied" ?

                        <div className="text-success">
                            <strong>{copyStatus}</strong>
                        </div>

                        :

                        <div >
                            <strong>{copyStatus}</strong>
                        </div>
                }

        </div>
    );
}

export default CopyCampaign;
