import React from 'react'
import Form from 'react-bootstrap/Form';


function CheckboxComponent({ name, label, type, placeholder, error, fieldRef, formText, as, additionalFields, rows, onChange, value, formLabel }) {
    return (
        <div className="mb-3">
            <Form.Check  type={"checkbox"} id={name}>
                {
                    error ?
                        <Form.Check.Input  ref={fieldRef} type={"checkbox"} isInvalid />
                        :
                        <Form.Check.Input  ref={fieldRef} type={"checkbox"} />

                }
                <Form.Check.Label>{<strong>{formLabel}</strong>}</Form.Check.Label>
            </Form.Check>
        </div>
    )
}

export default CheckboxComponent
