import React from 'react'
import Header from './Header';
import ExplorePage from './pages/ExplorePage'
import LoginPage from './pages/LoginPage'
import FeedPage from './pages/FeedPage';
import PaymentPage from './pages/PaymentPage';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import UserPage from './pages/UserPage';
import CampaignPage from './pages/CampaignPage';
import NewCampaignImagePage from './pages/NewCampaign/NewCampaignImagePage';
import NewConfirmationTokenPage from './pages/NewConfirmationTokenPage';
import NewCampaignTypes from './pages/NewCampaign/NewCampaignTypes';
import NewCampaignStartDate from './pages/NewCampaign/NewCampaignStartDate';
import NewCampaignDescription from './pages/NewCampaign/NewCampaignDescription';
import NewCampaignConfirmation from './pages/NewCampaign/NewCampaignConfirmation';
import NewCampaignMap from './pages/NewCampaign/NewCampaignMap';
import UserProvider from './contexts/UserProvider';
import StripeProvider from './contexts/StripeProvider';
import ApiProvider from './contexts/ApiProvider';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import FlashProvider from './contexts/FlashProvider';
import RegistrationPage from './pages/RegistrationPage';
import SampleCampaignVideoPage from './pages/SampleCampaignVideoPage';
import EditUserPage from './pages/EditUserPage';
import ChangePasswordPage from './pages/ChangePasswordPage';
import ResetRequestPage from './pages/ResetRequestPage';
import ResetPage from './pages/ResetPage';
import SupportedCampaigns from './pages/SupportedCampaignsPage';
import LandingPage from './pages/LandingPage';
import MyCampaignsPage from './pages/MyCampaignsPage';
import FavoriteCampaignPage from './pages/FavoriteCampaignPage';
import AuthenticatingLoadingPage from './pages/AuthenticatingLoadingPage';
import HowItWorksPage from './pages/HowItWorksPage';
import DMCATakedown from './pages/DMCATakedown';
import DMCACounter from './pages/DMCACounter';
import FAQPage from './pages/FAQPage';

import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfService from './pages/TermsOfService';
import AIImagePage from './pages/AIImagePage';
import ContactPage from './pages/ContactPage';
// import ThemeProvider from './contexts/ThemeProvider';
// import TopComponent from './components/TopComponent'
import WhatCanBePostedPage from './pages/WhatCanBePostedPage';
import ContributingToCampaignsPage from './pages/ContributingToCampaignsPage';
import CreatingCampaignsPage from './pages/CreatingCampaignsPage';
import PricingPage from './pages/PricingPage';
import AccountBalancePage from './pages/AccountBalancePage';
import PaymentWrapper from './components/PaymentWrapper';

import CampaignMapPage from './pages/CampaignMapPage';
import FeedbackPage from './pages/FeedbackPage';
import RefundPolicy from './pages/RefundPolicy';


function App() {


  return (
    // <ThemeProvider>
    // <TopComponent>
    <BrowserRouter>
      <FlashProvider>
        <ApiProvider>
          <UserProvider>
            <Header />
            <Routes>
              <Route path="/" element={
                <LandingPage />
              } />
              <Route path="/explore" element={
                <ExplorePage />
              } />
              <Route path="/authenticating" element={
                <AuthenticatingLoadingPage />
              } />
              <Route path="/how-it-works" element={
                <HowItWorksPage />
              } />
              <Route path="/what-can-be-posted" element={
                <WhatCanBePostedPage />
              } />
              <Route path="/pricing" element={
                <PricingPage />
              } />
              <Route path="/new-confirmation-token" element={
                <NewConfirmationTokenPage />
              } />
              <Route path="/creating-campaigns" element={
                <CreatingCampaignsPage />
              } />
              <Route path="/contributing-to-campaigns" element={
                <ContributingToCampaignsPage />
              } />
              <Route path="/common-questions" element={
                <FAQPage />
              } />
              <Route path="/dmca-takedown" element={
                <DMCATakedown />
              } />
              <Route path="/dmca-counter" element={
                <DMCACounter />
              } />
              <Route path="/dmca-policy" element={
                <DMCACounter />
              } />
              <Route path="/privacy-policy" element={
                <PrivacyPolicy />
              } />
              <Route path="/terms-of-service" element={
                <TermsOfService />
              } />
              <Route path="/refund-policy" element={
                <RefundPolicy />
              } />
              <Route path="/contact" element={
                <ContactPage />
              } />
              <Route path="/feedback" element={
                <FeedbackPage />
              } />
              <Route path="/ai-images" element={
                <AIImagePage />
              } />
              <Route path="/user/:username" element={
                <UserPage />
              } />
              <Route path="/campaign/:campaignId" element={
                <CampaignPage />
              } />
              <Route path="/campaign-map/:campaignId" element={
                <CampaignMapPage />
              } />
              <Route path="/login" element={
                <PublicRoute><LoginPage /></PublicRoute>
              } />
              <Route path="/reset-request" element={
                <PublicRoute><ResetRequestPage /></PublicRoute>
              } />
              <Route path="/reset" element={
                <PublicRoute><ResetPage /></PublicRoute>
              } />
              <Route path="/register" element={
                <PublicRoute><RegistrationPage /></PublicRoute>
              } />
              <Route path="/campaign-payment/:campaignId" element={
                <StripeProvider><PaymentPage /></StripeProvider>
              } />
              <Route path="/sample-campaign/:campaignId" element={
                <SampleCampaignVideoPage />
              } />
              <Route path="/new_campaign/campaign_type" element={<NewCampaignTypes />} />
              <Route path="/new_campaign/location" element={<NewCampaignMap />} />

              <Route path="/new_campaign/image" element={<NewCampaignImagePage />} />

              <Route path="/new_campaign/start_date" element={<NewCampaignStartDate />} />
              <Route path="/new_campaign/description" element={<NewCampaignDescription />} />
              <Route path="/new_campaign/confirmation" element={<NewCampaignConfirmation />} />



              <Route path="*" element={
                <PrivateRoute>
                  <Routes>
                    <Route path="/feed" element={<FeedPage />} />
                    <Route path="/account-balance" element={<StripeProvider><PaymentWrapper><AccountBalancePage /></PaymentWrapper>    </StripeProvider>} />

                    <Route path="/edit" element={<EditUserPage />} />
                    <Route path="/password" element={<ChangePasswordPage />} />
                    <Route path="/my_campaigns" element={<MyCampaignsPage />} />
                    <Route path="/supported_campaigns" element={<SupportedCampaigns />} />
                    <Route path="/favorite_campaigns" element={<FavoriteCampaignPage />} />
                  </Routes>
                </PrivateRoute>
              } />
            </Routes>
          </UserProvider>
        </ApiProvider>
      </FlashProvider>
    </BrowserRouter>
    /* </TopComponent> */
    // </ThemeProvider>
  );
}

export default App;
