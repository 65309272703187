import React, { useState } from 'react';
import Form from 'react-bootstrap/Form'
import Stack from 'react-bootstrap/Stack'
import InputField from './InputField';
import { IoEye } from "react-icons/io5";
import { IoEyeOffSharp } from "react-icons/io5";
import InputValidationRow from './InputValidationRow';
import FormButtonBlock from './FormButtonBlock';
import { useApi } from '../contexts/ApiProvider';
import { useNavigate } from 'react-router-dom';
import { useFlash } from '../contexts/FlashProvider';
import { Link } from 'react-router-dom';




export default function SignUp() {
  const api = useApi();
  const flash = useFlash();
  const navigate = useNavigate();

  const [accountName, setAccountName] = useState('');
  const [email, setEmail] = useState('')
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [accountNameTouched, setAccountNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [usernameTouched, setUsernameTouched] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordTouched, setPasswordTouched] = useState(false);
  const [confirmPasswordTouched, setConfirmPasswordTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [formErrors, setFormErrors] = useState({});




  const onSubmit = async (event) => {


    setIsLoading(true)
    event.preventDefault();
    const password2 = confirmPassword;
    //client sid field validation
    const errors = {};
    if (!username) {
      errors.username = 'Username must not be empty.';
    }
    if (!password) {
      errors.password = 'Password must not be empty.';
    }
    if (!password2) {
      errors.password2 = "Passwords must match";
    }
    if (!email) {
      errors.email = 'You must provide a valid email';
    }
    if (!accountName) {
      errors.accountName = 'You must provide a valid email';
    }
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      setIsLoading(false)
      return;
    }
    const response = await api.post('/register', {
      username: username,
      email: email,
      password: password,
      password2: password2,
      account_name: accountName
    });

    if (response.ok) {
      setFormErrors({});
      flash(<div>
        <div><strong>Please check your email to confirm your account.</strong></div> 
        <div>
          <ul>
            <li>The confirmation link will expire in {response.body.expiration_string}.</li>
            <li>If it expires, you can request a new one.</li>
            <li>If you do not see the email check your spam folder or contact us <Link to="/contact">here</Link></li>
          </ul>
        </div>
      </div>,
        'success', 60)
      setIsLoading(false);
      navigate('/login');
    }
    else {
      setIsLoading(false);
      if (response && 'body' in response && 'errors' in response.body) {

        setFormErrors(response.body.errors.json);
        flash(response.body.errors.json.message, 'danger')
      }
      else {
        flash("Server error", "danger")
      }
    }


  };



  // Validation rules with logic and messages
  const validationRules = {
    accountName: [
      {
        isValid: accountName.length >= 2 && accountName.length <= 30,
        message: 'Must be between 2 and 30 charachters',
      }
    ],
    email: [
      {
        isValid: email.length > 0,
        message: 'Email is required',
      }
    ],
    username: [
      {
        isValid: username.length >= 6,
        message: 'Minimum 6 characters',
      },
      {
        isValid: /^[a-zA-Z0-9]+$/.test(username),
        message: 'No special characters',
      },
    ],
    password: [
      {
        isValid: password.length >= 8,
        message: 'Minimum 8 characters',
      },
      {
        isValid: /\d/.test(password),
        message: 'Must contain a number',
      },
      {
        isValid: /[A-Z]/.test(password),
        message: 'Must contain an uppercase letter',
      },
      {
        isValid: /[a-z]/.test(password),
        message: 'Must contain a lowercase letter',
      },
      {
        isValid: /[!@#$%^&*]/.test(password),
        message: 'Must contain at lease one of the following special charachters (! @ # $ % ^ & *)',
      },
    ],
    confirmPassword: [
      {
        isValid: password === confirmPassword,
        message: 'Passwords match',
      },
    ],
  };

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
    setUsernameTouched(true);
  };

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
    setPasswordTouched(true);
  };

  const handleConfirmPasswordChange = (e) => {
    setConfirmPassword(e.target.value);
    setConfirmPasswordTouched(true);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setEmailTouched(true);
  };

  const handleAccountNameChange = (e) => {
    setAccountName(e.target.value);
    setAccountNameTouched(true);
  };

  // Check if the form is valid
  const isFormValid =
    validationRules.username.every((rule) => rule.isValid) &&
    validationRules.password.every((rule) => rule.isValid) &&
    validationRules.confirmPassword.every((rule) => rule.isValid);


  return (


    <Form onSubmit={onSubmit} >
      <Stack gap={4}>

        {/* AccountName Field */}
        <Form.Group controlId="formAccountName">
          <InputValidationRow isTouched={accountNameTouched} validationRules={validationRules['accountName']} >
            <InputField
              name="account_name"
              label="Account name"
              error={formErrors.account_name}
              onChange={handleAccountNameChange}
              onBlur={() => setAccountNameTouched(true)}
              hideBottomMargin
            />
          </InputValidationRow>
        </Form.Group>


        {/* Username Field */}
        <Form.Group controlId="formUsername">
          <InputValidationRow isTouched={usernameTouched} validationRules={validationRules['username']} >
            <InputField
              name="username"
              label="Username"
              error={formErrors.username}
              onChange={handleUsernameChange}
              onBlur={() => setUsernameTouched(true)}
              hideBottomMargin
            />
          </InputValidationRow>

        </Form.Group>

        {/* Email Field */}
        <Form.Group controlId="formEmail">
          <InputValidationRow isTouched={emailTouched} validationRules={validationRules['email']} >
            <InputField
              name="email"
              label="Email"
              error={formErrors.email}
              onChange={handleEmailChange}
              onBlur={() => setEmailTouched(true)}
              hideBottomMargin
            />
          </InputValidationRow>

        </Form.Group>

        <Form.Group controlId="formPassword">
          <InputValidationRow isTouched={passwordTouched} validationRules={validationRules['password']} >

            <div style={{
              position: "relative",

            }}>
              <InputField
                hideBottomMargin
                name="password"
                label="Password"
                type={!showPassword && "password"}
                error={formErrors.password}
                value={password}
                onChange={handlePasswordChange}
                onBlur={() => setPasswordTouched(true)}
              />
              {
                showPassword ?

                  <IoEye onClick={() => setShowPassword(false)} size={24} style={{
                    position: "absolute",
                    right: "2%",
                    top: "50%",
                    zIndex: "100",
                    cursor: "pointer",
                  }} />
                  :
                  <IoEyeOffSharp onClick={() => setShowPassword(true)} size={24} style={{
                    position: "absolute",
                    right: "2%",
                    top: "50%",
                    zIndex: "100",
                    cursor: "pointer",
                  }} />

              }
            </div>

          </InputValidationRow>
        </Form.Group>

        {/* Confirm Password Field */}
        <Form.Group controlId="formConfirmPassword">
          <InputValidationRow isTouched={confirmPasswordTouched} validationRules={validationRules['confirmPassword']}>
            <div style={{
              position: "relative",
            }}>
              <InputField
                name="password2" label="Password again" type={!showPassword2 && "password"}
                error={formErrors.password2} value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                onBlur={() => setConfirmPasswordTouched(true)}
                hideBottomMargin
              />
              {
                showPassword2 ?
                  <IoEye onClick={() => setShowPassword2(false)} size={24} style={{
                    position: "absolute",
                    right: "2%",
                    top: "50%",
                    zIndex: "100",
                    cursor: "pointer",
                  }} />
                  :
                  <IoEyeOffSharp onClick={() => setShowPassword2(true)} size={24} style={{
                    position: "absolute",
                    right: "2%",
                    top: "50%",
                    zIndex: "100",
                    cursor: "pointer",
                  }} />
              }
            </div>
          </InputValidationRow>


        </Form.Group>



        <FormButtonBlock
          text={"Sign up"}
          isProcessing={!isFormValid && !isLoading}
          className="mt-4"
        >
          Sign Up
        </FormButtonBlock>


      </Stack>


    </Form>

  );
};


