import React from 'react'

import Body from '../components/Body'
import { Helmet } from 'react-helmet';
import { useUser } from '../contexts/UserProvider'
import { useNavigate } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import YoutubeVideo from '../components/YoutubeVideo'
import { PiTruckDuotone } from "react-icons/pi";
import CircleIcon from './CircleIcon';
import { PiMapPinLineDuotone } from "react-icons/pi";
import { RiImageEditFill } from "react-icons/ri";
import { IoCalendarOutline } from "react-icons/io5";
import { PiSubtitlesBold } from "react-icons/pi";
import { CiCircleCheck } from "react-icons/ci";
import youtubeUrls from '../utils/youtubeUrls';
import { billboardSvg } from '../utils/staticUrls';

function CreatingCampaignsPage() {


    const { createCampaign } = useUser();
    const navigate = useNavigate()

    const ICON_SIZE = 26
    const timeframes = [
        {
            label: "Set a campaign type",
            subText: ['Choose a billboard or billboard truck campaign', 'Truck campaigns are able to accomodate a wider range of speech and area than billboards.', 'Billboard campaigns are last about 6 weeks'],
            icon:
                <div >
                    <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><PiTruckDuotone size={ICON_SIZE} color="white" /> </CircleIcon>
                    <strong className="mx-2">|</strong>
                    <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><img src={billboardSvg} alt="My Icon" width="24" height="24" /> </CircleIcon>

                </div>

        },
        {
            label: "Set a location",
            subText: ['Search for a point of interest and set a radius around it', 'Draw a custom area on the map', 'Optionally name your camapign areas'],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><PiMapPinLineDuotone size={ICON_SIZE} color="white" /> </CircleIcon>
        },
        {
            label: "Create your media",
            subText: ['Create a simple post', 'Use our drag and drop editor', 'Upload your own design using our media templates', 'Generate custom images from text prompts using AI'],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><RiImageEditFill size={ICON_SIZE} color="white" /> </CircleIcon>
        },
        {
            label: "Set a fundraising period",
            subText: ['The time perdiod your campaign will accept contributions', 'At the end of this period, CrowdPost submits your campaign to our media partners to see if your campaign can be accomodated.'],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><IoCalendarOutline size={ICON_SIZE} color="white" /> </CircleIcon>
        },
        {
            label: "Set a title and optional description.",
            subText: ['Title your campaign', 'Add an optional description about your campaign', 'You can link a youtube video', 'You can add up to 3 description images'],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><PiSubtitlesBold size={ICON_SIZE} color="white" /> </CircleIcon>
        },
        {
            label: "Agree to our terms of service & post.",
            subText: ['Your must agree to our terms of service before posting, here.',],
            icon: <CircleIcon color={"linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))"} ><CiCircleCheck size={ICON_SIZE} color="white" /> </CircleIcon>
        },

    ];



    const handleNewCampaign = async () => {
        const proceed = await createCampaign();
        //This used to throw an error
        if (proceed) {
            navigate('/new_campaign/campaign_type', { replace: true })
        }
    }





    const cardStyle = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    };

    const cardBodyStyle = {
        flex: 1,
    };

    return (
        <>
            <Helmet>
                <title>CrowdPost - Creating campaigns</title>
                <meta property="og:title" content={`Let your voice reach the real town square`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
                {/* <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." /> */}

            </Helmet>
            <Body footer>



                <Row style={{ marginTop: '1rem' }} className="mb-3" >
                    <Col xs={12} className="text-center"
                    >
                        <h1 className="pricingHeader">Creating campaigns</h1>



                    </Col>
                </Row>
                <Row className="mb-5 justify-content-center">
                    <Col xs={{ span: 12 }} lg={{ span: 9, }}>



                        <YoutubeVideo
                            url={youtubeUrls.CREATING_CAMPAIGNS}
                            title={"Creating campagns"}
                        />


                    </Col>
                    <Col xs={12} className="d-flex justify-content-center my-5">
                        <div className="d-flex flex-column justify-content-center align-items-center ">
                            <Button
                                variant="success"
                                className="shadow-lg my-2 rounded-pill d-flex align-items-center justify-content-center px-5 py-2 w-100 " // Apply the Flexbox styles here
                                onClick={() => handleNewCampaign()}
                            >
                                <h2 className="m-0 "><strong>Start a Campaign</strong></h2>
                            </Button>

                        </div>
                    </Col>

                </Row>

                <Row xs={1} md={3} className="g-5">
                    {
                        timeframes.map((_, idx) => (
                            <Col key={idx}>
                                <Card style={cardStyle}>
                                    <Card.Body style={cardBodyStyle}>
                                        <Card.Header className="rounded bg-secondary"><Card.Title className=""><strong>{_.label}</strong></Card.Title></Card.Header >

                                        <div className="my-4 text-center">
                                            {
                                                _.icon
                                            }
                                        </div>

                                        <ul>

                                            {_.subText.map((val, i) =>

                                                <li key={i}>
                                                    {/* <Card.Text> */}
                                                    {val}
                                                    {/* </Card.Text> */}
                                                </li>

                                            )}
                                        </ul>
                                    </Card.Body>
                                </Card>
                            </Col>
                        ))}
                </Row>













            </Body >


        </>
    )
}

export default CreatingCampaignsPage