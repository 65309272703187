import React, { useState, useEffect } from 'react';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Carousel from 'react-bootstrap/Carousel';
import { mimicIcons } from '../utils/staticUrls';
import InstagramIcon from './InstagramIcon';
import XIcon from './XIcon';
import SocialMediaIcon from './SocialMediaIcon';
import { facebookIcon, mailIcon } from '../utils/staticUrls';
import CloudAnimation from './CloudAnimation';
import VideoProgressBar from './VideoProgressBar';



const HowItWorksVideo = () => {

    const INTERVAL = 1000 * 10;
    const itemStyle = {
        height: '563px',
        width: '524px',
        borderRadius: '10px',
    }
    const [activeIndex, setActiveIndex] = useState(0);

    const handleSelect = (selectedIndex) => {
        setActiveIndex(selectedIndex);
    };



    const circleStyle = {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        backgroundColor: 'red',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontSize: '1.5rem',
        marginRight: '.5em'
    };

    return (
        <Row>
            <Col xs={12} lg={6} className="d-flex justify-content-center">
                <div className="carousel-container">
                    <Carousel className="bg-success" activeIndex={activeIndex} onSelect={handleSelect} interval={INTERVAL}>
                        <Carousel.Item>
                            <div className={`carousel-item-content ${activeIndex === 0 ? 'animate-up' : ''}`}>
                                <Image src={'/imgs/UrbanBillboardMockup(1).webp'} style={{ width: '100%', height: '100%', objectFit: 'cover', objectPosition: 'right' }} />

                            </div>

                            <VideoProgressBar duration={INTERVAL} isActive={activeIndex === 0} />

                        </Carousel.Item>
                        <Carousel.Item>

                            <div className={`carousel-item-content ${activeIndex === 1 ? 'animate-up' : ''}`}>



                                <Card
                                    className="d-block"
                                    style={{
                                        position: 'relative',
                                        width: '320px',
                                        borderRadius: '8px',
                                        height: '100%',
                                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                                        zIndex: 1,
                                    }}
                                >

                                    <Card.Img className="rounded" variant="top" src={mimicIcons.mockup2} alt="Placeholder Image" />

                                    <Card.Body className="text-left pt-0 mb-3" style={{ textAlign: 'left' }}>
                                        <div className="d-flex justify-content-evenly align-items-start my-3">
                                            <InstagramIcon size={32} />
                                            <XIcon size={32} />
                                            <SocialMediaIcon size={32} iconPath={facebookIcon} alt={'Facebook icon'} />
                                            <SocialMediaIcon size={35} iconPath={mailIcon} alt={'Facebook icon'} />
                                        </div>

                                        <div><Card.Text>Status: <span className="fw-bold"> Accepting funding</span></Card.Text></div>
                                        <div><Card.Text><strong className="text-success">$20,000</strong><span className="text-secondary"> raised</span></Card.Text></div>
                                        <div><Card.Text ><span className="text-danger fw-bold">1h 15m</span><span className="text-secondary"> left to contribute</span></Card.Text></div>
                                    </Card.Body>
                                </Card>
                            </div>
                            <VideoProgressBar duration={INTERVAL} isActive={activeIndex === 1} />
                        </Carousel.Item>

                        <Carousel.Item>
                            <div className={`carousel-item-content ${activeIndex === 2 ? 'animate-up' : ''}`}>
                                <div style={{ position: 'relative', width: '100%', height: '100%', }}>
                                    <div style={{
                                        position: 'relative',
                                        zIndex: 1,
                                        width: '100%',
                                        height: '100%',
                                    }}>
                                        <CloudAnimation />
                                    </div>

                                    <div style={{
                                        zIndex: "2",
                                        width: "100%",
                                        display: "grid",
                                        justifyContent: "center",
                                        alignItems: "center",
                                        height: "100%",
                                        position: 'absolute',
                                        left: '0',
                                        top: '0',
                                    }}>
                                        <Stack gap={3} style={{
                                            justifyContent: 'center',
                                            width: '100%',
                                            height: '100%',
                                            alignItems: 'center'
                                        }}>

                                            <Badge className="p-3 shadow" pill bg="dark"><span className=" fw-bold fs-4 text-white">Campaign submitted to media partners</span></Badge>

                                            <Badge style={{ maxWidth: '300px' }} className="shadow" pill bg="dark"><span className="px-3 py-2 fw-bold fs-4 text-success">Accepted</span></Badge>
                                            <Badge style={{ maxWidth: '300px' }} className="shadow" pill bg="dark"><span className="px-3 py-2 fw-bold fs-4 text-danger">Denied</span></Badge>
                                            <Badge style={{ maxWidth: '300px' }} className="shadow" pill bg="dark"><span className="px-3 py-2 fw-bold fs-4 text-warning">Underfunded</span></Badge>
                                        </Stack>
                                    </div>

                                </div>
                            </div>
                            <VideoProgressBar duration={INTERVAL} isActive={activeIndex === 2} />
                        </Carousel.Item>


                    </Carousel>
                </div>
            </Col>
            <Col xs={12} lg={6} className="mt-3 mt-lg-0">
                <Stack className="text-left">
                    <Row>
                        <Col xs={12} className="d-flex flex-column justify-content-start text-left" style={{ textAlign: 'left', }}>
                            <h4 onClick={() => setActiveIndex(0)} style={{ cursor: 'pointer', textAlign: 'left', }} className="text-sm-left text-lg-left"><Badge bg={activeIndex === 0 ? 'success' : 'dark'} style={circleStyle}>
                                1
                            </Badge>Create a campaign with our tools</h4>
                            <p className="text-muted fs-5 text-left">You'll be guided through prompts to set your location, media, fundraising perdiod and title.</p>
                        </Col>


                        <Col style={{ textAlign: 'left', }} xs={12} className="d-flex flex-column justify-content-start text-left">
                            <h4 onClick={() => setActiveIndex(1)} style={{ cursor: 'pointer', textAlign: 'left', }} className="text-sm-left text-left"><Badge bg={activeIndex === 1 ? 'success' : 'dark'} style={circleStyle}>
                                2
                            </Badge>Reach donors by sharing</h4>
                            <p className="text-muted fs-5 ">Share your campaign link. Use the dashboard to link X posts, youtube videos and more.</p>
                        </Col>


                        <Col style={{ textAlign: 'left', }} xs={12} className="d-flex flex-column justify-content-start text-left">
                            <h4 onClick={() => setActiveIndex(2)} style={{ cursor: 'pointer', textAlign: 'left', }} className="text-left"><Badge bg={activeIndex === 2 ? 'success' : 'dark'} style={circleStyle}>
                                3
                            </Badge>Campaign submitted to media partners</h4>
                            <p className="text-muted fs-5 ">Your campaign will be accepted, underfunded or denied. If not enough is raised nobody is charged. If extra is raised, contributions are reduced.</p>
                        </Col>
                    </Row>
                </Stack>
            </Col>

        </Row>
    );
};

export default HowItWorksVideo;
