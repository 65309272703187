import React from 'react'

function OrDivider() {
    return (
        <div className="divider-container">
            <hr className="divider-line" />
            <div className="divider-or"><strong>or</strong></div>
            <hr className="divider-line" />
        </div>
    )
}

export default OrDivider
