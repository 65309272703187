import React from 'react'
import Placeholder from 'react-bootstrap/Placeholder';
import Stack from 'react-bootstrap/Stack';
import Col from 'react-bootstrap/Col';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Card from 'react-bootstrap/Card';

function LoadingNewCampaignImage() {
    return (
        <Placeholder animation="glow">
            <Stack gap={2}>
                <Col xs={12} lg={'auto'} className="d-flex justify-content-center"><Placeholder.Button xs={5} lg={2} variant="success" /><Placeholder.Button className="mx-2" xs={5} lg={2} /> </Col>
                <hr />
                <Col className="px-3" xs={12}>
                    <Placeholder as={Card.Title} animation="glow">
                        <Placeholder className="mb-3 p-3 rounded" xs={10} lg={4} />
                    </Placeholder>

                    <Tabs
                        id="placeholder-controlled-tab-example"
                        activeKey={"dragDrop"}
                        className="mb-3"
                    >
                        <Tab eventKey="dragDrop" title="Custom design">
                            <Stack >
                                <div className="text-center">
                                    <Placeholder as={Card.Text} animation="glow">
                                        <Placeholder style={{ height: '300px' }} className="rounded" xs={12} xl={10} />
                                    </Placeholder>
                                </div>
                                <div className="text-left mt-3">
                                    <Placeholder className="mb-3 p-3 rounded" xs={9} lg={7} />
                                    <Placeholder className="mb-3 p-3 rounded" xs={9} lg={7} />
                                    <Placeholder className="mb-3 p-3 rounded" xs={9} lg={7} />
                                </div>
                            </Stack>
                        </Tab>
                        <Tab eventKey="dragDrop2" title="Post a message" />
                        <Tab eventKey="dragDrop2" title="Upload custom media" />
                    </Tabs>
                </Col>

            </Stack>

        </Placeholder>
    )
}

export default LoadingNewCampaignImage
