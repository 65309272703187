import React, { useState, useRef, useEffect } from 'react'
import Form from 'react-bootstrap/esm/Form';
import Button from 'react-bootstrap/esm/Button';
import Modal from 'react-bootstrap/Modal';
import InputField from './InputField';
import { useFlash } from '../contexts/FlashProvider';
import { useApi } from '../contexts/ApiProvider';
import CheckboxComponent from './CheckboxComponent';


function ReportModal({ show, hide }) {
    const [formErrors, setFormErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const flash = useFlash()
    const api = useApi()


    const campaign_url = useRef(null);
    const full_name = useRef(null);
    const phone = useRef(null);
    const email = useRef(null);
    const knows_organizer = useRef(null);
    const acknowledged = useRef(null);



    const onSubmit = async (ev) => {
        ev.preventDefault();
        setIsLoading(true);
        (async () => {
            setFormErrors({})
            const response = await api.post(`/report-campaign`, {
                full_name: full_name.current.value,
                campaign_url: campaign_url.current.value,
                phone: phone.current.value,
                email: email.current.value,
                knows_organizer: knows_organizer.current.checked,
                acknowledged: acknowledged.current.checked,

            });

            if (response.ok) {

                if (response.body.success === true) {

                    flash("Your request was submmited", 'success');
                }
                else {
                    flash('Error with your request', 'danger')
                }

                setIsLoading(false)

            }
            else {
                setIsLoading(false)

                if (response.body && 'errors' in response.body) {
                    flash(response.body.errors.json.message, 'danger');
                    setFormErrors(response.body.errors.json);

                }
                else {

                    flash('Error with your request', 'danger')
                }


            }
        })();
    };

    useEffect(() => {
        if (show && campaign_url.current) {
            campaign_url.current.value = window.location.href;
        }
    }, [show]);

    return (
        <Modal size="lg" onHide={hide} show={show} backdrop='static'>
            <Modal.Header closeButton>
                <Modal.Title >
                    <h1>Report fundraiser</h1>
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={onSubmit}>
                <Modal.Body >
                    <InputField
                        label="Your full name"
                        fieldRef={full_name}
                        error={formErrors.full_name}
                    />
                    <InputField
                        label="Your phone number"
                        fieldRef={phone}
                        error={formErrors.phone}
                    />
                    <InputField
                        label="Your email address"
                        fieldRef={email}
                        error={formErrors.email}
                    />
                    <InputField
                        label="Campaign url"
                        fieldRef={campaign_url}
                        error={formErrors.campaign_url}

                    />

                    <CheckboxComponent
                        fieldRef={knows_organizer}
                        formLabel="Do you know the campaign organizer? Check the box if you do."
                        // fieldRef={goodFaithField}
                        error={formErrors.knows_organizer}
                    />
                    <CheckboxComponent
                        fieldRef={acknowledged}
                        formLabel="To the best of my knowledge, I certify that the information I have provided is accurate and complete."
                        name="accurateInfoAgreement"
                        // fieldRef={goodFaithField}
                        error={formErrors.acknowledged}
                    />

                </Modal.Body>
                <Modal.Footer>
                    <div className="text-danger text-center w-100">
                        <strong>{'message' in formErrors && formErrors.message}</strong>
                    </div>
                    <span className="text-muted">
                        By hitting the submit button to report a fundraiser, you agree to the Terms of Service and you acknowledge receipt and understanding of the Privacy Notice.
                    </span>
                    <Button type='button' onClick={hide} variant={'secondary'}>
                        Close
                    </Button>
                    <Button disable={isLoading} type='submit' variant={'primary'}>
                        Submit claim
                    </Button>
                </Modal.Footer>
            </Form >
        </Modal>
    )
}

export default ReportModal
