import React,{useEffect,useState} from 'react'

const secondsTable = [
    ['year', 60 * 60 * 24 * 365],
    ['month', 60 * 60 * 24 * 30],
    ['week', 60 * 60 * 24 * 7],
    ['day', 60 * 60 * 24],
    ['hour', 60 * 60],
    ['minute', 60],
];

const rtf = new Intl.RelativeTimeFormat(undefined, { numeric: 'auto' }); //first arg is language. instead of 1 day ago it says yesterday for numeric



function getTimeAgo(date) {
    const seconds = Math.round((date.getTime() - new Date().getTime()) / 1000);
    const absSeconds = Math.abs(seconds);
    let bestUnit, bestTime, bestInterval;
    for (let [unit, unitSeconds] of secondsTable) {
        if (absSeconds >= unitSeconds) {
            bestUnit = unit;
            bestTime = Math.round(seconds / unitSeconds);
            bestInterval = unitSeconds / 2;
            break;
        }
    };
    if (!bestUnit) {
        bestUnit = 'second';
        bestTime = parseInt(seconds / 10) * 10;
        bestInterval = 10;
    }
    return [bestTime, bestUnit, bestInterval];
}


function TimeAgo({ children, isoDate }) {

    const date = new Date(Date.parse(isoDate));
    const [time, unit, interval] = getTimeAgo(date)
    const [,setUpdate] = useState(0); //each time u want this to re render increment setUpdate

    useEffect(()=> {
        const timerId = setInterval(
            () => setUpdate(update => update+1),//updating itself using function,
            interval * 1000
        );
        return  () => clearInterval(timerId);
    },[interval]);

    return (
        <div ><strong> {children} </strong><span  className="text-muted">{rtf.format(time,unit)}</span></div>
    )
}

export default TimeAgo