import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import FAQ from './FAQ'
import AboutSidebar from '../components/AboutSidebar';
import { Link } from 'react-router-dom'




function FAQPage() {
    const faqs = [
        { label: 'What is a campaign?', a: 'A campaign is what CrowdPost users create to crowdfund for an image or message to be displayed on a billboard or billboard truck.' },
        { label: 'Who can post a campaign?', a: 'Anyone with a registered account can post a campaign. There is no cost to posting.' },
        { label: 'Who can contribute to a campaign?', a: 'No account needed to contribute. Only a valid credit/debit card is neededs. Contributions are non refundable.' },
        { label: 'What happens if a campaign is denied?', a: "CrowdPost is not the company in charge of deciding what can be posted. Our media partners have the final say. If the companies reject the campaign media,contributors are charged 99¢ instead of their max contribution. A reason for campaign denial is posted and the campaign is never removed from the platform. For 99¢ contributors can help shed light on narratives kept out of public discourse. Social media platforms have censored topics in the past without users knowledge. CrowdPost wants to shed light on topics kept out of public discourse." },

        { label: 'When is my payment processed?', a: "After the campaign's fundraising period. Your payment is NOT processed up front when contributing to a campaign. After the campaign fundraising period your campaign is submitted to our media partners. If not enough is raised to purchase media, contributors are not charged." },
        { label: 'What types of messages can be posted?', a: <span><strong>CrowdPost is a free speech platform.</strong>. What is permissible by US free speech law is permissible on CrowdPost. You must have the rights to post any media included in your design. No direct advertising allowed. We reserve the right to change our policy of what can be posted. To view a full criteria, click <Link  to={{ pathname: "/what-can-be-posted" }} className="" >here </Link>. The images submitted to CrowdPosted are subject to approval by our media partners. If media is denied, CrowdPost posts a reason for denial on the campaign homepage and never deletes the campaign.</span> },
        { label: 'What if the image I post is copywritten or I do not have permission to use it?', a: "You bear the responsibility of not using copywritten, trademarked, or otherwise protected material in your campaign without legally documented permission from the owner of the intellectual property. By posting on the platform you will be asked to accept the terms & conditions of CrowdPost's platform. Violation of the rule can result in banning from the platform, cancellation of the campaign, and you may face legal action from the copyright owner." },
        { label: 'What happens if the campaign is overfunded compared to the value of the eligible outdoor media?', a: <span>
            The reason contributors set a "max contribution amount" is because they are only charged for media purchased. If a campaign is overfunded (the amount raised is higher than the cost), users are charged based off the percent their contribution made up of the total raised. So if your contribution made up 50% of the total raised your final charge is 50% of the final cost -- up to your set maximum contribution amount.
        </span> },

    ]
    const [activeSection, setActiveSection] = useState(null);

    const handleSectionChange = (id) => {
        setActiveSection(id);

    }

    return (


        <Container fluid>

            <Stack style={{ alignItems: 'baseline' }} direction="horizontal">

                <AboutSidebar activeSection={activeSection} handleSectionChange={handleSectionChange} faqs={faqs} />
                <div className={'container-fluid'}>




                    <Row className="mb-5" >

                        <Col className="my-4" xs={12} >
                            <h1 className="mb-4 text-center pricingHeader">Common Questions</h1>

                            <FAQ activeSection={activeSection} faqs={faqs} />

                        </Col>
                    </Row>
                </div>
            </Stack>
        </Container>



    )
}

export default FAQPage