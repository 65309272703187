import React, { useRef, useEffect } from 'react'
import Image from 'react-bootstrap/Image';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import CrowdPostLogo from './CrowdPostLogo'




function BaseMessageImage({ mediaText, topHalf, image_data, height, messageWidth, value, minFontSize }) {

    const quillRef = useRef(null);





    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            if (quill && quill.root) {
                quill.root.setAttribute('spellcheck', false);
                // const toolbar = quillRef.current.getEditor().getModule('toolbar');
                // toolbar.addHandler('customDropdown', CustomDropdown);
            }
        }
    }, []);

    useEffect(() => {
        if (quillRef.current) {
            const editor = quillRef.current.getEditor();
            editor.setContents(value); // Clear existing content
            // editor.setText(value); // Clear existing content
            // Insert text with multiple spaces using non-breaking spaces
            // editor.setContents([{ insert: value }])
            // Apply any additional formatting if needed
        }
    }, [value]);

    const textShadow = `
            ${image_data['base_message']['outline_width']}em 0px ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
            0px ${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
            -${image_data['base_message']['outline_width']}em 0px ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
            0px -${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
            -${image_data['base_message']['outline_width']}em -${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
            -${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
            ${image_data['base_message']['outline_width']}em -${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}, 
            ${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_width']}em ${image_data['base_message']['outline_blur']}em ${image_data['base_message']['outline_color']}
        `

    return (

        <>

            {
                !image_data['image']['split_post'] && image_data['image']['url'] && !topHalf &&


                <div style={{
                    position: "absolute",
                    height: '100%',
                    width: "50%",
                    left: "50%",
                    top: '0',
                    backgroundImage: `url(${image_data['image']['url']})`,
                    zIndex: '3',
                    // backgroundPosition: 'center',
                    // backgroundSize: 'contain',
                    // backgroundRepeat: 'no-repeat',
                    backgroundPosition: image_data['image']['background_position'],
                    backgroundSize: image_data['image']['background_size'],
                    backgroundRepeat: image_data['image']['background_repeat'],
                }} />


            }

            {
                image_data['image']['url'] && topHalf && !image_data['image']['split_post'] &&


                <div style={{
                    position: "absolute",
                    height: '50%',
                    width: "100%",
                    left: "0",
                    bottom: "0",
                    zIndex: '3',
                    backgroundImage: `url(${image_data['image']['url']})`,
                    // backgroundPosition: 'center',
                    // backgroundSize: 'contain',
                    // backgroundRepeat: 'no-repeat',
                    backgroundPosition: image_data['image']['background_position'],
                    backgroundSize: image_data['image']['background_size'],
                    backgroundRepeat: image_data['image']['background_repeat'],
                }} />


            }

            <Row className="d-flex m-0 p-0" style={{
                height: topHalf ? '50%' : "100%",
                minFontSize: minFontSize,
                // backgroundColor: 'red',
                width: "100%",
                position: 'absolute',
                zIndex: '3',
                // width: !image_data.image.fullscreen ? '50%' : '100%',


            }}>

                <Col
                    className={`rounded m-0 p-0 text-dark`}
                    xs={!image_data['image']['split_post'] || topHalf ? "12" : messageWidth}
                    // xs={!image_data['image']['fullscreen'] || topHalf || !image_data['image']['url'] ? "12" : messageWidth}
                
                
                    style={{
                        backgroundColor: image_data['base_message']['background_color'],

                    }}
                >
                    <span className="text-danger">{topHalf}</span>
                    {
                        image_data['base_message']['include_name'] &&
                        <div className={`d-flex ${image_data['base_message']['include_name'] ? 'justify-content-between' : 'justify-content-end'} m-0 p-0 mb-3`}>


                            <Row className="d-flex align-items-center"
                            >
                                <Col className="d-flex" xs="auto">
                                    <div className="d-flex align-items-center">
                                        <Image style={{ objectFit: 'cover', marginRight: '.25em' }} className="crowdPostAvatar" src={image_data['base_message']['avatar_url']} roundedCircle />
                                    </div>
                                    <div style={{
                                        fontFamily: `${image_data['base_message']['font_family']}`, wordWrap: 'anywhere', display: "flex",
                                        flexDirection: "column", "justifyContent": "center"
                                    }}>
                                        <div
                                            className="fw-bolder"
                                            style={{
                                                lineHeight: 1,
                                                color: image_data['base_message']['color'],
                                                fontSize: '80%',
                                                textShadow: textShadow,
                                                textAlign: "left",
                                            }}
                                        >
                                            {image_data['base_message']['fullname']}
                                        </div>
                                        <div
                                            style={{
                                                lineHeight: 1,
                                                color: image_data['base_message']['color'],
                                                fontSize: '65%',
                                                textShadow: textShadow,
                                                textAlign: "left",
                                            }}>
                                            @{image_data['base_message']['username']}
                                        </div>
                                    </div>
                                </Col>


                            </Row>
                            {/* {!topHalf && <div style={{ marginRight: '.25rem' }}> */}
                            {!topHalf && <div>
                                <CrowdPostLogo responsive={minFontSize} />
                            </div>}

                        </div>

                    }




                    <div className="" style={{
                        fontFamily: `${image_data['base_message']['font_family']}`,
                        textShadow: textShadow,


                    }}>

                        <ReactQuill
                            ref={quillRef}
                            theme={false}
                            value={value}
                            modules={{
                                toolbar: false,
                            }}
                            readOnly

                        />
                    </div>

                </Col>



            </Row >

            {
                topHalf &&
                <div style={{
                    position: 'absolute',
                    right: 0,
                    bottom: 0,
                    zIndex: 2,
                    textAlign: 'right'
                }}>
                    <CrowdPostLogo responsive={minFontSize} />
                </div>
            }




        </>




    )
}

export default BaseMessageImage
