import React, { forwardRef, useEffect } from 'react'
import Popover from 'react-bootstrap/Popover';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';



import { FaBold } from "react-icons/fa";
import { FaItalic } from "react-icons/fa";
import { FaUnderline } from "react-icons/fa";
import { FaAlignLeft } from "react-icons/fa6";
import { FaAlignJustify } from "react-icons/fa6";
import { FaAlignRight } from "react-icons/fa6";
import { MdAlignVerticalBottom } from "react-icons/md";
import { MdAlignVerticalCenter } from "react-icons/md";
import { MdAlignVerticalTop } from "react-icons/md";
import { useImageBuilder } from '../contexts/ImageBuilderProvider';
import CustomRangeField from './CustomRangeField';


const FontEditor = forwardRef(({ mediaName, handleImageHover, index, msgData, message_index, height, image_data, fontHandlers, handleMessageWidthChange, messageWidth }, fieldRef) => {

    const { handleImageMessageListChange, defaultColors, } = fontHandlers



    const { handleUpdateBaseMessage, handleUpdateRespData, handleFontBackgroundColorOpacityChange } = useImageBuilder()

    useEffect(() => {
        message_index !== 'base_message' && handleImageHover(true)
        return () => {
            message_index !== 'base_message' && handleImageHover(false)
        }
    }, [])

    const handleFontStyleChange = (style) => {

        msgData[style] === true ?
            handleImageMessageListChange(index, { [style]: false })
            :
            handleImageMessageListChange(index, { [style]: true })

    }



    const handleOutlineChange = (e, key_type, base = false) => {

        let data
        if (key_type === 'outline_blur') {
            console.log('blur func', e.target.value);


            data = { "outline_blur": e.target.value }

        }
        else if (key_type === 'outline_color') {
            data = { "outline_color": e }
        }
        else if (key_type === 'outline_width') {

            data = { "outline_width": e.target.value }

        }
        else if (key_type === 'change_to_blur') {
            data = { 'outline_width': 0, 'outline_type': 'shadow', 'outline_color': 'white' }

        }
        else if (key_type === 'change_to_none') {


            data = { 'outline_color': 'transparent', 'outline_type': 'none', 'outline_width': 0 }

        }
        else if (key_type === 'change_to_outline') {

            data = { 'outline_type': 'outline', 'outline_blur': 0, 'outline_color': 'white' }


        }
        if (base) {
            console.log(data)
            handleUpdateBaseMessage(mediaName, data)
        }
        else {
            handleImageMessageListChange(index, data)
        }
        return


    }
    const handleBackgroundColorOpacityChange = (e, index) => {
        let opacity = e.target.value;
        let updated_resp_data;
        let currentColor;
        let color;


        if (parseInt(opacity) <= 99) {

            if (opacity <= 9) {
                opacity = `0${opacity}`
            }


            if (image_data['image']['background_color'].length === 9) {
                currentColor = image_data['image']['background_color'].slice(0, 7); // Remove the alpha value
                color = currentColor + opacity

            }
            else {
                color = image_data['image']['background_color'] + `${opacity}`

            }

            handleUpdateRespData(mediaName, { "background_color_opacity": opacity, "background_color": color })

        }
        if (parseInt(opacity) === 100) {
            currentColor = image_data['image']['background_color'].slice(0, 7); // Remove the alpha value
            color = currentColor;
            handleUpdateRespData(mediaName, { "background_color_opacity": opacity, "background_color": color })



        }
    }




    return (
        <Popover ref={fieldRef} id="popover-basic"
            style={{
                maxHeight: "800px",
                width: '100%',
                overflowY: 'scroll',
                zIndex: '1000001',


            }}
        >
            <Popover.Header>
                Background & Default Font Settings
            </Popover.Header>
            <Popover.Body>

                {message_index !== 'base_message' &&
                    <>
                        <Form.Group>

                            <Form.Label><strong>Font Style</strong></Form.Label>
                            <Row className="d-flex justify-content-center p-1">
                                <Col xs={10} className="d-flex justify-content-between ">
                                    <FaBold style={{
                                        color: msgData['bold'] ? 'green' : 'white',
                                        cursor: 'pointer'
                                    }}
                                        onClick={(e) => handleFontStyleChange('bold')} />

                                    <FaItalic onClick={(e) => handleFontStyleChange('italic')}
                                        style={{
                                            cursor: 'pointer',
                                            color: msgData['italic'] ? 'green' : 'white',
                                        }} />

                                    <FaUnderline onClick={(e) => handleFontStyleChange('underline')}
                                        style={{
                                            cursor: 'pointer',
                                            color: msgData['underline'] ? 'green' : 'white',
                                        }} />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Row className="d-flex justify-content-center p-1">
                            <hr />
                            <Col xs={10} className="d-flex justify-content-between">


                                <FaAlignLeft
                                    style={{
                                        cursor: 'pointer',
                                    }}

                                    onClick={() => handleImageMessageListChange(index, { "text_align": 'left' })} className={msgData['text_align'] === 'left' ? 'text-success' : ''} />


                                <FaAlignJustify
                                    style={{
                                        cursor: 'pointer',
                                    }}

                                    onClick={(e) => handleImageMessageListChange(index, { "text_align": 'center' })} className={msgData['text_align'] === 'center' ? 'text-success' : ''} />


                                <FaAlignRight
                                    style={{
                                        cursor: 'pointer',
                                    }}

                                    onClick={() => handleImageMessageListChange(index, { "text_align": 'right' })} className={msgData['text_align'] === 'right' ? 'text-success' : ''} />


                            </Col>
                            <hr className="my-3" />
                            <Col xs={10} className="d-flex justify-content-between ">


                                <MdAlignVerticalBottom
                                    style={{
                                        cursor: 'pointer',
                                    }}

                                    onClick={() => handleImageMessageListChange(index, { 'vertical_align': 'end' })} className={msgData['vertical_align'] === 'end' ? 'text-success' : ''} />


                                <MdAlignVerticalCenter
                                    style={{
                                        cursor: 'pointer',
                                    }}

                                    onClick={(e) => handleImageMessageListChange(index, { 'vertical_align': 'center' })} className={msgData['vertical_align'] === 'center' ? 'text-success' : ''} />


                                <MdAlignVerticalTop
                                    style={{
                                        cursor: 'pointer',
                                    }}

                                    onClick={() => handleImageMessageListChange(index, { 'vertical_align': 'baseline' })} className={msgData['vertical_align'] === 'baseline' ? 'text-success' : ''} />


                            </Col>


                        </Row>
                        <hr />
                        <Form.Group>
                            <Form.Label><strong>Font Family</strong></Form.Label>
                            <Form.Select
                                value={msgData['font_family']}
                                onChange={(e) => (e.preventDefault, handleImageMessageListChange(index, { 'font_family': e.target.value }))}
                                aria-label="Default select example"
                            >
                                {['Helvetica', 'Times New Roman', 'Arial', 'Courier New', 'Georgia', 'Trebuchet MS', 'Tahoma', 'Verdana', 'Monospace'].map((v, i) =>
                                    <option
                                        style={{
                                            fontFamily: v
                                        }}
                                        key={i}
                                        value={v}>

                                        {v}

                                    </option>
                                )}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="mt-3">
                            <Form.Label><strong>Font Size</strong></Form.Label>


                            <CustomRangeField
                                value={msgData['font_size']}
                                min={100}
                                max={1000}
                                step={10}
                                onChange={(e) => (e.preventDefault(), handleImageMessageListChange(index, { 'font_size': e.target.value }))}
                            ><strong>%</strong></CustomRangeField>


                            {/* <Form.Range value={msgData['font_size']} min={100} max={1000} step={10} onChange={(e) => (e.preventDefault(), handleImageMessageListChange(index, { 'font_size': e.target.value }))}
                            /> */}
                        </Form.Group>
                        <Form.Group className="my-3">
                            <Form.Label><strong>Font Color</strong></Form.Label>


                            <Row className="d-flex justify-content-between">
                                <Col className="d-flex justify-content-center">
                                    {
                                        defaultColors.map((c, i) =>
                                            <div
                                                key={i}
                                                className="m-1"
                                                style={{
                                                    backgroundColor: c,
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={() => handleImageMessageListChange(index, { 'font_color': c })}
                                            >
                                            </div>

                                        )
                                    }
                                </Col>
                            </Row>
                            <div className="mt-2">
                                <p><strong>Custom</strong></p>
                                <input style={{ cursor: 'pointer' }} type="color" value={msgData['font_color']} onChange={e => handleImageMessageListChange(index, { "font_color": e.target.value })} />
                            </div>



                        </Form.Group>


                        <hr />

                        <>

                            <Form.Group>
                                <Form.Label>
                                    <strong>Text outline</strong>

                                </Form.Label>
                                <Form.Check // prettier-ignore

                                    type={'radio'}
                                    id={`no-outline`}
                                    label={`None`}
                                    name='outline'
                                    value={'none'}
                                    onChange={(e) => handleOutlineChange(e, 'change_to_none')}
                                    // onClick={(e) => (handleImageMessageListChange(index, 'outline_type', 'none'))}
                                    checked={msgData['outline_type'] === 'none' ? true : false}
                                />
                                <Form.Check // prettier-ignore
                                    type={'radio'}
                                    id={`shadow-outline`}
                                    label={`Shadow`}
                                    name='outline'
                                    value={'shadow'}
                                    onChange={(e) => handleOutlineChange(e, 'change_to_blur')}
                                    checked={msgData['outline_type'] === 'shadow' ? true : false}
                                />
                                <Form.Check // prettier-ignore
                                    type={'radio'}
                                    id={`outline-outline`}
                                    label={`Outline`}
                                    name='outline'
                                    value={'outline'}
                                    onChange={(e) => handleOutlineChange(e, 'change_to_outline')}
                                    checked={msgData['outline_type'] === 'outline' ? true : false}
                                />

                            </Form.Group>





                            <Form.Group>
                                <Form.Label><strong>Outline Width</strong></Form.Label>



                                {
                                    msgData['outline_type'] === 'outline' ?

                                        <CustomRangeField
                                            value={msgData['outline_width']}
                                            min={0}
                                            max={.1}
                                            step={.005}
                                            onChange={(e) => handleOutlineChange(e, 'outline_width')}
                                        ><strong> px</strong></CustomRangeField>
                                        :
                                        msgData['outline_type'] === 'shadow' ?
                                            <CustomRangeField
                                                value={msgData['outline_blur']}
                                                min={0}
                                                max={1}
                                                step={.005}
                                                onChange={(e) => handleOutlineChange(e, "outline_blur")}
                                            ><strong> px</strong></CustomRangeField>

                                            :
                                            <CustomRangeField
                                                value={0}
                                                min={0}
                                                max={1}
                                                step={.005}
                                                disabled
                                            ><strong> px</strong></CustomRangeField>

                                }


                            </Form.Group>


                            {
                                msgData['outline_type'] !== 'none' &&
                                <Form.Group>
                                    <Form.Label><strong>Outline Color</strong></Form.Label>


                                    <Row className="d-flex justify-content-between">
                                        <Col className="d-flex justify-content-center">
                                            {
                                                defaultColors.map((c, i) =>
                                                    <div
                                                        key={i}
                                                        className="m-1"
                                                        style={{
                                                            backgroundColor: c,
                                                            width: '30px',
                                                            height: '30px',
                                                            borderRadius: '5px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => handleOutlineChange(c, "outline_color")}
                                                    >
                                                    </div>

                                                )
                                            }
                                        </Col>
                                    </Row>
                                    <div className="mt-2">
                                        <p><strong>Custom</strong></p>
                                        <input style={{ cursor: 'pointer' }} type="color" value={msgData['outline_color']} onChange={e => handleOutlineChange(e.target.value, "outline_color")} />
                                    </div>



                                </Form.Group>
                            }

                        </>



                        <hr />

                        <Form.Group className="mt-3">
                            <Form.Label><strong>Text Angle</strong></Form.Label>

                            {/* <div>{msgData['angle']}°</div> */}
                            <CustomRangeField
                                value={msgData['angle']}
                                min={0}
                                max={350}
                                step={1}
                                onChange={(e) => (e.preventDefault(), handleImageMessageListChange(index, { 'angle': e.target.value }))}
                            ><strong>°</strong></CustomRangeField>

                            {/* <Form.Range value={msgData['angle']} min={0} max={359} step={1} onChange={(e) => (e.preventDefault(), handleImageMessageListChange(index, { 'angle': e.target.value }))}
                            /> */}
                        </Form.Group>


                        <hr />

                        <Form.Group>

                            <Form.Label><strong>Background Color</strong></Form.Label>
                            <div>

                                <Row className="d-flex justify-content-between">
                                    <Col className="d-flex justify-content-center">
                                        {
                                            defaultColors.map((c, i) =>
                                                <div
                                                    key={i}
                                                    style={{
                                                        backgroundColor: c,
                                                        width: '30px',
                                                        height: '30px',
                                                        borderRadius: '5px',
                                                        cursor: 'pointer',

                                                    }}
                                                    className="m-1"
                                                    onClick={() => handleImageMessageListChange(index, { 'background_color': c })}
                                                >
                                                </div>
                                            )
                                        }
                                    </Col>
                                </Row>
                                <div className="mt-2">
                                    <p><strong>Custom</strong></p>
                                    <input style={{ cursor: 'pointer' }} type="color" value={msgData['background_color']} onChange={(e) => handleImageMessageListChange(index, { 'background_color': e.target.value })} />

                                    <div className="mt-3">

                                        <Form.Group>
                                            <Form.Label><strong>Background Opacity</strong></Form.Label>
                                            {/* <div>{msgData['background_opacity']}</div> */}

                                            <CustomRangeField
                                                value={msgData['background_opacity']}
                                                min={0}
                                                max={100}
                                                step={1}
                                                onChange={(e) => handleFontBackgroundColorOpacityChange(e, index)}
                                            ><strong>%</strong></CustomRangeField>

                                            {/* 
                                            <Form.Range onChange={(e) => handleFontBackgroundColorOpacityChange(e, index)} value={msgData['background_opacity']} min={0} max={100} step={1} /> */}
                                        </Form.Group>

                                    </div>



                                </div>

                            </div>

                            <Form.Switch
                                // active
                                className="mt-3"
                                onClick={(e) => e.target.checked === true ?
                                    handleImageMessageListChange(index, { 'rounded_border': true })
                                    :
                                    handleImageMessageListChange(index, { 'rounded_border': false })


                                }

                                label="Rounded border" />
                            <hr />
                            <Form.Switch
                                // active
                                className="mt-3"
                                onClick={(e) => e.target.value === 'on' ?
                                    handleImageMessageListChange(index, { 'background_color': 'transparent' })
                                    :
                                    handleImageMessageListChange(index, { 'background_color': '#FFFFFF' })


                                }

                                label="No background color" />



                        </Form.Group>


                    </>

                }

                {message_index === 'base_message' &&
                    <>

                        <Form.Group className="mb-3">
                            <Form.Label><strong>Split post</strong></Form.Label>
                            <Form.Select
                                value={image_data.image.split_post ? 'yes' : 'no'}
                                onChange={(e) => handleUpdateRespData(mediaName, {
                                    split_post: e.target.value === 'yes' ? true : false
                                })}
                            // aria-label="Default select example"
                            >
                                {['yes', 'no'].map((v, i) =>
                                    <option key={i} value={v}>
                                        {v}
                                    </option>
                                )}
                            </Form.Select>
                        </Form.Group>



                        {
                            image_data.image.split_post &&
                            <div >
                                <div>

                                    <Form.Group>
                                        <Form.Label><strong>Message Width</strong></Form.Label>
                                        <CustomRangeField
                                            value={messageWidth}
                                            min={6}
                                            max={12}
                                            step={1}
                                            onChange={(e) => handleMessageWidthChange(e, message_index)}
                                        />
                                    </Form.Group>

                                </div>
                            </div>
                        }
                        <hr />
                        <Form.Group className="mb-3">
                            <Form.Label><strong>Username text color</strong></Form.Label>


                            <Row className="d-flex justify-content-between">
                                <Col className="d-flex justify-content-center">
                                    {
                                        defaultColors.map((c, i) =>
                                            <div
                                                key={i}
                                                className="m-1"
                                                style={{
                                                    backgroundColor: c,
                                                    width: '30px',
                                                    height: '30px',
                                                    borderRadius: '5px',
                                                    cursor: 'pointer',
                                                }}
                                                onClick={(e) => handleUpdateBaseMessage(mediaName, { color: c })}
                                            >
                                            </div>

                                        )
                                    }
                                </Col>
                            </Row>
                            <div className="mt-2">
                                <p><strong>Custom</strong></p>
                                <input style={{ cursor: 'pointer' }} type="color" value={msgData['font_color']} onChange={e => handleUpdateBaseMessage(mediaName, { color: e.target.value })} />
                            </div>



                        </Form.Group>

                        <hr />
                        <>

                            <Form.Group>
                                <Form.Label>
                                    <strong>Text outline</strong>

                                </Form.Label>
                                <Form.Check // prettier-ignore

                                    type={'radio'}
                                    id={`no-outline`}
                                    label={`None`}
                                    name='outline'
                                    value={'none'}
                                    onChange={(e) => handleOutlineChange(e, 'change_to_none', true)}
                                    // onClick={(e) => (handleImageMessageListChange(index, 'outline_type', 'none'))}
                                    checked={msgData['outline_type'] === 'none' ? true : false}
                                />
                                <Form.Check // prettier-ignore
                                    type={'radio'}
                                    id={`shadow-outline`}
                                    label={`Shadow`}
                                    name='outline'
                                    value={'shadow'}
                                    onChange={(e) => handleOutlineChange(e, 'change_to_blur', true)}
                                    checked={msgData['outline_type'] === 'shadow' ? true : false}
                                />
                                <Form.Check // prettier-ignore
                                    type={'radio'}
                                    id={`outline-outline`}
                                    label={`Outline`}
                                    name='outline'
                                    value={'outline'}
                                    onChange={(e) => handleOutlineChange(e, 'change_to_outline', true)}
                                    checked={msgData['outline_type'] === 'outline' ? true : false}
                                />

                            </Form.Group>





                            <Form.Group>
                                <Form.Label><strong>Outline Width</strong></Form.Label>



                                {
                                    msgData['outline_type'] === 'outline' ?

                                        <Form.Range value={msgData['outline_width']} min={0} max={.1} step={.005} onChange={(e) => handleOutlineChange(e, 'outline_width', true)} />
                                        :
                                        msgData['outline_type'] === 'shadow' ?
                                            <Form.Range value={msgData['outline_blur']} min={0} max={1} step={.005} onChange={(e) => handleOutlineChange(e, "outline_blur", true)} />
                                            :
                                            <Form.Range disabled value={0} min={0} max={.1} step={.005} />

                                }


                            </Form.Group>


                            {
                                msgData['outline_type'] !== 'none' &&
                                <Form.Group>
                                    <Form.Label><strong>Outline Color</strong></Form.Label>


                                    <Row className="d-flex justify-content-between">
                                        <Col className="d-flex justify-content-center">
                                            {
                                                defaultColors.map((c, i) =>
                                                    <div
                                                        key={i}
                                                        className="m-1"
                                                        style={{
                                                            backgroundColor: c,
                                                            width: '30px',
                                                            height: '30px',
                                                            borderRadius: '5px',
                                                            cursor: 'pointer',
                                                        }}
                                                        onClick={() => handleOutlineChange(c, "outline_color", true)}
                                                    >
                                                    </div>

                                                )
                                            }
                                        </Col>
                                    </Row>
                                    <div className="mt-2">
                                        <p><strong>Custom</strong></p>
                                        <input style={{ cursor: 'pointer' }} type="color" value={msgData['outline_color']} onChange={e => handleOutlineChange(e.target.value, "outline_color", true)} />
                                    </div>



                                </Form.Group>
                            }

                        </>

                    </>
                }


            </Popover.Body >



        </Popover >


    )
});



export default FontEditor