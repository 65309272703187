import React from 'react';

import CampaignMediaComponent from './CampaignMediaComponent';
import { useImageBuilder } from '../contexts/ImageBuilderProvider';
import MockupButtons from './MockupButtons';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';




function ImageBuilder({ addMediaRef, handleShowModal, handleSubmit, isMounted, mediaRefs }) {

    const { respData, handleUpdateRespData } = useImageBuilder()
    // const [showModal, setShowModal] = useState(false);






    return (
        <Col>


            {/* <div>{respData && JSON.stringify(respData['image_types'])}</div> */}
            <div className="my-3 my-lg-3">
                <MockupButtons handleShowModal={handleShowModal} respData={respData} handleSubmit={handleSubmit} />
            </div>


            <Row className="mb-5 d-flex justify-content-center m-0" >
                {
                    respData && 'image_types' in respData && Object.keys(respData.image_types).map((i, index) => 
                        Object.keys(respData.image_types).length > 1 ?
                            <div key={index}>
                                <CampaignMediaComponent index={index}  mediaName={i} image_data={respData['image_types'][i]} addMediaRef={addMediaRef} handleUpdateRespData={handleUpdateRespData} showThumbnail={true} isMounted={isMounted} mediaRefs={mediaRefs} />
                            </div>
                            :
                            <div key={index}>
                                <CampaignMediaComponent index={index} mediaName={i} image_data={respData['image_types'][i]} addMediaRef={addMediaRef} handleUpdateRespData={handleUpdateRespData} showThumbnail={false} isMounted={isMounted} mediaRefs={mediaRefs} />
                            </div>
                    

                    )}

            </Row>


        </Col>





    )
}

export default ImageBuilder
