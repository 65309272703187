import React from 'react'


import Col from 'react-bootstrap/Col';

import Button from 'react-bootstrap/Button';

function ShowMapButton({ toggleMap, showMap }) {
    return (
        <Col xs={'12'} className="d-flex d-lg-none justify-content-center fixed-bottom mb-3" style={{ zIndex: '100000' }} >
            <Button
                variant="light"
                className="shadow-lg rounded-pill d-flex align-items-center justify-content-center px-4 py-2 fs-5" // Apply the Flexbox styles here
                onClick={toggleMap}
                style={{
                    border: '2px solid black'
                }}
            >
                <strong> {showMap ? "Hide Map" : "Show map"} </strong>
            </Button>

            {/* <Badge className="shadow" onClick={toggleMap} pill style={{ cursor: 'pointer', padding: "1rem", paddingInline: '2rem' }} bg="dark">

            <h5><strong> {showMap ? "Hide Map" : "Show map"} </strong></h5>
        </Badge> */}
        </Col>
    )
}

export default ShowMapButton
