import React, { useState, useRef } from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputField from './InputField'

function ConfirmDeleteModal({ showModal, handleHideModal, isLoading, handleDeleteCampaign, deleteID }) {


    const [formErrors, setFormErrors] = useState({});
    const confirmDeleteRef = useRef();

    const handleDeleteAccount = () => {
        setFormErrors({});


        if (confirmDeleteRef.current && confirmDeleteRef.current.value === 'delete') {
            handleDeleteCampaign(deleteID)

        }
        else {
            setFormErrors({ confirmation: "Input must match `delete`" })


        }

    }


    return (
        <Modal show={showModal} onHide={handleHideModal}>
            <Modal.Header closeButton>

                <Modal.Title>
                    Delete Account
                </Modal.Title>

            </Modal.Header>
            <Modal.Body>
                <InputField
                    fieldRef={confirmDeleteRef}
                    formText={"Type `delete` to confirm your account deletion"}
                    label="Confirmation"
                    error={formErrors['confirmation']}

                />
                {
                    isLoading ?
                        <Button disabled className="mt-4" onClick={() => handleDeleteAccount()} variant="danger">Deleting account...</Button>
                        :
                        <Button className="mt-4" onClick={() => handleDeleteAccount()} variant="danger">Delete Account</Button>
                }

            </Modal.Body>
        </Modal>


    )
}

export default ConfirmDeleteModal
