import React, { useState, useEffect } from 'react'
import Body from '../components/Body'
import { useApi } from '../contexts/ApiProvider';
import Spinner from 'react-bootstrap/esm/Spinner';
import Stack from 'react-bootstrap/Stack';
import Container from 'react-bootstrap/Container';
import Campaign from '../components/Campaign';



function FavoriteCampaignPage() {

    const [campaigns, setCampaigns] = useState();
    const api = useApi();

    useEffect(() => {

        (async () => {
            const response = await api.get('/favorite_campaigns');
            if (response.ok) {
                response.body.data.length === 0 ?
                    setCampaigns(null)
                    :
                    setCampaigns(() => response.body.data);
            }
            else {
                setCampaigns(null);
            }
        })();

    }, [api]);


    return (
        <Body scrollable sidebar>
            <div className="scrollable">
                <h2 className="pricingSubHeader">Favorite Campaigns</h2>
                <Container>
                    {
                        campaigns === undefined ?
                            <div className="mt-5 d-flex justify-content-center"><Spinner /></div>
                            :
                            campaigns === null ?
                                <div className="mt-5 d-flex justify-content-center"><h4>No favorited campaigns</h4></div>
                                :
                                <Stack gap={3}>
                                    {
                                        campaigns.map((i, v) =>
                                            <div key={v}>
                                                <Campaign campaign={i} isLoading={false} />
                                            </div>
                                        )}
                                </Stack >
                    }
                </Container>
            </div>
        </Body>
    )
}

export default FavoriteCampaignPage
