import React from 'react'
import Spinner from 'react-bootstrap/Spinner';
import FollowerRow from './FollowRow'



function Following({following,handleRefresh}) {

    // const api = useApi();

    // // const [following, setFollowing] = useState();


    // useEffect(() => {
    //     (async () => {
    //         const response = await api.get("/me/following");
    //         if (response.ok) {
    //             response.body.data.length === 0 ? 
    //             setFollowing(null)
    //             :
    //             setFollowing(() => response.body.data);

    //         }
    //         else {
    //             setFollowing(null);
    //         }
    //     })();

    // }, [])

    return (
        <div>
            {
                following === undefined ?
                    <Spinner />
                    :
                    following === null ?
                        <p>No following</p>
                    :
                    following.map(follower => <FollowerRow key={follower.url} handleRefresh={handleRefresh} username={follower.username} isFollowing={true} url={follower.url}/> )
        }
        </div>
    )
}

export default Following

