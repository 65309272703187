import React from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Button from 'react-bootstrap/Button';
import { FaCircleInfo } from "react-icons/fa6";

function InfoPopover({ size, popover }) {
    return (

        <OverlayTrigger flip={true} trigger={['hover', 'focus']} placement="bottom" overlay={popover}>
            <Button className="mx-2" variant="link" style={{ padding: 0 }}>
                <FaCircleInfo style={{ verticalAlign: 'top' }} size={!size ? 16 : size} />
            </Button>
        </OverlayTrigger>

    )
}

export default InfoPopover
