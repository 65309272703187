import React from 'react'
import Row from 'react-bootstrap/Row';
import Card from 'react-bootstrap/Card';
import Col from 'react-bootstrap/Col';



function CardRowGroup({ timeframes }) {

    const cardStyle = {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    };

    const cardBodyStyle = {
        flex: 1,
    };

    return (
        <Row xs={1} md={3} className="g-5 d-flex justify-content-center my-5">
            {
                timeframes.map((_, idx) => (
                    <Col key={idx}>
                        <Card style={cardStyle}>
                            <Card.Body style={cardBodyStyle}>
                                <Card.Header className="rounded bg-secondary"><Card.Title className=""><strong>{_.label}</strong></Card.Title></Card.Header >

                                <div className="my-4 text-center">
                                    {
                                        _.icon
                                    }
                                </div>

                                <ul>

                                    {_.subText.map((val, i) =>

                                        <li key={i}>
                                            <div>
                                                {val}
                                            </div>
                                        </li>

                                    )}
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                ))}
        </Row>
    )
}

export default CardRowGroup
