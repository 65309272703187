import React, { useState } from 'react';
import { Button, Overlay, Tooltip, Popover } from 'react-bootstrap';
import { FaGear } from "react-icons/fa6";


const ClickHoverPopover = ({ children, tooltipMsg, icon, color }) => {
    const [show, setShow] = useState(false); // Manages visibility for overlay
    const [isPopover, setIsPopover] = useState(false); // Determines the content type (tooltip or popover)
    const [targetElement, setTargetElement] = useState(null); // Tracks the target for positioning

    const handleMouseEnter = (event) => {
        if (!isPopover) {
            setTargetElement(event.target);
            setShow(true);
        }
    };

    const handleMouseLeave = () => {
        if (!isPopover) {
            setShow(false);
        }
    };

    const handleClick = (event) => {
        setTargetElement(event.target);
        setIsPopover((prev) => !prev);
        setShow(true);
    };

    const handleBlur = () => {
        // Only hide when not interacting with the popover
        if (!isPopover) {
            setShow(false);
        }
    };

    const handleHide = () => {
        setShow(false);
        setIsPopover(false); // Reset to tooltip mode
    };

    return (
        <>
            {
                <Button
                    variant={color ? color : "outline-light"}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                    onClick={handleClick}
                    onBlur={handleBlur}
                >
                    {
                        !icon ?
                            <FaGear size={24} />
                            :
                            icon
                    }

                </Button>
            }

            <Overlay rootClose={isPopover} // Close when clicking outside, only in popover mode
                onHide={handleHide} target={targetElement} show={show} placement={isPopover ? 'right' : 'top'}>
                {(props) =>
                    isPopover ? (

                        <Popover id="popover" {...props}>

                            {children}
                        </Popover>
                        // <Popover id="popover" {...props}>
                        //     <Popover.Header as="h3">Popover Title</Popover.Header>
                        //     <Popover.Body>{children}</Popover.Body>
                        // </Popover>
                    ) : (
                        <Tooltip id="tooltip" {...props}>
                            {tooltipMsg}
                        </Tooltip>
                    )
                }
            </Overlay>
        </>
    );
};

export default ClickHoverPopover;
