import React from 'react'
import FollowButton from './FollowButton';

import { Link } from 'react-router-dom'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function FollowRow({ isFollowing, username, url, handleRefresh }) {

    return (
        <Row className="mb-3">
            <Col>
                <Link to={{ pathname: `/user/${username}` }}>
                    {username}
                </Link>
            </Col>
            <Col className="d-flex justify-content-end">
                <FollowButton handleRefresh={handleRefresh} isFollowing={isFollowing} url={url} />
            </Col>
        </Row>
    )
}

export default FollowRow
