import React, { useRef, useState } from 'react'

import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import { FaGear } from "react-icons/fa6";

import { useImageBuilder } from '../contexts/ImageBuilderProvider';


function NewCampaignImageBackground({ image_data, mediaName, handleFileInputChange, message_index, isUpload, hideGear }) {

    const { handleUpdateRespData, handleToggleImgEditDisplay } = useImageBuilder()
    const popover = <Popover id={`popover-${12}`}>
        <Popover.Body>Background settings</Popover.Body>
    </Popover>

    const backgroundInputRef = useRef()

    const handleButtonClick = () => {
        backgroundInputRef.current.click()
    };

    const [isLoading, setIsLoading] = useState(false);

    const handleUpload = async (e) => {
        setIsLoading(true);
        handleFileInputChange(e, mediaName, (() => setIsLoading(false)));
        ;

        // let resp = handleFileInputChange(e, mediaName);
        // console.log(resp);
        // setIsLoading(false)
    }
    return (
        image_data["image"]['url'] && image_data['image']['url'] !== null ?
            <Col className="my-4" xs={12} style={{ display: 'flex', justifyContent: !hideGear ? "space-between" : "center" }}>
                <div className={`w-100 ${!hideGear ? "justify-content-between" : "justify-content-center"} d-flex`}>

                    <Button variant="danger" onClick={() => handleUpdateRespData(mediaName, {
                        url: false,
                        final_url: false,
                        filename: false
                    })} className="rounded-pill">
                        {isUpload ? 'Remove your media' : 'Remove background image'}

                    </Button>

                    {!hideGear &&
                        <Button onClick={() => handleToggleImgEditDisplay(mediaName)} className="rounded-pill" variant="outline-primary"><FaGear size={24} /></Button>
                    }

                </div>
            </Col >
            :
            <Col className="my-4" xs={"12"} >

                {/* <Form.Group controlId="formFile" > */}

                <Form.Control style={{ display: 'none' }} onChange={(e) => handleUpload(e)} ref={backgroundInputRef} accept="image/*" type="file" />
                <Row className="d-flex justify-content-center">
                    <Col xs={'auto'} className="d-grid justify-content-center" >

                        <div className="d-block" style={{ minWidth: '250px', maxWidth: '500px' }}>
                            <Button className="d-block w-100 rounded-pill" disabled={isLoading} size="sm" onClick={() => handleButtonClick(true)} variant="primary">
                                <strong>{isUpload ? 'Upload your media' : 'Upload a background image'}</strong>
                            </Button>
                            {!hideGear ?
                                <Form.Text>Edit the background image and other settings using the gear icon</Form.Text>
                                :
                                <Form.Text>Edit the background image and other background settings</Form.Text>

                            }
                        </div>

                    </Col>
                    {
                        !hideGear &&
                        <Col className="d-flex justify-content-end">
                            <div>
                                <OverlayTrigger rootClose={true} trigger={['focus', 'hover']} placement="left" overlay={popover}>
                                    <Button onClick={() => handleToggleImgEditDisplay(mediaName)} variant="outline-primary"><FaGear size={24} /></Button>
                                </OverlayTrigger>

                                {/* <Button onClick={() => handleToggleImgEditDisplay(mediaName)} variant="outline-primary"><FaGear size={24} /></Button> */}

                            </div>
                        </Col>
                    }


                </Row>

            </Col>

    )
}

export default NewCampaignImageBackground
