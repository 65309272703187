import React from 'react'

import Spinner from 'react-bootstrap/Spinner'


function LoadingPage() {



    return (


        <div style={{
            height: '100vh'
        }}>

            <div style={{
                height: "100%",
                textAlign: "center",
                alignContent: "center",
            }}>
                <Spinner size={40} />
            </div>

        </div >


    )
}

export default LoadingPage
