import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import YoutubeVideo from '../components/YoutubeVideo'
import { Link, useNavigate } from 'react-router-dom'
import { GiPoliceBadge } from "react-icons/gi";
import Badge from 'react-bootstrap/Badge';
import { GoArrowDown } from "react-icons/go";
import { useUser } from '../contexts/UserProvider'
import Footer from '../components/Footer'
import { Helmet } from 'react-helmet';
import HeroSectionV1 from '../components/HeroSectionV1'
// import HeroSectionV2 from './HeroSectionV2'
// import HeroSectionV3 from './HeroSectionV3'
import MimicIconsWithReactIcons from '../components/MimicIconsWithReactIcons';
import { FcNews } from "react-icons/fc";
import { HiUserGroup } from "react-icons/hi";
import { MdOutlineTheaterComedy } from "react-icons/md";
// import CircleBadge from '../components/CircleBadge'
import { GiPaintBrush } from "react-icons/gi";
import { PiShootingStar } from "react-icons/pi";
import ContributionPricing from '../components/ContributionPricing';
import FlashMessage from "../components/FlashMessage"
import youtubeUrls from '../utils/youtubeUrls';
import { landingPageCarousel, mimicIcons } from '../utils/staticUrls';
import ContributionOutcomeCardGroup from '../components/ContributionOutcomeCardGroup';
import HowItWorksVideo from '../components/HowItWorksVideo';
import CloudAnimation from '../components/CloudAnimation';


function LandingPage() {

    const { createCampaign } = useUser();

    // alert(JSON.stringify(youtubeUrls))
    const navigate = useNavigate()
    const CAROUSEL_INTERVAL = 3000



    const handleNewCampaign = async () => {
        const proceed = await createCampaign();
        //This used to throw an error
        if (proceed) {
            navigate('/new_campaign/campaign_type', { replace: true })
        }
    }
    const HEADER_CLASSES = "pricingSubHeader text-center mb-5"

    const circleStyle = {
        width: '40px',
        height: '40px',
        borderRadius: '50%',
        backgroundColor: 'red',
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        fontSize: '1.5rem',
        marginRight: '.5em'
    };



    const landingPageImages = [
        mimicIcons.mockup2,
        landingPageCarousel.landing2,
        landingPageCarousel.anybody_can_contribute,
        landingPageCarousel.truck2,
        landingPageCarousel.voiceTownSquare,

        // landingPageCarousel.echoChamber,

    ]




    const timeframes = [
        { start: 0, end: 4, id: 0, label: "Starting a campaign:" },
        { start: 4, end: 11, id: 1, label: "Select a billboard or mobile campaign type." },
        { start: 11, end: 27, id: 2, label: "Select a location for your campaign." },
        { start: 27, end: 43, id: 3, label: "Create or upload media for your campaign" },
        { start: 43, end: 48, id: 4, label: "Set a fundraising period for your campaign" },
        { start: 48, end: 53, id: 5, label: "Create a title and optional description." },
        { start: 53, end: 65, id: 6, label: "Agree to our terms of service & post." },
        // Add more timeframes as needed
    ];
    const creatingMedia = [
        { start: 0, end: 4, id: 0, label: "To create media you can:", ind: true, },
        { start: 4, end: 11, id: 1, label: "Upload your own design" },
        { start: 11, end: 27, id: 2, label: "Post a message" },
        { start: 27, end: 43, id: 3, label: "Use our Drag & Drop editor" },
        { start: 43, end: 48, id: 4, label: "Generate and use AI images in your design (paid feture)" },
        { start: 48, end: 53, id: 5, label: "Generate free mockups of your design on your media type" },
        // Add more timeframes as needed
    ];

    const payment_video_timeframes = [
        { start: 0, end: 3, id: 0, label: "Contributing to a campaign:" },
        { start: 4, end: 6, id: 1, label: "Enter an amount to contribute" },
        { start: 6, end: 10, id: 2, label: "Enter the name your contribution will appear as on the campaign page or contribute anonymously" },
        { start: 10, end: 15, id: 3, label: "Add an optional message to your contribution" },
        { start: 15, end: 18, id: 4, label: "Enter an email to receive payment confirmations and campaign updates." },
        { start: 18, end: 32, id: 5, label: "Enter payment details" },
        { start: 32, end: 38, id: 6, label: "Contribute" },
        // Add more timeframes as needed
    ];
    const ai_image_video = [
        { start: 0, end: 3, id: 0, label: "Creating AI Images:" },
        {
            start: 4, end: 6, id: 1, label: <span>Create an account and purchase image credits. Pricing info can be found     <Link to={{ pathname: "/ai-images" }} className="" >here </Link></span>
        },
        { start: 6, end: 10, id: 2, label: "Enter a text prompt up to 4,000 charachters long." },
        { start: 10, end: 15, id: 3, label: "Set your image settings (size, style, quality)" },
        { start: 15, end: 18, id: 4, label: "Your image is sized for the media type you are creating" },
        { start: 18, end: 32, id: 5, label: "Use the image when creating your media campaigns" },

        // Add more timeframes as needed
    ];

    const favoriteFollowVideo = [
        { start: 6, end: 10, id: 2, label: "Favorite campaigns" },
        { start: 10, end: 15, id: 3, label: "Follow users" },
        { start: 15, end: 18, id: 4, label: "Create a personalized campaign feed of the users you follow and the campaigns you favorite" },
        { start: 18, end: 32, id: 5, label: "Create an account to create your campaign feed" },
        { start: 18, end: 32, id: 5, label: "CrowdPost is where social and outdoor media meet." },

        // Add more timeframes as needed
    ];



    const whosItForSubTextClass = 'fs-5 text-secondary'

    return (
        <div >

            <Helmet>
                <title>CrowdPost - Home</title>
                <meta property="og:title" content={`Let your voice reach the real town square`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
                <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." />

            </Helmet>






            <Container fluid>

                <FlashMessage />

            </Container>


            <Container fluid >
                <HeroSectionV1
                    handleNewCampaign={handleNewCampaign}
                    landingPageImages={landingPageImages}
                    CAROUSEL_INTERVAL={CAROUSEL_INTERVAL}
                />
            </Container>



            <Container fluid="xxl" style={{ marginTop: '6rem', marginBottom: '6rem' }}>



                <div>




                    <MimicIconsWithReactIcons />

                </div>

                <Row style={{ marginTop: '6rem', }} className="d-flex justify-content-center">
                    { /* Columns as flex containers */}
                    <Col xs={12} className={HEADER_CLASSES}>
                        How it works
                    </Col>

                    <Col xs={12} md={10} lg={4} className="d-flex flex-column">
                        <h4 className="text-sm-left text-lg-center mb-0 landingHeading"><strong><Badge bg='success' style={circleStyle}>
                            1
                        </Badge>Create a campaign</strong></h4>
                        <p className="text-muted fs-5 mt-2">Choose between a billboard or mobile truck campaign. Set a location, image, fundraising period, title, description and submit. There is <strong> no cost </strong> to create a campaign.</p>
                    </Col>
                    <GoArrowDown size={40} className="mb-3 d-lg-none" />
                    <Col xs={12} md={10} lg={4} className="d-flex flex-column">
                        <h4 className="text-sm-left text-lg-center mb-0 landingHeading"><strong><Badge bg='success' style={circleStyle}>
                            2
                        </Badge>Raise contributions</strong></h4>
                        <p className="text-muted fs-5 mt-2">Share your campaign. No account needed to contribute. Contributions are accepted throughout the fundraising period. </p>
                    </Col>
                    <GoArrowDown size={40} className="mb-3 d-lg-none" />
                    <Col xs={12} md={10} lg={4} className="d-flex flex-column">
                        <h4 className="text-sm-left text-lg-center mb-0 landingHeading"><strong><Badge bg='success' style={circleStyle}>
                            3
                        </Badge>Campaign is sent to our media partners</strong></h4>
                        <p className="text-muted fs-5 mt-2">The campaign stops accepting contributions at the end of its fundraising period and is submitted. If not enough funds are raised, no one is charged. Otherwise, our media partners determine if the posting can be accomodated.</p>
                    </Col>
                </Row>

                <Row className="d-flex justify-content-center justify-content-start my-5">

                    {/* <Col xs="12" className="text-center ">
                        <GoArrowDown size={40} />
                    </Col> */}
                    <Col xs="12" >
                        <div
                            style={{
                                position: "relative", // Ensures the text is positioned relative to the container
                                width: "100%",
                                height: "200px",
                                borderRadius: '10rem',
                                overflow: 'hidden',

                            }}
                            className="bg-success"
                        >
                            <CloudAnimation />
                            <div
                                style={{
                                    position: "absolute",
                                    top: "50%",
                                    left: "50%",
                                    transform: "translate(-50%, -50%)", // Centers text horizontally and vertically
                                    color: "white",
                                    fontSize: "2rem",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    zIndex: 2, // Ensures the text is above the CloudAnimation

                                }}

                            >
                                <Badge className="p-3 px-4" pill bg="dark">Campaign submitted to our media partners</Badge>
                            </div>
                        </div>

                    </Col>






                    <Row className="d-none justify-content-between text-center mb-5 mx-0 px-0">
                        {/* <Row className="d-flex d-md-none justify-content-between text-center mb-5 mx-0 px-0"> */}
                        <Col xs={4} >
                            <div className="d-column mt-5">
                                {/* <div className="mb-3">
                                    <GoArrowDown size={40} />
                                </div> */}
                                <div className="d-flex justify-content-center">
                                    <Badge style={{ fontSize: '1em' }} pill bg="success"><strong>Accepted</strong></Badge>
                                </div>
                            </div>

                        </Col>
                        <Col xs={4} className="">
                            <div className="d-column mt-5">
                                {/* <div className="mb-3">
                                    <GoArrowDown size={40} />
                                </div> */}

                                <div className="d-flex justify-content-center">
                                    <Badge size={'sm'} style={{ fontSize: '1em' }} pill bg="warning"><strong className="text-secondary">Underfunded</strong></Badge>
                                </div>
                            </div>
                        </Col>
                        <Col xs={4} className="">
                            <div className="d-column mt-5">
                                {/* <div className="mb-3">
                                    <GoArrowDown size={40} />
                                </div> */}

                                <div className="d-flex justify-content-center">

                                    <Badge style={{ fontSize: '1em' }} pill bg="danger"><strong>Denied</strong></Badge>
                                </div>
                            </div>
                        </Col>

                    </Row>


                    <div className="mt-5">
                        <ContributionOutcomeCardGroup />
                    </div>


                </Row>

            </Container>


            <Container style={{ marginTop: '12rem', marginBottom: '12rem' }} className="rounded " fluid >
                <Container>

                    <Col className="  " xs={12}>

                        <h1 className={HEADER_CLASSES}>Who's CrowdPost for?</h1>


                        <Row className="my-5">
                            <Col lg={4} xs={6} className="my-1 text-center">
                                <HiUserGroup style={{ color: 'whitesmoke', background: "linear-gradient(45deg, rgb(115, 115, 115), rgb(28, 96, 4), rgb(26, 162, 156))", border: '1px solid white', borderRadius: '.2rem', padding: '.2rem', backgroundColor: 'white' }} size={50} />
                                <h2><strong>Everyone!</strong></h2>
                                <div className={whosItForSubTextClass}>
                                    CrowdPost was created to let people have a say in their public discourse.
                                </div>
                            </Col>
                            <Col lg={4} xs={6} className="my-1 text-center">
                                <FcNews size={50} />
                                <h2><strong>Journalists & authors</strong></h2>
                                <div className={whosItForSubTextClass}>
                                    Bring your reporting directly to your audience
                                </div>
                            </Col>
                            <Col lg={4} xs={6} className="my-1 text-center">
                                <MdOutlineTheaterComedy style={{ color: 'whitesmoke', background: "linear-gradient(45deg, rgb(115, 115, 115), rgb(6, 111, 47), rgb(74, 196, 196))", border: '1px solid white', borderRadius: '.2rem', padding: '.2rem', }} size={50} />
                                <h2><strong>Comedians</strong></h2>

                                <div className={whosItForSubTextClass}>
                                    We prize comedians role in society. Pick a clever area and get your material out there
                                </div>
                            </Col>
                            <Col xs={12} className="d-none d-lg-block my-3"></Col>
                            <Col lg={4} xs={6} className="my-1 text-center">
                                <GiPaintBrush style={{ color: 'whitesmoke', border: '1px solid white', borderRadius: '.2rem', padding: '.2rem', background: 'linear-gradient(45deg, rgb(115, 115, 115), rgb(111, 6, 104), rgb(196, 183, 74))' }} size={50} />
                                <h2><strong>Artists</strong></h2>
                                <div className={whosItForSubTextClass}>
                                    Showcase your art where it matters most
                                </div>
                            </Col>
                            <Col lg={4} xs={6} className="my-1 text-center">
                                <PiShootingStar style={{ background: "linear-gradient(45deg, rgb(115, 115, 115), rgb(255, 214, 136), rgb(162, 109, 26))", border: '1px solid white', borderRadius: '.2rem', padding: '.2rem', color: 'whitesmoke' }} size={50} />
                                <h2><strong>Social influencers</strong></h2>
                                <div className={whosItForSubTextClass}>
                                    Launch your voice outside the online echo chambers and into key points of interest
                                </div>
                            </Col>
                            <Col lg={4} xs={6} className="my-1 text-center">
                                <GiPoliceBadge style={{ background: "linear-gradient(45deg, rgb(9, 24, 255), rgb(136, 194, 255), rgb(0, 15, 247))", border: '1px solid white', borderRadius: '.2rem', padding: '.2rem', color: 'whitesmoke' }} size={50} />
                                <h2><strong>Cold Case & Missing People</strong></h2>
                                <div className={whosItForSubTextClass}>
                                    Create no-cost campaigns to raise awarness in your community
                                </div>
                            </Col>
                        </Row>

                        {/* <h3 className="text-center">You don't have to do anything but create and post, CrowdPost manages the campaign. Anyone can contribute to campaigns, no account needed.</h3> */}
                        {/* 
                        <p className="lead fs-5">CrowdPost was built to give the community a chance to participate in its public discourse. Create a campaign and if it is captivating enough, people can vote with their dollars to post your message on a billboard or mobile LED truck. While social media's algorithms determine much of what we see online, outdoor media provides a way to sidestep the online echo chambers and speak directly to the community. CrowdPost manages the campaigns so creators don't have to do anything but create, <u>agree to our terms</u> and post. Anyone can contribute to campaigns, no account needed.</p> */}

                    </Col>


                </Container>
            </Container>



            <Container className="  " fluid >
                <Container fluid="md">
                    <Row className="">

                        <Col className="text-center" xs={12} >
                            <h1 className={HEADER_CLASSES}>How does pricing work?</h1>

                        </Col>

                        <Col className="my-5 bg-dark roundedBackground" xs={12} >
                            <h1 className="">Creating CrowdPost campaigns is free</h1>
                            <h4 className="text-secondary">
                                Create an accont and post your campaign for free.
                            </h4>
                        </Col>


                        <Col className="my-5 m-0 p-0" xs={12} >
                            <ContributionPricing receipt />
                        </Col>
                    </Row>
                </Container>
            </Container>





            <Container className="my-5  rounded " fluid>
                <Container>
                    <Row className="my-5 py-5 ">
                        <Col xs={12} className="text-center"
                        >
                            <h1 className="text-center pricingSubHeader mb-3">Creating Campaigns</h1>
                            <hr />
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} lg={{ span: 4, order: 1 }}>
                            <div className="mb-5">
                                <ul className="fs-5 campaignContributionList">
                                    <li>
                                        Free to create
                                    </li>
                                    <li>
                                        Anyone with an account can start a campaign.
                                    </li>
                                    <li>
                                        <strong>
                                            <span
                                            >{timeframes[0].label}</span>
                                        </strong>
                                        <ol>
                                            {timeframes.map((timeframe, index) => (
                                                timeframe.id !== 0 && <li
                                                    key={index}
                                                    className="text-secondary"
                                                >
                                                    {timeframe.label}
                                                </li>
                                            ))}
                                        </ol>
                                    </li>
                                </ul>
                                <div className="d-flex flex-column justify-content-center align-items-center"

                                >
                                    <Button
                                        variant="success"
                                        className="my-2 rounded-pill d-flex align-items-center justify-content-center px-3 py-2 w-100 w-sm-50" // Apply the Flexbox styles here
                                        onClick={() => handleNewCampaign()}

                                    >
                                        <h5 className="m-0"><strong>Start a Campaign</strong></h5>
                                    </Button>
                                    <Link style={{ textDecoration: 'none' }} to={{ pathname: "/creating-campaigns" }} className="my-2 rounded-pill d-flex align-items-center justify-content-center py-2 w-100 w-sm-100" >
                                        <Button
                                            variant="outline-primary"
                                            className="my-2 rounded-pill d-flex align-items-center justify-content-center px-3 py-2 w-100 w-sm-50" // Apply the Flexbox styles here
                                        >
                                            <h5 className="m-0"><strong>More Info</strong></h5>
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 12 }} lg={{ span: 8, order: 12 }}>
                            <YoutubeVideo
                                url={youtubeUrls['CREATING_CAMPAIGNS']}
                                title={"Creating campagns"}
                            />

                        </Col>
                    </Row>
                </Container>

            </Container>





            <Container className="my-5  rounded " fluid>
                <Container>
                    <Row className="my-5 py-5 ">
                        <Col xs={12} className="text-center"
                        >
                            <h1 className="text-center pricingSubHeader mb-3">Creating Campaign Media</h1>
                            <hr />
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} lg={{ span: 4, order: 12 }}>
                            <div className="mb-5">
                                <ul className="fs-5 campaignContributionList">
                                    <li>
                                        <strong>
                                            <span
                                            >{creatingMedia[0].label}</span>
                                        </strong>
                                        <ul>
                                            {creatingMedia.map((timeframe, index) => (
                                                index !== 0 && <li
                                                    key={index}
                                                    className="text-secondary"
                                                >
                                                    {timeframe.label}
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                </ul>
                                <div className="d-flex flex-column justify-content-center align-items-center"

                                >
                                    <Button
                                        variant="success"
                                        className="my-2 rounded-pill d-flex align-items-center justify-content-center px-3 py-2 w-100 w-sm-50" // Apply the Flexbox styles here
                                        onClick={() => handleNewCampaign()}

                                    >
                                        <h5 className="m-0"><strong>Start a Campaign</strong></h5>
                                    </Button>
                                    <Link style={{ textDecoration: 'none' }} to={{ pathname: "/creating-campaigns" }} className="my-2 rounded-pill d-flex align-items-center justify-content-center py-2 w-100 w-sm-100" >
                                        <Button
                                            variant="outline-primary"
                                            className="my-2 rounded-pill d-flex align-items-center justify-content-center px-3 py-2 w-100 w-sm-50" // Apply the Flexbox styles here
                                        >
                                            <h5 className="m-0"><strong>More Info</strong></h5>
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 12 }} lg={{ span: 8, order: 1 }}>
                            <YoutubeVideo
                                url={youtubeUrls['CREATING_MEDIA']}
                                title={"Creating campagns"}
                            />

                        </Col>
                    </Row>
                </Container>

            </Container>






            <Container fluid className="my-5  rounded ">
                <Container>

                    <Row className="my-5 py-5 "  >
                        <Col xs={12} className="text-center"
                        >
                            <h1 className="text-center pricingSubHeader mb-3">Contributing to Campaigns</h1>
                            <hr />
                        </Col>
                        <Col xs={{ order: 12, span: 12 }} lg={{ order: 2, span: 8 }}>

                            <YoutubeVideo
                                url={youtubeUrls['CONTRIBUTING_CAMPAIGNS']}
                                title={"Contributing to  campaigns"}
                            />

                        </Col>
                        <Col xs={{ order: 1, span: 12 }} lg={{ order: 4, span: 4 }} >
                            <div className="mb-5">
                                <div >
                                    <ul className="fs-5 campaignContributionList">
                                        <li>
                                            No account needed to contribute
                                        </li>
                                        <li>
                                            Contributions are not processed up front, but after the fundraising period.
                                        </li>
                                        <li>
                                            If not enough was raised to purchase media, contributions are not charged.
                                        </li>
                                        <li>
                                            <strong><span>{payment_video_timeframes[0].label}</span></strong>
                                            <ol>
                                                {payment_video_timeframes.map((timeframe, index) => (
                                                    timeframe.id !== 0 && <li
                                                        key={index}
                                                        className="text-secondary"
                                                    >
                                                        {timeframe.label}
                                                    </li>
                                                ))}
                                            </ol>
                                        </li>


                                    </ul>
                                    <div className="d-flex flex-column justify-content-center align-items-center">
                                        <Button
                                            variant="success"
                                            className="my-2 rounded-pill d-flex align-items-center justify-content-center px-3 py-2 w-100 w-sm-50" // Apply the Flexbox styles here
                                            onClick={() => handleNewCampaign()}

                                        >
                                            <h5 className="m-0"><strong>Start a Campaign</strong></h5>
                                        </Button>
                                        <Link style={{ textDecoration: 'none' }} to={{ pathname: "/pricing" }} className="my-2 rounded-pill d-flex align-items-center justify-content-center py-2 w-100 w-sm-100" >
                                            <Button
                                                variant="outline-primary"
                                                className="my-2 rounded-pill d-flex align-items-center justify-content-center px-3 py-2 w-100 w-sm-50" // Apply the Flexbox styles here
                                            >
                                                <h5 className="m-0"><strong>Pricing information</strong></h5>
                                            </Button>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>


            </Container>

            <Container fluid >
                <Container>
                    <Row >
                        <Col xs={12} className="text-center"
                        >
                            <h1 className="text-center pricingSubHeader mb-3">Generate Images with AI</h1>
                            <hr />
                        </Col>
                        <Col xs={{ span: 12, order: 1 }} lg={{ span: 4, order: 12 }}>
                            <div className="mb-5">
                                <ul className="fs-5 campaignContributionList">

                                    <li>
                                        <strong><span>{ai_image_video[0].label}</span></strong>
                                        <ul>
                                            {ai_image_video.map((timeframe, index) => (
                                                timeframe.id !== 0 &&
                                                <li
                                                    key={index}
                                                    className="text-secondary"
                                                >
                                                    {timeframe.label}
                                                </li>
                                            ))}
                                        </ul>
                                    </li>
                                </ul>
                                <span className="text-secondary small">
                                    Generating AI images is a good way to create unique images that are less likely to violate copyright law. Images still must not violate our terms and conditions like including copywritten material (logos, names, etc...).
                                </span>
                                <div className="d-flex flex-column justify-content-center align-items-center mt-3"

                                >
                                    <Button
                                        variant="success"
                                        className="my-2 rounded-pill d-flex align-items-center justify-content-center px-3 py-2 w-100 w-sm-50" // Apply the Flexbox styles here
                                        onClick={() => handleNewCampaign()}
                                    >
                                        <h5 className="m-0"><strong>Start a Campaign</strong></h5>
                                    </Button>
                                    <Link style={{ textDecoration: 'none' }} to={{ pathname: "/register" }} className="my-2 rounded-pill d-flex align-items-center justify-content-center py-2 w-100 w-sm-100" >
                                        <Button
                                            variant="outline-primary"
                                            className="my-2 rounded-pill d-flex align-items-center justify-content-center px-3 py-2 w-100 w-sm-50" // Apply the Flexbox styles here
                                        >
                                            <h5 className="m-0"><strong>Sign up</strong></h5>
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 12 }} lg={{ span: 8, order: 1 }}>

                            <YoutubeVideo
                                url={youtubeUrls.AI_IMGS_CAMPAIGNS}
                                title={"Create AI Images"}
                            />

                        </Col>
                    </Row>
                </Container>

            </Container>



            <Container fluid className="my-5  rounded " style={{ paddingBottom: '10rem' }}>
                <Container>
                    <Row >
                        <Col xs={12} className="text-center"
                        >
                            <h1 className="text-center pricingSubHeader mb-3">Where Social & Outdoor Media Meet</h1>
                            <hr />
                        </Col>

                        <Col xs={{ span: 12, order: 1 }} lg={{ span: 4, order: 1 }}>
                            <div className="mb-5">
                                <ul className="fs-5 campaignContributionList">




                                    {favoriteFollowVideo.map((timeframe, index) => (
                                        timeframe.id !== 0 &&
                                        <li
                                            key={index}
                                        >
                                            {timeframe.label}
                                        </li>
                                    ))}


                                </ul>

                                <div className="d-flex flex-column justify-content-center align-items-center mt-3"

                                >
                                    <Button
                                        variant="success"
                                        className="my-2 rounded-pill d-flex align-items-center justify-content-center px-3 py-2 w-100 w-sm-50" // Apply the Flexbox styles here
                                        onClick={() => handleNewCampaign()}
                                    >
                                        <h5 className="m-0"><strong>Start a Campaign</strong></h5>
                                    </Button>
                                    <Link style={{ textDecoration: 'none' }} to={{ pathname: "/register" }} className="my-2 rounded-pill d-flex align-items-center justify-content-center py-2 w-100 w-sm-100" >
                                        <Button
                                            variant="outline-primary"
                                            className="my-2 rounded-pill d-flex align-items-center justify-content-center px-3 py-2 w-100 w-sm-50" // Apply the Flexbox styles here
                                        >
                                            <h5 className="m-0"><strong>Sign up</strong></h5>
                                        </Button>
                                    </Link>
                                </div>
                            </div>
                        </Col>
                        <Col xs={{ span: 12, order: 12 }} lg={{ span: 8, order: 12 }}>

                            <YoutubeVideo
                                url={youtubeUrls.FAVORITE_FALLOW_CAMPAIGNS}
                                title={"Create AI Images"}
                            />

                        </Col>
                    </Row>
                </Container>

            </Container>





            <Footer />




        </div >


    )
}

export default LandingPage
