import React, { useState, useRef, useEffect } from 'react'
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';
import Popover from 'react-bootstrap/Popover';
import emojiData from '../data/emoji_final.json'


function EmojiPicker({ handleEmojiUpdate, index, mediaName, handleImageMessageListChange, messageValue }) {
    const refGroup = useRef({});
    const [activeEmoji, setActiveEmoji] = useState()
    const [emojiSearchResults, setEmojiSearchResults] = useState()
    const [emojiSearch, setEmojiSearch] = useState('')
    const searchRef = useRef();

    useEffect(() => {
        searchRef.current && searchRef.current.focus()
    }, [])

    const scrollToGroup = (groupName) => {
        refGroup.current[groupName].scrollIntoView({ behavior: 'smooth', block: 'start' });
    };

    const handleShowEmoji = (emoji, description) => {
        console.log(emoji)
        setActiveEmoji({ emoji: emoji, description: description })
    }


    function handleEmojiSearch(e) {
        // Convert query to lowercase to make the search case-insensitive
        e.preventDefault();
        let value = e.target.value
        setEmojiSearch(value)
        if (value && value.length > 0 && value.length < 30) {


            let query = value.toLowerCase();

            // Filter the items based on the 'message' field containing the query substring
            const results = [];

            // Iterate over all groups in the data object
            for (const group in emojiData) {
                // Filter the items in each group
                const filteredItems = emojiData[group].filter(item => item.message.toLowerCase().includes(query));
                results.push(...filteredItems);
            }
            // setEmojiSearchResults(results)
            console.log(results)
            setEmojiSearchResults(results)
        }
    }

    // Example usage

    const handleClearSearch = () => {
        setEmojiSearch('')
        setEmojiSearchResults()
    }


    return (
        <Popover style={{ backgroundColor: 'black', height: '380px',  }} id="popover-basic">
            <Popover.Header style={{ backgroundColor: 'black' }} >
                <InputGroup className="m-0">
                    {/* <InputGroup.Text>$</InputGroup.Text> */}
                    <Form.Control ref={searchRef} value={emojiSearch} onChange={(e) => handleEmojiSearch(e)} aria-label="Amount (to the nearest dollar)" />
                    <InputGroup.Text style={{ cursor: 'pointer' }} onClick={() => handleClearSearch()}><strong>x</strong></InputGroup.Text>
                </InputGroup>
                {/* <InputGroup>
                
                </InputGroup>
                <InputField
                    value={emojiSearchResults}
                    name="search"
                    label="Search"
                    onChange={(e) => handleEmojiSearch(e)}
                /> */}

                <div style={{ overflowX: 'auto', whiteSpace: 'nowrap', position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'black' }}>
                    {Object.keys(emojiData).map((group, idx) => (
                        <span key={idx} style={{ padding: '10px', cursor: 'pointer', display: 'inline-block' }} onClick={() => scrollToGroup(group)}>
                            <strong>{group}</strong>
                        </span>
                    ))}
                </div>
            </Popover.Header>

            <Popover.Body style={{ padding: '0', display: 'flex', flexDirection: 'column', height: '250px' }}>

                {/* <div style={{ overflowY: 'auto', height: activeEmoji ? '40%': '100%' }}> */}
                <div style={{ flex: '1', overflowY: 'auto', padding: '0.5rem 1rem', height: '75%' }}>

                    {
                        emojiSearch && emojiSearch.length > 0 ?


                            emojiSearchResults && emojiSearchResults.length > 0 ?

                                <div style={{ display: 'flex', flexWrap: 'wrap', }}>
                                    {emojiSearchResults.map((item, _i) => (
                                        <div style={{ fontSize: '1.75em', cursor: 'pointer', margin: '5px' }}
                                            // onClick={(e) => (console.log({ 'message': messageValue ? messageValue + e.target.textContent : e.target.textContent })
                                            // )}
                                            onClick={(e) => index === 'base' ?
                                                handleEmojiUpdate(e, 'base', mediaName)
                                                :
                                                handleImageMessageListChange(index,
                                                    { 'message': messageValue ? messageValue + e.target.textContent : e.target.textContent })}
                                            key={_i}
                                            onMouseEnter={(e) => handleShowEmoji(item.emoji, item.message)}
                                            onMouseLeave={(e) => setActiveEmoji()}
                                        >
                                            {item.emoji}
                                        </div>
                                    ))}
                                </div>
                                :
                                <div>No results</div>



                            :

                            Object.keys(emojiData).map((group, idx) => (
                                <div key={idx} ref={el => refGroup.current[group] = el}>
                                    <h5 style={{ position: 'sticky', top: 0, zIndex: 1, backgroundColor: 'black', paddingBottom: '5px' }}><strong>{group}</strong></h5>
                                    <div style={{ display: 'flex', flexWrap: 'wrap', }}>
                                        {emojiData[group].map((item, _i) => (
                                            <div style={{ fontSize: '1.75em', cursor: 'pointer', margin: '5px' }}
                                                onClick={(e) => index === 'base' ?
                                                    handleEmojiUpdate(e, 'base', mediaName)
                                                    :
                                                    handleImageMessageListChange(index,
                                                        { 'message': messageValue ? messageValue + e.target.textContent : e.target.textContent })}
                                                // onClick={(e) => handleImageMessageListChange(index,
                                                //     { 'message': messageValue ? messageValue + e.target.textContent : e.target.textContent })}
                                                key={_i}
                                                onMouseEnter={(e) => handleShowEmoji(item.emoji, item.message)}
                                                onMouseLeave={(e) => setActiveEmoji()}
                                            >
                                                {item.emoji}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            ))}
                </div>
                {/* {
                    activeEmoji && */}

                < div style={{ flex: '0 0 auto', height: '25%', overflowY: 'scroll', padding: '.3em' }}>

                    <span><span style={{ fontSize: '1.75em' }} > {activeEmoji && activeEmoji.emoji}</span> {activeEmoji && "-" + activeEmoji.description}</span>
                </div>

            </Popover.Body>

        </Popover >
        // <Popover style={{ backgroundColor: 'black', height: '300px', width: '300px', overflowY: 'auto', }} id="popover-basic">
        //     <Popover.Body >
        //         <div >
        //             {Object.keys(emojiData).map((group, idx) => (
        //                 <div key={idx}>
        //                     <h5 style={{ backgroundColor: 'black', position: 'sticky', top: 0, zIndex: 1 }}><strong>{group}</strong></h5>
        //                     {emojiData[group].map((item, index) => (
        //                         // <p key={index}>{item.emoji} - {item.message}</p>
        //                         <div style={{ fontSize: '1.75em', cursor: 'pointer' }} onClick={(e) => handleEmojiUpdate(e, message_id, mediaName)} key={index}>{item.emoji}</div>
        //                     ))}
        //                 </div>
        //             ))}
        //         </div>
        //     </Popover.Body>
        // </Popover>
    )
}

export default EmojiPicker
