import React, { useState } from 'react'
import FeedbackModal from './modals/FeebackModal';
import Button from 'react-bootstrap/Button';

function FeedbackButton() {
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const handleShowFeedbackModal = (data) => {
        console.log('1', data)
        setShowFeedbackModal(data);
    }

    return (
        <div>

            <FeedbackModal showModal={showFeedbackModal} handleCloseModal={handleShowFeedbackModal} />

            <div className="fs-5 fw-light my-4 px-2">
                <Button className="rounded-pill" onClick={() => handleShowFeedbackModal(true)} size="sm" variant="outline-secondary">Feedback</Button>
            </div>
        </div>
    )
}

export default FeedbackButton
