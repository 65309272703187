import React, { useEffect, useRef } from 'react'

import Spinner from 'react-bootstrap/Spinner'
import { useLocation, useNavigate } from 'react-router-dom';
import { useApi } from '../contexts/ApiProvider';
import { useUser } from '../contexts/UserProvider';
import { useFlash } from '../contexts/FlashProvider';

function AuthenticatingLoadingPage() {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get('code');
    const api = useApi();
    const { setUser } = useUser();
    const navigate = useNavigate();
    const flash = useFlash();
    const requestMadeRef = useRef(false);

    useEffect(() => {


        if (!requestMadeRef.current) {
            (async () => {


                const response = await api.get(`/google-auth`, new URLSearchParams({
                    authorization_response: window.location.href,
                    code: code
                }));


                if (response.ok) {
                    //I THINK THE PROBLEM IS THAT THERE ARE 2 ASYNC FUNCTIONS IN HERE?
                    console.log(response, 'GOOGLE AUTH RESP')
                    requestMadeRef.current = true
                    localStorage.setItem('accessToken', response.body.access_token)
                    const r2 = await api.get('/me');
                    setUser(r2.ok ? r2.body : null);
                    if ('new_user_greeting' in response.body && response.body.new_user_greeting === true) {
                        flash(<span>Welcome to <strong>CrowdPost!</strong></span>, 'success')
                    }
                    return navigate('/feed', { replace: true })

                }
                else {
                    requestMadeRef.current = true
                    // setUser(null)
                    console.log('error',response)
                }
            })();
        }


    },[])


    return (


        <div style={{
            height: '100vh'
        }}>

            <div style={{
                height: "100%",
                textAlign: "center",
                alignContent: "center",
            }}>
                <Spinner size={40} />
            </div>

        </div >


    )
}

export default AuthenticatingLoadingPage
