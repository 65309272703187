import React, { useState, useEffect, useRef } from 'react'
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Placeholder from 'react-bootstrap/Placeholder';
import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Row from 'react-bootstrap/Row';
import DatePicker from 'react-date-picker';
import { useApi } from '../../contexts/ApiProvider';

import { useNavigate } from 'react-router-dom';
import { useFlash } from '../../contexts/FlashProvider';
import NewCampaignPage from '../../components/NewCampaignPage';
import 'react-date-picker/dist/DatePicker.css';
import 'react-calendar/dist/Calendar.css';





function NewCampaignStartDate() {
    const flash = useFlash()
    const [selectedDate, setSelectedDate] = useState(null);
    const [daysDiff, setDaysDiff] = useState(null);
    const [requestSent, setRequestSent] = useState(false);
    

    const minStartDays = useRef()

    const handleChange = (date) => {
        date.setHours(23, 59, 0, 0);
        console.log("Selected date: ", date);
        setSelectedDate(date);
    }


    const api = useApi();
    const navigate = useNavigate();

    function handleSubmit(event) {
        event.preventDefault();
        setRequestSent(true);

        (async () => {
            const response = await api.post(`/new_campaign/start_date`, JSON.stringify({ start_date: selectedDate }));
            if (response.ok) {
                setRequestSent(false);
                flash('Your fundraising period was set', 'success')
                return navigate(`/new_campaign/description`, { replace: true })

            }
            else {
                "errors" in response.body ?
                    flash(response.body.errors.json.message, 'danger')
                    :
                    flash('There was an error sending your message', 'danger')

                setRequestSent(false)

            }
        })();
    }


    useEffect(() => {
        setRequestSent(true);
        (async () => {

            const response = await api.get(`/new_campaign/start_date`);
            if (response.ok) {

                let d = new Date(Date.parse(response.body.start_date))

                // setMinStartDate(d.toLocaleDateString('en-US', { day: '2-digit', month: '2-digit', year: 'numeric', hour: '2-digit', }))
                // // d = d.toLocaleDateString()
                // console.log(d, 'date here')
                setSelectedDate(d)
                minStartDays.current = response.body.min_start_days
                // setFormComplete(() => [response.body.image, response.body.mode, new Date(response.body.start_date)])
                setRequestSent(false);

            }
            else {

                'errors' in response.body ?
                    flash(response.body.errors.json.message, 'danger')
                    :
                    flash("Error with request", 'danger')
                setRequestSent(false)
                return false
            }

        })();
    }, [api, flash])


    useEffect(() => {
        // console.log(' change ', selectedDate)
        if (selectedDate) {

            let diff = calculateDaysDifference(selectedDate)

            // if (diff > 0) {
            // console.log('dif', diff)
            setDaysDiff(diff)
            // }
        }


    }, [selectedDate])



    const pageDescription = <div>
        <ul>
            <li>
                At the end of your fundraising period the campaign will:
                <ul>
                    <li className="my-2">
                        Stop accepting contribution payments
                    </li>
                    <li>
                        <p>Be submitted to our media partners</p>
                    </li>
                </ul>

            </li>
            <li>
                The longer your campaign is up, the more time it has to raise contributions and vice versa
            </li>

        </ul>

    </div>


    function calculateDaysDifference(inputDate) {
        inputDate.setHours(23, 59, 0, 0)
        // console.log('calculateDaysDifference', inputDate)
        // Ensure the input is a valid Date object
        // if (!(inputDate instanceof Date) || isNaN(inputDate)) {
        //     console.log(typeof(inputDate))
        //     throw new Error('Invalid date object');
        // }
        // Get the current date
        const currentDate = new Date();
        // console.log(currentDate, ' Current Date ')
        // Calculate the difference in time (milliseconds)
        const differenceInTime = inputDate - currentDate;

        // Convert the difference in time to days
        const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));


        return differenceInDays;
    }

    const HELP_HEADER = <h6>Setting your fundraising period:</h6>


    return (
        <NewCampaignPage disabled={requestSent} handleSubmit={handleSubmit} title="Set a fundraising period for your campaign" description={pageDescription} currentStep={3} helpHeader={HELP_HEADER}>


            <Container>


                <Form >
                    <Row className="d-flex justify-content-center mb-5">
                        {/* <h3 className="d-flex justify-content-center my-3" >Select a start date for the campaign</h3> */}
                        <div className=" text-center  my-4  fs-4" >

                            {/* Approximated start date should be <strong>{minStartDays.current && minStartDays.current} days</strong>  in the future, <strong>{minStartDate}</strong>.  */}

                            <div>
                                {
                                    ((daysDiff === undefined) || (daysDiff === false)) ?
                                        <Placeholder as={Card.Text} animation="glow">
                                            <Placeholder xs={3} lg={3} />
                                        </Placeholder>
                                        :
                                        <span>
                                            {daysDiff} {daysDiff > 1 ?
                                                'days '
                                                :
                                                'day '

                                            }
                                            of fundraising
                                        </span>

                                }

                            </div>

                        </div>

                        <Col className="d-flex justify-content-top" xs={9} md={4} >
                            {
                                selectedDate &&
                                <DatePicker
                                    value={selectedDate}
                                    onChange={(date) => handleChange(date)}
                                    dateFormat="dd/MM/yyyy hh:mm aa"
                                    isOpen={true}
                                    className="w-100"
                                    style={{ height: "100%" }}


                                    autoFocus
                                />
                            }


                        </Col>
                        <Col className="d-flex justify-content-top" xs={3} md={2} ><span className="fw-bolder"> 11:59 PM     {
                            selectedDate && selectedDate.toLocaleTimeString('en-US', { timeZoneName: 'short' }).split(' ').pop()
                        }</span></Col>

                        {/* <Col className="d-flex align-contnet-center justify-content-center my-3" xs={12} md={"auto"}>
                                <LoadingButton text="Submit" />
                            </Col> */}
                    </Row>
                </Form>
            </Container>



        </NewCampaignPage>

    )
}

export default NewCampaignStartDate
