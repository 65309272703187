import React, { useEffect, useState } from "react";
import ProgressBar from "react-bootstrap/ProgressBar";

const VideoProgressBar = ({ isActive, duration }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        var interval;
        if (isActive) {
            interval = setInterval(() => {
                setProgress((prev) => Math.min(prev + 1, 100));
            }, duration / 100); // Increment progress every (duration / 100) ms

            return () => clearInterval(interval); // Cleanup interval on unmount or when isActive changes
        } else {
            setProgress(0); // Reset progress when not active
        }
        return () => interval && clearInterval(interval);
    }, [isActive, duration]);

    return (
        <ProgressBar
            now={progress}
            style={{
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                height: "8px",
                backgroundColor: "rgba(255, 255, 255, 0.3)",
                // backgroundColor: "rgba(255, 255, 255, 0.3)",
            }}
        />
    );
};

export default VideoProgressBar;
