import React, { useState,useEffect } from 'react'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Followers from './Followers';
import Following from './Following';
import { useApi } from '../contexts/ApiProvider';


function FollowTab() {

    const [followers, setFollowers] = useState();
    const [following, setFollowing] = useState();

    const [activeTab, setActiveTab] = useState("followers");
    const api = useApi();


    const handleTabSelect = (key) => {
        setActiveTab(key);

    };

    const getFollowers = () => {
        
        (async () => {
            const response = await api.get("/me/followers");
            if (response.ok) {
                response.body.data.length === 0 ?
                    setFollowers(null)
                    :
                    setFollowers(() => response.body.data)

            }
            else {
                setFollowers(null);
            }
        })();
    };


    const getFollowing = () => {
        (async () => {
            const response = await api.get("/me/following");
            if (response.ok) {
                response.body.data.length === 0 ?
                    setFollowing(null)
                    :
                    setFollowing(() => response.body.data);

            }
            else {
                setFollowing(null);
            }
        })();
    };


    const handleRefresh = () => {
        getFollowing();
        getFollowers();
    }

    useEffect(() => {
        activeTab === 'following' && getFollowing();
        activeTab === 'followers' && getFollowers();

    }, [activeTab])


    return (
        <Tabs
            defaultActiveKey="followers"
            id="fill-tab-example"
            className="mb-3"
            fill
            onSelect={handleTabSelect}
        >
            <Tab className="p-3" eventKey="followers" title="Followers">
                <Followers handleRefresh={handleRefresh} followers={followers}  />
            </Tab>
            <Tab className="p-3" eventKey="following" title="Following">
                <Following handleRefresh={handleRefresh} following={following}  />
            </Tab>

        </Tabs>
    )
}

export default FollowTab
