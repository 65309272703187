import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Body from '../components/Body';
import InputField from '../components/InputField';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

export default function ResetRequestPage() {
    const [formErrors, setFormErrors] = useState({});
    const emailField = useRef();
    const api = useApi();
    const flash = useFlash();

    useEffect(() => {
        emailField.current.focus();
    }, []);

    const onSubmit = async (event) => {
        event.preventDefault();
        const response = await api.post('/tokens/reset', {
            email: emailField.current.value,
        });
        if (!response.ok) {
            setFormErrors(response.body.errors.json);
        }
        else {
            emailField.current.value = '';
            setFormErrors({});
            flash(
                'If you have an account, you will receive an email with instructions ' +
                'to reset your password.', 'info'
            );
        }
    };

    return (
        <Body classStr={{height:'100vh'}}>
            <Row>
                <Col xs={12} md={5}>
                    <h1>Reset Your Password</h1>
                    <Form onSubmit={onSubmit}>
                        <InputField
                            name="email" label="Email Address"
                            error={formErrors.email} fieldRef={emailField} />
                        <Button className="mt-3" variant="primary" type="submit">Reset Password</Button>
                    </Form>
                </Col>
            </Row>
        </Body>
    );
}