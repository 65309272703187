import React, { useState, useEffect } from 'react'
import { useParams, useNavigate } from 'react-router'
import Body from '../components/Body'
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

import { useApi } from '../contexts/ApiProvider';
import CheckoutSummary from '../components/CheckoutSummary';
import { Link } from 'react-router-dom';


import { MdArrowBackIosNew } from 'react-icons/md';
import { useFlash } from '../contexts/FlashProvider';


import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/esm/Container';
import LoadingSpinner from '../components/LoadingSpinner';



const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PROMISE);

function PaymentPage() {
    // const location = useLocation()
    // // const stripe = useStripe();
    // const [campaign, setCampaign] = useState(location)
    const flash = useFlash()
    const navigate = useNavigate()
    const [isProcessing, setIsProcessing] = useState(true)
    const api = useApi();

    const { campaignId } = useParams()

    // const { key, handleRefreshStripe } = useStripeContext()



    const [clientSecret, setClientSecret] = useState();
    const [setupIntentId, setSetupIntentId] = useState();


    const darkTheme = true






    useEffect(() => {

        setIsProcessing(true);

        if (!clientSecret) {

            (async () => {

                const response = await api.post(`/campaign-payment-config`, { setup_intent_id: setupIntentId ? setupIntentId : false, campaign_url: campaignId });
                if (response.ok) {

                    setIsProcessing(false)
                    setClientSecret(() => response.body.clientSecret);
                    setSetupIntentId(() => response.body.setupIntentId);


                }
                else {
                    setIsProcessing(false)
                    setClientSecret(null);
                    setSetupIntentId(null)
                    if ('errors' in response.body) {
                        flash(response.body.errors.json.message, 'danger')
                    }
                    navigate('/')

                }

            })();
        }

    }, [clientSecret]);
    // }, [api, campaignId]);

    const handleIsProcessing = (s) => setIsProcessing(s)





    return (


        clientSecret && setupIntentId && stripePromise ?
            <>
                <Container fluid>
                    <Row>
                        <Col xs={12}>
                            <Link

                                to={{ pathname: '/campaign/' + campaignId }}
                            ><Button variant="light">
                                    <MdArrowBackIosNew size={20} />  <u>Visit Campaign Page</u>
                                </Button>
                            </Link>
                        </Col>
                    </Row>
                </Container>
                <Body >

                    <Elements onChange={(e) => console.log(e)} onEscape={(e) => console.log(e)} key={setupIntentId} stripe={stripePromise} options={{ clientSecret, appearance: { theme: `${darkTheme ? 'night' : 'default'}`, } }} >
                        {/* <Row>
                            <Col xs={12}>
                                <PaymentStatus />
                            </Col>
                        </Row> */}
                        {/* <Row>
                    <Col xs={12}>
                        <Link

                            to={{ pathname: '/campaign/' + campaign.id }}
                        ><Button active variant="dark">
                                <MdArrowBackIosNew size={20} />  <u>Visit Campaign Page</u>
                            </Button>
                        </Link>
                    </Col>
                </Row> */}
                        <Row className="d-flex justify-content-center mb-5" >
                            {/* <Col xs={12} lg={6} className="order-sm-last order-lg-first">
                        <CampaignSummary isProcessing={isProcessing} campaign={campaign} />
                    </Col> */}

                            <Col className="" lg={8}>
                                <CheckoutSummary handleIsProcessing={handleIsProcessing} isProcessing={isProcessing} setupIntentId={setupIntentId} clientSecret={clientSecret} />
                            </Col>


                        </Row>

                    </Elements>


                </Body >
            </>
            :
            <LoadingSpinner />

        // <Body classStr={{ "height": "100vh", "alignContent": "center" }}>
        //     <div className="d-flex justify-content-center align-items-center">
        //         <Spinner />
        //     </div>
        // </Body>




    )
}

export default PaymentPage
