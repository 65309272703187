import React from 'react';
import Button from 'react-bootstrap/Button';
import Image from 'react-bootstrap/Image';
import Modal from 'react-bootstrap/Modal';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

function AIImageModal({ show, hide, image, }) {
    const textColor = 'text-secondary';

    return (
        <Modal size="lg" show={show} onHide={hide} >

            <Container>
                <Row>
                    <Col xs={12} className="mb-4">
                        {
                            image ?
                                <div>
                                    <Image style={{ maxHeight: '600px', width: '100%', objectFit: 'contain' }} src={image['url']} />
                                    <div style={{ overflowY: 'scroll', }}>
                                        {/* {JSON.stringify(image)} */}
                                        <div><strong>Image quality</strong></div>
                                        <div className={textColor}>{image['ai_is_hd']}</div>
                                        <div><strong>Style</strong></div>
                                        <div className={textColor}>{image['ai_style']}</div>
                                        <div><strong>Size</strong></div>
                                        <div className={textColor}>{image['ai_size']}</div>
                                        <div><strong>Message</strong></div>
                                        <div className={textColor}>{image['ai_message']} </div>
                                    </div>
                                </div>
                                :
                                <div></div>
                        }
                    </Col>
                </Row>
            </Container>

            <Modal.Footer>
                <Button variant="secondary" onClick={hide} >Close</Button>
            </Modal.Footer>
        </Modal >
    )
}

export default AIImageModal




