import React, { useState, useEffect } from 'react'
import { useImageBuilder } from '../contexts/ImageBuilderProvider';
// import Row from 'react-bootstrap/Row';
// import Col from 'react-bootstrap/Col';
import Collapse from 'react-bootstrap/Collapse';
// import ImageSettingEditor from './ImageSettingEditor';
import ImageEditorDisplay from './ImageEditorDisplay';
import Container from 'react-bootstrap/esm/Container';


function TabEditorBody({ children, mediaName, image_data, handleFileInputChange, imgEditDisplay }) {
    // const { imgEditDisplay } = useImageBuilder()
    // const [shouldFade, setShouldFade] = useState(true)

    // useEffect(() => {
    //     if (imgEditDisplay === false) {
    //         setShouldFade(true)
    //     }
    //     else if (!(Object.hasOwn(imgEditDisplay, mediaName)) || (imgEditDisplay[mediaName] === false)) {
    //         setShouldFade(true)
    //     }
    //     else {
    //         setShouldFade(false)
    //     }


    // }, [imgEditDisplay])




    return (




        <div >

            <div>
                <Collapse in={((imgEditDisplay === false) || !(Object.hasOwn(imgEditDisplay, mediaName)) || imgEditDisplay[mediaName] === false) ? true : false} ><div className=" p-0 m-0">{children}</div></Collapse>
                <Collapse in={((imgEditDisplay === false) || !(Object.hasOwn(imgEditDisplay, mediaName)) || imgEditDisplay[mediaName] === false) ? false : true} >
                    <div className=" p-0 m-0">
                        {<ImageEditorDisplay
                            mediaName={mediaName}
                            image_data={image_data}
                            handleFileInputChange={handleFileInputChange}
                        />}
                    </div>
                </Collapse>
            </div>


        </div>








    )
}

export default TabEditorBody
