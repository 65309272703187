import React from 'react';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import Image from 'react-bootstrap/Image';
import Card from 'react-bootstrap/Card';
import { mimicIcons } from '../utils/staticUrls';
import HowItWorksVideo from './HowItWorksVideo';



const MimicIconsWithReactIcons = () => {
    return (
        <div className=" shadow-lg rounded-lg  p-lg-5 text-center my-5" style={{ alignContent: 'center' }}>
            <Container fluid={"lg"} className="mb-3">

                <h1 style={{
                    fontWeight: 900
                }}
                    className="mb-4"
                >
                    <span className="gradient-text">Share your voice</span>. Create outdoor media campaigns anyone can fund. You share it, we manage the rest.
                    {/* Create hands-free outdoor media with your message that the anyone can fund. We manage the rest */}
                </h1>
                {/* <h1 style={{
                    fontWeight: 900
                }}
                    className="mb-4"
                >
                    <span className="gradient-text">Share your voice</span> by creating outdoor media campaigns anyone can fund. You share it, we manage the rest.
                    
                </h1> */}
                <div className="text-left fs-3 text-secondary" style={{ textAlign: 'left' }}>

                    {/* <div>Sidestep online echo chambers and engage directly in your public discourse. At no cost, create billboard and billboard truck campaigns around key points of interest <strong>that anyone can fund</strong>. If a campaign does not raise enough to purchase media, nobody is charged. CrowdPost manages your campaign from start to finish, all you have to do is post.



                    </div> */}

                </div>
                <div className="mt-5">
                    <HowItWorksVideo />
                </div>
            </Container>


            <Container className="d-none" fluid >
                {/* <Container className="d-block d-lg-none" fluid > */}

                <Card
                    className="d-block d-lg-none shadow p-2 py-3"

                >
                    <Card.Title className="">
                        <h3><strong>Your campaign title</strong></h3>
                    </Card.Title>


                    {/* Image on Top */}
                    <Stack direction="vertical" gap={1}>
                        <Image
                            src={mimicIcons.mockup2}
                            alt="Placeholder Image"
                            style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                                borderRadius: '4px',
                                // width: '100%',
                                maxHeight: '350px',
                            }}
                            className="rounded"
                            fluid

                        />
                        <div style={{ textAlign: 'center', }}>

                            <div style={{ textAlign: 'left', padding: '.5rem', marginBottom: '.25rem' }}>

                                <div><strong>Status:</strong> Accepting funding</div>
                                <div><strong className="text-success"> $30,000 raised </strong></div>
                                <div><strong className="text-warning"> 12h 15m left to contribute </strong></div>



                            </div>

                        </div>

                        <Image
                            src={mimicIcons.map1}
                            style={{
                                objectFit: 'cover',
                                objectPosition: 'center',
                                borderRadius: '4px',
                                // width: '100%',
                                maxHeight: '350px',
                            }}
                            className="rounded"
                            fluid

                        />
                        <Card.Body className="">
                            <h6 style={{ textAlign: 'left' }} className="">Custom areas</h6>
                            <ul style={{ textAlign: 'left' }}><li className="text-secondary">Downtown LA</li></ul>

                            <h6 style={{ textAlign: 'left' }} className="">Points of Interest</h6>
                            <ul style={{ textAlign: 'left' }}><li className="text-secondary">Los angeles city hall<Badge className="mx-3 bg-primary rounded-pill">1.5 mile radius</Badge>
                            </li></ul>


                        </Card.Body>
                    </Stack>


                </Card>

            </Container >

            {/* Desktop */}
            <Container className="d-none" fluid style={{
                position: 'relative', height: '500px', padding: '5px'
            }}>
                {/* <Container className="d-none d-lg-block" fluid style={{
                position: 'relative', height: '500px', padding: '5px'
            }}> */}

                <Card
                    className="d-block d-lg-none shadow"

                >
                    {/* Image on Top */}
                    {/* <Card.Img variant="top" src="sampleMockup3.png" alt="Placeholder Image" /> */}
                    <Image
                        src={mimicIcons.mockup2}
                        alt="Placeholder Image"
                        style={{
                            objectFit: 'contain',
                            objectPosition: 'top',

                            // width: '100%',
                            maxHeight: '250px',
                        }}
                        className="rounded"
                        fluid

                    />
                    <Card.Title className="mt-3 mb-0">
                        <strong>Your campaign</strong>
                    </Card.Title>


                    <Card.Text className="mt-0"><strong>Status:</strong> Accepting funding</Card.Text>
                    <Card.Text>$30,000 raised</Card.Text>


                    <Card.Text className="text-warning">12h 15m left to contribute </Card.Text>
                    <Card.Title><strong>Campaign Area</strong></Card.Title>
                    <Card.Body className="pt-0">
                        <h5 style={{ textAlign: 'left' }} className=" mt-4">Custom areas</h5>
                        <ul style={{ textAlign: 'left' }}><li className="text-secondary">Downtown LA</li></ul>
                        <hr />
                        <h5 style={{ textAlign: 'left' }} className=" mt-4">Points of Interest</h5>
                        <ul style={{ textAlign: 'left' }}><li className="text-secondary">Los angeles city hall                     <Badge className="mx-3 bg-primary rounded-pill">1.5 mile radius</Badge>
                        </li></ul>
                        <Form.Label style={{ textAlign: 'left' }}>Radius

                        </Form.Label>
                        <Form.Range disabled defaultValue={1.5} min={0} max={5} step={0.1} />

                    </Card.Body>
                    {/* Loading Placeholders on Bottom */}

                </Card>




                {/*
            <Container className="my-5" fluid style={{
                position: 'relative', height: '500px', padding: '5px', backgroundImage: "url('/mapImage2.png')", backgroundSize: 'contain',
                backgroundPosition: 'center', backgroundRepeat: 'no-repeat',borderRadius:'1rem'
            }}> */}
                <Image
                    src={mimicIcons.map1}
                    style={{
                        objectFit: 'contain',
                        borderRadius: '8px',
                        width: '100%',
                        height: '500px',
                    }}
                    className="rounded"
                    fluid
                />

                {/* Controls on the Top Right */}
                <Card
                    className="d-none d-lg-block"
                    style={{
                        position: 'absolute',
                        top: '20px',
                        right: '20px',
                        width: '300px',
                        padding: '.5em',
                        borderTopColor: '1px solid blue',
                        borderRadius: '8px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {/* <Card.Title><strong>Campaign Area</strong></Card.Title> */}
                    <Card.Body className="pt-0">
                        <h5 style={{ textAlign: 'left' }} className=" mt-4">Custom areas</h5>
                        <ul style={{ textAlign: 'left' }}><li className='text-secondary'>Downtown LA</li></ul>
                        <hr />
                        <h5 style={{ textAlign: 'left' }} className=" mt-4">Points of Interest</h5>
                        <ul style={{ textAlign: 'left' }}><li><div className='text-secondary'>Los angeles city hall</div> <div>
                            <Badge className="mx-2 bg-primary rounded-pill">1.5 mile radius</Badge>
                        </div></li></ul>
                        <Form.Label style={{ textAlign: 'left' }}>Radius

                        </Form.Label>
                        <Form.Range disabled defaultValue={1.5} min={0} max={5} step={0.1} />
                        {/* <p className="bg-primary rounded-pill">1.5 miles</p> */}
                    </Card.Body>
                </Card>




                {/* Card in the Bottom Left */}
                <Card
                    className="d-none d-lg-block"
                    style={{
                        position: 'absolute',
                        bottom: '20px',
                        left: '20px',
                        width: '320px',
                        borderRadius: '8px',

                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                    }}
                >
                    {/* Image on Top */}
                    <Card.Img className="rounded" variant="top" src={mimicIcons.mockup2} alt="Placeholder Image" />

                    <Card.Body className="text-left pt-0 mb-3" style={{ textAlign: 'left' }}>
                        <Card.Title className="mt-3 text-center">
                            <strong>Your campaign</strong>
                        </Card.Title>
                        <div><Card.Text>Status: <span className="fw-bold"> Accepting funding</span></Card.Text></div>
                        <div><Card.Text><strong className="text-success">$100,000</strong><span className="text-secondary"> raised</span></Card.Text></div>
                        <div><Card.Text ><span className="text-danger fw-bold">1h 15m</span><span className="text-secondary"> left to contribute</span></Card.Text></div>
                    </Card.Body>
                    {/* Loading Placeholders on Bottom */}

                </Card>




            </Container >




        </div>
    );
};

export default MimicIconsWithReactIcons;
