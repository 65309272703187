import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import MapboxDraw from "@mapbox/mapbox-gl-draw";



export const initNewCampaignMapLayers = (map, show) => {

    map.current.on("load", () => {

        let r = new MapboxDraw({
            displayControlsDefault: false,
            // Select which mapbox-gl-draw control buttons to add to the map.
            controls: {
                // polygon: true,
                // trash: true
            },
            styles: [
                // ACTIVE (being drawn)
                // line stroke
                {
                    "id": "gl-draw-line",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#336699",
                        "line-dasharray": [0.2, 2],
                        "line-width": 2
                    }
                },
                // polygon fill
                {
                    "id": "gl-draw-polygon-fill",
                    "type": "fill",
                    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                    "paint": {
                        "fill-color": "#336699",
                        "fill-outline-color": "#336699",
                        "fill-opacity": 0.15
                    }
                },
                // polygon mid points
                {
                    'id': 'gl-draw-polygon-midpoint',
                    'type': 'circle',
                    'filter': ['all',
                        ['==', '$type', 'Point'],
                        ['==', 'meta', 'midpoint']],
                    'paint': {
                        'circle-radius': 3,
                        'circle-color': '#fbb03b'
                    }
                },
                // polygon outline stroke
                // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
                {
                    "id": "gl-draw-polygon-stroke-active",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#336699",
                        "line-dasharray": [0.2, 2],
                        "line-width": 2
                    }
                },
                // vertex point halos
                {
                    "id": "gl-draw-polygon-and-line-vertex-halo-active",
                    "type": "circle",
                    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                    "paint": {
                        "circle-radius": 5,
                        "circle-color": "#FFF"
                    }
                },
                // vertex points
                {
                    "id": "gl-draw-polygon-and-line-vertex-active",
                    "type": "circle",
                    "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                    "paint": {
                        "circle-radius": 3,
                        "circle-color": "#336699",
                    }
                },

                // INACTIVE (static, already drawn)
                // line stroke
                {
                    "id": "gl-draw-line-static",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#000",
                        "line-width": 3
                    }
                },
                // polygon fill
                {
                    "id": "gl-draw-polygon-fill-static",
                    "type": "fill",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                    "paint": {
                        "fill-color": "#000",
                        "fill-outline-color": "#000",
                        "fill-opacity": 0.15
                    }
                },
                // polygon outline
                {
                    "id": "gl-draw-polygon-stroke-static",
                    "type": "line",
                    "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                    "layout": {
                        "line-cap": "round",
                        "line-join": "round"
                    },
                    "paint": {
                        "line-color": "#000",
                        "line-width": 3
                    }
                }
            ]
        });
        // setDraw(r);
        return r

        // const sourceId = 'billboard-point-source'; // Replace with the actual source ID from Mapbox Studio



        // map.current.on('click', 'clusters', (e) => {

        //     if (!drawMode) {
        //         const features = map.current.queryRenderedFeatures(e.point, {
        //             layers: ['clusters']
        //         });
        //         const clusterId = features[0].properties.cluster_id;
        //         map.current
        //             .getSource(sourceId)
        //             .getClusterExpansionZoom(clusterId, (err, zoom) => {
        //                 if (err) return;

        //                 map.current.easeTo({
        //                     center: features[0].geometry.coordinates,
        //                     zoom: zoom
        //                 });
        //             });
        //     }
        // });




    });


}


export function addBillboardCluster(map, drawMode = false) {
    const sourceId = 'billboards_1'; // Replace with the actual source ID from Mapbox Studio
    if (map.current) {


        // function isPointInPolygon(point, polygon) {
        //     const [x, y] = point;
        //     let inside = false;
        //     for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
        //         const [xi, yi] = polygon[i];
        //         const [xj, yj] = polygon[j];
        //         const intersect = ((yi > y) !== (yj > y)) && (x < (xj - xi) * (y - yi) / (yj - yi) + xi);
        //         if (intersect) inside = !inside;
        //     }
        //     return inside;
        // }


        // map.current.addSource(sourceId, {
        //     type: 'geojson',
        //     data: 'http://localhost:3000/api/main-geojson',
        //     cluster: true,
        //     clusterMaxZoom: 14,
        //     clusterRadius: 50
        // });




        // // Add layers for clusters and unclustered points
        // map.current.addLayer({
        //     id: 'clusters',
        //     type: 'circle',
        //     source: sourceId,
        //     filter: ['has', 'point_count'],
        //     paint: {
        //         'circle-color': [
        //             'step',
        //             ['get', 'point_count'],
        //             'rgba(81, 187, 214, 0.8)', // '#51bbd6' with 80% opacity
        //             100,
        //             'rgba(241, 240, 117, 0.8)', // '#f1f075' with 80% opacity
        //             750,
        //             'rgba(242, 140, 177, 0.8)'  // '#f28cb1' with 80% opacity
        //         ],
        //         'circle-radius': [
        //             'step',
        //             ['get', 'point_count'],
        //             20,
        //             100,
        //             30,
        //             750,
        //             40
        //         ]

        //     }
        // });

        // map.current.addLayer({
        //     id: 'cluster-count',
        //     type: 'symbol',
        //     source: sourceId,
        //     filter: ['has', 'point_count'],
        //     layout: {
        //         'text-field': '{point_count_abbreviated}',
        //         'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        //         'text-size': 12
        //     }
        // });

        // map.current.addLayer({
        //     id: 'unclustered-point',
        //     type: 'circle',
        //     source: sourceId,
        //     filter: ['!', ['has', 'point_count']],
        //     paint: {
        //         'circle-color': '#11b4da',
        //         'circle-radius': 4,
        //         'circle-stroke-width': 1,
        //         'circle-stroke-color': '#fff'
        //     }
        // });


        // map.current.on('click', 'clusters', (e) => {
        //     alert(drawMode);
        //     if (!drawMode) {
        //         const features = map.current.queryRenderedFeatures(e.point, {
        //             layers: ['clusters']
        //         });
        //         const clusterId = features[0].properties.cluster_id;
        //         map.current
        //             .getSource(sourceId)
        //             .getClusterExpansionZoom(clusterId, (err, zoom) => {
        //                 if (err) return;

        //                 map.current.easeTo({
        //                     center: features[0].geometry.coordinates,
        //                     zoom: zoom
        //                 });
        //             });
        //     }
        // });


        // map.current.getSource('compostite').setData({
        //     cluster: true, // Enable clustering
        //     clusterMaxZoom: 14, // Maximum zoom level to cluster points on
        //     clusterRadius: 50, // Radius of each cluster when clustering points (default is 50)
        // });


        // const sources = map.current.getStyle().sources;

        // console.log('All sources in the map:', sources);

        // // If you want to print each source individually
        // Object.keys(sources).forEach(sourceId => {
        //     console.log(`Source ID: ${sourceId}`, sources[sourceId]);
        // });
        // map.current.addLayer({
        //     id: 'clusters',
        //     type: 'circle',
        //     source: 'billboard-point-source',
        //     filter: ['has', 'point_count'],
        //     paint: {
        //         // Use step expressions (https://docs.mapbox.com/style-spec/reference/expressions/#step)
        //         // with three steps to implement three types of circles:
        //         //   * Blue, 20px circles when point count is less than 100
        //         //   * Yellow, 30px circles when point count is between 100 and 750
        //         //   * Pink, 40px circles when point count is greater than or equal to 750
        //         'circle-color': [
        //             'step',
        //             ['get', 'point_count'],
        //             '#51bbd6',
        //             100,
        //             '#f1f075',
        //             750,
        //             '#f28cb1'
        //         ],
        //         'circle-radius': [
        //             'step',
        //             ['get', 'point_count'],
        //             20,
        //             100,
        //             30,
        //             750,
        //             40
        //         ]
        //     }
        // });

        // map.current.addLayer({
        //     id: 'cluster-count',
        //     type: 'symbol',
        //     source: 'billboard-point-source',
        //     filter: ['has', 'point_count'],
        //     layout: {
        //         'text-field': ['get', 'point_count_abbreviated'],
        //         'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
        //         'text-size': 12
        //     }
        // });

        // map.current.addLayer({
        //     id: 'unclustered-point',
        //     type: 'circle',
        //     source: 'billboard-point-source',
        //     filter: ['!', ['has', 'point_count']],
        //     paint: {
        //         'circle-color': '#11b4da',
        //         'circle-radius': 4,
        //         'circle-stroke-width': 1,
        //         'circle-stroke-color': '#fff'
        //     }
        // });

        // map.current.on('click', 'clusters', (e) => {
        //     const features = map.queryRenderedFeatures(e.point, {
        //         layers: ['clusters']
        //     });
        //     const clusterId = features[0].properties.cluster_id;
        //     map.getSource('billboard-point-source').getClusterExpansionZoom(
        //         clusterId,
        //         (err, zoom) => {
        //             if (err) return;

        //             map.easeTo({
        //                 center: features[0].geometry.coordinates,
        //                 zoom: zoom
        //             });
        //         }
        //     );
        // });

        // // When a click event occurs on a feature in
        // // the unclustered-point layer, open a popup at
        // // the location of the feature, with
        // // description HTML from its properties.
        // map.current.on('click', 'unclustered-point', (e) => {
        //     const coordinates = e.features[0].geometry.coordinates.slice();
        //     const mag = e.features[0].properties.mag;
        //     const tsunami =
        //         e.features[0].properties.tsunami === 1 ? 'yes' : 'no';

        //     // Ensure that if the map is zoomed out such that
        //     // multiple copies of the feature are visible, the
        //     // popup appears over the copy being pointed to.
        //     if (['mercator', 'equirectangular'].includes(map.getProjection().name)) {
        //         while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        //             coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        //         }
        //     }

        //     new mapboxgl.Popup()
        //         .setLngLat(coordinates)
        //         .setHTML(
        //             `magnitude: ${mag}<br>Was there a tsunami?: ${tsunami}`
        //         )
        //         .addTo(map);
        // });

        // map.current.on('mouseenter', 'clusters', () => {

        //     if (!drawMode || drawMode.current === false) {

        //         map.current.getCanvas().style.cursor = 'pointer';
        //     }
        // });
        // map.current.on('mouseleave', 'clusters', () => {
        //     map.current.getCanvas().style.cursor = '';
        // });

        // map.current.on('mouseleave', 'unclustered-point', () => {

        //     map.current.getCanvas().style.cursor = '';
        // });
        // map.current.on('mouseenter', 'unclustered-point', () => {
        //     if (!drawMode || drawMode.current === false) {

        //         map.current.getCanvas().style.cursor = 'pointer';
        //     }
        // });

        // map.current.on('mouseleave', 'billboard-points', () => {
        //     map.current.getCanvas().style.cursor = '';
        // });
        // map.current.on('mouseenter', 'billboard-points', () => {
        //     map.current.getCanvas().style.cursor = 'pointer';
        // });
        map.current.on('mouseleave', sourceId, () => {
            if (!drawMode || !drawMode.current) {
                map.current.getCanvas().style.cursor = '';
            }
        });
        map.current.on('mouseenter', sourceId, () => {
            if (!drawMode || !drawMode.current) {
                map.current.getCanvas().style.cursor = 'pointer';
            }
        });

        // map.current.on('click', 'unclustered-point', (e) => {
        map.current.on('click', sourceId, (e) => {

            console.log(e.features)
            // Copy coordinates array.
            if (((!drawMode || drawMode.current === false) && (e.features.length > 0))) {

                const feature = e.features[0];
                const coordinates = feature.geometry.coordinates.slice();

                let og_imgs = feature['properties']['p'].replace(/'/g, '"').trim();



                let x = JSON.parse(og_imgs);


                const imgs = x.map((i, _i) => `<img  style="width: 100%; height: 150px;margin-bottom:.5rem;" src=${i} />`).join('')

                const description = `
                <div >
            
                    <div style="text-align:center"><h6><strong>${feature['properties']['n']} facing ${feature['properties']['c']} (${feature['properties']['h']} height x ${feature['properties']['w']} width )</strong></h6></div>     
                    <hr/>
                    <div>${feature.properties['p_t']}</div>
                    <hr/>
                
                    ${imgs
                    }
                        
                </div>
                
                `



                while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
                    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
                }

                new mapboxgl.Popup()
                    .setLngLat(coordinates)
                    .setHTML(description)
                    .addTo(map.current);

            }

        });


        // map.current.on('click', 'clusters', (e) => {
        //     const sourceId = 'billboard-point-source'; // Replace with the actual source ID from Mapbox Studio


        //     if (((!drawMode) || (drawMode.current === false))) {


        //         const features = map.current.queryRenderedFeatures(e.point, {
        //             layers: ['clusters']
        //         });
        //         const clusterId = features[0].properties.cluster_id;
        //         map.current
        //             .getSource(sourceId)
        //             .getClusterExpansionZoom(clusterId, (err, zoom) => {
        //                 if (err) return;

        //                 map.current.easeTo({
        //                     center: features[0].geometry.coordinates,
        //                     zoom: zoom
        //                 });
        //             });
        //     }
        // });



        return true
    }
}

export const handleShowBillboardLayer = (map, show) => {
    if (map.current) {


        const BILLBOARD_LAYERS = [
            'billboards_1'
        ]
        // const BILLBOARD_LAYERS = [
        //     'unclustered-point',
        //     'cluster-count',
        //     'clusters'
        // ]
        if (show) {
            BILLBOARD_LAYERS.map((i) => map.current.setLayoutProperty(i, 'visibility', 'visible'))
        }
        else {
            BILLBOARD_LAYERS.map((i) => map.current.setLayoutProperty(i, 'visibility', 'none'))
        }
    }
}


export const initMapboxDraw = () => {
    let drawInstance = new MapboxDraw({
        displayControlsDefault: false,
        // Select which mapbox-gl-draw control buttons to add to the map.
        controls: {
            // polygon: true,
            // trash: true
        },
        styles: [
            // ACTIVE (being drawn)
            // line stroke
            {
                "id": "gl-draw-line",
                "type": "line",
                "filter": ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
                "layout": {
                    "line-cap": "round",
                    "line-join": "round"
                },
                "paint": {
                    "line-color": "#FF0000",
                    "line-dasharray": [0.2, 2],
                    "line-width": 2
                }
            },
            // polygon fill
            {
                "id": "gl-draw-polygon-fill",
                "type": "fill",
                "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                "paint": {
                    "fill-color": "#FFFF00",
                    "fill-outline-color": "#FF0000",
                    "fill-opacity": 0.15
                }
            },
            // polygon mid points
            {
                'id': 'gl-draw-polygon-midpoint',
                'type': 'circle',
                'filter': ['all',
                    ['==', '$type', 'Point'],
                    ['==', 'meta', 'midpoint']],
                'paint': {
                    'circle-radius': 3,
                    'circle-color': '#fbb03b'
                }
            },
            // polygon outline stroke
            // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
            {
                "id": "gl-draw-polygon-stroke-active",
                "type": "line",
                "filter": ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
                "layout": {
                    "line-cap": "round",
                    "line-join": "round"
                },
                "paint": {
                    "line-color": "#FF0000",
                    "line-dasharray": [0.2, 2],
                    "line-width": 2
                }
            },
            // vertex point halos
            {
                "id": "gl-draw-polygon-and-line-vertex-halo-active",
                "type": "circle",
                "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                "paint": {
                    "circle-radius": 5,
                    "circle-color": "#FFF"
                }
            },
            // vertex points
            {
                "id": "gl-draw-polygon-and-line-vertex-active",
                "type": "circle",
                "filter": ["all", ["==", "meta", "vertex"], ["==", "$type", "Point"], ["!=", "mode", "static"]],
                "paint": {
                    "circle-radius": 3,
                    "circle-color": "#FF0000",
                }
            },

            // INACTIVE (static, already drawn)
            // line stroke
            {
                "id": "gl-draw-line-static",
                "type": "line",
                "filter": ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
                "layout": {
                    "line-cap": "round",
                    "line-join": "round"
                },
                "paint": {
                    "line-color": "#000",
                    "line-width": 3
                }
            },
            // polygon fill
            {
                "id": "gl-draw-polygon-fill-static",
                "type": "fill",
                "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                "paint": {
                    "fill-color": "#000",
                    "fill-outline-color": "#000",
                    "fill-opacity": 0.15
                }
            },
            // polygon outline
            {
                "id": "gl-draw-polygon-stroke-static",
                "type": "line",
                "filter": ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
                "layout": {
                    "line-cap": "round",
                    "line-join": "round"
                },
                "paint": {
                    "line-color": "#000",
                    "line-width": 3
                }
            }
        ]
    });
    return drawInstance

}


// map.current.on('click', (e) => {

//     console.log(boundaries.current)
//     const { lng, lat } = e.lngLat;
//     const point = [lng, lat];

//     let foundPolygons = [];

//     for (let feature of boundaries.current) {
//         if (feature.properties.companies === 'tx-truck') {

//             console.log(feature)
//         }
//         const geometry = feature.geometry;

//         if (geometry.type === 'Polygon') {
//             const polygons = [geometry.coordinates];
//             polygons.forEach(polygon => {
//                 polygon.forEach(ring => {
//                     if (isPointInPolygon(point, ring)) {
//                         // if (feature.layer.id === 'billboard-area') {
//                         //     foundPolygons.push('Billboard area')
//                         // }
//                         if (feature.layer.id === 'truck-area') {
//                             foundPolygons.push('Truck area')
//                         }
//                     }
//                 });
//             });
//         } else if (geometry.type === 'MultiPolygon') {
//             geometry.coordinates.forEach(polygon => {
//                 polygon.forEach(ring => {
//                     if (isPointInPolygon(point, ring)) {
//                         // if (feature.layer.id === 'billboard-area') {
//                         //     foundPolygons.push('Billboard area')
//                         // }
//                         if (feature.layer.id === 'truck-area') {
//                             foundPolygons.push('Truck area')
//                         }
//                     }
//                 });
//             });
//         }

//     }





//     if (foundPolygons.length > 0) {
//         foundPolygons = [...new Set(foundPolygons)]
//         const description = `
//             <div style="color:black">
//                     <strong>Eligble media types</strong>

//                     <ul>
//                         ${foundPolygons.map((i) =>
//             `<li>${i}</li>`).join('')
//             }
//                     </ul>

//             </div>
//             `

//         new mapboxgl.Popup()
//             .setLngLat(e.lngLat)
//             .setHTML(description)
//             .addTo(map.current);
//     }

// });

// map.current.moveLayer('truck-area');
// console.log(map.current.getStyle().layers)
// map.current.on('mouseenter', 'clusters', () => {
//     map.current.getCanvas().style.cursor = 'pointer';
// });

// map.current.on('mouseleave', 'clusters', () => {
//     map.current.getCanvas().style.cursor = '';
// });


// map.current.on('mouseenter', 'unclustered-point', () => {
//     map.current.getCanvas().style.cursor = 'pointer';
// });

// map.current.on('mouseleave', 'unclustered-point', () => {
//     map.current.getCanvas().style.cursor = '';
// });
// return () => {
//     map.current = map.current && null
// }

