import React, { useRef, useState } from 'react'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import TextAreaField from '../TextAreaField';
import InputField from '../InputField';
import { useApi } from '../../contexts/ApiProvider';
import FormButtonBlock from '../FormButtonBlock';





function CampaignUpdateModal({ showModal, showVar, campaignId, handleCampaignUpdates }) {

    const [formErrors, setFormErrors] = useState({});
    const api = useApi();
    const twitterURLField = useRef();
    // const youtubeUrlField = useRef();
    const postUpdateField = useRef();

    const [apiResp, setApiResp] = useState(null);

    const submitPostUpdate = async (ev) => {

        ev.preventDefault();
        const message = postUpdateField.current.value;
        const twitterURL = twitterURLField.current.value;
        // const youtubeUrl = youtubeUrlField.current.value;
        setApiResp(null)
        //client sid field validation
        const errors = {};
        if (!message) {
            errors.message = 'Username must not be empty.';
        }
        // setFormErrors(errors);
        // if (Object.keys(errors).length > 0) {
        //     return;
        // }
        // end client side field validation 
        (async () => {
            const response = await api.post("/campaign-updates/" + campaignId, {
                'update_message': message,
                'twitterURL': twitterURL,
                // 'youtubeUrl': youtubeUrl,

            });
            if (response.ok) {

                setApiResp(['Your message was sent. Update will display on your next page load', 'success']);
                handleCampaignUpdates(response.body);
                showModal(false);

            }
            else {
                setFormErrors(response.body.errors.json);
                setApiResp([response.body.errors.json.message, 'danger']);
                // showModal(false);
            }

        })();
        //TODO:post it 
    };


    return (
        <Modal onHide={() => showModal(false)} show={showVar} 
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <h3 className="fw-bold">Post update or link tweet</h3>
            </Modal.Header>
            <Modal.Body className="m-4">
                <Row>

                    <Col>
                        <p className='text-success fw-bolder'>
                            {
                                apiResp &&
                                <p className={apiResp[1] === 'success' ? 'text-success' : 'text-danger'}>
                                    {apiResp[0]}
                                </p>
                            }
                        
                        </p>
                    </Col>

                    <Col xs={12}>
                        <Form onSubmit={submitPostUpdate}>
                            <TextAreaField
                                fieldRef={postUpdateField}
                                name="update_message"
                                label="message"
                                type="textarea"
                                placeholder="update message"
                                formText="No more than 250 charachters in each update. "
                                error={formErrors.update_message}
                            // value={'hias'}
                            />
                            <InputField
                                fieldRef={twitterURLField}
                                name="twitterURL"
                                label="twitter url"
                                type="input"
                                formText="Paste the url to a tweet here to post the tweet as an update"
                                error={formErrors.twitterURL}
                            // value={'hias'}
                            />
                            <FormButtonBlock text={"Submit update"} />
                        </Form>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default CampaignUpdateModal
