import React from 'react';
import Body from '../components/Body';
import Stack from 'react-bootstrap/Stack';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';




function TermsOfService() {


    const page_info = [
        {
            label: "CrowdPost Terms of Service",
            text:
                <div>
                    < p >
                        Effective date: Mach 20, 2024
                    </p >
                    <p>

                    </p>
                </div>
        },
        {
            label: "Definitions",
            text:
                < p >
                    Short version: We use these basic terms throughout the agreement, and they have specific meanings. You should know what we mean when we use each of the terms. There's not going to be a test on it, but it's still useful information.

                    <ol>
                        <li>An "Account" represents your legal relationship with CrowdPost. A “Personal Account” represents an individual User’s authorization to log in to and use the Service and serves as a User’s identity on CrowdPost.</li>

                        <li>The “Agreement” refers, collectively, to all the terms, conditions, notices contained or referenced in this document (the “Terms of Service” or the "Terms") and all other operating rules, policies (including the CrowdPost Privacy Statement, available at https://crowdpost.com/privacy-policy) and procedures that we may publish from time to time on the Website.</li>

                        <li>"Beta Previews" mean software, services, or features identified as alpha, beta, preview, early access, or evaluation, or words or phrases with similar meanings.</li>

                        <li>
                            “Content” refers to content featured or displayed through the Website, including without limitation code, text, data, articles, images, photographs, graphics, software, applications, packages, designs, features, and other materials that are available on the Website or otherwise available through the Service. "Content" also includes Services. “User-Generated Content” is Content, written or otherwise, created or uploaded by our Users. "Your Content" is Content that you create or own.

                        </li>
                        <li>
                            “CrowdPost,” “We,” and “Us” refer to CrowdPost, LLC, as well as our affiliates, directors, subsidiaries, contractors, licensors, officers, agents, and employees.
                            The “Service” refers to the applications, software, products, and services provided by CrowdPost, including any Beta Previews.
                        </li>

                        <li>“The User,” “You,” and “Your” refer to the individual person, company, or organization that has visited or is using the Website or Service; that accesses or uses any part of the Account; or that directs the use of the Account in the performance of its functions. A User must be at least 13 years of age.</li>

                        <li>The “Website” refers to CrowdPost’s website located at CrowdPost.com, and all content, services, and products provided by CrowdPost at or through the Website. It also refers to CrowdPost-owned subdomains of CrowdPost.com, such as support.CrowdPost.com and nonprofit.CrowdPost.com.</li>
                        <li>
                            “Campaign” is what is created when a User goes through the process of posting a crowdfunding campaign on CrowdPost. Campaigns are made up of a type (billboard or mobile truck), location, image, start date, and title and an optional description. They are posted to CrowdPost and Users can contribute money to them.

                        </li>
                        <li>
                            “Contributor” refers to a User that contributes money towards a "Campaign"

                        </li>
                        <li>
                            “PaymentIntent” refers to when a User initially contributes to a "Campaign". Their payment/contribution is not charged immediately. It is only charged when a decision is received by our media partners.
                        </li>
                    </ol>

                </p >
        },
        {
            label: "Account Terms",
            text:
                < p >
                    Short version: Personal Accounts and Organizations have different administrative controls; a human must create your Account; you must be 13 or over; you must provide a valid email address; and you may not have more than one Account. You alone are responsible for your Account and anything that happens while you are signed in to or using your Account. You are responsible for keeping your Account secure.

                    <br />    <br />
                    1. Account Controls

                    Users. Subject to these Terms, you retain ultimate administrative control over your Personal Account and the Content within it.

                    The User has ultimate administrative control content within its Personal Account. The User is permitted to delete their account. The account will immediately delete and the data associated with your account will be removed from our Service.
                    <br />    <br />

                    2. Required Information

                    You must provide a valid email address in order to complete the signup process. Any other information requested, such as your real name, is optional, unless you are accepting these terms on behalf of a legal entity (in which case we need more information about the legal entity).
                    <br />    <br />

                    3. Account Requirements
                    <br />    <br />

                    We have a few simple rules for Personal Accounts on CrowdPost's Service.
                    <br />    <br />

                    You must be a human to create an Account. Accounts registered by "bots" or other automated methods are not permitted. We do permit machine accounts:
                    A machine account is an Account set up by an individual human who accepts the Terms on behalf of the Account, provides a valid email address, and is responsible for its actions. A machine account is used exclusively for performing automated tasks. Multiple users may direct the actions of a machine account, but the owner of the Account is ultimately responsible for the machine's actions. You may maintain no machine accounts.

                    You must be age 13 or older. While we are thrilled to see young people partake in public discourse, we must comply with United States law. CrowdPost does not target our Service to children under 13, and we do not permit any Users under 13 on our Service. If we learn of any User under the age of 13, we will terminate that User’s Account immediately. If you are a resident of a country outside the United States, your country’s minimum age may be older; in such a case, you are responsible for complying with your country’s laws.
                    Your login may only be used by one person — i.e., a single login may not be shared by multiple people. A paid Organization may only provide access to as many Personal Accounts as your subscription allows.
                    You may not use CrowdPost in violation of export control or sanctions laws of the United States or any other applicable jurisdiction. You may not use CrowdPost if you are or are working on behalf of a Specially Designated National (SDN) or a person subject to similar blocking or denied party prohibitions administered by a U.S. government agency.
                    <br />    <br />

                    4. Account Security
                    <br />    <br />

                    You are responsible for keeping your Account secure while you use our Service. We offer tools such as two-factor authentication to register your Account's security, but the content of your Account and its security are up to you. Ideally, use a complex passowrd stored in a password manager. CrowdPost does not recommend any particular password manager.
                    <br />    <br />

                    You are responsible for all content posted and activity that occurs under your Account (even when content is posted by others who have Accounts under your Account).
                    You are responsible for maintaining the security of your Account and password. CrowdPost cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.
                    You will promptly notify CrowdPost by contacting us through the CrowdPost Support portal if you become aware of any unauthorized use of, or access to, our Service through your Account, including any unauthorized use of your password or Account.
                    <br />    <br />

                    5. Additional Terms
                    <br />    <br />

                    In some situations, third parties' terms may apply to your use of CrowdPost. For example, you may be a member of an organization on CrowdPost with its own terms or license agreements; you may download an application that integrates with CrowdPost; or you may use CrowdPost to authenticate to another service. Please be aware that while these Terms are our full agreement with you, other parties' terms govern their relationships with you.
                    <br />    <br />

                    If you are a government User or otherwise accessing or using any CrowdPost Service in a government capacity, this Government Amendment to CrowdPost Terms of Service applies to you, and you agree to its provisions.

                    If you have signed up for CrowdPost Enterprise Cloud, the Enterprise Cloud Addendum applies to you, and you agree to its provisions.

                </p >
        },
        {
            label: "Aceptable Use",
            text: <div>
                <p>
                    Short version: CrowdPost hosts a wide variety of collaborative projects from all over the world, and that collaboration only works when our users are able to work together in good faith. While using the service, you must follow the terms of this section, which include some restrictions on content you can post, conduct on the service, and other limitations. In short, be excellent to each other.
                    Your use of the Website and Service must not violate any applicable laws, including copyright or trademark laws, export control or sanctions laws, or other laws in your jurisdiction. You are responsible for making sure that your use of the Service is in compliance with laws and any applicable regulations.
                    You agree that you will not under any circumstances violate our Acceptable Use Policies or Community Guidelines.

                    <br />
                    <br />
                    <h5>No Adertising</h5>
                    Short version: We do not generally prohibit use of CrowdPost for advertising. However, we expect our users to follow certain limitations.
                    <br />
                    <br />
                    While we understand that you may want to promote your Content by posting supporters' names or logos in your Account, the primary focus of the Content posted in or through your Account to the Service should not be advertising or promotional marketing. This includes the media created or uploaded whenc creating a new CrowdPost Campaign and all other parts of the Service. You may include content of your business but they must not be aimed at direclty monetizing any good or service. Think of a "Buy this today", or "On sale now" statements.
                    <br />
                    <br />
                    You may not promote or distribute content or activity that is illegal or otherwise prohibited by our Terms of Service or Acceptable Use Policies, including excessive automated bulk activity (for example, spamming), get-rich-quick schemes, and misrepresentation or deception related to your promotion.
                    <br />
                    <br />
                    If you decide to post any promotional materials in your Account, you are solely responsible for complying with all applicable laws and regulations, including without limitation the U.S. Federal Trade Commission's Guidelines on Endorsements and Testimonials. We reserve the right to remove any promotional materials or advertisements that, in our sole discretion, violate any CrowdPost terms or policies.
                    <br />
                    <br />
                    <h5>What is allowed to be posted:</h5>
                    We do not allow content or activity on CrowdPost that:
                    <ul className="fs-5" >
                        <li>
                            No posting of copywritten material or other types of intellectual property unless you have permission. No impersonation or posting of other persons personal information without consent.
                        </li>
                        {/* <li>is false, inaccurate, or intentionally deceptive information and likely to adversely affect the public interest (including health, safety, election integrity, and civic participation);</li> */}
                        <li>
                            No false advertisement
                        </li>
                        <li>No content that harasses or abuses another individual or group, including our employees, officers, and agents, or other users;</li>
                        <li>
                            Speech That Endangers Public Safety (Speech that creates a clear and present danger to public safety or the general welfare.)
                        </li>
                        <li>
                            No defamatory statements (i.e. Libel, statements that can damage someone's reputation through false information, defamatory, or fraudulent;)
                        </li>
                        <li>
                            No incitement to imminent lawless damage. (See Brandenburg v. Ohio (1969), Schenck v. United States )
                        </li>
                        <li>
                            No speech promoting illegal activities
                        </li>
                        <li>
                            No nudity or sexually explicit material. No content that is sexually obscene or relates to sexual exploitation or abuse, including of minors;
                        </li>
                        <li>
                            No direct advertisement with calls to action

                        </li>

                        <li>Content that gratuitously depicts or glorifies violence, including violent images; or is off-topic, or interacts with platform features in a way that significantly or repeatedly disrupts the experience of other users.</li>



                    </ul>
                    <br />
                    <br />
                    <h5>We do not allow content or activity on CrowdPost that:</h5>

                    <ul>
                        <div>
                            directly supports unlawful active attack or malware campaigns that are causing technical harms — such as using our platform to deliver malicious executables or as attack infrastructure, for example by organizing denial of service attacks or managing command and control servers — with no implicit or explicit dual-use purpose prior to the abuse occurring; or
                            uses our servers to disrupt or to attempt to disrupt, or to gain or to attempt to gain unauthorized access to, any service, device, data, account or network.
                        </div>
                    </ul>
                    <br />
                    <br />
                    <h5>No excessive bandwith use</h5>


                    <div>
                        The Service's bandwidth limitations vary based on the features you use. If we determine your bandwidth usage to be significantly excessive in relation to other users of similar features, we reserve the right to suspend your Account, throttle your account or otherwise limit your activity until you can reduce your bandwidth consumption. We also reserve the right—after providing advance notice—to delete campaigns that we determine to be placing undue strain on our infrastructure.
                    </div>


                </p>


            </div>
        },
        {
            label: "Copyright Infringement and DMCA Policy",
            text: <div>
                <p>
                    If you believe that content on our website violates your copyright, please contact us in accordance with our Digital Millennium Copyright Act Policy. If you are a copyright owner and you believe that content on CrowdPost violates your rights, please contact us via our convenient DMCA form or by emailing copyright@CrowdPost.com. There may be legal consequences for sending a false or frivolous takedown notice. Before sending a takedown request, you must consider legal uses such as fair use and licensed uses.
                    <br /><br />
                    We will terminate the Accounts of repeat infringers of this policy.
                </p>
                <div>
                    <p><strong>To file a DMCA Takedown Request please click the link below</strong></p>
                    <Link to="/dmca-takedown">DMCA Takedown Request</Link>
                </div>

                <div className="mt-3">
                    <p><strong>To file a DMCA Counter-Takedown Request please click the link below</strong></p>
                    <Link to="/dmca-counter">DMCA Counter-Takedown Request</Link>
                </div>
            </div>
        },
        {
            label: "Intellectual Property Notice",
            text: <p>
                Short version: We own the service and all of our content. In order for you to use our content, we give you certain rights to it, but you may only use our content in the way we have allowed.
                <br />
                <br />
                1. CrowdPost's Rights to Content

                CrowdPost and our licensors, vendors, agents, and/or our content providers retain ownership of all intellectual property rights of any kind related to the Website and Service. We reserve all rights that are not expressly granted to you under this Agreement or by law.  You may not duplicate, copy, or reuse any portion of the HTML/CSS, JavaScript, or visual design elements or concepts without express written permission from CrowdPost.

                <br />
                <br />
                3. License to CrowdPost Policies

                This Agreement is licensed under this Creative Commons Zero license. For details, see our site-policy repository.

            </p>
        },
        {
            label: "Cancellation and Termination",
            text: <p>
                Short version: You may close your Account at any time. If you do, we'll treat your information responsibly. It is immediately removed from our Service hardware.
                <br />
                <br />
                1. Account Cancellation

                It is your responsibility to properly cancel your Account with CrowdPost. You can cancel your Account at any time by going into your Settings in the global navigation bar at the top of the screen. The Account screen provides a simple, no questions asked cancellation link. We are not able to cancel Accounts in response to an email.
                <br />
                <br />
                2. Upon Cancellation

                All of the Users data will be removed from the Service including the Users campaigns. If a Campaign is active and has outstanding PaymentIntents, the PaymentIntents will be cancelled and not processed.



                <br />
                <br />
                3. CrowdPost May Terminate

                CrowdPost has the right to suspend or terminate your access to all or any part of the Website at any time, with or without cause, with or without notice, effective immediately. CrowdPost reserves the right to refuse service to anyone for any reason at any time.
                4. Survival

                All provisions of this Agreement which, by their nature, should survive termination will survive termination — including, without limitation: ownership provisions, warranty disclaimers, indemnity, and limitations of liability.
            </p>
        },
        {
            label: "Communications with CrowdPost",
            text: <p>
                Short version: We use email and other electronic means to stay in touch with our users.
                <br />
                <br />
                1. Electronic Communication Required

                For contractual purposes, you (1) consent to receive communications from us in an electronic form via the email address you have submitted or via the Service; and (2) agree that all Terms of Service, agreements, notices, disclosures, and other communications that we provide to you electronically satisfy any legal requirement that those communications would satisfy if they were on paper. This section does not affect your non-waivable rights.
                <br />
                <br />
                2. Legal Notice to CrowdPost Must Be in Writing

                Communications made through email or CrowdPost Support's messaging system will not constitute legal notice to CrowdPost or any of its officers, employees, agents or representatives in any situation where notice to CrowdPost is required by contract or any law or regulation. Legal notice to CrowdPost must be in writing and served on CrowdPost's legal agent.
                <br />
                <br />
                3. No Phone Support

                CrowdPost only offers support via email, in-Service communications, and electronic messages. We do not offer telephone support.
            </p>
        },
        {
            label: "Release and Indemnification",
            text: <p>
                Short version: You are responsible for your use of the service. If you harm someone else or get into a dispute with someone else, we will not be involved.
                <br />
                <br />
                If you have a dispute with one or more Users, you agree to release CrowdPost from any and all claims, demands and damages (actual and consequential) of every kind and nature, known and unknown, arising out of or in any way connected with such disputes.
                <br />
                <br />
                You agree to indemnify us, defend us, and hold us harmless from and against any and all claims, liabilities, and expenses, including attorneys’ fees, arising out of your use of the Website and the Service, including but not limited to your violation of this Agreement, provided that CrowdPost (1) promptly gives you written notice of the claim, demand, suit or proceeding; (2) gives you sole control of the defense and settlement of the claim, demand, suit or proceeding (provided that you may not settle any claim, demand, suit or proceeding unless the settlement unconditionally releases CrowdPost of all liability); and (3) provides to you all reasonable assistance, at your expense.
            </p>
        },
        {
            label: "Changes to These Terms",
            text: <p>
                Short version: We want our users to be informed of important changes to our terms, but some changes aren't that important — we don't want to bother you every time we fix a typo. So while we may modify this agreement at any time, we will notify users of any material changes and give you time to adjust to them.
                <br />
                <br />
                We reserve the right, at our sole discretion, to amend these Terms of Service at any time and will update these Terms of Service in the event of any such amendments. We will notify our Users of material changes to this Agreement, such as price increases, at least 30 days prior to the change taking effect by posting a notice on our Website or sending email to the primary email address specified in your CrowdPost account. Customer's continued use of the Service after those 30 days constitutes agreement to those revisions of this Agreement. For any other modifications, your continued use of the Website constitutes agreement to our revisions of these Terms of Service. You can view all changes to these Terms in our Site Policy repository.
                <br />
                <br />
                We reserve the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Website (or any part of it) with or without notice.
            </p>
        },
        {
            label: "Limitation of Liability",
            text: <p>
                Short version: We will not be liable for damages or losses arising from your use or inability to use the service or otherwise arising under this agreement. Please read this section carefully; it limits our obligations to you.
                <br />    <br />
                You understand and agree that we will not be liable to you or any third party for any loss of profits, use, goodwill, or data, or for any incidental, indirect, special, consequential or exemplary damages, however arising, that result from

                the use, disclosure, or display of your User-Generated Content;
                your use or inability to use the Service;
                any modification, price change, suspension or discontinuance of the Service;
                the Service generally or the software or systems that make the Service available;
                unauthorized access to or alterations of your transmissions or data;
                statements or conduct of any third party on the Service;
                any other user interactions that you input or receive through your use of the Service; or
                any other matter relating to the Service.
                <br />
                <br />
                Our liability is limited whether or not we have been informed of the possibility of such damages, and even if a remedy set forth in this Agreement is found to have failed of its essential purpose. We will have no liability for any failure or delay due to matters beyond our reasonable control. Your use of the CrowdPost platform is AS IS.
            </p>
        },
        {
            label: "Communications with CrowdPost",
            text: <p>
                Short version: We provide our service as is, and we make no promises or guarantees about this service. Please read this section carefully; you should understand what to expect.
                <br />    <br />
                CrowdPost provides the Website and the Service “as is” and “as available,” without warranty of any kind. Without limiting this, we expressly disclaim all warranties, whether express, implied or statutory, regarding the Website and the Service including without limitation any warranty of merchantability, fitness for a particular purpose, title, security, accuracy and non-infringement.
                <br />                <br />
                CrowdPost does not warrant that the Service will meet your requirements; that the Service will be uninterrupted, timely, secure, or error-free; that the information provided through the Service is accurate, reliable or correct; that any defects or errors will be corrected; that the Service will be available at any particular time or location; or that the Service is free of viruses or other harmful components. You assume full responsibility and risk of loss resulting from your downloading and/or use of files, information, content or other material obtained from the Service.
            </p>
        },
        {
            label: "Payment",
            text: <p>
                Short version: You are responsible for any fees associated with your use of CrowdPost. We are responsible for communicating those fees to you clearly and accurately, and letting you know well in advance if those prices change.
                <br /><br />
                1. Pricing

                Our pricing and payment terms are available at CrowdPost.com/pricing. When a Contributor, that will remain your price for the duration of the payment term; however, prices are subject to change at the end of a payment term.
                <br /><br />
                2. Payment

                Payment is processed when a decision is received from our media partners. Payment is detailed at https://crowdpost.com/pricing
                <br /><br />

                3. No Refunds
                <br /><br />
                No refunds will be allowed after a User contributes to a campaign. In order to treat everyone equally, no exceptions will be made.
                4. Authorization
                <br /><br />
                By agreeing to these Terms, you are giving us permission to charge your on-file credit card, PayPal account, or other approved methods of payment for fees that you authorize for CrowdPost. S
                <br /><br />
                5. Responsibility for Payment
                <br /><br />
                You are responsible for all fees, including taxes, associated with your use of the Service. By using the Service, you agree to pay CrowdPost any charge incurred in connection with your use of the Service. If you dispute the matter, contact us through the CrowdPost Support portal. You are responsible for providing us with a valid means of payment for paid Accounts. User Accounts are not required to provide payment information.
            </p>
        },
        {
            label: "Miscellaneous",
            text: <p>
                1. Governing Law

                Except to the extent applicable law provides otherwise, this Agreement between you and CrowdPost and any access to or use of the Website or the Service are governed by the federal laws of the United States of America and the laws of the State of California, without regard to conflict of law provisions. You and CrowdPost agree to submit to the exclusive jurisdiction and venue of the courts located in the City and County of Los Angeles, California.
                <br /><br />
                2. Non-Assignability

                CrowdPost may assign or delegate these Terms of Service and/or the CrowdPost Privacy Statement, in whole or in part, to any person or entity at any time with or without your consent, including the license grant in Section D.4. You may not assign or delegate any rights or obligations under the Terms of Service or Privacy Statement without our prior written consent, and any unauthorized assignment and delegation by you is void.
                <br /><br />
                3. Section Headings and Summaries

                Throughout this Agreement, each section includes titles and brief summaries of the following terms and conditions. These section titles and brief summaries are not legally binding.
                <br /><br />
                4. Severability, No Waiver, and Survival

                If any part of this Agreement is held invalid or unenforceable, that portion of the Agreement will be construed to reflect the parties’ original intent. The remaining portions will remain in full force and effect. Any failure on the part of CrowdPost to enforce any provision of this Agreement will not be considered a waiver of our right to enforce such provision. Our rights under this Agreement will survive any termination of this Agreement.
                <br /><br />
                5. Amendments; Complete Agreement

                This Agreement may only be modified by a written amendment signed by an authorized representative of CrowdPost, or by the posting by CrowdPost of a revised version in accordance with Section Q. Changes to These Terms. These Terms of Service, together with the CrowdPost Privacy Statement, represent the complete and exclusive statement of the agreement between you and us. This Agreement supersedes any proposal or prior agreement oral or written, and any other communications between you and CrowdPost relating to the subject matter of these terms including any confidentiality or nondisclosure agreements.
                <br /><br />
                6. Questions

                Questions about the Terms of Service? Contact us through the CrowdPost Support portal.
            </p>
        },
        {
            label: "Enforcement",
            text: <p>
                Enforcement. CrowdPost retains full discretion to take action in response to a violation of these policies, including account suspension, account termination, or removal of content. Please also see our Community Guidelines for actions you can take if something or someone offends you.

                Reinstatement and appeal. If your content or account has been disabled or restricted and you seek reinstatement or wish to appeal, please see our Appeal and Reinstatement page for information about the process and use our Appeal and Reinstatement form to submit a request.
            </p>
        },

    ]

    return (
        <Body aboutSidebar={true} faqs={page_info} footer scrollable>
            <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '3rem', marginBottom: '5rem' }}>
                <Col className="text-center mb-5" xs={12}>
                    <h2 className="fw-bolder">
                        Terms of Service
                    </h2>
                </Col>

                <Col lg={10}>

                    <Stack gap={4}>


                        <p>
                            Thank you for using CrowdPost! We're happy you're here. Please read this Terms of Service agreement carefully before accessing or using CrowdPost. Because it is such an important contract between us and our users, we have tried to make it as clear as possible. For your convenience, we have presented these terms in a short non-binding summary followed by the full legal terms.
                        </p>

                        {
                            page_info.map(((v, i) =>
                                <>
                                    <h3 className="mb-0 pb-0 fw-bolder" id={`sidebar_${i}`}>{v['label']}</h3>
                                    <hr className="m-0 p-0" />
                                    <p>{v['text']}</p>
                                </>
                            ))
                        }


                    </Stack>


                </Col>
            </Row>
        </Body >
    )
}

export default TermsOfService
