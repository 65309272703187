// urls.js
export const landingPageCarousel = {
    echoChamber: 'https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/WhatIsYourMessage.webp',
    voiceTownSquare: 'https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/LetVoiceReachSquare.webp',
    crowdFunding: 'https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/crowdFunding.webp',
    freedomOfSpeech: 'https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/FreedomofSpeech.webp',
    truck: 'https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/Truck2.png',
    landing2: "https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/LandingPageMockupMountains2.webp",
    anybody_can_contribute: "https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/LandingPageCrowdfundingV3.webp",
    // anybody_can_contribute: "https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/LandingPageCrowdfundingV3.webp",
    anybody_can_contribute: "/imgs/BillboardCrowdfunding.webp",
    truck2: "/imgs/LandingPageTruck.webp",
    // truck2: "https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/TruckMockupSample.webp",
};


export const mimicIcons = {
    map1: 'https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/mapImage2.png',
    mockup1: 'https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/sampleMockup3.png',
    mockup2:'/imgs/UrbanBillboardMockup-1.webp'
    // mockup2:'https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/UrbanBillboardMockup.webp'
}

export const badBillboardExamples = {
    billboard1: 'https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/mapImage2.png',
    billboard2: 'https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/sampleMockup3.png',
    billboard3: 'https://crowdpost-production.us-lax-1.linodeobjects.com/static-images/sampleMockup3.png',
}

export const billboardSvg = "https://crowdpost-production.us-lax-1.linodeobjects.com/icons/Billboard.svg"

export const googleIcon = "https://crowdpost-production.us-lax-1.linodeobjects.com/icons/g-logo.png"


export const discordIcon = '/imgs/discord-logo-blue.svg'

export const instagramIcon = '/imgs/Instagram_Glyph_Gradient.png'

export const facebookIcon = '/imgs/Facebook_Logo_Primary.png'
export const mailIcon = "/imgs/Apple_Mail-1024.webp"