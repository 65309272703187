import React from 'react'
import Campaigns from '../components/Campaigns';
import { Helmet } from 'react-helmet';
import Body from '../components/Body';

export default function FeedPage() {


    return (
        <>

            <Helmet>
                <title>CrowdPost - Feed</title>
                <meta property="og:title" content={`Let your voice reach the real town square`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
                <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." />

            </Helmet>
            <Body fullwidth classStr={{ padding: 0, margin: 0 }}>
                <Campaigns routeName="feed" />
            </Body>


        </>
    );
}