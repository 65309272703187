import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import CardGroup from 'react-bootstrap/CardGroup';
import Card from 'react-bootstrap/Card';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Badge from 'react-bootstrap/Badge';
import Stack from 'react-bootstrap/Stack';
import { TbArrowFork } from "react-icons/tb"
import { PiProhibit } from "react-icons/pi";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { RiSlowDownFill } from "react-icons/ri";
import { GoArrowDown } from "react-icons/go";

import "../styles/PricingPage.css"
import OrDivider from '../components/OrDivider';
import CrowdPostLogo from '../components/CrowdPostLogo';
import GetPrice from './GetPrice';


function ContributionPricing({ modal, receipt }) {
    const [campaignFunded, setCampaignFunded] = useState(true);

    const [userPayment, setUserPayment] = useState();

    const UNDERFUNDED_EXP = <span><u>You are not charged</u> if not enough money was raised to purchase media</span>
    const DENIED_EXP = <span><u>You are charged 99¢</u>, instead of your original contribution. The campaign raised enough but the media was not accepted by our partners.</span>
    const ACCEPTED_EXP = <span>If the campaign raises more than the cost of media, your contribution is reduced. You are only charged for media that is purchased.</span>
    // const ACCEPTED_EXP = <span>You are charged based off of the % your contribution made up of the total raised-- up to your original contribution amount. </span>



    const [scenario, setScenario] = useState({
        'payment': 20 * 100,
        'raised': 3000 * 100,
        'cost': 3000 * 100
    });

    useEffect(() => {

        // console.log('payment ', scenario['payment'], ' raised ', scenario['raised'], ' cost ', scenario['cost'], 'percentage ', (scenario['payment'] / scenario['raised']) * 100);

        (((scenario['payment'] + scenario['raised']) >= scenario['cost']) ?
            setCampaignFunded(true)
            :
            setCampaignFunded(false)
        );

        let userCost2 = ((scenario['payment'] / scenario['raised']) * scenario['cost'])

        let userPercentage = (scenario['payment'] / scenario['raised']) * 100

        setUserPayment({
            userCost: userCost2,
            userPercentage: userPercentage
        })




    }, [scenario])

    const handleScenarioChange = (e, key) => {


        setScenario({
            ...scenario,
            [key]: parseInt(e.target.value)
        })
    }

    return (

        <div>{
            modal ?
                <Container className="mb-5">
                    <Row className="mx-0" >
                        <h2>Contributing to a campaign has 3 outcomes</h2>
                        <h5 className="text-muted">
                            <u>Contributions are handled after the fundraising period, not up front. No account needed to contribute.</u>
                        </h5>
                        <Col xs={12} className="my-2">
                            <div className="mb-2  text-center">
                                <IoIosCheckmarkCircle style={{ color: 'green' }} size={40} />
                            </div>
                            <h3 className="text-center ">
                                <strong>1. Accepted campaigns</strong>
                            </h3>
                            <h4 className="text-secondary">
                                {ACCEPTED_EXP}
                            </h4>
                        </Col>
                        <hr />
                        <Col xs={12} className="my-2">
                            <div className="mb-2 text-center">
                                <RiSlowDownFill style={{ color: 'grey' }} size={50} />
                            </div>
                            <h3 className="text-center ">
                                <strong>2. Underfunded campaigns</strong>
                            </h3>
                            <h4 className="text-secondary pricingCardMobile">
                                {UNDERFUNDED_EXP}
                            </h4>
                        </Col>
                        <hr />
                        <Col xs={12} className="my-2" >
                            <div className="mb-2 text-center">
                                <PiProhibit style={{ color: 'red' }} size={50} />
                            </div>
                            <h3 className="text-center ">
                                <strong>3. Denied campaigns</strong>
                            </h3>
                            <h4 className="text-secondary pricingCardMobile">
                                {DENIED_EXP}
                            </h4>
                        </Col>
                    </Row>
                    <hr />
                    <Row className="mt-5">
                        <Col xs={12} className="d-flex">
                            <h1 >
                                Here is an example
                            </h1>
                        </Col>
                        <Col>
                            <h4 >If you <strong>contribute</strong>
                                <Form.Select value={scenario['payment']} onChange={(e) => handleScenarioChange(e, 'payment')} className="no-border-select pricingPageSelect" borderaria-label="Select option" style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', }}>
                                    <option value={20 * 100}>$20</option>
                                    <option value={200 * 100}>$200</option>
                                    <option value={1200 * 100}>$1,200</option>
                                </Form.Select> to a campaign that <strong>costs</strong>
                                <Form.Select value={scenario['cost']} onChange={(e) => handleScenarioChange(e, 'cost')} className={`pricingPageSelect ${campaignFunded ? 'text-success' : 'text-danger'} no-border-select`} aria-label="Select option" style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', fontWeight: 400, }}>
                                    <option value={900 * 100}>$900</option>
                                    <option value={3000 * 100}>$3,000</option>
                                    <option value={20000 * 100}>$20,000</option>
                                </Form.Select>
                                and <strong>raised</strong>
                                <Form.Select value={scenario['raised']} onChange={(e) => handleScenarioChange(e, 'raised')} className="no-border-select pricingPageSelect " borderaria-label="Select option" style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', fontWeight: 400, }}>
                                    <option value={3000 * 100}>$3,000</option>
                                    <option value={5000 * 100}>$5,000</option>
                                    <option value={20000 * 100}>$20,000</option>
                                </Form.Select>
                                {campaignFunded && <span > CrowdPost submits the campaign and it is...</span>}
                            </h4>
                        </Col>
                        {campaignFunded ?
                            <>
                                <TbArrowFork className="d-none d-lg-block my-5" style={{ transform: 'rotate(180deg)' }} size={40} />
                                <Col className="pricingPageForm mt-5 mt-lg-0" xs={12} lg={6} >
                                    <div className="text-center">
                                        <h1 style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', }}><strong className="text-danger">Denied</strong></h1>
                                    </div>
                                    <div className="text-center">
                                        You are charged <strong><span>99¢</span></strong>
                                    </div>
                                </Col>
                                <Col xs={12} className="d-block d-lg-none">
                                    <OrDivider />
                                </Col>
                                <Col xs={12} lg={6} className="pricingPageForm text-center">
                                    <div className="text-center">
                                        <h1 style={{ display: 'inline-block', width: 'auto', marginLeft: '10px' }}><strong className="text-success">Accepted</strong></h1>
                                    </div>
                                    {
                                        userPayment ?
                                            <div className="align-items-center text-center" style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', textAlign: 'center' }}>
                                                {
                                                    parseInt(scenario['raised']) === parseInt(scenario['cost']) ?
                                                        <div>
                                                            You are charged <strong className="text-success">${parseInt(userPayment['userCost']) / 100}</strong>.
                                                        </div>
                                                        :
                                                        <div>
                                                            You are charged <strong className="text-success">${parseInt(userPayment['userCost']) / 100}</strong> instead of your original <strong className="text-success">${parseInt(scenario['payment']) / 100}</strong>.                                                                 {parseInt(userPayment['userCost'])}
                                                            {parseInt(scenario['payment'])}Your contribution made up <strong className="text-success">
                                                                {(userPayment['userPercentage']) >= 1 ?
                                                                    `${parseInt(userPayment['userPercentage'])}%`
                                                                    :
                                                                    `${userPayment['userPercentage'].toFixed(2)}%`
                                                                }
                                                            </strong> of the <strong className="text-success">${parseInt(scenario['raised']) / 100}</strong> raised.
                                                        </div>
                                                }
                                            </div>
                                            :
                                            <div>
                                                loading
                                            </div>
                                    }
                                </Col>
                            </>
                            :
                            <h1 className="my-5 text-primary text-center"><strong>You are not charged. The campaign did not reach the minimum threshold to purchase media.</strong></h1>
                        }
                    </Row>
                </Container>
                :
                <>
                    <Row className="">
                        <h1 className={`${!receipt && "pricingSubHeader"} mt-5 pricingSubHeader`} >Contributing has 3 possible outcomes</h1>
                        <h4 className="text-muted mb-5">
                        Contributions are handled after the fundraising period, not up front. No account needed to contribute.
                        </h4>
                        <Col className="pricingCardDesktop" xs={12} lg={4} >
                            <div className="mb-2  text-center">
                                <IoIosCheckmarkCircle style={{ color: 'green' }} size={50} />
                            </div>
                            <h3 className="text-center ">
                                <strong>1. Accepted campaigns</strong>
                            </h3>
                            <h4 className="text-secondary">
                                {ACCEPTED_EXP}
                            </h4>
                        </Col>
                        <Col xs={12} lg={4} className="pricingCardDesktop" >
                            <div className="mb-2 text-center">
                                <RiSlowDownFill style={{ color: 'grey' }} size={50} />
                            </div>
                            <h3 className="text-center ">
                                <strong>2. Underfunded campaigns</strong>
                            </h3>
                            <h4 className="text-secondary pricingCardMobile">
                                {UNDERFUNDED_EXP}
                            </h4>
                        </Col>
                        <Col xs={12} lg={4}  >
                            <div className="mb-2 text-center">
                                <PiProhibit style={{ color: 'red' }} size={50} />
                            </div>
                            <h3 className="text-center ">
                                <strong>3. Denied campaigns</strong>
                            </h3>
                            <h4 className="text-secondary pricingCardMobile">
                                {DENIED_EXP}
                            </h4>
                        </Col>
                    </Row>
                    {
                        receipt ?
                            <Row style={{ marginTop: '10rem', marginBottom: '3rem' }}>
                                <Col xs={12} className="d-flex">
                                    <h1 className={!receipt ? "pricingSubHeader" : ''}>
                                        Here are some examples
                                    </h1>
                                </Col>
                                <hr />
                                <Col>
                                    <Stack gap={3}>
                                        <div className="d-inline-flex fs-2">
                                            <div className="d-flex-column align-content-end">
                                                <div>Your max contribution is:</div>
                                                <div className="text-secondary fs-6">Payment is <u>not</u> processed yet.</div>
                                            </div>
                                            <div>
                                                <Form.Select value={scenario['payment']} onChange={(e) => handleScenarioChange(e, 'payment')} className="no-border-select pricingPageSelect text-success" borderaria-label="Select option " style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', }}>
                                                    <option value={20 * 100}>$20</option>
                                                    <option value={200 * 100}>$200</option>
                                                    <option value={1200 * 100}>$1,200</option>
                                                </Form.Select>
                                            </div>
                                        </div>
                                        <div className="d-inline-flex fs-2">
                                            <div className="d-flex-column align-content-end">
                                                <div>The campaign raised:</div>
                                            </div>
                                            <div>
                                                <Form.Select value={scenario['raised']} onChange={(e) => handleScenarioChange(e, 'raised')} className="no-border-select pricingPageSelect text-success" borderaria-label="Select option" style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', fontWeight: 400, }}>
                                                    <option value={3000 * 100}>$3,000</option>
                                                    <option value={5000 * 100}>$5,000</option>
                                                    <option value={20000 * 100}>$20,000</option>
                                                </Form.Select>
                                            </div>
                                        </div>

                                        {/* <div className="w-100 d-flex justify-content-center my-3">
                                             <FaArrowDown size={42} />
                                         </div> */}
                                        <div className=" text-center fs-1 w-100 d-flex justify-content-center  p-3 mt-5">
                                            <div className="d-inline-flex align-items-center">  <span className=""> <CrowdPostLogo responsive={'2.5rem'} /> submits the campaign and receives a decision from our media partners</span></div>
                                            {/* <Badge style={{ border: '1px solid grey', textWrap: 'balance', }} className="px-3" pill bg=""><strong>Campaign Submitted & Decision Received</strong>
                                            </Badge> */}
                                        </div>
                                    </Stack>
                                </Col>
                                {campaignFunded ?
                                    <Col xs={12} >
                                        <div className="d-flex justify-content-center">
                                            <TbArrowFork className="justify-content-center my-4" style={{ transform: 'rotate(180deg)' }} size={50} />
                                        </div>
                                        <div
                                            className="d-lg-none mb-5"
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'space-evenly',
                                            }}>
                                            <Badge className="fs-4" style={{ fontSize: '1em' }} pill bg="success"><strong>Accepted</strong></Badge>
                                            <Badge className="fs-4" style={{ fontSize: '1em' }} pill bg="danger"><strong>Denied</strong></Badge>
                                        </div>
                                        <CardGroup>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title bsPrefix="fs-3 text-center bg-success p-2 rounded" ><strong>Campaign Accepted</strong></Card.Title>
                                                    <ul className="fs-4 text-left">
                                                        <li>
                                                            The campaign costs: <Form.Select value={scenario['cost']} onChange={(e) => handleScenarioChange(e, 'cost')} className={`pricingPageSelect ${campaignFunded ? 'text-success' : 'text-danger'} no-border-select`} aria-label="Select option" style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', fontWeight: 400, }}>
                                                                <option value={900 * 100}>$900</option>
                                                                <option value={3000 * 100}>$3,000</option>
                                                                <option value={20000 * 100}>$20,000</option>
                                                            </Form.Select>
                                                        </li>
                                                        {
                                                            userPayment ?
                                                                parseInt(scenario['raised']) === parseInt(scenario['cost']) ?
                                                                    <li style={{ marginLeft: '.25em' }}>
                                                                        Your <strong className="d-inline-flex text-success">{<GetPrice priceInCents={parseInt(userPayment['userCost'])} />}</strong><span>  maximum contribution is charged</span>
                                                                    </li>
                                                                    :
                                                                    <>
                                                                        <li>
                                                                            <span>
                                                                                The campaign was overfunded by <span className="d-inline-flex text-warning">
                                                                                    <GetPrice priceInCents={parseInt(scenario['raised']) - parseInt(scenario['cost'])} />
                                                                                </span><span className=""></span>
                                                                            </span>
                                                                            <ul>
                                                                                <li>
                                                                                    <strong className="text-muted">Your contribution is reduced.</strong>
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                        <li>
                                                                            Your <strong className="d-inline-flex text-success">{<GetPrice priceInCents={parseInt(scenario['payment'])} />} </strong> max contribution is reduced too <strong className="d-inline-flex text-success">{<GetPrice priceInCents={parseInt(userPayment['userCost'])} />} </strong>.
                                                                            <ul className="fs-5 text-secondary">
                                                                                <li>
                                                                                    <span>Since your max contribution was </span>
                                                                                    <span className="text-primary text-decoration-underline">
                                                                                        {(userPayment['userPercentage']) >= 1 ?
                                                                                            `${parseInt(userPayment['userPercentage'])}%`
                                                                                            :
                                                                                            `${userPayment['userPercentage'].toFixed(2).toString().substring(1)}%`
                                                                                        }
                                                                                    </span>
                                                                                    <span> of the <u>total</u></span> <div className="d-inline-flex text-success">
                                                                                        {<GetPrice priceInCents={parseInt(scenario['raised'])} />}
                                                                                    </div> raised
                                                                                </li>
                                                                                <li>
                                                                                    <span>Your <span className="d-inline-flex text-success">{<GetPrice priceInCents={parseInt(userPayment['userCost'])} />} </span> final charge is </span>
                                                                                    {(userPayment['userPercentage']) >= 1 ?
                                                                                        <span className="text-decoration-underline text-primary">${parseInt(userPayment['userPercentage'])}%</span>
                                                                                        :
                                                                                        <span><span className='text-decoration-underline text-primary'>{userPayment['userPercentage'].toFixed(2).toString().substring(1)}%</span> </span>
                                                                                    }
                                                                                    <span>of the campaign's <u>final cost</u> of</span> <span className='d-inline-flex text-success'>  <GetPrice priceInCents={parseInt(scenario['cost'])} /></span>.
                                                                                </li>
                                                                            </ul>
                                                                        </li>
                                                                    </>
                                                                :
                                                                <div>
                                                                    loading
                                                                </div>
                                                        }
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                            <Col xs={12} className="d-block d-lg-none my-4">
                                                <OrDivider />
                                            </Col>
                                            <Card>
                                                <Card.Body>
                                                    <Card.Title bsPrefix="bg-danger p-2 rounded text-center fs-3" ><strong>Campaign Denied</strong></Card.Title>
                                                    <ul className="fs-4">
                                                        <li>You are charged <strong><span>99¢</span></strong>.</li>
                                                        <li>
                                                            The campaign stays on CrowdPost and a reason for denial is posted.
                                                            <ul>
                                                                <li className="text-secondary fs-5">
                                                                    For 99¢ you can shed light on a topic that is trying to be hidden from public discourse
                                                                </li>
                                                                <li className="text-secondary fs-5">This lets users search to find reasons for denial for fully funded campaigns.</li>
                                                            </ul>
                                                        </li>
                                                    </ul>
                                                </Card.Body>
                                            </Card>
                                        </CardGroup>
                                    </Col>
                                    :
                                    <Col xs={12} className="d-inline justify-content-center">
                                        <div className="mb-3 text-center fw-bolder">
                                            <GoArrowDown className="fw-bold" size={50} />
                                        </div>
                                        <Row className="d-flex justify-content-center">
                                            <Col xs={12} lg={8} >
                                                <Card>
                                                    <Card.Body>
                                                        <Card.Title bsPrefix="bg-warning p-2 rounded text-center fs-3 text-secondary" ><strong>Campaign Underfunded</strong></Card.Title>
                                                        <ul className="fs-4 text-left">
                                                            <li><strong>You are are not charged.</strong></li>
                                                            <li >
                                                                <div className="d-inline-flex">
                                                                    <span  >The campaign was underfunded by
                                                                        <span className="d-inline-flex text-warning mx-1">
                                                                            <strong >
                                                                                {
                                                                                    <GetPrice priceInCents={(parseInt(scenario['cost']) - parseInt(scenario['raised']))} />
                                                                                }
                                                                            </strong>
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </li>
                                                            <li>Not enough was raised to purchase media.</li>
                                                            <li>
                                                                <span>The</span> <div className="d-inline-flex text-success fw-light fs-3">
                                                                    {<GetPrice priceInCents={parseInt(scenario['raised'])} />}
                                                                </div> <span>raised did not reach the</span>
                                                                <Form.Select value={scenario['cost']} onChange={(e) => handleScenarioChange(e, 'cost')} className={`pricingPageSelect ${campaignFunded ? 'text-success' : 'text-danger'} no-border-select`} aria-label="Select option" style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', fontWeight: 400, }}>
                                                                    <option value={900 * 100}>$900</option>
                                                                    <option value={3000 * 100}>$3,000</option>
                                                                    <option value={20000 * 100}>$20,000</option>
                                                                </Form.Select>
                                                                <span > needed to purchase media</span>
                                                            </li>
                                                        </ul>
                                                    </Card.Body>
                                                    <Card.Footer className="fs-5">
                                                        <strong >No contributions are charged</strong> if a campaign does not raise enough to purchase media.
                                                    </Card.Footer>
                                                </Card>
                                            </Col>
                                        </Row>
                                    </Col >
                                }
                            </Row >
                            :
                            <Row style={{ marginTop: '3rem', marginBottom: '3rem' }}>
                                <Col xs={12} className="d-flex">
                                    <h1 className="pricingSubHeader">
                                        Here is an example
                                    </h1>
                                </Col>
                                <hr />
                                <Col>
                                    <h1 className="pricingPageForm" >You contribute
                                        <Form.Select value={scenario['payment']} onChange={(e) => handleScenarioChange(e, 'payment')} className="no-border-select pricingPageSelect" borderaria-label="Select option" style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', }}>
                                            <option value={20 * 100}>$20</option>
                                            <option value={200 * 100}>$200</option>
                                            <option value={1200 * 100}>$1,200</option>
                                        </Form.Select> to a campaign that costs
                                        <Form.Select value={scenario['cost']} onChange={(e) => handleScenarioChange(e, 'cost')} className={`pricingPageSelect ${campaignFunded ? 'text-success' : 'text-danger'} no-border-select`} aria-label="Select option" style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', fontWeight: 400, }}>
                                            <option value={900 * 100}>$900</option>
                                            <option value={3000 * 100}>$3,000</option>
                                            <option value={20000 * 100}>$20,000</option>
                                        </Form.Select>
                                        and raised
                                        <Form.Select value={scenario['raised']} onChange={(e) => handleScenarioChange(e, 'raised')} className="no-border-select pricingPageSelect " borderaria-label="Select option" style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', fontWeight: 400, }}>
                                            <option value={3000 * 100}>$3,000</option>
                                            <option value={5000 * 100}>$5,000</option>
                                            <option value={20000 * 100}>$20,000</option>
                                        </Form.Select>.
                                        {campaignFunded && <span > CrowdPost submits the campaign and it is...</span>}
                                    </h1>
                                </Col>
                                {campaignFunded ?
                                    <>
                                        <TbArrowFork className="d-none d-lg-block my-5" style={{ transform: 'rotate(180deg)' }} size={50} />
                                        <Col xs={12} lg={6} className="pricingPageForm text-center">
                                            <div className="text-center">
                                                <h1 style={{ display: 'inline-block', width: 'auto', marginLeft: '10px' }}><strong className="text-success">Accepted</strong></h1>
                                            </div>
                                            {
                                                userPayment ?
                                                    <div className="align-items-center text-center" style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', textAlign: 'center' }}>
                                                        {
                                                            parseInt(scenario['raised']) === parseInt(scenario['cost']) ?
                                                                <div>
                                                                    You are charged <strong className="text-success">${parseInt(userPayment['userCost']) / 100}</strong>.
                                                                </div>
                                                                :
                                                                <div>
                                                                    You are charged <strong className="text-success">${parseInt(userPayment['userCost']) / 100}</strong> instead of your original <strong className="text-success">${parseInt(scenario['payment']) / 100}</strong>.
                                                                    {parseInt(userPayment['userCost']) !== parseInt(scenario['payment']) &&
                                                                        <span>
                                                                            Your contribution made up <strong className="text-success">
                                                                                {(userPayment['userPercentage']) >= 1 ?
                                                                                    `${parseInt(userPayment['userPercentage'])}%`
                                                                                    :
                                                                                    `${userPayment['userPercentage'].toFixed(2)}%`
                                                                                }
                                                                            </strong> of the <strong className="text-success">${parseInt(scenario['raised']) / 100}</strong> raised.
                                                                        </span>
                                                                    }
                                                                </div>
                                                        }
                                                    </div>
                                                    :
                                                    <div>
                                                        loading
                                                    </div>
                                            }
                                        </Col>
                                        <Col xs={12} className="d-block d-lg-none">
                                            <OrDivider />
                                        </Col>
                                        <Col className="pricingPageForm mt-5 mt-lg-0" xs={12} lg={6} >
                                            <div className="text-center">
                                                <h1 style={{ display: 'inline-block', width: 'auto', marginLeft: '10px', }}><strong className="text-danger">Denied</strong></h1>
                                            </div>
                                            <div className="text-center">
                                                You are charged <strong><span>99¢</span></strong>
                                            </div>
                                        </Col>
                                    </>
                                    :
                                    <h1 className="my-5 text-primary text-center"><strong>You are not charged. The campaign did not reach the minimum threshold to purchase media.</strong></h1>
                                }
                            </Row>
                    }
                </>
        }
        </div>


    )
}

export default ContributionPricing
