import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import CampaignDescriptionForm from '../CampaignDescriptionForm';



function UpdateDescriptionModal({ showModal, description, handleCloseModal, campaignId, handleDescriptionUpdate }) {


    return (
        <Modal
            size="lg"
            onHide={() => handleCloseModal()} show={showModal}
            dialogClassName="vw-100"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title><h3 className="fw-bold">Edit your campaign description</h3></Modal.Title>
            </Modal.Header>
            <Modal.Body className="mx-4 my-2">
                <Row>


                    <Col xs={12}>
                        {
                            description &&
                            <CampaignDescriptionForm
                                campaignId={campaignId}
                                descriptionData={description}
                                handleDescriptionUpdate={handleDescriptionUpdate}
                            />
                        }
                    </Col>


                </Row>
            </Modal.Body>
        </Modal>
    )
}

export default UpdateDescriptionModal
