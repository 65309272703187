
import React from 'react'
import NewCampaignImageBackground from './NewCampaignImageBackground';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';


function UploadMedia({  image_data, mediaName, handleFileInputChange }) {




    return (
        <Row className="1">
            <Col xs={12}>

                <Form.Group className="my-3">


                    <Col xs="12"><Button variant="outline-primary" className="px-5 rounded-pill" href={image_data['template']} download><strong>Download sample media template</strong></Button></Col>
                    <Form.Text>Download a template for your media here</Form.Text>

                </Form.Group>


                <Form.Group controlId="formFile" >

                    <NewCampaignImageBackground
                        isUpload
                        image_data={image_data}
                        mediaName={mediaName}
                        handleFileInputChange={handleFileInputChange}
                        message_index="base_message"


                    />
                </Form.Group>




            </Col>



        </Row>


    )
}

export default UploadMedia
