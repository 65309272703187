import { useState, useEffect, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Body from '../components/Body';
import InputField from '../components/InputField';
import { useApi } from '../contexts/ApiProvider';
import { useUser } from '../contexts/UserProvider';
import { useFlash } from '../contexts/FlashProvider';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ProfilePhoto from '../components/ProfilePhoto';


export default function EditUserPage() {
    const [isLoading, setIsLoading] = useState(false);

    const [formErrors, setFormErrors] = useState({});
    const usernameField = useRef();
    // const emailField = useRef();
    const fullNameField = useRef();
    const avatarField = useRef();
    const aboutMeField = useRef();
    const accountNameField = useRef();
    const api = useApi();
    const { user, setUser } = useUser();
    const flash = useFlash();
    const navigate = useNavigate();

    useEffect(() => {
        usernameField.current.value = user.username;
        accountNameField.current.value = user.account_name ? user.account_name : '';
        aboutMeField.current.value = user.about_me;
        fullNameField.current.value = user.full_name;
        usernameField.current.focus();

        
    }, [user]);

    const handleImageUpload = (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = (e) => {
                setImageSrc(e.target.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const onSubmit = async (event) => {

        event.preventDefault();
        try {
            console.log(user);
            setIsLoading(true);
            const file = avatarField.current.files[0];
            if (file) {
                const filename = file.name
                console.log(file)
                const reader = new FileReader();

                console.log(file, 'FILE')
                if (file) {
                    reader.readAsDataURL(file);
                }

                reader.onloadend = async () => {
                    const base64String = reader.result;

                    const response = await api.put('/me', {
                        username: usernameField.current.value,
                        // email: emailField.current.value,
                        about_me: aboutMeField.current.value,
                        profile_photo: base64String,
                        filename: filename,
                        full_name: fullNameField.current.value,
                        account_name: accountNameField.current.value,
                    });

                    if (response.ok) {

                        setFormErrors({});
                        setUser(response.body);
                        flash('Your profile has been updated.', 'success');
                        navigate('/user/' + response.body.username);
                    }
                    else {
                        if (response.body && 'errors' in response.body) {
                            console.log(response.body.errors, 'errors')
                            setFormErrors(response.body.errors.json);
                        }
                    }
                }
            }
            else {
                const response = await api.put('/me', {
                    username: usernameField.current.value,
                    // email: emailField.current.value,
                    about_me: aboutMeField.current.value,
                    full_name: fullNameField.current.value,
                    account_name: accountNameField.current.value,

                });

                if (response.ok) {

                    setFormErrors({});
                    setUser(response.body);
                    flash('Your profile has been updated.', 'success');
                    navigate('/user/' + response.body.username);
                }
                else {
                    if (response.body &&  'errors' in response.body) {
                        console.log(response.body.errors, 'errors')
                        setFormErrors(response.body.errors.json);
                    }
                }
            }
            setIsLoading(false)

        }
        catch {
            setIsLoading(false)
        }
        finally {
            setIsLoading(false); // Reset loading state when the request completes
          }

    };


    const imgRef = useRef(null);
    const [imageSrc, setImageSrc] = useState('');


    return (
        <Body classStr={{ height: '100vh' }} sidebar>
            <Row>
                <Col xs={12} md={3}>
                    <Form onSubmit={onSubmit}>
                        <InputField
                            name="accountName" label="Account Name"
                            error={formErrors.account_name} fieldRef={accountNameField} />
                        <InputField
                            name="username" label="Username"
                            error={formErrors.username} fieldRef={usernameField} />
                        <InputField
                            name="fullName" label="Full Name"
                            error={formErrors.full_name} fieldRef={fullNameField} />
                        {/* <InputField
                            name="email" label="Email"
                            error={formErrors.email} fieldRef={emailField} /> */}
                        <Form.Group >
                            <Form.Label>About me</Form.Label>
                            <Form.Control style={{minHeight:'250px'}} error={formErrors.about_me} ref={aboutMeField} as="textarea" placeholder="Enter text" />
                        </Form.Group>
                        <Form.Group controlId="formFile" className="my-3">
                            <Form.Label>Upload profile photo</Form.Label>

                            <div
                                className="my-2"
                            >


                                <ProfilePhoto
                                    avatar_url={imageSrc ? imageSrc : user && user.avatar_url}
                                    fieldRef={imgRef}
                                    size={2}
                                />
                            </div>
                            <Form.Control
                                type="file"
                                accept="image/*"
                                ref={avatarField}
                                onChange={handleImageUpload}

                            />
                        </Form.Group>
                        <Button disabled={isLoading} className='mt-3' type="submit">Save</Button>
                    </Form>
                </Col>
            </Row>
        </Body>
    );
}