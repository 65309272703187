import React from 'react'
import Body from '../components/Body'
import Col from 'react-bootstrap/Col'
import Row from 'react-bootstrap/Row'
import { Helmet } from 'react-helmet';




function WhatCanBePostedPage() {

    return (
        <>
            <Helmet>
                <title>CrowdPost - What can be posted</title>
                <meta property="og:title" content={`Let your voice reach the real town square`} />
                <meta property="og:type" content="website" />
                {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
                {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
                {/* <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." /> */}

            </Helmet>
            <Body footer>



                <Row className="mb-5">


                    <Col className="mb-5" xs={12}>

                        <h1 className="pricingHeader text-center mb-3">What can be posted?</h1>

                        <p className="fs-5">CrowdPost was built with fostering an open public discourse in mind. Laws and regulations that vary by locality can impact what is permissable to appear on a billboard in a given area. Ultimately, our media partners decide what postings they can accomodate. If for whatever reason they cannot accomodate a post, the reason for the denial will appear on the campaigns page and the campaigns contributions will be charged the 99¢ platform fee. Since users have to contribute money to support a campaign they will be more selective to contribute to campaigns that will be published. If a campaign is not accepted by our media partners for </p>
                        <hr />
                        <strong >Following the criteria below while desiging your post will increase the likliehood your campaign is fundraised, accepted by our media partners, and not taken down after publishing:</strong>

                        <ul className="fs-5" >
                            <li>
                                No posting of copywritten material or other types of intellectual property unless you have permission. No impersonation or posting of other persons personal information without consent.                             </li>
                            {/* <li>is false, inaccurate, or intentionally deceptive information and likely to adversely affect the public interest (including health, safety, election integrity, and civic participation);</li> */}
                            <li>
                                No false advertisement
                            </li>
                            <li>No content that harasses or abuses another individual or group, including our employees, officers, and agents, or other users;</li>
                            <li>
                                Speech That Endangers Public Safety (Speech that creates a clear and present danger to public safety or the general welfare.)
                            </li>
                            <li>
                                No defamatory statements (i.e. Libel, statements that can damage someone's reputation through false information, defamatory, or fraudulent;)
                            </li>
                            <li>
                                No incitement to imminent lawless damage. (See Brandenburg v. Ohio (1969), Schenck v. United States )
                            </li>
                            <li>
                                No speech promoting illegal activities
                            </li>
                            <li>
                                No nudity or sexually explicit material. No content that is sexually obscene or relates to sexual exploitation or abuse, including of minors;
                            </li>
                            <li>
                                No direct advertisement with calls to action

                            </li>

                            <li>Content that gratuitously depicts or glorifies violence, including violent images; or is off-topic, or interacts with platform features in a way that significantly or repeatedly disrupts the experience of other users.</li>



                            <li>Where to find non-copywritten images:
                                <ul>
                                    <li>
                                        <a href={"https://www.si.edu/OpenAccess"} target="_blank" rel="noopener noreferrer">
                                            Smithsonian historical images
                                        </a>

                                    </li>
                                    <li>
                                        <a href={"https://unsplash.com/"} target="_blank" rel="noopener noreferrer">
                                            Unsplash free use images
                                        </a>

                                    </li>
                                    <li>
                                        <a href={"https://pixabay.com/"} target="_blank" rel="noopener noreferrer">
                                            Pixabay free use images
                                        </a>

                                    </li>
                                    <li>
                                        <a href={"https://isorepublic.com/"} target="_blank" rel="noopener noreferrer">
                                            ISO Republic free use images
                                        </a>

                                    </li>
                                    <li>
                                        CrowdPost does not specifically endorse any image provider. By agreeing to our terms of service when posting you agree that you are responsible for not using copywritten material and if you do make sure to have permission.

                                    </li>
                                </ul>
                            </li>
                        </ul>
                        CrowdPost reserves the right to take down any campaign that violates its terms of service. If you have any questions please contact us.


                    </Col>





                </Row>





            </Body >


        </>
    )
}

export default WhatCanBePostedPage