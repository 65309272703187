import React from 'react'
import InfoPopover from './InfoPopover';
import Popover from 'react-bootstrap/Popover';

function HelpPopover({ popoverHeader, popoverBody, children }) {
    return (
        <div className="d-flex fw-bold align-items-center" style={{

        }}>

            {children}
            <span >
                <InfoPopover popover={
                    <Popover id={`popover-basic-${popoverHeader}`}>
                        <Popover.Header as="h3">{popoverHeader}</Popover.Header>
                        <Popover.Body>
                            {popoverBody}
                        </Popover.Body>
                    </Popover>
                }
                />
            </span>
        </div>
    )
}

export default HelpPopover
