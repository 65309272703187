import React, { useState, useEffect } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import InputField from './InputField';
import Button from 'react-bootstrap/Button';
import Placeholder from 'react-bootstrap/Placeholder';
import ImpressionValue from './ImpressionValue';
import RadiusBadge from './RadiusBadge';


function PolygonPOIList({ featureCollection, removePolygon, showPopup, handleUpdateFeatureCollection, polygon, handleGetAvgImpressions, isGetAvgImpressionLoading }) {
    const [editNameIndex, setEditNameIndex] = useState(false);

    const [poiEmpty, setPoiEmpty] = useState(true);

    const [avgTotalImp, setAvgTotalImp] = useState(false)



    useEffect(() => {

        if (featureCollection && 'features' in featureCollection) {

            var poi_features = featureCollection.features.filter((item) => ('properties' in item && "poi_radius" in item.properties && item.properties['poi_radius'] !== null));
            if (poi_features.length === 0) {
                setPoiEmpty(true)
            }
            else {
                setPoiEmpty(false)
            }
            var sum = featureCollection['features'].reduce((acc, value) => {
                if (value.hasOwnProperty('properties') && value['properties'].hasOwnProperty('imp')) {
                    return acc + value['properties']['imp']
                }
                return acc
            }, 0);

            setAvgTotalImp(sum)

        }

    }, [featureCollection])


    const BUTTON_CLASS = 'm-1';


    const handleConfirmEditName = (id) => {

        let editLocation = featureCollection.features.find(item => item.id === id)

        if (editLocation) {
            if (editNameIndex.value.length > 0) {

                let name = editNameIndex.value
                // foundId.name = name
                editLocation.properties.name = name

                handleUpdateFeatureCollection(featureCollection)

                setEditNameIndex(false)
            }
        }

        else {
            setEditNameIndex(false)
            return
        }





    }


    const handleUpdateEditableLocationValue = (e, id) => {
        // e.preventDefault();
        e.preventDefault()

        if (editNameIndex && 'value' in editNameIndex) {
            setEditNameIndex((prev) => ({
                ...prev,
                value: e.target.value
            }))
        }



    }

    return (
        <Col xs={12}>
            <Row className="mt-3">

                <Col xs={12}>
                    <h3><strong>Points of interest</strong></h3>
                </Col>

                <Col xs={12}>

                    {
                        featureCollection === undefined ?
                            <Placeholder className="rounded" animation="glow">
                                <Placeholder xs={4} className="rounded" />
                            </Placeholder>
                            :
                            featureCollection === null || poiEmpty === true ?
                                <div className="text-muted" >
                                    <p>None</p>
                                </div>
                                :

                                featureCollection['features'].map((i, index) =>
                                    i && 'properties' in i && 'poi_radius' in i['properties'] &&
                                    <Row className="mb-5 mb-lg-3" key={index}>
                                        {/* <div className="d-flex align-items-center justify-content-between  py-2"> */}

                                        <Col xs={8} >
                                            <div>
                                                {
                                                    // 'properties' in i && 'name' in i.properties && i.properties.poi_name ?
                                                    editNameIndex && editNameIndex.id === i['id'] ?
                                                        <InputField
                                                            label={i['properties']['name']}
                                                            value={editNameIndex.value}
                                                            // label={i.name}
                                                            // value={i.value}
                                                            onChange={(e) => handleUpdateEditableLocationValue(e, i['id'])}
                                                        // fieldRef={(el) => editNameRefs.current.push(el)}
                                                        />
                                                        :
                                                        <div>{i['properties']['name']}<br />
                                                            {/* <p>{i.properties.poi_name}<br /> */}
                                                            <strong className="text-muted">Radius: </strong>
                                                            {
                                                                'properties' in i && i['properties']['poi_radius'] &&
                                                                    <RadiusBadge radius={i.properties.poi_radius} />
                                                                
                                                            }
                                                            {/* i.properties.poi_radius} */}

                                                            {/* {
                                                                i['properties']['poi_radius'] > 1 ?
                                                                    <span> miles</span>
                                                                    :
                                                                    <span> mile</span>
                                                            } */}
                                                        </div>
                                                }
                                            </div>
                                            <div className="text-muted">
                                                {
                                                    'imp' in i['properties'] &&
                                                    <ImpressionValue impression={i['properties']['imp']} />
                                                }
                                            </div>
                                        </Col>
                                        <Col sm={4} style={{ textAlign: 'right' }}>


                                            {
                                                editNameIndex && editNameIndex.id === i['id'] ?
                                                    <Button className="m-1" size="sm" onClick={() => handleConfirmEditName(i['id'])} variant="outline-primary" >Confirm</Button>
                                                    :
                                                    <Button className="m-1" size="sm" onClick={() => setEditNameIndex({ 'value': '', 'id': i['id'] })} variant="outline-primary"  >Edit</Button>
                                            }
                                            <Button className="m-1" size="sm" onClick={() => showPopup(i['id'])} variant="outline-success" >Show</Button>
                                            <Button className="m-1" size="sm" onClick={() => removePolygon(i['id'])} variant="outline-danger">Remove</Button>

                                        </Col>
                                    </Row>
                                )


                    }


                </Col>
            </Row>


            <Row className="mt-3">
                <Col xs={12}>
                    <h3><strong>Custom Areas</strong></h3>
                </Col>
                <Col xs={12}>
                    {
                        polygon === undefined ?
                            <Placeholder className="rounded" animation="glow">
                                <Placeholder xs={4} className="rounded" />
                            </Placeholder>
                            :
                            ((!polygon) || (polygon.length === 0)) ?
                                <p className="text-muted">None</p>
                                :
                                polygon.map((i, index) =>
                                    <Row className="mb-5 mb-lg-3" key={index}>


                                        <Col xs={8}>

                                            {
                                                // 'properties' in i && 'name' in i.properties && i.properties.poi_name ?
                                                editNameIndex && editNameIndex.id === i.id ?
                                                    <InputField
                                                        label={i.properties.name}
                                                        value={editNameIndex.value}
                                                        // label={i.name}
                                                        // value={i.value}
                                                        onChange={(e) => handleUpdateEditableLocationValue(e, i.id)}
                                                    // fieldRef={(el) => editNameRefs.current.push(el)}
                                                    />
                                                    :
                                                    <span >{i['properties']['name']}</span>
                                            }
                                            <div className="text-muted">
                                                {
                                                    'imp' in i['properties'] &&
                                                    <ImpressionValue impression={i['properties']['imp']} />
                                                }
                                            </div>
                                        </Col>

                                        <Col sm={4} style={{ textAlign: 'right' }} >
                                            {
                                                editNameIndex && editNameIndex.id === i.id ?
                                                    <Button className={BUTTON_CLASS} size="sm" onClick={() => handleConfirmEditName(i.id)} variant="outline-primary" >Confirm</Button>
                                                    :
                                                    <Button className={BUTTON_CLASS} size="sm" onClick={() => setEditNameIndex({ 'value': '', 'id': i.id })} variant="outline-primary"  >Edit</Button>
                                            }
                                            <Button className={BUTTON_CLASS} size="sm" onClick={() => showPopup(i.id)} variant="outline-success" >Show</Button>
                                            <Button className={BUTTON_CLASS} size="sm" onClick={() => removePolygon(i.id)} variant="outline-danger">Remove</Button>
                                        </Col>



                                    </Row>


                                )




                    }




                </Col>
            </Row >


            {
                featureCollection !== null && featureCollection !== undefined && 'features' in featureCollection && featureCollection['features'].length > 0 &&

                <Row>
                    <Col xs={12}>
                        <hr />
                    </Col>
                    <Col xs={12} className="d-flex justify-content-between">
                        <span>
                            {avgTotalImp && parseInt(avgTotalImp) > 0 ?
                                <span className="d-flex">
                                    <span><strong>Est. avg weekly impressions per billboard: </strong> </span>
                                    <span className="mx-1">
                                        <ImpressionValue label={" "} impression={avgTotalImp} />
                                    </span>

                                </span>
                                :
                                <div></div>
                            }
                        </span>

                    </Col>
                    <Col className="mt-5 d-flex justify-content-end" xs={12}>



                        <div className="text-end">
                            <Button disabled={isGetAvgImpressionLoading} className={BUTTON_CLASS} size="sm" onClick={() => handleGetAvgImpressions()} variant="primary" ><strong>Calculate avg impressions</strong></Button>
                            <div className="text-muted small text-right"><span className="text-muted small text-right">Avg. weekly impression data provided by Geopath Inc.</span></div>
                        </div>


                    </Col>
                </Row>
            }
            {/* If your campaign raises more than the const. your contribution amount will be reduced.  */}
        </Col >
    )
}

export default PolygonPOIList
