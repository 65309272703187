import { useState, useRef } from 'react';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useNavigate } from 'react-router-dom';
import Body from '../components/Body';
import InputField from '../components/InputField';
import { useApi } from '../contexts/ApiProvider';
import { useFlash } from '../contexts/FlashProvider';

import { useUser } from '../contexts/UserProvider';
import FormButtonBlock from '../components/FormButtonBlock';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';



export default function EditUserPage() {
    const [formErrors, setFormErrors] = useState({});
    const [showModal, setShowModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const oldPasswordField = useRef();
    const passwordField = useRef();
    const password2Field = useRef();
    const navigate = useNavigate();
    const api = useApi();
    const flash = useFlash();
    const { logout } = useUser();
    const user = useUser();
    const confirmDeleteRef = useRef();

    const onSubmit = async (event) => {
        event.preventDefault();
        if (passwordField.current.value !== password2Field.current.value) {
            setFormErrors({ password2: "New passwords don't match" });
        }
        else {
            const response = await api.put('/me', {
                old_password: oldPasswordField.current.value,
                password: passwordField.current.value
            });
            if (response.ok) {
                flash('Your password has been updated.', 'success');
                setInterval(() => {
                    setFormErrors({});
                    logout()
                    navigate('/login');
                    window.location.reload(true);
                }, 2000);

            }
            else {
                setFormErrors(response.body.errors.json);
            }
        }
    };

    const handleClose = () => setShowModal(false);


    const handleDeleteAccount = () => {
        setFormErrors({});
        setIsLoading(true);

        confirmDeleteRef.current && confirmDeleteRef.current.value === 'delete' ?


            (async () => {
                const response = await api.delete(`/delete-account`);
                if (response.ok) {
                    setIsLoading(false);
                    window.localStorage.removeItem('accessToken');
                    window.localStorage.removeItem('campaignID');
                    window.localStorage.removeItem('taskID');
                    window.localStorage.removeItem('last_search')
                    flash('Account was removed', 'success');
                    navigate('/', { replace: true });
                    window.location.reload(true);

                }
                else {
                    setIsLoading(false);
                    if ('errors' in response.body) {
                        logout()
                        flash(response.body.errors.json.messageƒ, 'success');
                        setFormErrors(response.body.errors.json);
                    }
                    else {
                        flash('Your password has been updated.', 'success');
                    }
                }
            })()
            :

            setFormErrors({ confirmation: "Input must match `delete`" })
    }



    return (
        <Body sidebar classStr={{ height: '100vh' }}>
            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header>

                    <Modal.Title>
                        Delete Account
                    </Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <InputField
                        fieldRef={confirmDeleteRef}
                        formText={"Type `delete` to confirm your account deletion"}
                        label="Confirmation"
                        error={formErrors['confirmation']}

                    />
                    {
                        isLoading ?
                            <Button disabled className="mt-4" onClick={() => handleDeleteAccount()} variant="danger">Deleting account...</Button>
                            :
                            <Button className="mt-4" onClick={() => handleDeleteAccount()} variant="danger">Delete Account</Button>
                    }

                </Modal.Body>
            </Modal>

            <Container fluid>


                <Row>
                    <Col xs={12}>
                        <h2 className="pricingSubHeader">Manage Account</h2>
                    </Col>
                </Row>
                <Container className="mt-5">
                    <Row>
                        <Col xs={12} md={4} lg={3}>
                            {
                                user && user.auth_type === 'password' ?
                                    <>
                                        <h3>Change Your Password</h3>
                                        <Form onSubmit={onSubmit}>
                                            <InputField
                                                name="oldPassword" label="Old Password" type="password"
                                                error={formErrors.old_password} fieldRef={oldPasswordField} />
                                            <InputField
                                                name="password" label="New Password" type="password"
                                                error={formErrors.password} fieldRef={passwordField} />
                                            <InputField
                                                name="password2" label="Confirm New Password" type="password"
                                                error={formErrors.password2} fieldRef={password2Field} />
                                            <FormButtonBlock text={'Change Password'} />
                                        </Form>
                                    </>
                                    :
                                    <h3>Signed in with google</h3>
                            }
                        </Col>
                        <hr className="mt-5" />
                        <Col xs={12}>
                            <h4>Delete Account</h4>
                            <Button className="mt-4" onClick={() => setShowModal(true)} variant="danger">Delete Account</Button>
                        </Col>
                    </Row>
                </Container>
            </Container>

        </Body>
    );
}