import { useState, useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Body from '../components/Body';
import OrDivider from '../components/OrDivider';
import InputField from '../components/InputField';
import { useUser } from '../contexts/UserProvider';
import { useFlash } from '../contexts/FlashProvider';

import { useApi } from '../contexts/ApiProvider';
import GoogleButton from '../components/GoogleButton';
import FormButtonBlock from '../components/FormButtonBlock';
import { Helmet } from 'react-helmet';
import { IoEye } from "react-icons/io5";
import { IoEyeOffSharp } from "react-icons/io5";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


export default function LoginPage({ modal, handleClose }) {

  const [formErrors, setFormErrors] = useState({});

  const { login } = useUser();
  const flash = useFlash();
  const navigate = useNavigate();
  const usernameField = useRef();
  const passwordField = useRef();
  const api = useApi();
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);




  useEffect(() => {

    usernameField.current.focus();
    const queryParams = new URLSearchParams(window.location.search);
    const token = queryParams.get('token');

    token &&

      (async () => {

        const response = await api.get("/tokens/verify_confirmation", { 'token': token });

        if (response.ok) {
          flash("Your account has been verified, please login", "success")

        }
        else {
          if (response && 'body' in response && 'errors' in response.body) {

            flash(<div>
              <div><strong>{response.body.errors.json.message}:</strong></div>
              <div>
                <ul>
                  <li>Request <Link to="/new-confirmation-token">here</Link></li>
                  <li>Find the link on the <Link to="/register">Sign Up</Link> page</li>
                </ul>

              </div>
            </div>
              , "danger", 60)
          }
          else {

            flash("Error registering your account, try again", "danger")
          }


        }


      })();

  }, [api, flash]);



  const onSubmit = async (ev) => {
    ev.preventDefault();
    setIsLoading(true);
    const username = usernameField.current.value;
    const password = passwordField.current.value;

    //client sid field validation
    const errors = {};
    if (!username) {
      errors.username = 'Username must not be empty.';
    }
    if (!password) {
      errors.password = 'Password must not be empty.';
    }
    setFormErrors(errors);
    if (Object.keys(errors).length > 0) {
      setIsLoading(false)
      return;
    }
    // end client side field validation 

    const result = await login(username, password);


    if (result['resp'] === 'fail') {
      setIsLoading(false);
      flash(result['error']['message'], 'danger');

    }
    else if (result['resp'] === 'ok' || result === true) {

      setIsLoading(false);
      if (handleClose) {
        handleClose()
        return true
      }

      let next = '/feed';
  


      navigate(next);

    }
    setIsLoading(false);
  };



  const a = {

    height: "100vh"
  }



  return (
    <>
      <Helmet>
        <title>CrowdPost - Login</title>
        <meta property="og:title" content={`Let your voice reach the real town square`} />
        <meta property="og:type" content="website" />
        {/* <meta property="og:image" content={`${process.env.REACT_APP_BASE_API_URL}${campaign.image_url}`} /> */}
        {/* <meta property="og:url" content={`${process.env.REACT_APP_BASE_API_URL}/campaigns/${campaign.id} `} /> */}
        <meta property="og:description" content="Contribute to this campaign for this image to appear on a billboard." />

      </Helmet>
      <Body footer={!modal && true} classStr={modal ? '' : a}>
        <Row style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', }}>
          <Col className="my-4 border-black" sm={modal ? "12" : "12"} xxl={modal ? "10" : "5"}  >
            {
              !modal && <h1 className="mb-3"><strong>Sign in</strong></h1>
            }
            <Form onSubmit={onSubmit}>
              <InputField fieldRef={usernameField}
                name="username" label="Email address"
                error={formErrors.username} />


              <div style={{
                position: "relative",

              }}>
                <InputField
                  name="password" label="Password" type={!showPassword && "password"}
                  error={formErrors.password} fieldRef={passwordField} />

                {
                  showPassword ?

                    <IoEye onClick={() => setShowPassword(false)} size={24} style={{
                      position: "absolute",
                      right: "2%",
                      top: "50%",
                      zIndex: "100",
                      cursor: "pointer",
                    }} />
                    :
                    <IoEyeOffSharp onClick={() => setShowPassword(true)} size={24} style={{
                      position: "absolute",
                      right: "2%",
                      top: "50%",
                      zIndex: "100",
                      cursor: "pointer",
                    }} />

                }
              </div>



              <FormButtonBlock isProcessing={isLoading} text={"Sign in"} />



            </Form>

            <OrDivider />

            <Row className="mt-3">

              <Col xs={12}  >
                <GoogleButton text={'Sign in with Google'} />
              </Col>
            </Row>
            <p className="fs-5 mt-3">Don&apos;t have an account? <Link to="/register">Register here</Link>!</p>
            <p className="fs-5" >Forgot your password? You can <Link to="/reset-request">reset it</Link>.</p>

          </Col>
        </Row>
      </Body>



    </>
  );

}
