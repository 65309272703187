import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import ContributionPricing from './ContributionPricing';



function DonationProtectionModal({ showModal, setShowModal }) {
    // const { darkTheme } = useTheme()
    const darkTheme = true

    return (
        <Modal size="lg" centered show={showModal} onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Contribution pricing details</Modal.Title>
            </Modal.Header>
            <Modal.Body className={`${darkTheme ? 'bg-dark' : 'bg-light'}`}>
                <ContributionPricing receipt />
            </Modal.Body>
            <Modal.Footer className={`${darkTheme ? 'bg-dark' : 'bg-light'}`}>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DonationProtectionModal
