import React from 'react';

const SocialMediaIcon = ({ iconPath, alt, size }) => {
    return (

        <img
            src={iconPath}
            alt={!alt ? 'Social media icon' : alt}
            style={!size ? { height: '26px', } : { width: size, height: size, }}
        />

    );
};

export default SocialMediaIcon;
