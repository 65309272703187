import React, { useState, useRef, useEffect } from 'react';
import { IoResize } from "react-icons/io5";
import { useImageBuilder } from '../contexts/ImageBuilderProvider';


function Draggable({ mediaName, initialSize, index, zIndex, initialPos, imageHover, handleImagePositionChange, angle, scale, handleImageHover, children, handleIsDraggingChange, mediaRef, handleIsCentered }) {

    // const [size, setSize] = useState(initialSize);
    const [dragging, setDragging] = useState(false)
    const [resizing, setResizing] = useState(false);
    const [resizeStart, setResizeStart] = useState(null);
    const [rel, setRel] = useState(null);
    const ref = useRef(null);

    const { handleImageMessageListContextChange, respData, disabled } = useImageBuilder()


    useEffect(() => {

        const onMouseMove = (e) => {
            // const mediaRect = mediaRef.current.getBoundingClientRect();
            const parentRect = ref.current.parentNode.getBoundingClientRect();


            if (resizing) {
                if (!resizeStart) {
                    setResizeStart({ x: e.clientX, y: e.clientY });
                    return;
                }

                const deltaX = e.clientX - resizeStart.x;
                const deltaY = e.clientY - resizeStart.y;

                let newWidth = respData['image_types'][mediaName]['image_messages'][index].width + deltaX;
                let newHeight = respData['image_types'][mediaName]['image_messages'][index].height + deltaY;

                newWidth = Math.min(newWidth, parentRect.width - respData['image_types'][mediaName]['image_messages'][index]['x_position']);
                newHeight = Math.min(newHeight, parentRect.height - respData['image_types'][mediaName]['image_messages'][index]['y_position']);

                newWidth = Math.max(newWidth, 50); // minimum width
                newHeight = Math.max(newHeight, 50); // minimum height

                handleImageMessageListContextChange(mediaName, index, { width: newWidth, height: newHeight })
                // setSize({ width: newWidth, height: newHeight });
                setResizeStart({ x: e.clientX, y: e.clientY });
            } else if (dragging) {

                const newLeft = e.clientX - rel.x - parentRect.left;
                const newTop = e.clientY - rel.y - parentRect.top;



                const newPos = {
                    x: Math.max(0, Math.min(newLeft, parentRect.width - ref.current.offsetWidth)) / parentRect.width * 100,
                    y: Math.max(0, Math.min(newTop, parentRect.height - ref.current.offsetHeight)) / parentRect.height * 100
                };


                const centerX = 50; // Center of the parent container in percentage
                const centerY = 50; // Center of the parent container in percentage

                // Calculate the draggable element's center position in percentage
                const draggableCenterX = newPos.x + ((ref.current.offsetWidth / parentRect.width) * 50);
                const draggableCenterY = newPos.y + ((ref.current.offsetHeight / parentRect.height) * 50);

                // Check if centered within tolerance
                const tolerance = .5; // Percentage tolerance for centering
                const isCenteredHorizontally = Math.abs(centerX - draggableCenterX) < tolerance;
                const isCenteredVertically = Math.abs(centerY - draggableCenterY) < tolerance;

                handleIsCentered(isCenteredHorizontally, isCenteredVertically)
                // setPos(newPos);

                let data = { y_position: newPos.y, x_position: newPos.x }
                handleImageMessageListContextChange(mediaName, index, data)


                e.stopPropagation();
                e.preventDefault();
            }
        };


        const onTouchMove = (e) => {
            e.stopPropagation()
            e.preventDefault();

            if (e.touches.length > 0) {
                const parentRect = ref.current.parentNode.getBoundingClientRect();
                const pos = ref.current.getBoundingClientRect();
                const touch = e.touches[0];
                const x = touch.clientX
                const y = touch.clientY
                if (resizing) {
                    if (!resizeStart) {

                        setResizeStart({ x: x, y: y });
                        return;
                    }

                    const deltaX = x - resizeStart.x;
                    const deltaY = y - resizeStart.y;

                    let newWidth = respData['image_types'][mediaName]['image_messages'][index].width + deltaX;
                    let newHeight = respData['image_types'][mediaName]['image_messages'][index].height + deltaY;


                    const offsetX = pos.left - parentRect.left;
                    const offsetY = pos.top - parentRect.top;

                    newWidth = Math.min(newWidth, parentRect.width - offsetX);
                    newHeight = Math.min(newHeight, parentRect.height - offsetY);

                    newWidth = Math.max(newWidth, 50); // minimum width
                    newHeight = Math.max(newHeight, 50); // minimum height



                    // newWidth = Math.min(newWidth, parentRect.width - respData['image_types'][mediaName]['image_messages'][index]['x_position']);
                    // newHeight = Math.min(newHeight, parentRect.height - pos.y);

                    // newWidth = Math.max(newWidth, 50); // minimum width
                    // newHeight = Math.max(newHeight, 50); // minimum height

                    // setSize({ width: newWidth, height: newHeight });
                    handleImageMessageListContextChange(mediaName, index, { width: newWidth, height: newHeight })
                    setResizeStart({ x: x, y: y });
                }
                else if (dragging) {

                    const newLeft = x - rel.x - parentRect.left;
                    const newTop = y - rel.y - parentRect.top;



                    const newPos = {
                        x: Math.max(0, Math.min(newLeft, parentRect.width - ref.current.offsetWidth)) / parentRect.width * 100,
                        y: Math.max(0, Math.min(newTop, parentRect.height - ref.current.offsetHeight)) / parentRect.height * 100
                    };



                    // setPos(newPos);
                    // e.stopPropagation();
                    // e.preventDefault();


                    const centerX = 50; // Center of the parent container in percentage
                    const centerY = 50; // Center of the parent container in percentage

                    // Calculate the draggable element's center position in percentage
                    const draggableCenterX = newPos.x + ((ref.current.offsetWidth / parentRect.width) * 50);
                    const draggableCenterY = newPos.y + ((ref.current.offsetHeight / parentRect.height) * 50);

                    // Check if centered within tolerance
                    const tolerance = .5; // Percentage tolerance for centering
                    const isCenteredHorizontally = Math.abs(centerX - draggableCenterX) < tolerance;
                    const isCenteredVertically = Math.abs(centerY - draggableCenterY) < tolerance;

                    handleIsCentered(isCenteredHorizontally, isCenteredVertically)



                    let data = { y_position: newPos.y, x_position: newPos.x }
                    handleImageMessageListContextChange(mediaName, index, data)


                }

            }

        };

        const handleTouchEnd = (e) => {
            e.preventDefault();
            if (e.changedTouches.length > 0) {
                const touch = e.changedTouches[0];
                console.log(`Touch end at: ${touch.clientX}, ${touch.clientY}`);
                handleIsDraggingChange(false);
                setDragging(false);
                setResizing(false);
                setResizeStart(null);
                // handleImageHover()


            }
        }

        const onMouseUp = () => {
            handleIsDraggingChange(false);
            setDragging(false);
            setResizing(false);
            setResizeStart(null);

        };

        if (dragging || resizing) {
            document.addEventListener('mousemove', onMouseMove);
            document.addEventListener('touchmove', onTouchMove);
            document.addEventListener('mouseup', onMouseUp);
            document.addEventListener('touchend', handleTouchEnd);
        } else {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('touchmove', onTouchMove);
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('touchend', handleTouchEnd);
        }

        return () => {
            document.removeEventListener('mousemove', onMouseMove);
            document.removeEventListener('mouseup', onMouseUp);
            document.removeEventListener('touchmove', onTouchMove);
            document.removeEventListener('touchend', handleTouchEnd);
        };
    }, [dragging, resizing, rel, resizeStart]);
    // }, [dragging, resizing, rel, resizeStart, pos, size]);

    const onMouseDown = (e) => {

        if (e.button !== 0) return;


        console.log(e, 'WHERE')

        const pos = ref.current.getBoundingClientRect();
        const mouseX = e.clientX - pos.left;
        const mouseY = e.clientY - pos.top;
        const resizeIconX = pos.width - 20; // 20px from the right edge
        const resizeIconY = pos.height - 20; // 20px from the bottom edge



        if (mouseX >= resizeIconX && mouseY >= resizeIconY) {
            setResizing(true);
            setResizeStart({ x: e.clientX, y: e.clientY });
        } else {
            handleIsDraggingChange(true);
            setDragging(true);
            setRel({
                x: e.clientX - pos.left,
                y: e.clientY - pos.top
            });
            e.stopPropagation();
            e.preventDefault();
        }
    };


    const handleTouchStart = (e) => {
        e.preventDefault();
        e.stopPropagation();
        if (e.touches.length > 0) {
            handleImageHover(true)
            const pos = ref.current.getBoundingClientRect();
            const touch = e.touches[0];
            const x = touch.clientX;
            const y = touch.clientY;
            const mouseX = x - pos.left;
            const mouseY = y - pos.top;


            const resizeIconX = pos.width - 20; // 20px from the right edge
            const resizeIconY = pos.height - 20; // 20px from the bottom edge



            if (mouseX >= resizeIconX && mouseY >= resizeIconY) {
                setResizing(true);
                setResizeStart({ x: x, y: y });
            } else {
                handleIsDraggingChange(true);
                setDragging(true);
                setRel({
                    x: x - pos.left,
                    y: y - pos.top
                });
                console.log({
                    x: x - pos.left,
                    y: y - pos.top
                })
                // e.stopPropagation();
                // e.preventDefault();
            }
            // e.stopPropagation();
            // e.preventDefault();
        }
    };


    // useEffect(() => {

    //     pos && handleImagePositionChange(index, { x_position: respData['image_types'][mediaName]['image_messages'][index]['x_position'], y_position: pos.y })
    // }, [pos])

    // useEffect(() => {
    //     dragging ? 
    //     return () => {
    //         cleanup
    //     }
    // }, [input])




    useEffect(() => {
        handleImagePositionChange(index, { 'height': respData['image_types'][mediaName]['image_messages'][index].height, 'width': respData['image_types'][mediaName]['image_messages'][index].width })
    }, [])

    // useEffect(() => {
    //     handleImagePositionChange(index, { 'height': respData['image_types'][mediaName]['image_messages'][index].height, 'width': respData['image_types'][mediaName]['image_messages'][index].width })
    // }, [size])





    return (
        <div
            ref={ref}
            onMouseDown={onMouseDown}
            onTouchStart={(e) => {
                // e.preventDefault();
                handleTouchStart(e)
            }

            }

            onClick={() => handleImageHover(true)}
            style={{
                position: 'absolute',
                touchAction: 'none',
                left: `${respData['image_types'][mediaName]['image_messages'][index]['x_position']}%`,
                top: `${respData['image_types'][mediaName]['image_messages'][index]['y_position']}%`,
                cursor: dragging ? 'move' : resizing ? 'nwse-resize' : 'default',
                wordWrap: 'break-word',
                maxWidth: '100%',
                borderRadius: respData['image_types'][mediaName]['image_messages'][index]['rounded_border'] === true ? '5px' : '0px',
                backgroundColor: respData['image_types'][mediaName]['image_messages'][index]['background_color'],
                // minWidth: 'fit-content',
                height: `${respData['image_types'][mediaName]['image_messages'][index].height}px`,
                width: `${respData['image_types'][mediaName]['image_messages'][index].width}px`,
                // border: '1px solid black',
                boxSizing: 'border-box',
                zIndex: zIndex,
                transform: `rotate(${angle}deg)`,
                alignContent: 'center',

            }}
        >

            {children}
            <div
                style={{
                    position: 'absolute',
                    bottom: '-10px',
                    right: '-10px',
                    width: '20px',
                    height: '20px',
                    cursor: 'nwse-resize',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',

                }}
                onMouseDown={(e) => {

                    e.stopPropagation();
                    onMouseDown(e);
                }}
                onTouchStart={(e) => {

                    // e.preventDefault();
                    handleTouchStart(e);
                }

                }
            >
                {imageHover && !disabled &&
                    <IoResize style={{
                        transform: "rotate(90deg)",
                        color: "black",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        // boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                        display: 'flex',
                        width: '20px',
                        position: 'relative',
                        fontWeight: '800',
                        cursor: 'nwse-resize'
                    }}
                        size={60} />

                }
            </div>

        </div>
    );
}

export default Draggable






